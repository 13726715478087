import {FixationControl} from "../../backend/api/interfaces/custom-datatypes/FixationControl";
import {IntervalTime} from "../../backend/api/interfaces/custom-datatypes/IntervalTime";
import {Comment} from "../../backend/api/interfaces/data/Comment";
import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {NavigateOptions, useMatch, useLocation} from "@reach/router";
import {PrivatePatientComponentProps} from "../PrivatePatientRoute";
import queryString from "query-string"
import {z} from "zod";
import {navigate} from "gatsby";
import EyePreview from "./EyePreview";
import {DefaultConfigurationSteps} from "./configuration";
import ExecuteExaminationPage, {settingsEyeToEyeMap} from "./ExecuteExaminationPage";
import FinishedExaminationPage from "./FinishedExaminationPage";
import {AudioPlayerFile, useAudioPlayerState} from "@oculus/component-library";
import {useAppState} from "../../globalAppState";
import {AbsoluteLossStaticDone} from "../../backend/websocket/interfaces/messages/AbsoluteLossStaticDone";
import {SupraThresholdStaticDone} from "../../backend/websocket/interfaces/messages/SupraThresholdStaticDone";
import {ThresholdStaticDone} from "../../backend/websocket/interfaces/messages/ThresholdStaticDone";
import {putVisualfieldLimits, putMeasurementComments, putMeasurementExaminationInfo} from "../../backend/api/Calls";
import NotFoundPage from "../../pages/404";
import FollowUpExaminationPage from "./FollowUpExaminationPage";
import {Position} from "../../backend/api/interfaces/data/Position";
import {IsoptereModesControllerState, useIsoptereModesControllerState} from "./manualKinetic/IsoptereModesController";
import {QualityScore} from "../../backend/api/interfaces/data/QualityScore";
import {VisualfieldLimits} from "../../backend/api/interfaces/data/VisualfieldLimits";
import {EyeRef} from "../../backend/api/interfaces/data/EyeRef";
import ExampleExplanationAudio1 from "../../audio/Erklaerung_Teil_I.mp3";
import ExampleExplanationAudio2 from "../../audio/Erklaerung_Teil_II.mp3";
import ExampleRobotAudio from "../../audio/robot.mp3";
import {useTranslation} from "@oculus/component-library";

/** Zod schema for boolean type passed in Query-Parameter */
const BooleanSchema = z.union([z.boolean(), z.enum(["true", "false"]).transform((v) => v === "true")]);
/** Zod schema for measurement settings passed in Query-Parameter */
export const ExaminationSettingsSchema = z.object({
    /* For GET-Parameter step is 1...*, for internal use it is converted to 0...* */
    step: z.coerce.number().int().positive().default(1).transform((v) => Math.min(v, DefaultConfigurationSteps.length)),
    /* For GET-Parameter unlockedStep is 1...*, for internal use it is converted to 0...* */
    unlockedStep: z.coerce.number().int().positive().default(1).transform((v) => Math.min(v, DefaultConfigurationSteps.length)),
    eye: z.optional(z.enum(["left", "right", "both"])),
    program: z.optional(z.string()),
    fixation: z.optional(z.nativeEnum(FixationControl)),
    speed: z.optional(z.nativeEnum(IntervalTime)),
    lensSphere: z.optional(z.coerce.number().min(-31.75).max(31.75)),
    lensCylinder: z.optional(z.coerce.number().min(-31.75).max(31.75)),
    lensAxialLength: z.optional(z.coerce.number().int().min(0).max(179)),
    lensCorrection: z.optional(BooleanSchema),
    patientCentered: z.optional(BooleanSchema),
    distanceSphere: z.optional(z.coerce.number().min(-31.75).max(31.75)),
    distanceCylinder: z.optional(z.coerce.number().min(-31.75).max(31.75)),
    distanceAxialLength: z.optional(z.coerce.number().int().min(0).max(179)),
    distanceCorrection: z.optional(BooleanSchema),
    secondExamination: z.optional(BooleanSchema),
    manualKinetic: z.optional(BooleanSchema),
    isTestRun: z.optional(BooleanSchema),
    startValueLensAxialLength: z.optional(z.coerce.number().int().min(0).max(179)),
    startValueLensSphere: z.optional(z.coerce.number().min(-31.75).max(31.75)),
    startValueLensCylinder: z.optional(z.coerce.number().min(-31.75).max(31.75)),
    followUpCurrentDate: z.optional(z.string()),
    followUpDate: z.optional(z.string()),
    followUpEye: z.optional(z.enum(["left", "right", "both"])),
    followUpPoints: z.optional(z.string().regex(/^(?:-?\d+(?:\.\d+)?~-?\d+(?:\.\d+)?(?:_-?\d+(?:\.\d+)?~-?\d+(?:\.\d+)?)*)?$/)),
    // Union type to handle both numeric values and the string "null" from URL parameters
    followUpIndex: z.optional(z.union([
        z.coerce.number(), // converts string numbers to actual numbers
        z.literal("null").transform(() => null) // handles the string "null" and converts it to actual null
    ])),

});

export function serializeFollowUpPoints(points: Position[]): NonNullable<ExaminationSettings["followUpPoints"]> {
    return points.map(({x, y}) => `${x}~${y}`).join("_");
}

export function parseFollowUpPoints(followUpPoints: ExaminationSettings["followUpPoints"]): Position[] {
    return !followUpPoints ? [] : followUpPoints.split("_").map((s) => {
        const [x, y] = s.split("~");
        return {x: Number.parseFloat(x), y: Number.parseFloat(y)};
    });
}

/**
 * The settings type utilized for pre-measurement configuration
 * and subsequently during the actual measurement process.
 */
export type ExaminationSettings = z.infer<typeof ExaminationSettingsSchema>;

/**
 * Navigate with settings in Query-Parameter.
 *
 * @param to - The destination URL.
 * @param settings - The settings which will be converted to query parameters.
 * @param options - Additional navigation options.
 *
 * @example
 * To stay on the same page while updating the query parameters,
 * use the `replace` option to avoid adding to the history stack,
 * and `disableScrollUpdate` to maintain the current page scroll position:
 * ```typescript
 * navigateWithSettings("", settings, { replace: true, state: { disableScrollUpdate: true } });
 * ```
 */
export function navigateWithSettings(to: string, settings: ExaminationSettings, options?: NavigateOptions<{}>) {
    const toUrl = `${to}?${queryString.stringify(ExaminationSettingsSchema.parse({
        ...settings,
        step: settings.step + 1,
        unlockedStep: Math.min(Math.max(settings.unlockedStep, settings.step) + 1, DefaultConfigurationSteps.length - 1)
    }))}`;
    return navigate(toUrl, options);
}

/**
 * This union brings together all message types that embody the measurement results.
 * These results are suitable for visualization in the `InvestigationMap` component.
 */
export type ExaminationMeasurement = AbsoluteLossStaticDone | SupraThresholdStaticDone | ThresholdStaticDone;

/** Interface of the properties provided to the components that manage the new examination. */
export interface ExaminationComponentProps extends Omit<PrivatePatientComponentProps, "deferredPopup"> {
    /** Element for rendering of the eye camera image */
    eyePreview: ReactNode;

    /** Measurement settings */
    settings: ExaminationSettings;

    /** Data points gathered during the course of the measurement */
    measurements: ExaminationMeasurement[];

    /** Function for updating the state of measurement results */
    setMeasurements: (setter: (prevState: ExaminationMeasurement[]) => ExaminationMeasurement[]) => void;

    /** Comments and logs to be displayed */
    comments: Comment[];

    /**
     * Setter for comments
     *
     * @param comments - The updated comments
     * @param skipBackendUpdate - If true, the comments update is not sent to the backend API
     * @returns A Promise; as long as the Promise is unresolved, the component displays a loading indicator.
     */
    setComments: (comments: Comment[], skipBackendUpdate?: boolean) => Promise<void>;

    /** State for {@link useIsoptereModesControllerState} */
    isoptereModesControllerState: IsoptereModesControllerState;

    /** Quality score information for the current measurement */
    qualityScore: QualityScore | null;

    /** Setter for `qualityScore` */
    setQualityScore: (qualityScore: QualityScore | null) => void;

    /** Examination info text for the current measurement */
    examinationInfo: string;

    /**
     * Setter for `examinationInfo`
     *
     * @param info - The updated examination info
     * @param skipBackendUpdate - If true, the examination info update is not sent to the backend API
     * @returns A Promise; as long as the Promise is unresolved, the component displays a loading indicator.
     */
    setExaminationInfo: (info: string, skipBackendUpdate?: boolean) => Promise<void>;

    /** Date and time of the examination (ISO 8061 format) */
    examinationDateTime: string;

    /** Setter for `examinationDateTime` */
    setExaminationDateTime: (dateTime: string) => void;

    /** VisualfieldLimits */
    visualFieldLimit: VisualfieldLimits | null;

    /** Setter for `visualFieldLimit` */
    setVisualFieldLimit: (visualFieldLimit: VisualfieldLimits) => void;
}

export function tryParseExaminationSettings(search: string): ExaminationSettings {
    let settings: ExaminationSettings = {step: 0, unlockedStep: 0};
    try {
        settings = ExaminationSettingsSchema.parse(queryString.parse(search));
        settings = {
            ...settings,
            step: settings.step - 1,
            unlockedStep: Math.max(settings.unlockedStep, settings.step) - 1,
        };
    } catch (e) {
        console.error(e);
    }
    return settings;
}

/**
 * This component manages the full lifecycle of a new measurement using dedicated sub-components for each step.
 *
 * It begins by facilitating the configuration of the measurement, proceeds to direct its execution,
 * and ultimately displays the result.
 *
 * @component
 * {@link PrivatePatientComponentProps}
 */
const Examination: React.FC<PrivatePatientComponentProps> = ({...props}) => {
    const {t, i18n} = useTranslation();
    const languageItems = Object.keys(i18n.options.resources).map((lang) => ({
        key: t(`languages.${lang}`, lang),
        lang,
    }));
    const audioFilesMap: Record<string, { file1: string, file2: string }> = {
        "de-DE": {
            file1: ExampleExplanationAudio1,
            file2: ExampleExplanationAudio2
        },
        "en-US": {
            file1: ExampleRobotAudio,
            file2: ExampleRobotAudio
        },
        "en-GB": {
            file1: ExampleRobotAudio,
            file2: ExampleRobotAudio
        }
    };


    /** The files for the first part of the audio explanation. */
    const audioExplanation1Files: AudioPlayerFile[] = languageItems.map((item) => ({
        key: item.key,
        url: audioFilesMap[item.lang].file1 || ExampleExplanationAudio1,
    }));


    /** The files for the second part of the audio explanation. */
    const audioExplanation2Files: AudioPlayerFile[] = languageItems.map((item) => ({
        key: item.key,
        url: audioFilesMap[item.lang].file2 || ExampleExplanationAudio2,
    }));


    const location = useLocation();
    const settings = tryParseExaminationSettings(location.search);
    const [_, setAppState] = useAppState();
    useEffect(() => {
        setAppState((old) => ({...old, eye: settings.eye}));
    }, [settings.eye]);
    const setSettings = (settings: ExaminationSettings) => navigateWithSettings("", settings, {
            replace: true,
            state: {disableScrollUpdate: true}
        })
    const [measurements, setMeasurements] = useState<ExaminationMeasurement[]>([]);
    const [comments, realSetComments] = useState<Comment[]>([]);
    const setComments: ExaminationComponentProps["setComments"] = useCallback(async (comments, skipBackendUpdate = false) => {
        if (!skipBackendUpdate) {
            await putMeasurementComments({comments});
        }
        realSetComments(comments);
    }, [realSetComments]);
    const isoptereModesControllerState = useIsoptereModesControllerState();
    const [qualityScore, setQualityScore] = useState<QualityScore | null>(null);
    const [examinationInfo, realSetExaminationInfo] = useState("");
    const setExaminationInfo: ExaminationComponentProps["setExaminationInfo"] = useCallback(async (examinationInfo, skipBackendUpdate = false) => {
        if (!skipBackendUpdate) {
            await putMeasurementExaminationInfo({examinationInfo});
        }
        realSetExaminationInfo(examinationInfo);
    }, [realSetExaminationInfo])
    const [examinationDateTime, setExaminationDateTime] = useState("")
    const [visualFieldLimit, setVisualFieldLimit] = useState<VisualfieldLimits | null>(null);

    const eyePreview = <EyePreview cameraUri={props.cameraUri}/>;
    const componentProps: ExaminationComponentProps = {
        ...props,
        eyePreview,
        settings,
        measurements,
        setMeasurements,
        comments,
        setComments,
        isoptereModesControllerState,
        qualityScore,
        setQualityScore,
        examinationInfo,
        setExaminationInfo,
        examinationDateTime,
        setExaminationDateTime,
        visualFieldLimit,
        setVisualFieldLimit
    };

    useEffect(() => {
        if (!settings.eye) {
            setVisualFieldLimit(null);
            return;
        }
        const eyeRef: EyeRef = {eye: settingsEyeToEyeMap[settings.eye]}
        putVisualfieldLimits(eyeRef).then(({data}) => setVisualFieldLimit(data));
    }, [settings.eye]);

    const isFinished = useMatch("finished");
    const isExecution = useMatch("execution");
    const isConfiguration = useMatch("configuration");
    const isFollowUp = useMatch("follow-up");
    const audioExplanation1 = useAudioPlayerState(audioExplanation1Files);
    const audioExplanation2 = useAudioPlayerState(audioExplanation2Files);
    const currentLanguage = i18n.language;
    function getAudioFile(audioFilesMap: Record<string, { file1: string, file2: string }>, fallback: string, fileKey: keyof { file1: string, file2: string }) {
        return audioFilesMap[currentLanguage]?.[fileKey] || fallback;
    }
    useEffect(() => {
        const selectedAudio1: AudioPlayerFile = {
            key: t("languages."+currentLanguage),
            url: getAudioFile(audioFilesMap, ExampleExplanationAudio1, "file1")
        };
        const selectedAudio2: AudioPlayerFile = {
            key: t("languages."+currentLanguage),
            url: getAudioFile(audioFilesMap, ExampleExplanationAudio2, "file2")
        };
        audioExplanation1.setSelectedFile(selectedAudio1);
        audioExplanation2.setSelectedFile(selectedAudio2);

    }, [i18n.language]);


    if (!isConfiguration) {
        // HACK: Reset configuration audio players, because we don't use a dedicated parent component for configuration pages
        audioExplanation1.reset();
        audioExplanation2.reset();
    }

    // Check if settings are valid and go to configuration if not
    //if (!isConfiguration && DefaultConfigurationSteps.map((step) => step.checkSettings(componentProps)).find((b) => !b) === false) {
    //  navigateWithSettings(`../configuration`, settings);
    //  return null;
    //}
    if (isExecution) {
        return <ExecuteExaminationPage {...componentProps} />;
    }
    if (isFollowUp) {
        return <FollowUpExaminationPage {...componentProps} />
    }
    if (isFinished) {
        return (<>
            <FinishedExaminationPage {...componentProps} />
        </>);
    }
    if (isConfiguration) {
        return (<>
            {React.createElement(DefaultConfigurationSteps[settings.step].component,
                {
                    ...componentProps,
                    setSettings,
                    steps: DefaultConfigurationSteps,
                    key: settings.step,
                    audioExplanation1,
                    audioExplanation2
                })
            }
        </>);
    }
    return <NotFoundPage/>;
}

export default Examination;