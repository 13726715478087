import Layout, {LayoutProps} from "../Layout";
import React, {PropsWithChildren, ReactNode} from "react";
import {ScrollPane} from "@oculus/component-library";

/**
 * ConfigurationLayoutProps is an interface for defining the properties
 * used in a configuration layout component. It extends PropsWithChildren
 * to support children elements and inherits the "brandSidebarDisabled"
 * property from LayoutProps using the Pick utility type.
 */
export interface ConfigurationLayoutProps extends PropsWithChildren, Pick<LayoutProps, "brandSidebarDisabled"> {
    sidepanelCamera?: ReactNode,
    sidepanel?: ReactNode,
    sidepanelButtons?: ReactNode,
}

/**
 * A React Functional Component that provides a customizable layout for a configuration interface. This layout includes sections for sidepanels, buttons, a camera view, and a main content area, making it adaptable for different UI configurations.
 */
const ConfigurationLayout: React.FC<ConfigurationLayoutProps> = ({
                                                                     children,
                                                                     sidepanelCamera = null,
                                                                     sidepanel = null,
                                                                     sidepanelButtons = null,
                                                                     brandSidebarDisabled,
                                                                 }) => {
    return (
        <Layout brandSidebarDisabled={brandSidebarDisabled}>
            <div className="w-full h-full flex flex-col gap-2">
                <div className="flex-1 relative">
                    <ScrollPane className="absolute inset-0">
                        <div
                            className="flex flex-col xl:flex-row gap-2 h-full xl:absolute xl:inset-0">
                            <div
                                className="xl:order-10 flex bg-white flex-row xl:bg-transparent xl:flex-col gap-2 w-[473px] min-h-150 xl:min-h-[auto]">
                                {sidepanelCamera !== null && (
                                    <div
                                        className="relative bg-white p-4 gap-4 grow-0 flex flex-col min-h-65 items-center justify-center ">
                                        {sidepanelCamera}
                                    </div>
                                )}
                                <div className="flex flex-1 flex-col gap-2">
                                    <ScrollPane
                                        className={`bg-white flex-1 !p-4`}>
                                        <div className="flex flex-col gap-4 min-h-full relative">
                                            {sidepanel}
                                        </div>
                                    </ScrollPane>
                                    {sidepanelButtons !== null && (
                                        <div
                                            className="flex-row gap-2 relative hidden xl:flex [&>*]:flex-1 min-h-14">
                                            {sidepanelButtons}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className="xl:flex-[2_2_0%] p-8 grid grid-cols-12 grid-rows-[auto_1fr] bg-white relative min-h-full">
                                {children}
                            </div>
                        </div>
                    </ScrollPane>
                </div>
                {sidepanelButtons !== null && (
                    <div className="flex-row gap-2 relative flex xl:hidden [&>*]:flex-1 min-h-14">
                        {sidepanelButtons}
                    </div>
                )}
            </div>
        </Layout>
    )
};

export default ConfigurationLayout;