import {
    CommentCategory,
    CommentsSection,
    CommentsSectionEntry,
    CommentType,
    formatTimePretty,
    NotificationState,
    formatTimeSince, useTranslation,
} from "@oculus/component-library";
import React, {useEffect, useMemo, useReducer} from "react";
import {Comment} from "../../backend/api/interfaces/data/Comment";
import {CombinedDateFormat} from "../../backend/api/interfaces/data/DeviceSettings";

/** `MeasurementComments` component properties */
interface MeasurementComments {
    /**
     * Comments to display in the component.
     *
     * @see ExaminationComments
     */
    comments?: Comment[],
    /** An optional Boolean property, `isTestRun`, used to indicate if the current * execution context corresponds to a test run */
    isTestRun?: boolean,
    /** An optional Boolean property, to disable the Comment button */
    buttonDisabled?:boolean
    /** An optional Boolean property, `isSave`, used to show or hide the Buttons inside CommentsSection */
    isSave?: boolean,
    /**
     * Callback invoked when comments change.
     *
     * @param comments - Updated comments
     * @returns A Promise; as long as the Promise is unresolved, the component displays a loading indicator.
     */
    onCommentsChange?: (comments: Comment[]) => Promise<void>,

    /** The format in which the dates should be displayed. */
    dateFormat?: CombinedDateFormat;
}

/**
 * This component is responsible for rendering comments and log entries related to a measurement.
 *
 * It provides functionality to modify and delete these comments as needed.
 *
 * @component
 * {@link MeasurementComments}
 */
const MeasurementComments: React.FC<MeasurementComments> = ({
                                                                isTestRun,
                                                                comments = [],
                                                                onCommentsChange,
                                                                isSave = false,
                                                                dateFormat,
                                                                buttonDisabled,
                                                            }) => {
    const {t} = useTranslation();
    const [now, updateNow] = useReducer(() => new Date(), undefined, () => new Date());
    // Rerender every minute to update relative clock
    useEffect(() => {
        const id = setInterval(updateNow, 60000);
        return () => clearInterval(id);
    }, []);
    const convertedComments = useMemo(() =>
        comments
            .sort(({timestamp: a}, {timestamp: b}) => new Date(a).valueOf() - new Date(b).valueOf())
            .map((comment, index) => ({
                content: comment.text,
                infoText: formatTimeSince(new Date(comment.timestamp), now),
                longInfoText: formatTimePretty(new Date(comment.timestamp), dateFormat, now),
                state: NotificationState[comment.type === CommentType.User ? NotificationState.Comment : comment.type],
                key: `${index}-${comment.category}-${comment.type}-${comment.timestamp}-${comment.text}`,
                headline: {
                    [CommentType.User]: t("comment"),
                    [CommentType.Warning]: t("warning"),
                    [CommentType.Confirmation]: t("confirmation"),
                    [CommentType.Notification]: t("notification")
                }[comment.type],
                stepText: "",
                origComment: comment,
            } satisfies CommentsSectionEntry<string> & { origComment: Comment })), [comments, now]);

    return (
        <CommentsSection
            withCreateInteraction={!isSave}
            isTestRun={isTestRun}
            buttonDisabled={buttonDisabled}
            entries={convertedComments.length ? convertedComments : [{
                key: "",
                content: t("new_entries_will_appear_here"),
                headline: t("no_log_entries_available"),
                infoText: "",
                state: NotificationState.Notification,
                stepText: "",
            }]}
            onEditComment={onCommentsChange && ((key: string, text: string) => onCommentsChange(convertedComments.map(({
                                                                                                                           origComment,
                                                                                                                           key: oKey
                                                                                                                       }) => key === oKey ? {
                ...origComment,
                text,
                timestamp: new Date().toISOString()
            } : origComment)))}
            onDeleteComment={onCommentsChange && ((key: string) => onCommentsChange(convertedComments.filter(({key: oKey}) => key !== oKey).map(({origComment}) => origComment)))}
            onCreateComment={onCommentsChange && ((text: string) => onCommentsChange([...comments, {
                type: CommentType.User,
                category: CommentCategory.Measurement,
                text,
                timestamp: new Date().toISOString()
            }]))}
        />
    );
};

export default MeasurementComments;