import React from "react";
import {
    BodyText, BodyTextType,
    Dialog,
    DialogType,
    ProgressList,
    ProgressListStates,
    TailwindStandardWidth,
    useTranslation
} from "@oculus/component-library";
import {navigate} from "gatsby";
import {exitApp} from "../../../helper";
import {ErrorMessage} from "../../../backend/api/interfaces/data/ErrorMessage";

/*** Represents the properties for the Initialization Update Popup component.*/
interface InitializationUpdatePopupProps {
    /** Indicates whether the popup is visible. */
    show: boolean;

    /** Array of steps displayed in the popup. */
    steps: string[];

    /**
     * Represents the current step in the process, if any.
     * Can be a string, null, or undefined.
     */
    currentStep?: string | null;

    /**
     * Contains an error message related to the current step, if applicable.
     * Can be an `ErrorMessage` object, null, or undefined.
     */
    stepError?: ErrorMessage | null;

    /** Function to retry the initialization process. */
    retry: () => void;

    /**
     * Optional token identifying the patient.
     * Can be a string or undefined.
     */
    patientToken?: string;

    /**
     * Indicates if a device is connected, if relevant.
     * Can be a boolean or undefined.
     */
    deviceConnected?: boolean;

    /** Callback function triggered when the popup is closed. */
    onClose: () => void;
}

/**
 * Representing the Initialization Update Popup dialog.
 * This component displays a dialog with progress steps and appropriate action buttons
 * depending on the current state of the initialization process. It handles user interactions
 * such as retrying the initialization, navigating to settings, exiting the software,
 * or proceeding to a specific evaluation page.
 */
const InitializationUpdatePopup: React.FC<InitializationUpdatePopupProps> = (props) => {
    const {t} = useTranslation();
    const stepsArray = props.steps ?? [];
    const deviceConnected = props.deviceConnected ?? true;
    const dialogButtonsErrorMessage = [
        {
            label: t("new_initialization"),
            primary: true,
            onClick: () => {
                props.onClose();
                props.retry();
            },
        },
        {
            label: t("navigate_to_settings_button"),
            onClick: () => {
                props.onClose();
                navigate(`/settings/general/`);
            },
        },
        {
            label: t("exit_software"),
            onClick: () => {
                props.onClose();
                exitApp();
            },
        },
    ];

    const dialogButtonsDeviceNotConnected = [
        {
            label: t("navigate_to_evaluation_button"),
            onClick: () => {
                props.onClose();
                navigate(`/patients/${props.patientToken}/examinations`);
            },
        },
        {
            label: t("navigate_to_settings_button"),
            onClick: () => {
                props.onClose();
                navigate(`/settings/general/`);
            },
        },
        {
            label: t("exit_software"),
            onClick: () => {
                props.onClose();
                exitApp();
            },
        },
    ];

    const errorMessage = props.stepError;
    const currentStepIndex = stepsArray.indexOf(props.currentStep || "");
    const steps = stepsArray.map((untranslated, index) => {
        const text: string = t("Initialization_step." + untranslated) ?? "";
        if ((!props.currentStep && index === 0) || (index === currentStepIndex + 1)) {
            return errorMessage !== null ? {text, state: ProgressListStates.ERROR} : {
                text,
                state: ProgressListStates.INPROGRESS
            }
        } else if (index === currentStepIndex) {
            return {text, state: ProgressListStates.COMPLETED};
        } else if (index < currentStepIndex) {
            return {text, state: ProgressListStates.COMPLETED};
        }
        return {text, state: ProgressListStates.PENDING};
    });
    return (<Dialog
            buttons={errorMessage ? dialogButtonsErrorMessage : deviceConnected ? [] : dialogButtonsDeviceNotConnected}
            label={""}
            show={props.show}
            showCloseButton={false}
            type={DialogType.Info}
            width={TailwindStandardWidth.W152}
            headline={t("Initialization_update_popup_headline")}
            text={
                <>
                    <BodyText text={t("Initialization_update_popup_text")} type={BodyTextType.B1Regular}
                              furtherClasses={"!mb-0"}/>
                    <br/>
                    <ProgressList steps={steps} stepError={props.stepError?.code + ": " + props.stepError?.message}/>
                </> satisfies React.ReactNode as unknown as string /* HACK: "text" supports only string */}

        />
    )
};

export default InitializationUpdatePopup;