import React from "react";
import {
    Dialog,
    TailwindStandardWidth, useTranslation,
} from "@oculus/component-library";
import {LampColorFilterInsertPopupProps, lampColorFilterPopupRequestFilterToString} from "./LampColorFilterInsertPopup";

/** `LampColorFilterRemovePopup` component properties */
export type LampColorFilterRemovePopupProps = LampColorFilterInsertPopupProps;

/**
 * A popup component for handling removal of inserted filter.
 *
 * @component
 * {@link LampColorFilterRemovePopupProps}
 *
 * @see LampColorFilterInsertPopup
 */
const LampColorFilterRemovePopup: React.FC<LampColorFilterRemovePopupProps> = ({
                                                                                   filter,
                                                                                   onResponse,
                                                                                   show = true,
                                                                               }) => {
    const {t} = useTranslation();
    return <Dialog
        label={t("hint")}
        headline={t("remove_color_filter")}
        text={`${t("color_filter_dialog_text_1")} ${lampColorFilterPopupRequestFilterToString(filter, true, t)} ${t("color_filter_dialog_text_2_2")}`}
        width={TailwindStandardWidth.W200}
        showCloseButton={false}
        show={show}
        buttons={[
            {
                label: t("button_continue_2"),
                primary: true,
                onClick: onResponse,
            },
        ]}
    />
};

export default LampColorFilterRemovePopup;