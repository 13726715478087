import React from "react";
import {
    BodyText,
    BodyTextType,
    Dialog,
    DialogType,
    TailwindStandardWidth, useTranslation,
} from "@oculus/component-library";
import {ErrorMessage} from "../../../backend/api/interfaces/data/ErrorMessage";
import {errorMessageToString} from "../../../helper";
import {BaseCriticalErrorPopupProps} from "./index";
import {navigate} from "gatsby";

/** `CriticalInitializationErrorPopup` component properties */
export interface CriticalInitializationErrorPopupProps extends BaseCriticalErrorPopupProps {
    /** The error message to be displayed to the user. */
    error?: ErrorMessage,
    onClose: () => void
}

// TODO
const expectedErrorMessage = `%0`;

/**
 * A popup component for handling special {@link InitializationError} message.
 *
 * @component
 * {@link CriticalInitializationErrorPopupProps}
 */
const CriticalInitializationErrorPopup: React.FC<CriticalInitializationErrorPopupProps> = ({
                                                                                               error,
                                                                                               onExit,
                                                                                               onSwitchToEvaluation,
                                                                                               show = true,
                                                                                               onClose
                                                                                           }) => {
    const {t} = useTranslation();
    return <Dialog
        label={""}
        headline={t("no_device_connected")}
        text={
            <>
                {t("no_device_connected_text")}{" "}
                {error?.message === expectedErrorMessage && error.arguments.length === 1 ? (
                    <>
                        {t("check_error_code")}{" "}
                        <BodyText
                            text={`${error.arguments[0].value}`}
                            type={BodyTextType.B1Bold}
                            furtherClasses={"inline"}
                        />{" "}
                        {t("refer_to_manual")}
                    </>
                ) : error && errorMessageToString(error)}
            </> satisfies React.ReactNode as unknown as string /* HACK: "text" supports only string */}
        type={DialogType.Info}
        width={TailwindStandardWidth.W200}
        showCloseButton={false}
        show={show}
        buttons={[
            {
                label: t("exit_software"),
                primary: true,
                onClick: onExit,
            },
            {
                label: t("navigate_to_settings_button"),
                onClick: () => {
                    void navigate(`/settings/general/`)
                    onClose();
                },
            },
            ...onSwitchToEvaluation ? [{
                label: t("navigate_to_evaluation_button"),
                onClick: () => {
                    onSwitchToEvaluation();
                },
            }] : [],
        ]}
    />
};

export default CriticalInitializationErrorPopup;