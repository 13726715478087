import {
    AudioPlayer,
    AudioPlayerType,
    BodyText,
    BodyTextType,
    Listing,
    ListingLevel, ExaminationPreviewList,
    ToggletipLabel,
    Toggletip,
    ToggletipSize,
    ToggletipPosition,
    formatDateTime,
    ListItem,
    Spinner,
    TailwindStandardSize,
} from "@oculus/component-library";
import React, {useEffect, useMemo, useState} from "react";
import { ConfigurationStepProps} from "./index";
import FooterNav from "./FooterNav";
import {ExaminationComponentProps} from "../index";
import {useEvaluationPreviewListState,} from "../../../helper";
import {getEvaluationPreviewList, putFollowUp} from "../../../backend/api/Calls";
import {ExaminationRef} from "../../../backend/api/interfaces/data/ExaminationRef";
import {LensCorrection} from "../../../backend/api/interfaces/data/LensCorrection";
import {makeExaminationPreviewItem, mapEyeToInvestigationMap} from "../../examinationResult/helper";
import ConfigurationLayout from "./ConfigurationLayout";
import {useTranslation} from "@oculus/component-library";


/** @see ConfigurationStep */
export function checkSettings({}: ExaminationComponentProps) {
    return true;
}

/** @see ConfigurationStep */
const FirstStep: React.FC<ConfigurationStepProps> = (props) => {
    const {eyePreview, audioExplanation1, audioExplanation2, settings, setSettings, deviceSettings} = props;
    const {evaluationPreviewList, setEvaluationPreviewList} = useEvaluationPreviewListState();
    const { t } = useTranslation();
    const listItems: ListItem[] = [
        {
            content: t("step_1_bullet_point_1"),
        },
        {
            content: t("step_1_bullet_point_2"),
        },
        {
            content: t("step_1_bullet_point_3"),
        },
        {
            content: t("step_1_bullet_point_4"),
        },
        {
            content: t("step_1_bullet_point_5"),
        },
        {
            content: t("step_1_bullet_point_6"),
        },
    ];

    useEffect(() => {
        if (!props.patient || !props.deviceInformation) {
            return;
        }
        if (evaluationPreviewList.patient !== props.patient) {
            setEvaluationPreviewList({patient: props.patient, requestPage: 1});
        }
        setEvaluationPreviewList((evaluationPreviewList) => {
            const {patient, requestPage, response} = evaluationPreviewList;
            const nextPage = (response?.pagination.currentPage ?? 0) + 1;
            if (patient && nextPage <= requestPage && nextPage <= (response?.pagination.totalPages ?? nextPage)) {
                getEvaluationPreviewList(nextPage).then(({data}) => setEvaluationPreviewList((prevState) => Object.is(prevState.patient, patient) && prevState.response?.pagination.currentPage === response?.pagination.currentPage ? {
                    ...prevState,
                    response: {
                        ...data,
                        previewItems: [...prevState.response?.previewItems ?? [], ...data.previewItems],
                    },
                } : prevState));
            }
            return evaluationPreviewList;
        });
    }, [props.patient, !props.deviceInformation, evaluationPreviewList.requestPage, evaluationPreviewList.response?.pagination.currentPage]);
    const examinationPreviewItems = useMemo(() => {
        return evaluationPreviewList.response?.previewItems
            ?.map(makeExaminationPreviewItem)
            ?.filter(item => item.examinationRef.programName !== "");
    }, [evaluationPreviewList.response?.previewItems]);
    const setPreviewSettings = (examRef: ExaminationRef) => {
        let date = undefined
        putFollowUp(examRef).then(({data}) => {
            date = formatDateTime(new Date(examRef.examinationDateTime), deviceSettings?.main.dateFormat, "/").replace(/:\d+$/, "");

            let sphere: number | undefined = undefined;
            let cylinder: number | undefined = undefined;
            let axis: number | undefined = undefined;

            if (data?.correction?.type === "Lens") {
                sphere = (data.correction as LensCorrection)?.sphere;
                cylinder = (data.correction as LensCorrection)?.cylinder;
                axis = (data.correction as LensCorrection)?.axis;

            }
            const selectedEye = mapEyeToInvestigationMap(data?.eye) as "left" | "right" | "both";
            setSettings({
                ...settings,
                eye: selectedEye,
                program: data.programRef?.uuid,
                speed: data.intervalTime,
                fixation: data.fixationControl,
                manualKinetic: data.measurementType !== "Static",
                lensCorrection: data?.correction?.type === "Lens",
                lensSphere: sphere,
                lensCylinder: cylinder,
                lensAxialLength: axis ? axis : 0,
                startValueLensSphere: sphere ? sphere : 0,
                startValueLensCylinder: cylinder ? cylinder : 0,
                startValueLensAxialLength: axis ? axis : 0,
                followUpEye: selectedEye,
                followUpDate: date,
                followUpCurrentDate: new Date().toISOString(),
                followUpIndex: examRef.index
            });
        })
    }
    const [examinationIndex, setExaminationIndex] = useState<(number | null)[]>(() => {
        return settings.followUpIndex !== undefined && settings.followUpIndex !== null ? [settings.followUpIndex] : [null];
    });

    // When the component is fully mounted, ensure the examinationIndex is properly set from settings
    useEffect(() => {
        // Only run once on component mount        
        if (settings.followUpIndex !== undefined && settings.followUpIndex !== null) {
            setExaminationIndex([settings.followUpIndex]);
        }
    }, []); // Empty dependency array - only run once on mount

    // Apply saved followUpIndex after variables are declared
    useEffect(() => {
        // If we have a followUpIndex in settings, make sure the UI reflects it once the preview list is loaded
        if (settings.followUpIndex !== undefined && settings.followUpIndex !== null && 
            evaluationPreviewList.response?.previewItems && 
            evaluationPreviewList.response.previewItems.length > 0) {
            setExaminationIndex([settings.followUpIndex]);
            
            // Find the matching exam reference
            const matchingExam = evaluationPreviewList.response.previewItems.find(
                item => item.examinationRef.index === settings.followUpIndex
            );

            // No need to call setPreviewSettings again if the selection matches what's already in settings
            if (matchingExam && !settings.followUpDate) {
                setPreviewSettings(matchingExam.examinationRef);
            }
        }
    }, [settings.followUpIndex, evaluationPreviewList.response?.previewItems, settings.followUpDate]);

    audioExplanation2.pause();
    return (
        <ConfigurationLayout
            headline={t("step_1_headline")}
            footer={<FooterNav {...props}/>}
            sidepanelCamera={eyePreview}
            sidepanel={
                <>
                    <ToggletipLabel
                        openedComponent={<Toggletip
                            content={t("step_1_follow_up_headline_toggle_tip_content")}
                            headline={t("step_1_follow_up_headline_toggle_tip_headline")}
                            size={ToggletipSize.Medium}
                            show={true}
                            arrowPosition={ToggletipPosition.TopRight}
                            useArrowPosition={true}
                        />}
                        iconSize={24}
                        label={<BodyText type={BodyTextType.B1Bold}
                                         text={t("step_1_follow_up_headline_1")}
                                         furtherClasses={"!mb-0 text-20px text-blue1"}/>}
                    />
                    {evaluationPreviewList.patient ?
                        <div
                            className="flex-1 relative min-h-80">
                            <div className="absolute inset-0 min-h-80 overflow-hidden">
                                <ExaminationPreviewList
                                    headline={t("step_1_follow_up_headline_2")}
                                    dateFormat={deviceSettings?.main.dateFormat}
                                    examinationIndex={examinationIndex ? examinationIndex : [null]}
                                    onChange={(examinationIndex) => {
                                        const examRef = evaluationPreviewList.response?.previewItems.find(item => item.examinationRef.index === examinationIndex[0])?.examinationRef
                                        if (examRef) {
                                            setExaminationIndex([examRef.index]);
                                            setPreviewSettings(examRef);
                                        } else {
                                            setSettings({
                                                // Only keep current step, but reset unlock progress
                                                step: settings.step,
                                                unlockedStep: 0,
                                                // Reset all exam-related settings
                                                eye: undefined,
                                                program: undefined,
                                                speed: undefined,
                                                fixation: undefined,
                                                manualKinetic: undefined,
                                                lensCorrection: undefined,
                                                lensSphere: undefined,
                                                lensCylinder: undefined, 
                                                lensAxialLength: undefined,
                                                startValueLensSphere: undefined,
                                                startValueLensCylinder: undefined,
                                                startValueLensAxialLength: undefined,
                                                followUpIndex: null,
                                                followUpDate: undefined,
                                                followUpEye: undefined,
                                                followUpCurrentDate: undefined
                                            })
                                            setExaminationIndex([null])
                                        }

                                    }}
                                    onRequestMore={() => setEvaluationPreviewList((prevState) => ({
                                        ...prevState,
                                        requestPage: (prevState.response?.pagination.currentPage ?? 0) + 1
                                    }))}
                                    previewItems={examinationPreviewItems}
                                    availabilityFilter={true}/>
                            </div>
                        </div>
                        :
                        <div className="mt-16 self-center">
                            <Spinner size={TailwindStandardSize.S32}/>
                        </div>
                    }
                </>
            }
        >
            <div className={`col-span-12`}>
                <BodyText
                    text={t("step_1_paragraph_1")}
                    type={BodyTextType.B1Regular}
                    furtherClasses="text-grey5 !mb-10"
                />
            </div>
            <div className={`col-span-12 mb-10 `}>
                <AudioPlayer
                    state={audioExplanation1}
                    title={t("audio_Explanation_1_title")}
                    type={AudioPlayerType.Default}
                />
            </div>
            <div className={`col-span-12`}>
                <BodyText
                    type={BodyTextType.B1Bold}
                    text={t("step_1_paragraph_2")}
                />
            </div>
            <div className={`col-span-12`}>
                <Listing items={listItems} renderAsLevel={ListingLevel.Level1}/>
            </div>
        </ConfigurationLayout>
    );
};
export default FirstStep;
