import {MessageType} from "../MessageType";
import {LightDensityClass} from "../../../api/interfaces/custom-datatypes/LightDensityClass";

export enum UnexpectedThresholdPopupResponseResponseType {
    Cancel = "Cancel",
    Continue = "Continue",
    Repeat = "Repeat",
}

export interface UnexpectedThresholdPopupResponse {
    type: MessageType.UnexpectedThresholdPopupResponse,
    responseType: UnexpectedThresholdPopupResponseResponseType,
    lightDensityClass?: LightDensityClass,
}