/**
 * The provides various functions for all HTTP routes specified in "Perimeter Device Backend REST-Protocol".
 */

import {del, get, put, request} from "./core/Api";
import {MeasurementConfigPayload} from "./interfaces/payload/MeasurementConfigPayload";
import {PatientPayload} from "./interfaces/payload/PatientPayload";
import {DeviceInformationResponse} from "./interfaces/response/DeviceInformationResponse";
import {InfoResponse} from "./interfaces/response/InfoResponse";
import {MeasurementStartResponse} from "./interfaces/response/MeasurementStartResponse";
import PatientResponse from "./interfaces/response/PatientResponse";
import {ProgramListResponse} from "./interfaces/response/ProgramListResponse";
import {SettingsResponse} from "./interfaces/response/SettingsResponse";
import {CameraSettingsResponse} from "./interfaces/response/CameraSettingsResponse";
import {CameraSettingsPayload} from "./interfaces/payload/CameraSettingsPayload";
import {ProgramPayload} from "./interfaces/payload/ProgramPayload";
import {ProgramRefListResponse} from "./interfaces/response/ProgramRefListResponse";
import {DicomSettingsPayload} from "./interfaces/payload/DicomSettingsPayload";
import {DeviceSettingsResponse} from "./interfaces/response/DeviceSettingsResponse";
import {DeviceSettingsPayload} from "./interfaces/payload/DeviceSettingsPayload";
import {DicomSettingsResponse} from "./interfaces/response/DicomSettingsResponse";
import {ComponentResponse} from "./interfaces/response/ComponentResponse";
import {ComponentRefPayload} from "./interfaces/payload/ComponentRefPayload";
import {PreviewListResponse} from "./interfaces/response/PreviewListResponse";
import {ExaminationRef} from "./interfaces/data/ExaminationRef";
import {ExaminationDataResponse} from "./interfaces/response/ExaminationDataResponse";
import {ExaminationCommentsRefPayload} from "./interfaces/payload/ExaminationCommentsRefPayload";
import {ExaminationInfoRef} from "./interfaces/data/ExaminationInfoRef";
import {Position} from "./interfaces/data/Position";
import {Arrow} from "./interfaces/data/Arrow";
import {ExaminationComments} from "./interfaces/data/ExaminationComments";
import {LensCorrection} from "./interfaces/data/LensCorrection";
import {LensCorrectionPayload} from "./interfaces/payload/LensCorrectionPayload";
import {IsopterManualConfig} from "./interfaces/data/IsopterManualConfig";
import {Isopter} from "./interfaces/data/Isopter";
import {IsopterCombineList} from "./interfaces/data/IsopterCombineList";
import {IsopterVectorRef} from "./interfaces/data/IsopterVectorRef";
import {IsopterRef} from "./interfaces/data/IsopterRef";
import {MotorPosition} from "./interfaces/data/MotorPosition";
import {ChinrestMove} from "./interfaces/data/ChinrestMove";
import {EyeRef} from "./interfaces/data/EyeRef";
import {VisualfieldLimits} from "./interfaces/data/VisualfieldLimits";
import {FrontendSettings} from "./interfaces/data/FrontendSettings";
import {ExaminationInfo} from "./interfaces/data/ExaminationInfo";
import {ViewRef} from "./interfaces/data/ViewRef";
import {View} from "./interfaces/data/View";
import {ViewType} from "./interfaces/custom-datatypes/ViewType";
import {Progression} from "./interfaces/data/Progression";
import {Component} from "./interfaces/data/Component";
import {DocpServerInfo} from "./interfaces/data/DocpServerInfo";
import {ScotomaPerimetryConfig} from "./interfaces/data/ScotomaPerimetryConfig";
import Patient from "./interfaces/data/Patient";
import {DicomExportConfigRef} from "./interfaces/data/DicomExportConfigRef";
import {DcmExportFilePaths} from "./interfaces/data/DcmExportFilePaths";
import {ProgramResourceFiles} from "./interfaces/data/ProgramResourceFiles";
import {MeasurementExportData} from "./interfaces/data/MeasurementExportData";
import {ExaminationLog} from "./interfaces/data/ExaminationLog";
import {ProgramFavorites} from "./interfaces/response/ProgramFavorites";
import {LicenseServerSettingsResponse} from "./interfaces/response/LicenseServerSettingsResponse";
import {LicenseServerSettingsPayload} from "./interfaces/payload/LicenseServerSettingsPayload";
import {LicensesResponse} from "./interfaces/response/LicensesResponse";
import {TrendGridRef} from "./interfaces/data/TrendGridRef";
import {MeasurementPositions} from "./interfaces/data/MeasurementPositions";
import {ErrorMessage} from "./interfaces/data/ErrorMessage";

/** Paths for the '/patient' API endpoints */
export enum PatientEndpoint {
    Base = "/patient",
    List = "/patient/list",
    FarCorrection = "/patient/farCorrection",
}

/** Paths for the '/info' API endpoints */
export enum InfoEndpoint {
    Base = "/info",
    Licences = "/info/licenses",
}

/** Paths for the '/device' API endpoints */
export enum DeviceEndpoint {
    Info = "/device/information",
    Connect = "/device/connect",
    IsConnected = "/device/isConnected",
    DocpUpdate = "/device/docp/update",
    DocpList = "/device/docp/list",
}

/** Paths for the '/settings' API endpoints */
export enum SettingsEndpoint {
    Base = "/settings",
    Save = "/settings/save",
    Camera = "/settings/camera",
    CameraPreview = "/settings/camera/preview",
    Device = "/settings/device",
    Dicom = "/settings/dicom",
    LicenseServer = "/settings/licenseServer",
    Frontend = "/settings/frontend",
    ProgramRefList = "/settings/programRef/list",
    ProgramRefListAll = "/settings/programRef/list/all",
    ProgramsAppend = "/settings/programs/append",
    ProgramsRemove = "/settings/programs/remove",
    ProgramsUpdate = "/settings/programs/update",
    ProgramsList = "/settings/programs/list",
    ProgramsListAll = "/settings/programs/list/all",
    ProgramsResourceFiles = "/settings/programs/resourceFiles",
    ProgramFavorites = "/settings/programFavorites"
}

/** Paths for the '/evaluation' API endpoints */
export enum EvaluationEndpoint {
    Component = "/evaluation/component",
    ExaminationData = "/evaluation/examinationData",
    PreviewList = "/evaluation/previewList",
    ExaminationComments = "/evaluation/comments",
    ExaminationInfo = "/evaluation/examinationInfo",
    View = "/evaluation/view",
    Pdf = "/evaluation/pdf",
    DicomEnabled = "/evaluation/dicom/enabled",
    DicomExport = "/evaluation/dicom/export",
    supportedGrids = "/evaluation/trend/supportedGrids",
    ConnectLicenseServer = "/evaluation/licenseServer/connect"
}

/** Paths for the '/measurement' API endpoints */
export enum MeasurementEndpoint {
    Start = "/measurement/start",
    Pause = "/measurement/pause",
    Resume = "/measurement/resume",
    Abort = "/measurement/abort",
    Restart = "/measurement/restart",
    Save = "/measurement/save",
    ExaminationInfo = "/measurement/examinationInfo",
    ExaminationComments = "/measurement/comments",
    Log = "/measurement/log",
    Export = "/measurement/export",
}

/** Paths for the '/measurement' Kinetic API endpoints */
export enum ManualKineticEndpoint {
    IsopterCreate = "/measurement/isopter/create",
    IsopterFinish = "/measurement/isopter/finish",
    IsopterList = "/measurement/isopter/list",
    IsopterRemove = "/measurement/isopter/remove",
    IsopterComment = "/measurement/isopter/comment",
    IsopterCombine = "/measurement/isopter/combine",
    VectorAdd = "/measurement/vector/add",
    VectorRemove = "/measurement/vector/remove",
    VectorComment = "/measurement/vector/comment",
    VectorLink = "/measurement/vector/link",
    Position = "/measurement/position",
    Direction = "/measurement/direction",
    Scotoma = "/measurement/scotoma",
}

/** Paths for the '/utility' API endpoints */
export enum UtilityEndpoint {
    VisualfieldLimits = "/utility/visualfieldLimits",
    MaxEccentricity = "/utility/maxEccentricity",
    FollowUp = "/utility/followUp",
    IsopterEditorPreview = "/utility/isopterEditorPreview",
    IsopterEditorSave = "/utility/isopterEditorSave",
}

/** Paths for the '/chinrest' API endpoints */
export enum ChinrestEndpoint {
    StartMove = "/chinrest/start-move",
    StopMove = "/chinrest/stop-move",
    MoveTo = "/chinrest/move-to",
}

// Patient endpoint calls

export function getPatient() {
    return get<PatientResponse>(PatientEndpoint.Base);
}

export function putPatient(payload: PatientPayload) {
    return put<PatientResponse, typeof payload>(PatientEndpoint.Base, payload);
}

export function getPatientList() {
    return get<Patient[]>(PatientEndpoint.List);
}

export function putPatientFarCorrection(payload: LensCorrection) {
    return put<LensCorrectionPayload, typeof payload>(PatientEndpoint.FarCorrection, payload);
}

// Info endpoint calls

export function getInfo() {
    return get<InfoResponse>(InfoEndpoint.Base, undefined);
}

export function getInfoLicences() {
    return get<LicensesResponse>(InfoEndpoint.Licences, undefined);
}

// Device endpoint calls

export function getDeviceInformation() {
    return get<DeviceInformationResponse>(DeviceEndpoint.Info);
}

/* This feature is not documented in the ServerProtocol as of version 1.40, yet it exists in the implementation. */
export function getDeviceIsConnected() {
    return get<{ value: boolean }>(DeviceEndpoint.IsConnected);
}

export function getDeviceConnect() {
    return get<never>(DeviceEndpoint.Connect);
}

export function getDeviceDocpUpdate() {
    return get<never>(DeviceEndpoint.DocpUpdate);
}

export function getDeviceDocpList() {
    return get<DocpServerInfo[]>(DeviceEndpoint.DocpList);
}

// Settings endpoint calls

export function getSettings() {
    return get<SettingsResponse>(SettingsEndpoint.Base);
}

export function getSettingsSave() {
    return get<never>(SettingsEndpoint.Save);
}

export function getCameraSettings() {
    return get<CameraSettingsResponse>(SettingsEndpoint.Camera);
}

export function putCameraSettings(payload: CameraSettingsPayload) {
    return put<CameraSettingsResponse, typeof payload>(SettingsEndpoint.Camera, payload);
}

export function putCameraSettingsPreview(payload: CameraSettingsPayload) {
    return put<never, typeof payload>(SettingsEndpoint.CameraPreview, payload);
}

export function deleteCameraSettingsPreview() {
    return del<never>(SettingsEndpoint.CameraPreview);
}

export function getDeviceSettings() {
    return get<DeviceSettingsResponse>(SettingsEndpoint.Device);
}

export function putDeviceSettings(payload: DeviceSettingsPayload) {
    return put<DeviceSettingsResponse, typeof payload>(SettingsEndpoint.Device, payload);
}

export function getDicomSettings() {
    return get<DicomSettingsResponse>(SettingsEndpoint.Dicom);
}

export function putDicomSettings(payload: DicomSettingsPayload) {
    return put<DicomSettingsResponse, typeof payload>(SettingsEndpoint.Dicom, payload);
}

export function getLicenseServerSettings() {
    return get<LicenseServerSettingsResponse>(SettingsEndpoint.LicenseServer);
}

export function putLicenseServerSettings(payload: LicenseServerSettingsPayload) {
    return put<LicenseServerSettingsResponse, typeof payload>(SettingsEndpoint.LicenseServer, payload);
}

export function getProgramFavorites() {
    return get<ProgramFavorites>(SettingsEndpoint.ProgramFavorites);
}

export function putProgramFavorites(payload: ProgramFavorites) {
    return put<ProgramFavorites, typeof payload>(SettingsEndpoint.ProgramFavorites, payload);
}

export function getFrontendSettings() {
    return get<FrontendSettings>(SettingsEndpoint.Frontend);
}

export function putFrontendSettings(payload: FrontendSettings) {
    return put<FrontendSettings, typeof payload>(SettingsEndpoint.Frontend, payload);
}

export function getProgramsList() {
    return get<ProgramListResponse>(SettingsEndpoint.ProgramsList);
}

export function getProgramsListAll(eye?: string) {
    return get<ProgramListResponse>(SettingsEndpoint.ProgramsListAll, { eye });
}

export function getProgramRefList(eye?: string) {
    return get<ProgramRefListResponse>(SettingsEndpoint.ProgramRefList, { eye });
}

export function getProgramRefListAll(eye?: string) {
    return get<ProgramRefListResponse>(SettingsEndpoint.ProgramRefListAll, { eye });
}

export function putProgramAppend(payload: ProgramPayload) {
    return put<ProgramListResponse, typeof payload>(SettingsEndpoint.ProgramsAppend, payload);
}

export function putProgramRemove(payload: ProgramPayload) {
    return put<ProgramListResponse, typeof payload>(SettingsEndpoint.ProgramsRemove, payload);
}

export function putProgramUpdate(payload: ProgramPayload) {
    return put<ProgramListResponse, typeof payload>(SettingsEndpoint.ProgramsUpdate, payload);
}

export function getProgramResourceFiles() {
    return get<ProgramResourceFiles>(SettingsEndpoint.ProgramsResourceFiles);
}

// Measurement endpoint calls

export function putMeasurementStart(payload: MeasurementConfigPayload) {
    return put<MeasurementStartResponse, typeof payload>(MeasurementEndpoint.Start, payload);
}

export function getMeasurementPause() {
    return get<never>(MeasurementEndpoint.Pause);
}

export function getMeasurementResume() {
    return get<never>(MeasurementEndpoint.Resume);
}

export function getMeasurementAbort() {
    return get<never>(MeasurementEndpoint.Abort);
}

export function getMeasurementRestart() {
    return get<never>(MeasurementEndpoint.Restart);
}

export function putMeasurementExaminationInfo(payload: ExaminationInfo) {
    return put<never, typeof payload>(MeasurementEndpoint.ExaminationInfo, payload);
}

export function putMeasurementComments(payload: ExaminationComments) {
    return put<never, typeof payload>(MeasurementEndpoint.ExaminationComments, payload);
}

export function getMeasurementSave() {
    return get<never>(MeasurementEndpoint.Save);
}

export function getMeasurementLog() {
    return get<ExaminationLog>(MeasurementEndpoint.Log);
}

export function getMeasurementExport() {
    return get<MeasurementExportData>(MeasurementEndpoint.Export);
}

// Manual kinetic endpoint calls

export function putManualKineticScotoma(payload: ScotomaPerimetryConfig) {
    return put<never, typeof payload>(ManualKineticEndpoint.Scotoma, payload);
}

export function putManualKineticDirection(payload: Arrow) {
    return put<never, typeof payload>(ManualKineticEndpoint.Direction, payload);
}

export function putManualKineticPosition(payload: MotorPosition) {
    return put<never, typeof payload>(ManualKineticEndpoint.Position, payload);
}

export function putManualKineticIsopterCreate(payload: IsopterManualConfig) {
    return put<Isopter, typeof payload>(ManualKineticEndpoint.IsopterCreate, payload);
}

export function getManualKineticIsopterFinish() {
    return get<Isopter>(ManualKineticEndpoint.IsopterFinish);
}

export function getManualKineticIsopterList() {
    return get<Isopter[]>(ManualKineticEndpoint.IsopterList);
}

export function putManualKineticIsopterRemove(payload: IsopterRef) {
    return put<never, typeof payload>(ManualKineticEndpoint.IsopterRemove, payload);
}

export function putManualKineticIsopterComment(payload: Isopter) {
    return put<never, typeof payload>(ManualKineticEndpoint.IsopterComment, payload);
}

export function putManualKineticIsopterCombine(payload: IsopterCombineList) {
    return put<Isopter, typeof payload>(ManualKineticEndpoint.IsopterCombine, payload);
}

export function putManualKineticVectorAdd(payload: IsopterVectorRef) {
    return put<Isopter, typeof payload>(ManualKineticEndpoint.VectorAdd, payload);
}

export function putManualKineticVectorRemove(payload: IsopterVectorRef) {
    return put<Isopter, typeof payload>(ManualKineticEndpoint.VectorRemove, payload);
}

export function putManualKineticVectorComment(payload: IsopterVectorRef) {
    return put<never, typeof payload>(ManualKineticEndpoint.VectorComment, payload);
}

export function putManualKineticVectorLink(payload: Isopter) {
    return put<Isopter, typeof payload>(ManualKineticEndpoint.VectorLink, payload);
}

// Evaluation endpoint calls

export function putExaminationData(payload: ExaminationRef) {
    return put<ExaminationDataResponse, typeof payload>(EvaluationEndpoint.ExaminationData, payload);
}

export function putEvaluationComponent<T extends ComponentRefPayload["componentType"]>(payload: ComponentRefPayload & {
    componentType: T
}) {
    return put<(ComponentResponse & { type: T } | ErrorMessage), typeof payload>(EvaluationEndpoint.Component, payload);
}

export function getEvaluationPreviewList(page: number) {
    const payload = {page};
    return get<PreviewListResponse, typeof payload>(EvaluationEndpoint.PreviewList, payload);
}

export function getConnectLicenseServer() {
    return get<never>(EvaluationEndpoint.ConnectLicenseServer);
}

export function putSupportedGrids(payload: TrendGridRef) {
    return put<string[], typeof payload>(EvaluationEndpoint.supportedGrids, payload);
}

export function putEvaluationComments(payload: ExaminationCommentsRefPayload) {
    return put<never, typeof payload>(EvaluationEndpoint.ExaminationComments, payload);
}

export function putEvaluationPdf(payload: ExaminationRef) {
    return request<Blob, typeof payload, undefined>("PUT", EvaluationEndpoint.Pdf, payload, undefined, "blob");
}

export function getEvaluationDicomEnabled() {
    return get<boolean>(EvaluationEndpoint.DicomEnabled);
}

export function putEvaluationDicomExport(payload: DicomExportConfigRef) {
    return put<DcmExportFilePaths, typeof payload>(EvaluationEndpoint.DicomExport, payload);
}

export function putEvaluationView<T extends ViewRef["viewType"], CT extends ViewRef["componentType"]>(payload: ViewRef & {
    viewType: T,
    componentType?: CT
}) {
    return put<
        { viewType: T } & (
        T extends ViewType.Progression ?
            Progression<Component & { type: CT }>
            : View
        ) | ErrorMessage,
        typeof payload
    >(EvaluationEndpoint.View, payload);
}

export function putEvaluationExaminationInfo(payload: ExaminationInfoRef) {
    return put<never, typeof payload>(EvaluationEndpoint.ExaminationInfo, payload);
}

// Utility endpoints

export function putVisualfieldLimits(payload: EyeRef) {
    return put<VisualfieldLimits, typeof payload>(UtilityEndpoint.VisualfieldLimits, payload);
}

export function putFollowUp(payload: ExaminationRef) {
    return put<MeasurementConfigPayload, typeof payload>(UtilityEndpoint.FollowUp, payload);
}

export function putMaxEccentricity(payload: MeasurementPositions) {
    return put<number, typeof payload>(UtilityEndpoint.MaxEccentricity, payload);
}

// Chinrest endpoints

export function putChinrestStartMove(payload: ChinrestMove) {
    return put<never, typeof payload>(ChinrestEndpoint.StartMove, payload);
}

export function putChinrestStopMove(payload: ChinrestMove) {
    return put<never, typeof payload>(ChinrestEndpoint.StopMove, payload);
}

export function putChinrestMoveTo(payload: Position) {
    return put<never, typeof payload>(ChinrestEndpoint.MoveTo, payload);
}