import {
    BrandSidebar,
    ButtonSize,
    Darkening,
    getNavButtonWithIcon,
    getNavListFurtherClassesBtn,
    getNavListFurtherClassesWrapper,
    SidebarFoldType,
} from "@oculus/component-library";
import {useLocation} from "@reach/router";
import {navigate} from "gatsby";
import React, {PropsWithChildren, ReactNode, useEffect, useMemo, useState} from "react";
import {isElectronEnvironment} from "../helper";
import {encodeConfigToken} from "../utils/configToken";
import Header from "./Header";
import {useAppState} from "../globalAppState";
import {useTranslation} from "@oculus/component-library";
import {TFunction} from "i18next";

/** `Layout` component properties */
export interface LayoutProps extends PropsWithChildren {
    /** Disable the brand sidebar */
    brandSidebarDisabled?: boolean;
    debugging?: boolean;
}

/**
 * Function that generates navigation items for the {@link BrandSidebar} component within the {@link Layout}
 *
 * @param foldTypeSetting - The expanded or collapsed state of the sidebar.
 * @param handleNavigate - A callback function that triggers when a navigation item is selected.
 * @param loc - The current browser location.
 * @param patientToken - The current patient token. Refer to `patientConfig` of {@link AppState} and {@link encodeConfigToken}.
 * @param disabled - Disables keyboard navigation for the buttons
 * @param t - Translation function from i18next
 * @param isInitializationComplete - isInitializationComplete true if initialization is complete, otherwise false.
 * @param usbConnected - usbConnected true if usbConnected is connected, otherwise false.
 * @param debugging - Enables navigation in the debugging page.
 * @retruns The `navigationList` property used by {@link BrandSidebar}
 */
const createNavList = (
    foldTypeSetting: SidebarFoldType,
    handleNavigate: (path: string) => void,
    loc: Location,
    patientToken: string | undefined,
    disabled: boolean,
    t:TFunction,
    isInitializationComplete = false,
    usbConnected = false,
    debugging = false,
): ReactNode[] => {
    if (!t){return []}
    const buttonTextFurtherClasses = "line-clamp text-left pr-6 pl-3";
    const hideLabel = foldTypeSetting === SidebarFoldType.In;

    const isExaminationActive = !!loc?.pathname?.match(/^\/patients\/[^/]*\/examinations\/new\//);
    const isEvaluationActive = !!loc?.pathname?.match(/^\/patients\/[^/]+\/examinations\/(?:[^/]+\/)?/) && !loc?.pathname?.match(/^\/patients\/[^/]*\/examinations\/new\//);
    const isSettingsActive = !!loc?.pathname?.match(/^\/settings\//);
    return [
        getNavButtonWithIcon(
            t("examination"),
            hideLabel,
            "visibility",
            isExaminationActive,
            true,
            ButtonSize.XLarge,
            getNavListFurtherClassesBtn,
            getNavListFurtherClassesWrapper,
            buttonTextFurtherClasses,
            () => handleNavigate(`/patients/${patientToken}/examinations/new/configuration/?step=1`),
            debugging ? !debugging : !usbConnected,
            true,
            disabled ? -1 : undefined
        ),
        getNavButtonWithIcon(
            t("evaluation"),
            hideLabel,
            "leaderboard",
            isEvaluationActive,
            isEvaluationActive,
            ButtonSize.XLarge,
            getNavListFurtherClassesBtn,
            getNavListFurtherClassesWrapper,
            buttonTextFurtherClasses,
            () => handleNavigate(`/patients/${patientToken}/examinations/`),
            debugging ? !debugging : !isInitializationComplete,
            true,
            disabled ? -1 : undefined
        ),
        getNavButtonWithIcon(
            t("settings"),
            hideLabel,
            "settings",
            isSettingsActive,
            isSettingsActive,
            ButtonSize.XLarge,
            getNavListFurtherClassesBtn,
            getNavListFurtherClassesWrapper,
            buttonTextFurtherClasses,
            () => handleNavigate("/settings/"),
            false,
            true,
            disabled ? -1 : undefined
        ),
    ].map((item, index) => React.cloneElement(item as React.ReactElement, { key: index }));
};

/** `Navigation` component properties */
export interface NavigationProps {
    /**Boolean to Disable the brand sidebar */
    disabled?: boolean

    /**turn off disabled navigation while debugging page is active*/
    debugging?: boolean;
}

/**
 * Wrapper that encapsulates the {@link BrandSidebar} within the {@link Layout}.
 *
 * @component
 */
const Navigation: React.FC<NavigationProps> = ({disabled = false, debugging = false}) => {
    const [isInitializationComplete, setIsInitializationComplete] = useState<string | null>((sessionStorage.getItem('isInitializationComplete')));
    const [usbConnected, setUsbConnected] = useState<string | null>(sessionStorage.getItem('usbDeviceConnected'));

    useEffect(() => {
        const updateValue = () => {
            setIsInitializationComplete(sessionStorage.getItem('isInitializationComplete'));
            setUsbConnected(sessionStorage.getItem('usbDeviceConnected'));
        };
        window.addEventListener('sessionStorageChange', updateValue);
        return () => window.removeEventListener('sessionStorageChange', updateValue);
    }, []);

    const loc = useLocation();
    const { t } = useTranslation();
    const [foldType, setFoldType] = useState(SidebarFoldType.In);
    const [{patientConfig}] = useAppState();
    const patientToken = patientConfig && encodeConfigToken(patientConfig);
    const exitBtn = useMemo(() => {
        const buttonTextFurtherClasses = "line-clamp text-left";
        const hideLabel = foldType === SidebarFoldType.In;
        if (foldType) {
            return (
                <div className="rotate-material-btn">
                    {getNavButtonWithIcon(
                        t("exit_perimeter"),
                        hideLabel,
                        "logout",
                        false,
                        true,
                        ButtonSize.XLarge,
                        getNavListFurtherClassesBtn,
                        getNavListFurtherClassesWrapper,
                        buttonTextFurtherClasses,
                        () => {
                            window.close();
                        },
                        false,
                        true,
                        disabled ? -1 : undefined
                    )}
                </div>
            );
        }
        return null;
    }, [foldType, disabled]);

    const renderExitBn = isElectronEnvironment() ? exitBtn : exitBtn;

    return (
        <div className="absolute flex min-h-full" style={{zIndex: 110}}>
            {disabled &&
                <div
                    className="absolute top-0 left-0 w-full h-full bg-dark/[.65] disabled-cursor-and-pointer-events-none" style={{zIndex:120}}></div>
            }
            <BrandSidebar
                label={t("fold_in")}
                foldType={foldType}
                navigationList={createNavList(foldType, navigate, loc, patientToken, disabled, t, isInitializationComplete === "true", usbConnected === "true", debugging)}
                onChange={setFoldType}
                bottomContentIn={renderExitBn}
                bottomContentOut={renderExitBn}
                tabIndex={disabled ? -1 : undefined}
            />

            <Darkening
                isOpen={foldType === SidebarFoldType.Out}
                onClick={() => setFoldType(SidebarFoldType.In)}
            />
        </div>
    );
};


/**
 * The component for the primary layout structure of the application with {@link Header} and {@link BrandSidebar}.
 *
 * It includes a dynamic zoom functionality that adjusts the zoom level
 * based on the current window dimensions, ensuring the application scales properly
 * on different screen sizes.
 *
 * @component
 * {@link LayoutProps}
 */
//let activeLayoutCounter = 0;
const Layout: React.FC<LayoutProps> = ({children, brandSidebarDisabled, debugging = false}) => {
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    /* UNUSED right now
    useEffect(() => {
        /* Parameters defining the zoom scaling based on window dimensions.
        const zoomParams = {
            width: {start: 1280, end: 1900, scale: 0.66},
            height: {start: 700, end: 980, scale: 0.70},
        };
        const updateZoom = () => {
            const [width, height] = [window.innerWidth, window.innerHeight];
            let zoom = 1;
            if (width >= zoomParams.width.start && height >= zoomParams.height.start) {
                zoom = Math.min(
                    zoom,
                    zoomParams.width.scale + (width - zoomParams.width.start) / (zoomParams.width.end - zoomParams.width.start) * (1 - zoomParams.width.scale),
                    zoomParams.height.scale + (height - zoomParams.height.start) / (zoomParams.height.end - zoomParams.height.start) * (1 - zoomParams.height.scale),
                );
            }
            if (zoom === 1) {
                document.body.style.removeProperty("zoom");
            } else {
                document.body.style.zoom = `${(zoom * 100).toFixed(1)}%`;
            }
        };
        updateZoom();
        window.addEventListener("resize", updateZoom);
        document.body.classList.add("overflow-hidden");
        activeLayoutCounter += 1;
        return () => {
            window.removeEventListener("resize", updateZoom);
            activeLayoutCounter = Math.max(0, activeLayoutCounter - 1);
            if (activeLayoutCounter === 0) {
                document.body.style.removeProperty("zoom");
                document.body.classList.remove("overflow-hidden");
            }
        }
    }, []);*/
    return (
        <div
            className="absolute inset-0 bg-grey2 flex flex-row clipping-boundary overflow-hidden" /* clipping-boundary is used by PositionPopup (comp.-lib.) */>
            <div className="w-15">
                <Navigation disabled={brandSidebarDisabled} debugging={debugging}/>
            </div>
            <div className="flex-1 flex flex-col  mb-2">
                <Darkening isOpen={dialogIsOpen} zIndex={10}/>
                <Header dialogIsOpen={dialogIsOpen} setDialogIsOpen={setDialogIsOpen}/>
                <div className="flex-1 relative mx-2">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Layout;
