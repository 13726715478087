import React from "react";
import {
    CursorArrowGroup,
    Dialog,
    Headline,
    HeadlineSize,
    TailwindStandardWidth,
    Toggletip,
    ToggletipLabel,
    ToggletipPosition,
    ToggletipSize, useTranslation
} from "@oculus/component-library";
import {
    FixationShiftPopupResponse,
    FixationShiftPopupResponseResponseType,
} from "../../../backend/websocket/interfaces/messages/FixationShiftPopupResponse";
import EyePreview from "../EyePreview";
import useChinrestController from "../../../utils/useChinrestController";
import {DeviceNameDeviceName} from "../../../backend/api/interfaces/custom-datatypes/DeviceNameDeviceName";

export interface FixationShiftPopupProps {
    /**
     * Callback function to handle the response of the popup
     *
     * @param response - The `responseType` from {@link FixationShiftPopupResponse}
     */
    onResponse?: (response: FixationShiftPopupResponse["responseType"]) => void;

    /** Controls the visibility of the popup. */
    show?: boolean;

    /** Controls the visibility of controls for adjusting the motorized chin rest in vertical direction. */
    supportsVerticalChinrestMove?: boolean;

    /** Controls the visibility of controls for adjusting the motorized chin rest in horizontal direction. */
    supportsHorizontalChinrestMove?: boolean;

    /** Depending on the deviceName, the appropriate dialog is selected */
    deviceName?: DeviceNameDeviceName;

    /** The URL representing the last received camera image from the backend. */
    cameraUri?: string | null;
}


const FixationShiftPopup: React.FC<FixationShiftPopupProps> = ({
                                                                   onResponse,
                                                                   show = true,
                                                                   supportsVerticalChinrestMove = false,
                                                                   supportsHorizontalChinrestMove = false,
                                                                   deviceName,
                                                                   cameraUri = null,
                                                               }) => {
    const {t} = useTranslation()
    const handleChinrestDirectionToggle = useChinrestController();
    if (deviceName === DeviceNameDeviceName.Easyfield) {
        return <Dialog
            label={t("hint")}
            headline={t("fixation_shift_while_examination")}
            text={t("fixation_shift_while_examination_text")}
            width={TailwindStandardWidth.AUTO}
            showCloseButton={false}
            show={show}
            buttons={[
                {
                    label: t("button_continue"),
                    primary: true,
                    onClick: onResponse && (() => onResponse?.(FixationShiftPopupResponseResponseType.Continue)),
                },
            ]}
        />
    } else if (deviceName === DeviceNameDeviceName.Centerfield || deviceName === DeviceNameDeviceName.Smartfield) {
        return <Dialog
            label={t("hint")}
            headline={t("fixation_shift")}
            text={t("fixation_shift_text")}
            width={TailwindStandardWidth.W200}
            showCloseButton={false}
            show={show}
            buttons={[
                {
                    label: t("examination_continue"),
                    primary: true,
                    onClick: onResponse && (() => onResponse?.(FixationShiftPopupResponseResponseType.Continue)),
                },
            ]}
        >
            <div className="flex flex-col items-center gap-4">
                <ToggletipLabel
                    openedComponent={
                        <Toggletip
                            content={"TODO"}
                            headline={"Toggletip"}
                            size={ToggletipSize.Medium}
                            show={true}
                            arrowPosition={ToggletipPosition.TopCenter}
                            useArrowPosition={true}
                            onClose={() => {
                            }}
                        />
                    }
                    iconSize={24}
                    label={
                        <Headline
                            size={HeadlineSize.H4}
                            text={t("configuration_steps_main_label_4_kinetic")}
                            furtherClasses="!mb-0"
                        />
                    }
                />
                <EyePreview cameraUri={cameraUri}/>
                {supportsHorizontalChinrestMove || supportsVerticalChinrestMove ? (
                    <CursorArrowGroup onDirectionToggle={handleChinrestDirectionToggle}
                                      enableKeyboardNavigation={true}
                                      disableVertical={!supportsVerticalChinrestMove}
                                      disableHorizontal={!supportsHorizontalChinrestMove}/>
                ) : null}
            </div>
        </Dialog>
    } else {
        console.error("Device not Supported for FixationShiftPopup");
        onResponse?.(FixationShiftPopupResponseResponseType.Cancel);
        return null;
    }
};

export default FixationShiftPopup;