import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    ReactNode, useRef, useMemo,
    ReactElement
} from "react";
import {getMockserver, initWebSocket, SimpleWebSocket} from "./core/Websocket";
import {AppStatePatientConfig, useAppState} from "../../globalAppState";
import {Message} from "./interfaces/Message";
import {MessageType} from "./interfaces/MessageType";
import {ApiError} from "../api/core/Api";
import {ErrorMessage} from "../api/interfaces/data/ErrorMessage";
import {getRandomEnumValue, isNotNull,} from "@oculus/component-library";
import {DeviceNameDeviceName} from "../api/interfaces/custom-datatypes/DeviceNameDeviceName";
import CommunicationErrorPopup from "../../components/examinations/popup/CommunicationErrorPopup";
import {errorMessageToString, exitApp, hasProgramKinetic, hasProgramStatic,} from "../../helper";
import InitializationAmbientBrightnessErrorPopup
    from "../../components/examinations/popup/InitializationAmbientBrightnessErrorPopup";
import InitializationAmbientRegulationPopup
    from "../../components/examinations/popup/InitializationAmbientRegulationPopup";
import CriticalExaminationErrorPopup from "../../components/examinations/popup/CriticalExaminationErrorPopup";
import CriticalInitializationErrorPopup from "../../components/examinations/popup/CriticalInitializationErrorPopup";
import {PrivatePatientComponentProps,} from "../../components/PrivatePatientRoute";
import {navigate} from "gatsby";
import {encodeConfigToken} from "../../utils/configToken";
import UnsupportedProgramNotificationPopup
    from "../../components/examinations/popup/UnsupportedProgramNotificationPopup";
import ReferencePositioningFailedPopup from "../../components/examinations/popup/ReferencePositioningFailedPopup";
import ZeroPositionInvalidPopup from "../../components/examinations/popup/ZeroPositionInvalidNotificationPopup";
import {
    getDeviceInformation,
    getDeviceSettings,
    getDicomSettings,
    getFrontendSettings,
    getInfo,
    getProgramRefListAll,
    getProgramFavorites,
    getProgramsListAll,
} from "../api/Calls";
import StandbyPopup from "../../components/examinations/popup/StandbyPopup";
import {LampColorFilterPopupRequestFilter} from "./interfaces/messages/LampColorFilterPopupRequest";
import LampColorFilterInsertPopup from "../../components/examinations/popup/LampColorFilterInsertPopup";
import LampColorFilterRemovePopup from "../../components/examinations/popup/LampColorFilterRemovePopup";
import LensChangeRemovePopup from "../../components/examinations/popup/LensChangeRemovePopup";
import {LensChangePopupRequestAction} from "./interfaces/messages/LensChangePopupRequest";
import LensChangeInsertPopup from "../../components/examinations/popup/LensChangeInsertPopup";
import {
    AmbientBrightnessErrorPopupResponseResponseType
} from "./interfaces/messages/AmbientBrightnessErrorPopupResponse";
import AmbientBrightnessErrorPopup from "../../components/examinations/popup/ AmbientBrightnessErrorPopup";
import FixationShiftPopup from "../../components/examinations/popup/FixationShiftPopup";
import {navigateWithSettings, tryParseExaminationSettings} from "../../components/examinations";
import {UnexpectedThresholdPopupResponseResponseType} from "./interfaces/messages/UnexpectedThresholdPopupResponse";
import UnexpectedThresholdPopup from "../../components/examinations/popup/UnexpectedThresholdPopup";
import ThresholdSuccessfullyMeasuredNotificationPopUp
    from "../../components/examinations/popup/ThresholdSuccessfullyMeasuredNotificationPopUp";
import {
    ContradictoryThresholdPopupResponseResponseType
} from "./interfaces/messages/ContradictoryThresholdPopupResponse";
import ContradictoryThresholdPopup from "../../components/examinations/popup/ContradictoryThresholdPopup";
import DicomExportPopup from "../../components/examinations/popup/DicomExportPopup";
import InitializationUpdatePopup from "../../components/examinations/popup/InitializationUpdatePopup";
import {ProgramRef} from "../api/interfaces/data/ProgramRef";
import {ProgramRefListResponse} from "../api/interfaces/response/ProgramRefListResponse";
import {ProgramListResponse} from "../api/interfaces/response/ProgramListResponse";
import {InfoResponse} from "../api/interfaces/response/InfoResponse";
import PatientResponse from "../api/interfaces/response/PatientResponse";
import {DeviceSettings} from "../api/interfaces/data/DeviceSettings";
import {DicomSettings} from "../api/interfaces/data/DicomSettings";
import {FrontendSettings} from "../api/interfaces/data/FrontendSettings";
import {ProgramFavorites} from "../api/interfaces/response/ProgramFavorites";

/**
 * Represents the context value for managing WebSocket connections and associated state in the application.
 */
interface WebSocketContextValue {
    // WebSocket and camera information
    websocket: SimpleWebSocket | null;
    cameraUri: string | null;
    token: string | null;

    // Device and positioning state
    referencePositioning: boolean;
    setReferencePositioning: React.Dispatch<React.SetStateAction<boolean>>;
    usbDeviceConnected?: boolean;
    setUsbDeviceConnected: React.Dispatch<React.SetStateAction<boolean | undefined>>;
    referencePositioningFailed: boolean;

    // Patient and program information
    patientToken?: string;
    programWithRefList: PrivatePatientComponentProps["programWithRefList"] | null;
    patientWithConfig: [AppStatePatientConfig, PatientResponse | null] | null;
    patient: PatientResponse | null;
    programFavoriteList: ProgramFavorites | null;

    // Settings
    deviceSettings: DeviceSettings | null;
    setDeviceSettings: React.Dispatch<React.SetStateAction<DeviceSettings | null>>;
    dicomSettings: DicomSettings | null;
    setDicomSettings: React.Dispatch<React.SetStateAction<DicomSettings | null>>;
    frontendSettings: FrontendSettings | null;
    setFrontendSettings: React.Dispatch<React.SetStateAction<FrontendSettings | null>>;
    programRefList: ProgramRefListResponse | null;
    setProgramRefList: React.Dispatch<React.SetStateAction<ProgramRefListResponse | null>>;
    programListAll: ProgramListResponse | null;
    setProgramListAll: React.Dispatch<React.SetStateAction<ProgramListResponse | null>>;
    info: InfoResponse | null;

    // Step and message count
    step: string | null;
    stepError: ErrorMessage | null;
    setMessageCount: React.Dispatch<React.SetStateAction<number>>;

    setProgramFavoriteList: React.Dispatch<React.SetStateAction<ProgramFavorites | null>>;
    setInfo: React.Dispatch<React.SetStateAction<InfoResponse | null>>;
    setPatientWithConfig: React.Dispatch<React.SetStateAction<[AppStatePatientConfig, PatientResponse | null] | null>>;

    // Error handling
    handleCriticalError: (err?: ApiError | unknown, retry?: () => void, options?: {
        isExamination?: boolean;
        errorMessage?: ErrorMessage;
    }) => void;

    privatePatientRouteRef: any
}

/**
 * Interface representing the properties for a popup component.
 * Extends the `PrivatePatientComponentProps` interface, omitting the `popupOpen` property.
 *
 * @interface
 * @extends Omit<PrivatePatientComponentProps, "popupOpen">
 *
 * @property {boolean} show - Determines if the popup is visible or not.
 * @property {() => void} onClose - Callback function invoked when the popup is closed.
 * @property {boolean} [usbDeviceConnected] - Optional property indicating if a USB device is connected.
 */

export interface PopupComponentProps extends Omit<PrivatePatientComponentProps, "popupOpen"> {
    show: boolean;
    onClose: () => void;
    usbDeviceConnected?: boolean;
}

/**
 * Defines a type for rendering a popup component.
 *
 * This type represents a function that receives properties for a popup component
 * and returns a ReactElement. It is commonly used to render custom popup components
 * with specific props.
 *
 * @callback PopupRender
 * @param {PopupComponentProps} props - The properties required by the popup component.
 * @returns {ReactElement} A React element representing the rendered popup.
 */
type PopupRender = (props: PopupComponentProps) => ReactElement;
/**
 * Represents a stack structure for managing popup components.
 *
 * This type is used to handle multiple popup components and their rendering,
 * priority status, and associated metadata. It helps manage the lifecycle
 * and ordering of popup elements on a user interface.
 */
type PopupStack = {
    nextKey: React.Key & number,
    stack: { key: React.Key, render: PopupRender, urgent: boolean, type: any }[]
};
/**
 * A type definition for a function responsible for setting a `PopupStack`.
 *
 * This function accepts a setter function as its parameter.
 * The setter function takes the previous `PopupStack` value (or `undefined` if it doesn't exist),
 * and returns the updated `PopupStack`.
 *
 * The `PopupStack` can represent a structure used to manage a stack of popup elements.
 *
 * @callback PopupStackSetter
 * @param {(prev: PopupStack | undefined) => PopupStack} setter - A function that receives the previous `PopupStack` value and returns a new `PopupStack`.
 */
type PopupStackSetter = (setter: ((prev: PopupStack | undefined) => PopupStack)) => void;

/**
 * Adds a new popup to the stack or updates the stack if a popup of the same type already exists and `urgent` is not set.
 *
 * @param {function} setPopupStack - A function setter to update the popup stack state, which receives the previous state as an argument.
 * @param {function} render - A function that defines the rendering logic for the popup.
 * @param {boolean} [urgent=false] - A flag indicating if the popup should be marked as urgent. Defaults to false if not provided.
 * @param {any} [type=null] - An optional type identifier for the popup. Defaults to null if not provided.
 */
function pushPopup(setPopupStack: PopupStackSetter, render: PopupRender, urgent: boolean = false, type: any = null) {
    setPopupStack((prev) => {
        if (type !== null && prev?.stack.some(({type: oType}) => type === oType)) {
            return prev;
        }
        const key = prev?.nextKey ?? 0;
        return {nextKey: key + 1, stack: [...(prev?.stack || []), {key, render, urgent, type}]};
    });
}

/**
 * Removes a specific type of popup from the popup stack.
 *
 * @param {function} setPopupStack - A function to update the popup stack state.
 * @param {any} typeToRemove - The type of popup to be removed from the stack.
 */
function removePopup(setPopupStack: PopupStackSetter, typeToRemove: any) {
    setPopupStack((prev) => {
        if (!prev || !prev.stack.length) {
            return {nextKey: 0, stack: []};
        }
        return {
            nextKey: prev.nextKey,
            stack: prev.stack.filter(({type}) => type !== typeToRemove),
        };
    });
}

/**
 * Renders the topmost popup from the popup stack, giving preference to urgent popups.
 * If there are no popups in the stack, it returns null.
 *
 * @param {PopupStack | undefined} popupStack - The object containing the stack of popups to be rendered.
 * @param {PopupStackSetter} setPopupStack - A setter function to update the popup stack state.
 * @param {Omit<PopupComponentProps, "show" | "onClose">} childProps - The properties to be passed to the popup component, excluding `show` and `onClose`.
 * @return {ReactElement | null} The rendered popup as a React element or null if no popups are present in the stack.
 */
function renderPopupStack(popupStack: PopupStack | undefined, setPopupStack: PopupStackSetter, childProps: Omit<PopupComponentProps, "show" | "onClose">): ReactElement | null {
    const popup = popupStack?.stack.find((p) => p.urgent) ?? popupStack?.stack[0];
    if (!popup) {
        return null;
    }
    // Must be encapsulated in Fragment because of key
    return <>{React.createElement(popup.render, {
        ...childProps,
        key: popup.key,
        show: true,
        onClose: () => setPopupStack((prev) => ({...prev!, stack: prev!.stack.filter(({key}) => key !== popup.key)})),
    })}</>;
}


/**
 * WebSocketContext is a React context for providing and consuming WebSocket-related state or functionality
 * throughout a component tree. It holds either a value conforming to the WebSocketContextValue interface
 * or undefined if no provider is available in the component tree.
 *
 * This context is useful for managing WebSocket connections, sharing WebSocket-related data,
 * or handling message transmission within a React application.
 */
const WebSocketContext = createContext<WebSocketContextValue | undefined>(undefined);

/**
 * WebSocketProvider is a React functional component that acts as a context provider
 * for handling state and logic necessary for WebSocket communications and device interactions.
 * Props:
 * - `children`: ReactNode - The child components that will have access to the context and state managed in the provider.
 */
export const WebSocketProvider: React.FC<{ children: ReactNode }> = ({
                                                                         children,
                                                                     }) => {
    const [token, setToken] = useState<string | null>(null)
    /* The `deviceConnected` state signifies that the device is physically connected via USB, it does not guarantee successful invocation of the `/device/connect` API call. */
    const [usbDeviceConnected, setUsbDeviceConnected] = useState<boolean>();

    useEffect(() => {
        if (typeof window !== "undefined") {
            const savedDeviceStatus = sessionStorage.getItem("usbDeviceConnected");
            if (savedDeviceStatus !== null) {
                setUsbDeviceConnected(savedDeviceStatus === "true");
            }
        }
    }, []);
    useEffect(() => {
        sessionStorage.setItem("usbDeviceConnected", usbDeviceConnected?.toString() ?? "false");
    }, [usbDeviceConnected]);

    const [popupStack, setPopupStack] = useState<PopupStack>();
    const [urgentPopupStack, setUrgentPopupStack] = useState<PopupStack>();
    const [, setMessageCount] = useState(0);
    const [websocket, setWebsocket] = useState<SimpleWebSocket | null>(null);
    const [cameraUri, setCameraUri] = useState<string | null>(null);
    const [step, setStep] = useState<string | null>(null)
    const [stepError, setStepError] = useState<ErrorMessage | null>(null);
    const [referencePositioning, setReferencePositioning] = useState(false);
    const [referencePositioningFailed, setReferencePositioningFailed] = useState(false);
    const [{patientConfig, deviceInformation}, setAppState] = useAppState();
    const patientToken = patientConfig && encodeConfigToken(patientConfig);
    const [deviceSettings, setDeviceSettings] = useState<DeviceSettings | null>(null);
    const [dicomSettings, setDicomSettings] = useState<DicomSettings | null>(null);
    const [frontendSettings, setFrontendSettings] = useState<FrontendSettings | null>(null);
    const [programRefList, setProgramRefList] = useState<ProgramRefListResponse | null>(null);
    const [programListAll, setProgramListAll] = useState<ProgramListResponse | null>(null);
    const programWithRefList: PrivatePatientComponentProps["programWithRefList"] = useMemo(() => {
        if (!programListAll || !programRefList) {
            return null;
        }
        return programListAll.map((program) => {
            let ref = programRefList.find(({uuid}) => uuid === program.uuid);
            if (ref) {
                ref.type = program.type;
                return {...program, ref, isFavoriteOnly: false};
            }
            const fakeRef: ProgramRef = {
                uuid: program.uuid,
                name: program.name,
                custom: program.custom,
                type: program.type,
                duration: 0, // TODO: Value not provided by server protocol
            };
            if (hasProgramStatic(program)) {
                fakeRef.staticParameters = program.staticParameters;
            }
            if (hasProgramKinetic(program)) {
                fakeRef.kineticParameters = program.kineticParameters;
            }
            return {...program, ref: fakeRef, isFavoriteOnly: true};
        }).filter(isNotNull);
    }, [programRefList, programListAll]);
    const [patientWithConfig, setPatientWithConfig] = useState<[AppStatePatientConfig, PatientResponse | null] | null>(null);
    const [_, patient] = patientWithConfig ?? [null, null];
    const [info, setInfo] = useState<InfoResponse | null>(null);
    const [programFavoriteList, setProgramFavoriteList] = useState<ProgramFavorites | null>(null)

    const switchToEvaluation = (onclose: () => void) => {
        void navigate(`/patients/${patientToken}/examinations`);
        onclose();
    };

    const handleCriticalError = (err?: ApiError | unknown, retry?: () => void, {isExamination = false, errorMessage}: {
        isExamination?: boolean,
        errorMessage?: ErrorMessage
    } = {}) => {
        errorMessage ??= err instanceof ApiError ? err.errorMessage : undefined;
        if (errorMessage) {
            console.error(errorMessage)
        } else if (err) {
            console.error(err);
        }
        if (errorMessage?.message === "E002" && retry) {
            setUsbDeviceConnected(false);
            if (patientConfig?.wsUrl === "mockserver") {
                setTimeout(() => {
                    getMockserver()?.send({
                        type: MessageType.DeviceConnected,
                        device: {
                            name: getRandomEnumValue(DeviceNameDeviceName),
                        },
                    });
                }, 3000)
            }
            pushPopup(setUrgentPopupStack, ({show, usbDeviceConnected, onClose}) => (
                <CommunicationErrorPopup
                    show={show}
                    onExit={exitApp}
                    onSwitchToEvaluation={() => switchToEvaluation(onClose)}
                    onRetry={usbDeviceConnected ? () => {
                        retry();
                        onClose();
                    } : undefined}/>
            ), undefined, CommunicationErrorPopup);
        } else if (errorMessage?.code === "E007" && retry) {
            pushPopup(setUrgentPopupStack, ({show, onClose}) => (
                <InitializationAmbientBrightnessErrorPopup
                    show={show}
                    onExit={exitApp}
                    onSwitchToEvaluation={() => switchToEvaluation(onClose)}
                    onRetry={() => {
                        retry();
                        onClose();
                    }}/>
            ), undefined, InitializationAmbientBrightnessErrorPopup);
        } else if (errorMessage?.code === "E009" && retry) {
            pushPopup(setUrgentPopupStack, ({show, onClose}) => (
                <InitializationAmbientRegulationPopup
                    show={show}
                    onExit={exitApp}
                    onSwitchToEvaluation={() => switchToEvaluation(onClose)}
                    onClose={() => {
                        onClose();
                    }}/>
            ), undefined, InitializationAmbientRegulationPopup);
        } else if (isExamination) {
            pushPopup(setUrgentPopupStack, ({show, onClose}) => (
                <CriticalExaminationErrorPopup
                    error={errorMessage}
                    show={show}
                    onExit={exitApp}
                    onSwitchToEvaluation={() => switchToEvaluation(onClose)}
                />
            ));
        } else {
            pushPopup(setUrgentPopupStack, ({show, onClose}) => (
                <CriticalInitializationErrorPopup onClose={onClose}
                                                  error={errorMessage}
                                                  show={show}
                                                  onExit={exitApp}
                                                  onSwitchToEvaluation={() => switchToEvaluation(onClose)}
                />
            ));
        }
    }
    const privatePatientRouteRef = useRef<{ setupFunction: (patientConfig: AppStatePatientConfig) => void }>(null);
    const callSetupFromParent = (patientConfig: AppStatePatientConfig) => {
        if (privatePatientRouteRef.current) {
            privatePatientRouteRef.current.setupFunction(patientConfig);
        }
    };
    useEffect(() => {
        if (token) {
            console.log(`Setting Client-Token "${token}"`);
            setAppState((old) => ({...old, token}));
        }
    }, [token]);
    // Socket Handling
    useEffect(() => {
        setCameraUri(null);
        setReferencePositioning(false);
        if (!patientConfig) {
            return;
        }
        const handleMessage = (message: Message) => {
            setMessageCount((n) => n + 1);

            if ("type" in message) {
                switch (message.type) {
                    case MessageType.InitializationStart:
                        pushPopup(setPopupStack, ({step, show, stepError, onClose}) => (

                            <InitializationUpdatePopup deviceConnected={usbDeviceConnected} show={show}
                                                       onClose={onClose}
                                                       steps={message.steps} currentStep={step}
                                                       patientToken={patientToken}
                                                       stepError={stepError} retry={() => {
                                removePopup(setPopupStack, InitializationUpdatePopup)
                                setStep(null);
                                setStepError(null);
                                callSetupFromParent(patientConfig)
                            }}/>
                        ), false, InitializationUpdatePopup);
                        break;
                    case MessageType.InitializationUpdate:
                        setStep(message.step)
                        break;
                    case MessageType.InitializationDone:
                        if (message.deviceConnected) {
                            getDeviceInformation().then(({data}) => setAppState((old) => ({
                                ...old,
                                deviceInformation: data
                            })));
                            getInfo()
                                .then(({data}) => setInfo(data)),
                            getProgramRefListAll()
                                .then(({data}) => setProgramRefList(data)),
                            getProgramsListAll()
                                .then(({data}) => setProgramListAll(data)),
                            getProgramFavorites()
                                .then(({data}) => setProgramFavoriteList(data)),
                            getDeviceSettings()
                                .then(({data}) => {
                                    setAppState((old) => ({
                                        ...old,
                                        deviceSettings: {main: {dateFormat: data.main.dateFormat, language: data.main.language}}
                                    }))
                                    setDeviceSettings(data);
                                }),
                            getDicomSettings()
                                .then(({data}) => setDicomSettings(data)),
                            getFrontendSettings()
                                .then(({data}) => setFrontendSettings(data))
                            sessionStorage.setItem('LicenseServerInitialisationDone', 'true');
                        } else {
                            setUsbDeviceConnected(false)
                            handleCriticalError(undefined, undefined, {isExamination: false});
                            sessionStorage.setItem('LicenseServerInitialisationDone', 'false');
                        }
                        removePopup(setPopupStack, InitializationUpdatePopup)
                        setStep(null);
                        setStepError(null);
                        sessionStorage.setItem('isInitializationComplete', 'true');
                        sessionStorage.setItem('usbDeviceConnected', 'true');
                        window.dispatchEvent(new Event('sessionStorageChange'));
                        break;
                    case MessageType.InitializationError: {
                        setStepError(message.errorMessage)
                        sessionStorage.setItem('isInitializationComplete', 'false');
                        sessionStorage.setItem('usbDeviceConnected', 'false');
                        window.dispatchEvent(new Event('sessionStorageChange'));
                    }
                        break;
                    case MessageType.ClientTokenAssignment:
                        setToken(message.token)
                        break
                    case MessageType.CameraImage:
                        setCameraUri(message.uri);
                        break
                    case MessageType.DicomWorkflowPopupRequest: {
                        pushPopup(setPopupStack, ({show, onClose, websocket, dicomSettings}) => {
                            const [errorMessage, setErrorMessage] = useState<string | null>(null);
                            const [loading, setLoading] = useState(false);
                            /* Recreates the callback function on every render to prevent issues related
                             * to stale properties within the closure.
                             */
                            const handleMessageRef = useRef<Parameters<SimpleWebSocket["addMessageListener"]>[0]>();
                            useEffect(() => {
                                handleMessageRef.current = (message) => {
                                    switch (message.type) {
                                        case MessageType.DicomWorkflowExportError: {
                                            const errorMessage = errorMessageToString(message.errorMessage);
                                            console.error(errorMessage);
                                            setErrorMessage(errorMessage);
                                            setLoading(false);
                                        }
                                            break;
                                        case MessageType.DicomWorkflowExportDone:
                                            onClose();
                                            break;
                                    }
                                };
                            }, undefined);
                            useEffect(() => {
                                if (websocket) {
                                    const handleMessageWrapper: NonNullable<typeof handleMessageRef.current> = (...args) =>
                                        handleMessageRef.current?.(...args);
                                    websocket.addMessageListener(handleMessageWrapper);
                                    return () => {
                                        websocket.removeMessageListener(handleMessageWrapper);
                                    };
                                }
                            }, [websocket]);
                            return (
                                <DicomExportPopup onResponse={(dicomExportConfig) => {
                                    if (!websocket || loading) {
                                        return;
                                    }
                                    setLoading(true);
                                    setErrorMessage(null);
                                    websocket.send({
                                        type: MessageType.DicomWorkflowPopupResponse,
                                        dicomExportConfig,
                                    });
                                }}
                                                  errorMessage={errorMessage}
                                                  onCancel={onClose} show={show} loading={loading}
                                                  mode={dicomSettings?.config?.enableDcmSend ? "pacs" : "save"}
                                                  config={message.dicomExportConfig}/>
                            )
                        }, undefined, DicomExportPopup);
                    }
                        break;
                    case MessageType.ContradictoryThresholdPopupRequest: {
                        pushPopup(setPopupStack, ({show, onClose}) => (
                            <ContradictoryThresholdPopup
                                measuredThreshold={message.measuredThreshold}
                                controlThreshold={message.controlThreshold}
                                show={show}
                                error={message.errorMessage}
                                measuredClass={message.measuredLightDensityClass}
                                preselectedClassBasedOnAge={message.ageExpectedLightDensityClass}
                                onResponse={(response, selected) => {
                                    socket.send({
                                        type: MessageType.ContradictoryThresholdPopupResponse,
                                        lightDensityClass: selected,
                                        responseType: response === ContradictoryThresholdPopupResponseResponseType.Repeat ? ContradictoryThresholdPopupResponseResponseType.Cancel : response,
                                    });
                                    onClose();
                                    if (response === ContradictoryThresholdPopupResponseResponseType.Cancel) {
                                        void navigate(`/patients/${patientToken}/examinations/new/configuration/?step=1`);
                                    } else if (response === ContradictoryThresholdPopupResponseResponseType.Repeat) {
                                        /*TODO: Need to be Checked Depending on the Backend Update */
                                        navigateWithSettings(`/patients/${patientToken}/examinations/new/configuration`,
                                            tryParseExaminationSettings(window.location.search));
                                    }
                                }}/>
                        ));
                    }
                        break;
                    case MessageType.DeviceConnected: {
                        setUsbDeviceConnected(true)
                        break;
                    }
                    case MessageType.DeviceDisconnected: {
                        setUsbDeviceConnected(false);
                        pushPopup(setUrgentPopupStack, ({show, usbDeviceConnected, onClose}) => (
                            <CommunicationErrorPopup
                                show={show}
                                onExit={exitApp}
                                onSwitchToEvaluation={() => switchToEvaluation(onClose)}
                                onRetry={usbDeviceConnected ? () => {
                                    void (callSetupFromParent(patientConfig));
                                    navigateWithSettings(`/patients/${patientToken}/examinations/new/configuration`,
                                        tryParseExaminationSettings(window.location.search));
                                    onClose();
                                } : undefined}/>
                        ), undefined, CommunicationErrorPopup);
                        break;
                    }
                    case MessageType.ThresholdSuccessfullyMeasuredNotification: {
                        pushPopup(setPopupStack, ({show, onClose}) => (
                            <ThresholdSuccessfullyMeasuredNotificationPopUp
                                measuredClass={message.measuredLightDensityClass}
                                preselectedClassBasedOnAge={message.ageExpectedLightDensityClass}
                                measuredThreshold={message.measuredThreshold} show={show}
                                onResponse={(selected) => {
                                    socket.send({
                                        type: MessageType.ThresholdSuccessfullyMeasuredConfirmation,
                                        lightDensityClass: selected,
                                    });
                                    onClose();
                                }}/>
                        ));
                        break;
                    }
                    case MessageType.UnexpectedThresholdPopupRequest: {
                        pushPopup(setPopupStack, ({show, onClose}) => (
                            <UnexpectedThresholdPopup
                                show={show}
                                measuredThreshold={message.measuredThreshold}
                                ageExpectedThreshold={message.ageExpectedThreshold}
                                measuredClass={message.measuredLightDensityClass}
                                preselectedClassBasedOnAge={message.ageExpectedLightDensityClass}
                                error={message.errorMessage}
                                onResponse={(response, selected) => {
                                    socket.send({
                                        type: MessageType.UnexpectedThresholdPopupResponse,
                                        lightDensityClass: selected,
                                        responseType: response,
                                    });
                                    onClose();
                                    if (response === UnexpectedThresholdPopupResponseResponseType.Cancel) {
                                        void navigate(`/patients/${patientToken}/examinations/new/configuration/?step=1`);
                                    } else if (response === UnexpectedThresholdPopupResponseResponseType.Repeat) {
                                        /*TODO: Need to be Checked Depending on the Backend Update*/
                                        navigateWithSettings(`/patients/${patientToken}/examinations/new/configuration/`,
                                            tryParseExaminationSettings(window.location.search));
                                    }
                                }}/>
                        ));
                    }
                        break;
                    case MessageType.FixationShiftPopupRequest: {
                        pushPopup(setPopupStack, ({show, onClose, deviceInformation, cameraUri}) => (
                            <FixationShiftPopup
                                show={show}
                                cameraUri={cameraUri}
                                deviceName={deviceInformation?.connectedDevice.name}
                                supportsVerticalChinrestMove={deviceInformation?.supportsVerticalChinrestMove}
                                supportsHorizontalChinrestMove={deviceInformation?.supportsHorizontalChinrestMove}
                                onResponse={(response) => {
                                    socket.send({
                                        type: MessageType.FixationShiftPopupResponse,
                                        responseType: response,
                                    });
                                    onClose();
                                }}
                            />
                        ))
                    }
                        break;
                    case MessageType.MeasurementStartError: {
                        handleCriticalError(undefined, undefined, {
                            isExamination: true,
                            errorMessage: message.errorMessage
                        });
                    }
                        break;
                    case MessageType.AmbientBrightnessErrorPopupRequest: {
                        pushPopup(setPopupStack, ({show, onClose}) => (
                            <AmbientBrightnessErrorPopup
                                show={show}
                                onResponse={(response) => {
                                    socket.send({
                                        type: MessageType.AmbientBrightnessErrorPopupResponse,
                                        responseType: response,
                                    });
                                    onClose();
                                    if (response === AmbientBrightnessErrorPopupResponseResponseType.Cancel) {
                                        void navigate(`/patients/${patientToken}/examinations/new/configuration/?step=1`);
                                    }
                                }}/>
                        ));
                    }
                        break;
                    case MessageType.LensChangePopupRequest: {
                        switch (message.action) {
                            case LensChangePopupRequestAction.Insert: {
                                pushPopup(setPopupStack, ({show, onClose}) => (
                                    <LensChangeInsertPopup
                                        show={show}
                                        onResponse={() => {
                                            socket.send({
                                                type: MessageType.LensChangePopupResponse,
                                            });
                                            onClose();
                                        }}/>
                                ));
                            }
                                break;
                            case LensChangePopupRequestAction.Remove: {
                                pushPopup(setPopupStack, ({show, onClose, deviceInformation, cameraUri}) => (
                                    <LensChangeRemovePopup
                                        cameraUri={cameraUri}
                                        show={show}
                                        supportsVerticalChinrestMove={deviceInformation?.supportsVerticalChinrestMove}
                                        supportsHorizontalChinrestMove={deviceInformation?.supportsHorizontalChinrestMove}
                                        onResponse={() => {
                                            socket.send({
                                                type: MessageType.LensChangePopupResponse,
                                            });
                                            onClose();
                                        }}/>
                                ));
                            }
                                break;
                            default:
                                void (message.action satisfies never);
                        }
                    }
                        break;
                    case MessageType.LampColorFilterPopupRequest: {
                        pushPopup(setPopupStack, ({show, onClose}) => React.createElement(
                            message.filter === LampColorFilterPopupRequestFilter.White ?
                                LampColorFilterRemovePopup
                                : LampColorFilterInsertPopup,
                            {
                                show,
                                filter: message.filter,
                                onResponse: () => {
                                    socket.send({
                                        type: MessageType.LampColorFilterPopupResponse,
                                    });
                                    onClose();
                                },
                            }
                        ));
                    }
                        break;
                    case MessageType.ShutdownNotification: {
                        // HACK: Use proper store for state instead
                        setMessageCount((messageCount) => {
                            let requestInProgress = false;
                            pushPopup(setPopupStack, ({show, onClose}) => {
                                setMessageCount((newMessageCount) => {
                                    // Close popup when more messages arrive
                                    if (show && messageCount != newMessageCount) {
                                        onClose();
                                    }
                                    return newMessageCount;
                                });
                                return (
                                    <StandbyPopup
                                        show={show}
                                        onWakeup={async () => {
                                            if (requestInProgress) {
                                                return;
                                            }
                                            requestInProgress = true;
                                            try {
                                                await getDeviceInformation();
                                            } finally {
                                                requestInProgress = false;
                                            }
                                        }}/>
                                );
                            });
                            return messageCount;
                        });
                    }
                        break;
                    case MessageType.ZeroPositionInvalidNotification: {
                        pushPopup(setUrgentPopupStack, ({show, onClose}) => (
                            <ZeroPositionInvalidPopup
                                show={show}
                                onExit={exitApp}
                                onSwitchToEvaluation={() => switchToEvaluation(onClose)}
                            />
                        ));
                    }
                        break;
                    case MessageType.ReferencePositioningStarted: {
                        setReferencePositioning(true);
                    }
                        break;
                    case MessageType.ReferencePositioningCompleted: {
                        setReferencePositioning(false);
                    }
                        break;
                    case MessageType.ReferencePositioningFailed: {
                        setReferencePositioningFailed(true)
                        pushPopup(setUrgentPopupStack, ({show, onClose}) => (
                            <ReferencePositioningFailedPopup
                                onExit={exitApp} show={show}
                                onSwitchToEvaluation={() => switchToEvaluation(onClose)}
                            />
                        ));
                    }
                        break;
                    case MessageType.UnsupportedProgramNotification: {
                        pushPopup(setUrgentPopupStack, ({show, onClose}) => (
                            <UnsupportedProgramNotificationPopup show={show} onResponse={() => onClose()}/>
                        ));
                    }
                        break;
                }
            }
        };

        let wsConnected = false;
        let wsFinalized = false;
        const onOpen = () => {
            wsConnected = true;
        }
        const checkError = () => {
            if (wsFinalized) {
                // Disregard any errors that may occur once we are done with the connection
                return;
            }
            handleCriticalError(undefined, undefined, {isExamination: wsConnected});
        }

        const socket: SimpleWebSocket = initWebSocket(
            patientConfig.wsUrl,
            {
                onOpen,
                onClose: checkError,
                onError: checkError,
            });
        socket.addMessageListener(handleMessage);
        if (patientConfig.wsUrl === "mockserver") {
            getMockserver()?.send({
                type: MessageType.ClientTokenAssignment,
                token: "mockserver",
            });
        }
        setWebsocket(socket);
        return () => {
            wsFinalized = true;
            socket.close();
        }
    }, [patientConfig?.wsUrl]);  // `patientToken` is updated only when there are modifications to `patientConfig`.

    const commonChildProps = {
        patient,
        info,
        programWithRefList,
        programFavoriteList,
        deviceInformation: deviceInformation ?? null,
        frontendSettings,
        deviceSettings,
        dicomSettings,
        websocket,
        cameraUri,
        step,
        stepError,
        referencePositioning,
        referencePositioningFailed,
        handleCriticalError: (err, retry) => handleCriticalError(err, retry, {isExamination: true}),
    } satisfies Partial<PrivatePatientComponentProps>;
    const popupChildProps: Omit<PopupComponentProps, "show" | "onClose"> = {
        ...commonChildProps,
        usbDeviceConnected,
    };
    const popup = renderPopupStack(urgentPopupStack, setUrgentPopupStack, popupChildProps)
        || renderPopupStack(popupStack, setPopupStack, popupChildProps);

    const [isBrowser, setIsBrowser] = useState(false);

    useEffect(() => {
        setIsBrowser(true);
    }, []);

    if (!isBrowser) {
        return null;
    }

    return (
        <WebSocketContext.Provider value={{
            websocket,
            cameraUri,
            referencePositioning,
            setReferencePositioning,
            token,
            usbDeviceConnected,
            patientToken,
            setMessageCount,
            step,
            stepError,
            referencePositioningFailed,
            setUsbDeviceConnected,
            deviceSettings,
            setDeviceSettings,
            dicomSettings,
            setDicomSettings,
            frontendSettings,
            setFrontendSettings,
            programRefList,
            setProgramRefList,
            programListAll,
            setProgramListAll,
            programWithRefList,
            patientWithConfig,
            setPatientWithConfig,
            patient,
            info,
            setInfo,
            programFavoriteList,
            setProgramFavoriteList,
            handleCriticalError,
            privatePatientRouteRef
        }}>
            {children}
            {popup}
        </WebSocketContext.Provider>
    );
};

/**
 * A custom hook that provides access to the WebSocketContextValue from the WebSocketContext.
 * This hook ensures that the `useWebSocket` function is only used within a valid `WebSocketProvider`.
 *
 * @throws {Error} If the hook is used outside a WebSocketProvider, an error is thrown.
 * @returns {WebSocketContextValue} The value of the WebSocketContext.
 */
export const useWebSocket = (): WebSocketContextValue => {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error("useWebSocket must be used within a WebSocketProvider");
    }
    return context;
};