import React from "react";
import {
    CursorArrowGroup,
    Dialog,
    Headline,
    HeadlineSize,
    TailwindStandardWidth,
    Toggletip,
    ToggletipLabel,
    ToggletipPosition,
    ToggletipSize, useTranslation,
} from "@oculus/component-library";
import EyePreview from "../EyePreview";
import useChinrestController from "../../../utils/useChinrestController";

/** `LensChangeRemovePopup` component properties */
export interface LensChangeRemovePopupProps {
    /**
     * Callback function to handle the response of the popup
     *
     * @see LensChangePopupResponse
     */
    onResponse?: () => void;

    /** Controls the visibility of the popup. */
    show?: boolean;

    /** Controls the visibility of controls for adjusting the motorized chin rest in vertical direction. */
    supportsVerticalChinrestMove?: boolean;

    /** Controls the visibility of controls for adjusting the motorized chin rest in horizontal direction. */
    supportsHorizontalChinrestMove?: boolean;

    /** The URL representing the last received camera image from the backend. */
    cameraUri?: string | null;
}

/**
 * A popup component for handling `Remove` action of {@link LensChangePopupRequest} message.
 *
 * @component
 * {@link LensChangeRemovePopupProps}
 *
 * @see LensChangeInsertPopup
 */
const LensChangeRemovePopup: React.FC<LensChangeRemovePopupProps> = ({
                                                                         onResponse,
                                                                         show = true,
                                                                         supportsVerticalChinrestMove = false,
                                                                         supportsHorizontalChinrestMove = false,
                                                                         cameraUri = null,
                                                                     }) => {
    const {t} = useTranslation()
    const handleChinrestDirectionToggle = useChinrestController();
    return (
        <Dialog
            label={t("hint")}
            headline={t("remove_correction_lens")}
            text={t("remove_correction_lens_text")}
            width={TailwindStandardWidth.W200}
            showCloseButton={false}
            show={show}
            buttons={[
                {
                    label: t("examination_continue"),
                    primary: true,
                    onClick: onResponse,
                },
            ]}
        >
            <div className="flex flex-col items-center gap-4">
                <ToggletipLabel
                    openedComponent={
                        <Toggletip
                            content={"TODO"}
                            headline={"Toggletip"}
                            size={ToggletipSize.Medium}
                            show={true}
                            arrowPosition={ToggletipPosition.TopCenter}
                            useArrowPosition={true}
                            onClose={() => {
                            }}
                        />
                    }
                    iconSize={24}
                    label={
                        <Headline
                            size={HeadlineSize.H4}
                            text={t("configuration_steps_main_label_4_kinetic")}
                            furtherClasses="!mb-0"
                        />
                    }
                />
                <EyePreview cameraUri={cameraUri}/>
                {supportsHorizontalChinrestMove || supportsVerticalChinrestMove ? (
                    <CursorArrowGroup onDirectionToggle={handleChinrestDirectionToggle} enableKeyboardNavigation={true}
                                      disableVertical={!supportsVerticalChinrestMove}
                                      disableHorizontal={!supportsHorizontalChinrestMove}/>
                ) : null}
            </div>
        </Dialog>
    );
};

export default LensChangeRemovePopup;