import React, {useEffect} from "react";
import {
    Dialog,
    TailwindStandardWidth, useTranslation,
} from "@oculus/component-library";

/** `StandbyPopup` component properties */
export interface StandbyPopupProps {
    /** Callback triggered when user input is detected, asking the device to wake up */
    onWakeup?: () => void,

    /** Controls the visibility of the popup. */
    show?: boolean;
}

/**
 * A popup component for handling {@link ShutdownNotification} message.
 *
 * @component
 * {@link StandbyPopupProps}
 */
const StandbyPopup: React.FC<StandbyPopupProps> = ({
                                                       onWakeup,
                                                       show = true,
                                                   }) => {
    const {t} = useTranslation();
    useEffect(() => {
        if (!show || !onWakeup) {
            return;
        }
        const listenerTypes: (keyof WindowEventMap)[] = ["mousemove", "input"];
        const listener = () => onWakeup();
        listenerTypes.forEach((type) => window.addEventListener(type, listener));
        return () => listenerTypes.forEach((type) => window.removeEventListener(type, listener));
    }, [onWakeup, show]);
    return (
        <Dialog
            label={t("error_message")}
            headline={t("device_in_standby_mode")}
            text={t("device_in_standby_mode_text")}
            width={TailwindStandardWidth.W200}
            showCloseButton={false}
            show={show}
        />
    );
};

export default StandbyPopup;