import {BaseCriticalErrorPopupProps} from "./index";
import React from "react";
import {Dialog, DialogType, TailwindStandardWidth, useTranslation} from "@oculus/component-library";

/**
 * Represents the properties for the UnsupportedProgramNotificationPopup component.
 * Extends a subset of properties from BaseCriticalErrorPopupProps, inheriting the 'show' property.
 */
export interface UnsupportedProgramNotificationPopupProps extends Pick<BaseCriticalErrorPopupProps, 'show'> {
    onResponse?: () => void;
}

/**
 * A React functional component that displays a notification popup for unsupported programs.
 * The popup includes a headline, body text, and a single primary button for user response.
 */
const UnsupportedProgramNotificationPopup: React.FC<UnsupportedProgramNotificationPopupProps> = ({
                                                                                                     show = true,
                                                                                                     onResponse
                                                                                                 }) => {
    const {t} = useTranslation();
    return <Dialog
        label={""}
        headline={t("unsupported_program_notification_popup_headline")}
        text={t("unsupported_program_notification_popup_body_text")}
        type={DialogType.Info}
        width={TailwindStandardWidth.W200}
        showCloseButton={false}
        buttons={[
            {
                label: t("button_continue"),
                primary: true,
                onClick: onResponse,
            },
        ]}
        show={show}
    />
};

export default UnsupportedProgramNotificationPopup;
