import {
    Dialog,
    DialogType,
    getVectorLength,
    Headline,
    HeadlineSize,
    InvestigationMap,
    IsoptereModes,
    isSamePosition,
    Line,
    ProjectorBorder,
    useResizeObserver,
} from "@oculus/component-library";
import {convertToSimpleIsopter, SimpleIsoptere} from "./IsoptereModesController";
import React, {useEffect, useState} from "react";
import {BaseIsopterDialogProps} from "./ManualKinetic";
import {degSteps} from "../ExecuteExaminationPage";

/** `CombineIsopterDialog` component properties */
export interface CombineIsopterDialogProps extends BaseIsopterDialogProps {
    /** Indices of the isopteres for combining */
    isopterIndices?: number[];
}

/**
 * A dialog component for combining isopteres.
 *
 * @component
 * @see CombineIsopterDialogProps
 */
const CombineIsopterDialog: React.FC<CombineIsopterDialogProps> = ({
                                                                       investigationMapProps,
                                                                       isopteresController,
                                                                       isopterIndices = [],
                                                                       show = true,
                                                                       onClose,
                                                                       projectorBorder,
                                                                   }) => {
    const [mapContainerSize, mapContainerSizeRef] = useResizeObserver({width: 0, height: 0}, ({
                                                                                                  width,
                                                                                                  height
                                                                                              }) => ({width, height}));
    const [previewIsopter, setPreviewIsopter] = useState<{
        isopterIndices: number[],
        isopter?: SimpleIsoptere
    }>({isopterIndices});
    const [linkCustom, setLinkCustom] = useState<Line | null>(null);
    const linkedPreviewIsopter: SimpleIsoptere | null = linkCustom && (previewIsopter.isopter ?? null) && (() => {
        const isopter = previewIsopter.isopter!;
        if (linkCustom.length !== isopter.elements.length) {
            return null;
        }
        const elements: (typeof isopter)["elements"] = [];
        for (const position of linkCustom) {
            const element = isopter.elements.find(({position: oPosition}) => isSamePosition(position, oPosition));
            if (!element) {
                return null;
            }
            elements.push(element);
        }
        return {...isopter, elements}
    })();
    useEffect(() => {
        // Reset when linkCustom is invalid
        if ((linkCustom?.length ?? 0) > 0 && !linkedPreviewIsopter) {
            setLinkCustom([]);
        }
    }, [(linkCustom?.length ?? 0) > 0, !linkedPreviewIsopter]);
    useEffect(() => {
        setPreviewIsopter({isopterIndices});
        setLinkCustom(show ? (prevState) => prevState && [] : null);
        if (!isopterIndices.length || !show) {
            return;
        }
        isopteresController.combineIsopteresPreview(isopterIndices)
            .then((isopter) =>
                setPreviewIsopter((prevState) =>
                    prevState.isopterIndices.toString() === isopterIndices.toString()
                        ? {...prevState, isopter: convertToSimpleIsopter(isopter)}
                        : prevState
                )
            ).catch(console.error);
    }, [isopterIndices.toString(), show]);
    const degOnAxis = (previewIsopter.isopter?.elements ?? []).reduce((acc, {position}) => Math.max(acc, getVectorLength(position)), 0) || undefined;
    return (
        <Dialog label="" headline="Isopteren zusammenführen" type={DialogType.None} showCloseButton={false}
                furtherclass="w-[100vmin]" buttons={[
            {
                primary: true, label: "Isopteren zusammenführen",
                disabled: !isopteresController.canHandleRequests || isopterIndices.length < 2 || (!!linkCustom && !linkedPreviewIsopter),
                onClick: async () => {
                    const newIsopterIndex = (await isopteresController.combineIsopteres(isopterIndices)).isopterRef.index;
                    if (linkCustom) {
                        await isopteresController.linkIsoptere(newIsopterIndex, linkCustom);
                    }
                    onClose?.();
                }
            },
            {
                primary: false, label: "Isopteren manuell zeichnen",
                disabled: (previewIsopter.isopter?.elements.length ?? 0) < 2 || (!!linkCustom && !linkedPreviewIsopter),
                onClick: () => setLinkCustom((prevState) => prevState ?? [])
            },
            {
                primary: false, label: "Abbrechen", onClick: onClose,
                disabled: !isopteresController.canHandleRequests
            },
        ]} show={show}>{({contentHeight, maxContentHeight}) => (
            <div className="flex flex-col">
                <div className="bg-grey4 text-white p-2 mb-4">
                    <Headline text="Vorschau" size={HeadlineSize.Subtitle1}
                              furtherClasses="text-center text-white !m-0"/>
                </div>
                <div style={{
                    height: Math.min(mapContainerSize.width, maxContentHeight - (contentHeight - mapContainerSize.height)),
                }} ref={mapContainerSizeRef}>
                    <InvestigationMap {...investigationMapProps}
                                      degOnAxis={degOnAxis} degSteps={degSteps}>
                        {(childProps) => (
                            <>
                                {projectorBorder && <ProjectorBorder {...childProps} {...projectorBorder}/>}
                                <IsoptereModes {...childProps}
                                               mode={linkCustom && !linkedPreviewIsopter ? "Link" : null}
                                               isopteres={previewIsopter.isopter ? [{
                                                   connect: true,
                                                   canLink: false,
                                                   canCombine: false, ...(linkedPreviewIsopter ?? previewIsopter.isopter)
                                               }] : []}
                                               onLinkIsopterDone={(isopterIndex, positions) => setLinkCustom(positions)}
                                />
                            </>
                        )}</InvestigationMap>
                </div>
            </div>
        )}</Dialog>
    );
};

export default CombineIsopterDialog;