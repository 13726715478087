import React, {useEffect, useState} from "react";
import {
    BodyText,
    BodyTextType,
    Dialog,
    DialogType,
    Dropdown,
    ErrorMarker,
    Input,
    TailwindStandardHeight,
    TailwindStandardWidth,
    ToggletipLabel, useTranslation,
} from "@oculus/component-library";
import {DicomExportConfig} from "../../../backend/api/interfaces/data/DicomExportConfig";

/** `DicomExportPopup` component properties */
export interface DicomExportProps {
    /**
     * Callback function to handle the response of the popup.
     *
     * @param config - The updated DICOM export configuration.
     */
    onResponse?: (config: DicomExportConfig) => void;

    /** Callback function to handle the cancellation of the popup. */
    onCancel?: () => void;

    /** The DICOM export configuration to prefill values in the form. */
    config?: DicomExportConfig;

    mode?: "save" | "pacs";

    /** Controls the visibility of the popup. */
    show?: boolean;

    /** Disables buttons and shows a loading animation when true. */
    loading?: boolean;

    /** An optional error message to display in the popup. */
    errorMessage?: string | null;
}

/**
 * Dicom Export Popup
 *
 * @component
 * {@link DicomExportProps}
 */
const DicomExportPopup: React.FC<DicomExportProps> = ({
                                                          onResponse,
                                                          onCancel,
                                                          config,
                                                          show = true,
                                                          mode = "save",
                                                          loading = false,
                                                          errorMessage = null,
                                                      }) => {
    const {t} = useTranslation();
    const [modifiedConfig, setModifiedConfig] = useState(config);
    useEffect(() => setModifiedConfig(config), [config, show]);
    const updateConfig = ((changes: Partial<typeof modifiedConfig>) => setModifiedConfig((config) => config && {...config, ...changes}));
    const nameOfPhysicianReadingItems = modifiedConfig?.readingPhysiciansName
        .map((dicomValue, readingPhysiciansNameIndex) => ({name: dicomValue.customName + " " + dicomValue.dicomName, readingPhysiciansNameIndex})) ?? [];
    const procedureCodeSequenceItems = modifiedConfig?.procedureCodeSequence
        .map((dicomValue, procedureCodeSequenceIndex) => ({name: dicomValue.customName + " " + dicomValue.dicomName, procedureCodeSequenceIndex})) ?? [];
    const operatorsNameItems = modifiedConfig?.operatorsName
        .map((dicomValue, operatorsNameIndex) => ({name: dicomValue.customName + " " + dicomValue.dicomName, operatorsNameIndex})) ?? [];
    const performedProcedureStepIdItems = modifiedConfig?.performedProcedureStepId
        .map((dicomValue, performedProcedureStepIdIndex) => ({name: dicomValue.customName + " " + dicomValue.dicomName, performedProcedureStepIdIndex})) ?? [];
    const performingPhysicianNameItems = modifiedConfig?.performingPhysicianName
        .map((dicomValue, performingPhysicianNameIndex) => ({name: dicomValue.customName + " " + dicomValue.dicomName, performingPhysicianNameIndex})) ?? [];
    return <Dialog
        label={t("configuration")}
        headline={t("dicom_export")}
        width={TailwindStandardWidth.W228}
        showCloseButton={false}
        show={show}
        type={DialogType.None}
        customButtonElements={<ErrorMarker className="ml-1" error={errorMessage !== null && (<>
            <BodyText type={BodyTextType.B2Bold}
                      text={t("export_error")}/>
            {errorMessage}
        </>)} size={40}/>}
        buttons={[
            {
                label: mode === "save" ? t("button_save") : t("data_transfer_tp_pacs"),
                primary: true,
                disabled: loading || !modifiedConfig,
                loading,
                onClick: modifiedConfig && (() => {
                    modifiedConfig && onResponse?.(modifiedConfig);
                }),
            },
            ...onCancel ? [{
                label: t("button_cancel"),
                primary: false,
                disabled: loading,
                onClick: () => {
                    onCancel();
                },
            }] : [],
        ]}
    >
        <div className="grid grid-cols-2 auto-rows-auto gap-y-6 gap-x-20">
            <div>
                <Dropdown items={nameOfPhysicianReadingItems} width={TailwindStandardWidth.FULL}
                          currentDropdownItem={nameOfPhysicianReadingItems.find(({readingPhysiciansNameIndex}) => readingPhysiciansNameIndex === modifiedConfig!.readingPhysiciansNameIndex)}
                          label={<ToggletipLabel label={t("name_of_physician_reading")}/>}
                          onChange={({readingPhysiciansNameIndex}) => updateConfig({readingPhysiciansNameIndex})}/>
            </div>
            <div>
                <Dropdown items={operatorsNameItems} width={TailwindStandardWidth.FULL}
                          currentDropdownItem={operatorsNameItems.find(({operatorsNameIndex}) => operatorsNameIndex === modifiedConfig!.operatorsNameIndex)}
                          label={<ToggletipLabel label={t("operators_name")}/>}
                          onChange={({operatorsNameIndex}) => updateConfig({operatorsNameIndex})}/>
            </div>
            <div>
                <Dropdown items={procedureCodeSequenceItems} width={TailwindStandardWidth.FULL}
                          currentDropdownItem={procedureCodeSequenceItems.find(({procedureCodeSequenceIndex}) => procedureCodeSequenceIndex === modifiedConfig!.procedureCodeSequenceIndex)}
                          label={<ToggletipLabel label={t("procedure_code_sequence")}/>}
                          onChange={({procedureCodeSequenceIndex}) => updateConfig({procedureCodeSequenceIndex})}/>
            </div>
            <div>
                <Dropdown items={performedProcedureStepIdItems} width={TailwindStandardWidth.FULL}
                          currentDropdownItem={performedProcedureStepIdItems.find(({performedProcedureStepIdIndex}) => performedProcedureStepIdIndex === modifiedConfig!.performedProcedureStepIdIndex)}
                          label={<ToggletipLabel label={t("performed_procedure_step_id")}/>}
                          onChange={({performedProcedureStepIdIndex}) => updateConfig({performedProcedureStepIdIndex})}/>
            </div>
            <div>
                <Input
                    label={<ToggletipLabel label={t("description")}/>}
                    onChange={(description) => updateConfig({description})}
                    value={modifiedConfig?.description}
                    showStateText={false}
                    width={TailwindStandardWidth.FULL}
                />
            </div>
            <div>
                <Input
                    label={<ToggletipLabel label={t("performed_procedure_description")}/>}
                    onChange={(performedProcedureStepDescription) => updateConfig({performedProcedureStepDescription})}
                    value={modifiedConfig?.performedProcedureStepDescription}
                    showStateText={false}
                    width={TailwindStandardWidth.FULL}
                />
            </div>
            <div>
                <Input
                    label={<ToggletipLabel label={t("accession_number")}/>}
                    onChange={(accessionNumber) => updateConfig({accessionNumber})}
                    value={modifiedConfig?.accessionNumber}
                    showStateText={false}
                    width={TailwindStandardWidth.FULL}
                />
            </div>
            <div className="col-start-1">
                <Dropdown items={performingPhysicianNameItems} width={TailwindStandardWidth.FULL}
                          currentDropdownItem={performingPhysicianNameItems.find(({performingPhysicianNameIndex}) => performingPhysicianNameIndex === modifiedConfig!.performingPhysicianNameIndex)}
                          label={<ToggletipLabel label={t("performing_physicians_name")}/>}
                          onChange={({performingPhysicianNameIndex}) => updateConfig({performingPhysicianNameIndex})}/>
            </div>
            <div className="col-start-2 row-start-4 row-span-2">
                <Input
                    label={<ToggletipLabel label={("comment_on_the_pps")}/>}
                    height={TailwindStandardHeight.FULL}
                    onChange={(performedProcedureStepComments) => updateConfig({performedProcedureStepComments})}
                    value={modifiedConfig?.performedProcedureStepComments}
                    showStateText={false}
                    renderAsTextArea={true}
                    width={TailwindStandardWidth.FULL}
                />
            </div>
        </div>
    </Dialog>;
};

export default DicomExportPopup;