import {
    BodyText,
    BodyTextType, IsoptereSteps,
    Isopteresymbols,
    IsoptereSymbolsColor, IsoptereSymbolsForm,
    RadioButtonGroup, StimulusSize,
    ToggletipLabel, useTranslation
} from "@oculus/component-library";
import React from "react";
import {SimpleIsopterConfig} from "./manualKinetic/IsoptereModesController";

/**
 * Represents the properties for configuring Isopter settings.
 * Extends the SimpleIsopterConfig interface to inherit base configuration options.
 * Includes an optional callback function for handling configuration changes.
 */
export interface IsopterSettingsProps extends SimpleIsopterConfig {
    onChange?: (config: SimpleIsopterConfig) => void;
    stimulusSizes?: StimulusSize[];
}

/**
 * Array defining the options for isoptere sizes, each with a specific label and associated color.
 * Each element in the array represents an isoptere size option, characterized by a label and a corresponding color value.
 */
const isoptereSizeOptions = [
    {label: "V", isoptereColor: IsoptereSymbolsColor.Red},
    {label: "IV", isoptereColor: IsoptereSymbolsColor.Violet},
    {label: "III", isoptereColor: IsoptereSymbolsColor.DarkBlue},
    {label: "II", isoptereColor: IsoptereSymbolsColor.Magenta},
    {label: "I", isoptereColor: IsoptereSymbolsColor.Orange},
]

/**
 * Represents an array of options for configuring isoptere brightness levels.
 * The options define various brightness levels ranging from "1" to "4", with corresponding symbols and colors.
 */
const isoptereBrightnessOptions = [
    {label: "4", isoptereSymbol: IsoptereSymbolsForm.Triangle, isoptereColor: IsoptereSymbolsColor.Grey},
    {label: "3", isoptereSymbol: IsoptereSymbolsForm.Square, isoptereColor: IsoptereSymbolsColor.Grey},
    {label: "2", isoptereSymbol: IsoptereSymbolsForm.Hexagon, isoptereColor: IsoptereSymbolsColor.Grey},
    {label: "1", isoptereSymbol: IsoptereSymbolsForm.Circle, isoptereColor: IsoptereSymbolsColor.Grey},
]

/**
 * An array of objects representing options for isoptere steps.
 * Each object in the array contains a label and an associated isoptere step.
 */
const isoptereStepOptions = [
    {label: "e", isoptereStep: IsoptereSteps.E},
    {label: "d", isoptereStep: IsoptereSteps.D},
    {label: "c", isoptereStep: IsoptereSteps.C},
    {label: "b", isoptereStep: IsoptereSteps.B},
    {label: "a", isoptereStep: IsoptereSteps.A},
];

/**
 * Represents the sequential order of stimulus sizes to be used.
 * The array contains predefined size values from the StimulusSize enumeration.
 * Each element in the array corresponds to a specific stimulus size.
 *
 * The order of stimulus sizes is:
 * 1. StimulusSize.One
 * 2. StimulusSize.Two
 * 3. StimulusSize.Three
 * 4. StimulusSize.Four
 * 5. StimulusSize.Five
 */
const stimulusSizeOrder = [StimulusSize.One, StimulusSize.Two, StimulusSize.Three, StimulusSize.Four, StimulusSize.Five];
/**
 * Maps a given stimulus size to its corresponding isopter label and color.
 */
const stimulusSizeToIsopterSize = (size: StimulusSize) => {
    switch (size) {
        case StimulusSize.One:
            return {label: "I", isoptereColor: IsoptereSymbolsColor.Orange}
        case StimulusSize.Two:
            return {label: "II", isoptereColor: IsoptereSymbolsColor.Magenta}
        case StimulusSize.Three:
            return {label: "III", isoptereColor: IsoptereSymbolsColor.DarkBlue}
        case StimulusSize.Four:
            return {label: "IV", isoptereColor: IsoptereSymbolsColor.Violet}
        case StimulusSize.Five:
            return {label: "V", isoptereColor: IsoptereSymbolsColor.Red}
    }
}

/**
 * IsopterSettings is a React functional component that provides settings configuration
 * for isopter symbols. The component includes options to adjust size, brightness, and
 * stage, with a real-time preview of the selected configurations.
 */
const IsopterSettings: React.FC<IsopterSettingsProps> = ({form, color, steps, onChange, stimulusSizes}) => {
    const sortedStimulusSizes = stimulusSizes?.sort((a, b) =>
        stimulusSizeOrder.indexOf(b) - stimulusSizeOrder.indexOf(a)
    );
    const {t} = useTranslation();
    return <div className="flex flex-row gap-8">
        <RadioButtonGroup
            label={<ToggletipLabel label={t("size")}/>}
            onChange={(key) => onChange?.({form, steps, color: key as IsoptereSymbolsColor})}
            selected={color}
            elements={sortedStimulusSizes?.map((stimulusSize) => {
                const isopterConfig = stimulusSizeToIsopterSize(stimulusSize);
                return {
                    ...isopterConfig,
                    key: isopterConfig.isoptereColor
                };
            })}/>
        <RadioButtonGroup
            label={<ToggletipLabel label={t("brightness")}/>}
            onChange={(key) => onChange?.({form: key as IsoptereSymbolsForm, steps, color})}
            selected={form}
            elements={isoptereBrightnessOptions.map((props) => ({...props, key: props.isoptereSymbol}))}/>
        <RadioButtonGroup
            label={<ToggletipLabel label={t("stage")}/>}
            onChange={(key) => onChange?.({form, steps: key as IsoptereSteps, color})}
            selected={steps}
            elements={isoptereStepOptions.map((props) => ({...props, key: props.isoptereStep}))}
        />
        <div className="flex flex-col min-h-full max-w-64 w-full bg-grey1 ml-6">
            <div className="w-full min-h-8 flex items-center justify-center bg-grey4">
                <BodyText text={t("preview")} type={BodyTextType.B2Bold}
                          furtherClasses="text-white !mb-0"/>
            </div>
            <div className="h-full flex items-center justify-center">
                <Isopteresymbols
                    color={color}
                    formType={form}
                    withLine={true} withArrow={false}
                />
                <BodyText type={BodyTextType.B1Bold} furtherClasses={`ml-4 !mb-0 text-dark`}
                          text={isoptereSizeOptions.find(({isoptereColor}) => isoptereColor === color)!.label
                              + " "
                              + isoptereBrightnessOptions.find(({isoptereSymbol}) => isoptereSymbol === form)!.label
                              + isoptereStepOptions.find(({isoptereStep}) => isoptereStep === steps)!.label}/>
            </div>
        </div>
    </div>
};

export default IsopterSettings;