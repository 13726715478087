import {
    ActivePoint,
    BodyText,
    BodyTextType,
    Button,
    ButtonTextSize,
    ButtonType,
    getElementMatrix,
    getVectorLength,
    Headline,
    HeadlineSize,
    InteractionMenuGroup,
    InvestigationMap,
    InvestigationMapCartesianPoint,
    isInsideProjectorBorder,
    MapControlGroup,
    multiplyVector,
    NumberPointColor,
    PositionPopupDialog,
    ProjectorBorder,
    rectMatrixTransform,
    SymbolPointStyle,
    useTranslation,
    VisualFieldLimits,
} from "@oculus/component-library";
import React, {useEffect, useState} from "react";
import {
    ExaminationComponentProps,
    navigateWithSettings,
    parseFollowUpPoints,
    serializeFollowUpPoints
} from "./index";
import SettingsPreview from "./SettingsPreview";
import {
    calculateDegOnAxis,
    mapMeasurementsToInvestigationMap,
    MeasurementMapLegend,
    useMapControlsCentering,
} from "./ExecuteExaminationPage";
import {Position} from "../../backend/api/interfaces/data/Position";
import {isEqualPosition} from "../../helper";
import {PatternType} from "../../backend/api/interfaces/custom-datatypes/PatternType";
import ExaminationLayout from "./ExaminationLayout";
import {ProgramType} from "../../backend/api/interfaces/custom-datatypes/ProgramType";
import {putMaxEccentricity} from "../../backend/api/Calls";

/**
 * A component designed to display an ongoing measurement.
 *
 * It not only shows the status of the measurement but also handles kinetic mode.
 *
 * @component
 * {@link ExaminationComponentProps}
 */
const FollowUpExaminationPage: React.FC<ExaminationComponentProps> = (props) => {
    const {
        eyePreview,
        settings,
        measurements,
        programWithRefList,
        deviceInformation,
        frontendSettings,
    } = props;
    const {editState, applyEdit, updateEdit} = (() => {
        type State = { select: boolean, custom: boolean, customPopup: Position | null, followUpPoints: Position[] };
        const [state, setState] = useState<State | null>(null);
        const apply = () => setState((prevState) => {
            if (prevState) {
                navigateWithSettings("", {
                    ...settings,
                    followUpPoints: serializeFollowUpPoints(prevState.followUpPoints)
                });
            }
            return null;
        });
        const update = (s: Partial<State> | ((prevState: State) => Partial<State>)) => setState((prevState) => {
            const prevStateOrBasis = {
                followUpPoints: parseFollowUpPoints(settings.followUpPoints),
                select: false,
                custom: false,
                customPopup: null,
                ...prevState,
            };
            return {
                ...prevStateOrBasis,
                ...typeof s === "function" ? s(prevStateOrBasis) : s,
            };
        });
        return {editState: state, applyEdit: apply, updateEdit: update};
    })();

    const selectedPoints = editState?.followUpPoints ?? parseFollowUpPoints(settings.followUpPoints);
    const { t } = useTranslation();

    const [hideAxialValues, setHideAxialValues] = useState(false);
    const [hideRadialValues, setHideRadialValues] = useState(true);
    const [eccentricity, setEccentricity] = useState<number | null>(null);
    const {setMapControlsContainerElement, setMapCenterElement} = useMapControlsCentering();
    const [showVisualFieldLimit, setShowVisualFieldLimit] = useState(false)

    // settings are checked on higher level
    const program = settings.manualKinetic ? null : programWithRefList?.find(({uuid}) => uuid === settings.program) ?? null;

    const customPoints: ({
        position: InvestigationMapCartesianPoint
    })[] = selectedPoints.filter((oPosition) => !measurements.find(({position}) => isEqualPosition(position, oPosition))).map((position) => ({position}));
    const {symbolPoints, numberPoints} = mapMeasurementsToInvestigationMap(measurements);
    [...symbolPoints ?? [], ...numberPoints ?? []].forEach((props) => {
        const {position} = props;
        const active = selectedPoints.some((oPosition) => isEqualPosition(position, oPosition));
        props.active = active;
        if (editState?.select) {
            props.onClick = () => updateEdit(({followUpPoints}) => ({
                followUpPoints: [
                    ...followUpPoints.filter((oPosition) => !isEqualPosition(position, oPosition)),
                    ...active ? [] : [position],
                ],
            }));
        }
    });
    const [calculatedEccentricity, setCalculatedEccentricity] = useState<number>(0)
    const positions = [
        ...symbolPoints.map(point => point.position),
        ...numberPoints.map(point => point.position)
    ];
    useEffect(() => {
        calculateDegOnAxis(
            settings.manualKinetic ?? false,
            program,
            deviceInformation,
            false,
            positions,
            setCalculatedEccentricity,
            putMaxEccentricity
        ).then();
    }, [eccentricity]);

    return (
        <ExaminationLayout
            sidepanelButtons={
                <Button size={ButtonTextSize.Large} furtherClassesBtn="h-[56px]"
                        label={t("examination_start_follow_up_alternative")} furtherClassesWrapper="!w-full"
                        onClick={() => {
                            navigateWithSettings("../execution", settings, {replace: true});
                        }}
                        disabled={selectedPoints.length === 0 || !!editState}
                />
            }
            sidepanelCamera={eyePreview}
            sidepanel={<>
                <div className={"hidden xl:block mb-2"}>
                    <SettingsPreview {...props}  />
                </div>
                {editState && <Button
                    label={t("save_point_selection")}
                    type={ButtonType.Primary}
                    size={ButtonTextSize.Large}
                    onClick={applyEdit}
                />}
                {editState?.select && (numberPoints.length > 0) && numberPoints.some(({color, active}) => color === NumberPointColor.RED && !active) &&  <Button
                    label={t("auto_selection_button_follow_up")}
                    type={ButtonType.Secondary}
                    size={ButtonTextSize.Large}
                    onClick={() => updateEdit(({followUpPoints}) => ({
                        followUpPoints: [
                            ...followUpPoints,
                            ...numberPoints.filter(({color, position}) =>
                                color === NumberPointColor.RED
                                && !followUpPoints.some((oPosition) => isEqualPosition(position, oPosition))
                            ).map(({position}) => position),
                        ],
                    }))}
                />}
                {editState?.select && (numberPoints.length > 0) && numberPoints.some(({color, active}) => color === NumberPointColor.RED && active) && <Button
                    label={t("auto_deselection_button_follow_up")}
                    type={ButtonType.Secondary}
                    size={ButtonTextSize.Large}
                    onClick={() => updateEdit(({followUpPoints}) => ({
                        followUpPoints: followUpPoints.filter((position) =>
                            !numberPoints.some(({color, active, position: numberPosition}) =>
                                color === NumberPointColor.RED && active && isEqualPosition(position, numberPosition)
                            )
                        ),
                    }))}
                />}
                {editState?.select && (symbolPoints.length > 0) && symbolPoints.some(({pattern, active}) => pattern.type !== PatternType.Seen && !active) && <Button
                    label={t("auto_selection_button_follow_up")}
                    type={ButtonType.Secondary}
                    size={ButtonTextSize.Large}
                    onClick={() => updateEdit(({followUpPoints}) => ({
                        followUpPoints: [
                            ...followUpPoints,
                            ...symbolPoints.filter(({pattern, position}) =>
                                pattern.type !== PatternType.Seen
                                && !followUpPoints.some((oPosition) => isEqualPosition(position, oPosition))
                            ).map(({position}) => position),
                        ],
                    }))}
                />}
                {editState?.select && (symbolPoints.length > 0) && symbolPoints.some(({pattern, active}) => pattern.type !== PatternType.Seen && active) && <Button
                    label={t("auto_deselection_button_follow_up")}
                    type={ButtonType.Secondary}
                    size={ButtonTextSize.Large}
                    onClick={() => updateEdit(({followUpPoints}) => ({
                        followUpPoints: followUpPoints.filter((position) =>
                            !symbolPoints.some(({pattern, position: symbolPosition}) =>
                                pattern.type !== PatternType.Seen
                                && isEqualPosition(position, symbolPosition)
                            )
                        ),
                    }))}
                />}
                {!editState?.select && <Button
                    label={t("change_point_selection")}
                    type={ButtonType.Secondary}
                    size={ButtonTextSize.Large}
                    disabled={!numberPoints.length && !symbolPoints.length}
                    onClick={() => updateEdit({select: true})}
                />}
                {!editState?.custom && <Button
                    label={t("set_individual_points")}
                    type={ButtonType.Secondary}
                    size={ButtonTextSize.Large}
                    disabled={false}
                    onClick={() => updateEdit({select: true, custom: true})}
                />}
            </>}>
            <div className="col-span-9">
                <Headline
                    size={HeadlineSize.H3}
                    text={t("select_points_follow_up")}
                    furtherClasses="!mb-8 text-blue1"
                />
                <BodyText
                    text={t("follow_up_text")}
                    type={BodyTextType.B1Regular}
                    furtherClasses="text-grey5 !mb-0"
                />
            </div>
            <div className="col-span-12 z-10 mt-8 flex justify-center items-center gap-4" onContextMenu={(event) => event.preventDefault()}>
                <div className={"h-full max-w-[90%] aspect-square"}>
                <InvestigationMap
                    eyeSelection={settings.eye}
                    staticMap={!settings.manualKinetic}
                    degOnAxis={calculatedEccentricity}
                    hideAxialValues={hideAxialValues}
                    hideRadialValues={hideRadialValues}
                    symbolPointsStyle={frontendSettings?.useModernDesign ?? true ? SymbolPointStyle.Modern : SymbolPointStyle.Classic}
                    symbolPoints={symbolPoints}
                    numberPoints={numberPoints}
                    rightChild={program?.type === ProgramType.Static || program?.type === ProgramType.Combi ?
                        <MeasurementMapLegend strategy={program.staticParameters.strategy}/> : null}
                >
                    {(childProps) => {
                        const posToStyle = (position: Position) => ({
                            left: (position.x / childProps.degOnAxis + 1) / 2 * childProps.innerSize,
                            top: (-position.y / childProps.degOnAxis + 1) / 2 * childProps.innerSize,
                        });
                        return (<>
                            {deviceInformation &&
                                <ProjectorBorder {...deviceInformation} {...childProps} />}
                            <div className="absolute top-1/2 right-0 pointer-events-none"
                                 ref={setMapCenterElement}/>
                            {showVisualFieldLimit && (
                                <VisualFieldLimits
                                    {...childProps}
                                    manualKinetic={settings.manualKinetic}
                                    blindspot={props.visualFieldLimit?.blindspot}
                                    peripheral={props.visualFieldLimit?.peripheral}
                                    projectorBorder={deviceInformation ?? undefined}
                                />
                            )}
                            {customPoints.map(({position}, index) => getVectorLength(position) <= childProps.degOnAxis && (
                                <div className="absolute -translate-x-1/2 -translate-y-1/2"
                                     key={`custom-point-${index}`}
                                     style={posToStyle(position)}
                                     onContextMenu={() => {
                                         if (editState?.custom) {
                                             updateEdit(() => ({customPopup: position}));
                                         }
                                     }}>
                                    <ActivePoint/>
                                </div>
                            ))}
                            {editState?.custom && deviceInformation ? (
                                <div className="absolute inset-0 -z-10"
                                     style={{margin: -childProps.innerOffset}}
                                     onClick={(event) => {
                                         const invTargetMatrix = getElementMatrix(event.currentTarget).inverse();
                                         const targetRect = rectMatrixTransform(event.currentTarget.getBoundingClientRect(), invTargetMatrix);
                                         const {
                                             x: cursorX,
                                             y: cursorY
                                         } = (new DOMPoint(event.clientX, event.clientY)).matrixTransform(invTargetMatrix);
                                         const position = multiplyVector({
                                             x: ((cursorX - targetRect.x) / childProps.outerSize * 2 - 1),
                                             y: -((cursorY - targetRect.y) / childProps.outerSize * 2 - 1),
                                         }, childProps.outerDegOnAxis);
                                         if (getVectorLength(position) <= childProps.degOnAxis
                                             && isInsideProjectorBorder(deviceInformation, position)) {
                                             updateEdit((prevState) => ({
                                                 followUpPoints: [...prevState.followUpPoints, position],
                                             }));
                                         }
                                     }}/>
                            ) : null}
                            <PositionPopupDialog open={!!editState?.customPopup}
                                                 onClose={() => updateEdit({customPopup: null})}
                                                 style={editState?.customPopup ? posToStyle(editState.customPopup) : undefined}>
                                <div className="inset-0 fixed"
                                     onClick={() => updateEdit({customPopup: null})}
                                     onContextMenu={() => updateEdit({customPopup: null})}/>
                                <InteractionMenuGroup items={[{
                                    iconKey: "delete",
                                    label: t("delete_point"),
                                    onClick: () => updateEdit(({followUpPoints, customPopup}) => ({
                                        followUpPoints: followUpPoints.filter((oPosition) => !(customPopup && isEqualPosition(oPosition, customPopup))),
                                        customPopup: null,
                                    })),
                                },]}/>
                            </PositionPopupDialog>
                        </>);
                    }}
                </InvestigationMap>
                </div>
                <div className="" ref={setMapControlsContainerElement}>
                    <MapControlGroup
                        degOnAxis={calculatedEccentricity}
                        onDegOnAxisChange={setEccentricity}
                        degOnAxisItems={Array.from({length: 9}, (_, i) => (i + 1) * 10)}
                        singleView
                        withHideAxialValues={false}
                        withHideRadialValues={false}
                        withShowLimits={!!props.visualFieldLimit}
                        showLimits={showVisualFieldLimit}
                        onShowLimitsChange={() => setShowVisualFieldLimit(!showVisualFieldLimit)}
                        hideRadialValues={hideRadialValues}
                        onHideRadialValuesChange={setHideRadialValues}
                        hideAxialValues={hideAxialValues}
                        onHideAxialValuesChange={setHideAxialValues}
                    />
                </div>
            </div>

        </ExaminationLayout>
    );
};

export default FollowUpExaminationPage;