/**
 * Global App State
 *
 * This encompasses all data that the application needs to manage and persist across sessions.
 * Data is stored in Local Storage for persistence, enabling the state to be
 * successfully restored even after the application is closed or reloaded.
 *
 * All data included in the Local Storage is validated using Zod, to ensure
 * consistency and adherence to expected data structures.
 */

import {util as zodUtil, z, ZodDefault, ZodOptional, ZodType, ZodTypeDef} from "zod";
import {newStore, Store, useStore} from "@oculus/component-library";
import {DeviceInformationResponse} from "./backend/api/interfaces/response/DeviceInformationResponse";
import {DeviceNameDeviceName} from "./backend/api/interfaces/custom-datatypes/DeviceNameDeviceName";
import {PatientSex} from "./backend/api/interfaces/custom-datatypes/PatientSex";
import {CombinedDateFormat, DeviceSettings} from "./backend/api/interfaces/data/DeviceSettings";

/** Zod schema for patient's personal information displayed in the `TopBar`. */
export const AppStatePatientInfoSchema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    age: z.number(),
    sex: z.nativeEnum(PatientSex),
    externalId: z.string().or(z.null()),
    dateOfBirth: z.string().regex(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/).refine((v) => !isNaN(new Date(v).getDate())),
});

/** Zod schema for the eye displayed in the `TopBar`. */
export const EyeSchema = z.enum(["left", "right", "both"]);

/** Default patient configuration preset. */
export const patientConfigPreset = {
    apiUrl: process.env.GATSBY_API_BASE_URL,
    wsUrl: process.env.GATSBY_WEBSOCKET_URL,
    examinationDirPath: process.env.GATSBY_EXAMINATION_DIR_PATH,
    patientFilePath: process.env.GATSBY_PATIENT_FILE_PATH,
};

/**
 * Wraps a Zod type with an optional default value, if provided.
 *
 * If no default value is specified, the original Zod type is returned.
 *
 */
function optionalDefault<Output, Def extends ZodTypeDef, Input>(type: ZodType<Output, Def, Input>, def: zodUtil.noUndefined<Input> | undefined): ZodType<Output, Def, Input> | ZodDefault<ZodType<Output, Def, Input>> {
    if (def === undefined) {
        return type;
    }
    return type.default(def);
}

/** Zod schema used to validate and parse the patient token. */
export const AppStatePatientConfigSchema = z.object({
    wsUrl: optionalDefault(z.union([z.string().url().regex(/^wss?:/i), z.literal("mockserver")]), patientConfigPreset.wsUrl),
    apiUrl: optionalDefault(z.string().url(), patientConfigPreset.apiUrl),
    patientFilePath: optionalDefault(z.string().min(1), patientConfigPreset.patientFilePath),
    examinationDirPath: optionalDefault(z.string().min(1), patientConfigPreset.examinationDirPath),
});

export const AppStateDeviceSettingsSchema: z.ZodType<{
    main: {
        dateFormat: DeviceSettings["main"]["dateFormat"],
        language: DeviceSettings["main"]["language"],
    },
}> = z.object({
    main: z.object({
        dateFormat: z.nativeEnum(CombinedDateFormat),
        language: z.string(),
    }),
});

/**
 * Schema for device information.
 *
 * @see DeviceInformationResponse
 */
export const AppStateDeviceInformationSchema: z.ZodType<DeviceInformationResponse> = z.object({
    connectedDevice: z.object({
        name: z.nativeEnum(DeviceNameDeviceName),
    }),
    serialNumber: z.string(),
    deviceNumber: z.string(),
    examCounter: z.number(),
    firmwareVersion: z.string(),
    supportsBlueStimulus: z.boolean(),
    supportsRedStimulus: z.boolean(),
    supportsKinetic: z.boolean(),
    supportsVerticalChinrestMove: z.boolean(),
    supportsHorizontalChinrestMove: z.boolean(),
    xMaxEccentricity: z.number().int(),
    yMaxEccentricity: z.number().int(),
});

/** Main schema for storing AppState as defined by the individual schemas. */
export const AppStateSchema = z.object({
    patientInfo: z.optional(AppStatePatientInfoSchema),
    patientConfig: z.optional(AppStatePatientConfigSchema),
    eye: z.optional(EyeSchema),
    deviceInformation: z.optional(AppStateDeviceInformationSchema),
    deviceSettings: z.optional(AppStateDeviceSettingsSchema),
    token: z.optional(z.string()),
});

/** Type definition for {@link AppStatePatientInfoSchema}. */
export type AppStatePatientInfo = z.infer<typeof AppStatePatientInfoSchema>;
/** Type definition for {@link AppStatePatientConfigSchema}. */
export type AppStatePatientConfig = z.infer<typeof AppStatePatientConfigSchema>;
/** Type definition for {@link AppStateSchema}. */
export type AppState = z.infer<typeof AppStateSchema>;

/**
 * Global store for application state.
 *
 * This store is initialized from the local storage ("app-state").
 *
 * The stored state is then written in local storage whenever it changes.
 *
 * @see Store
 */
export const appStateStore: Store<AppState> = (() => {
    let initAppState: AppState = {};
    if (typeof window !== "undefined") {
        try {
            const raw = localStorage.getItem("app-state");
            if (raw) {
                const json = z.object({}).passthrough().parse(JSON.parse(raw));
                for (const [key, subSchema] of Object.entries(AppStateSchema.shape)) {
                    void (subSchema satisfies ZodOptional<any>);
                    try {
                        // @ts-ignore
                        initAppState[key] = subSchema.parse(json[key]);
                    } catch (e) {
                        console.error(`failed to load app state "${key}"`, e);
                    }
                }
            }
        } catch (e) {
            console.error("failed to load app state", e);
        }
    }
    const store = newStore(initAppState);
    store.subscribe(() => {
        try {
            localStorage.setItem("app-state", JSON.stringify(AppStateSchema.parse(store.get())));
        } catch (e) {
            console.error("failed to store app state", e);
        }
    });
    return store;
})();
/**
 * Hook for accessing the global application state.
 *
 * This hook provides reactive access to the global store initialized using `appStateStore`.
 * Changes to the state will trigger re-renders in components where this hook is used.
 *
 */
export const useAppState = () => useStore(appStateStore);