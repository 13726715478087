import {MessageType} from "../MessageType";
import {LightDensityClass} from "../../../api/interfaces/custom-datatypes/LightDensityClass";

export enum ContradictoryThresholdPopupResponseResponseType {
    Cancel = "Cancel",
    Continue = "Continue",
    Repeat = "Repeat",
    Switch = "Switch",
}

export interface ContradictoryThresholdPopupResponse {
    type: MessageType.ContradictoryThresholdPopupResponse,
    responseType: ContradictoryThresholdPopupResponseResponseType,
    lightDensityClass?: LightDensityClass,
}