import React from "react";

import {
    fixationControlToString,
    formatTimeDuration,
    intervalTimeToString,
    ListItemsI,
    SelectionMade, strategyToString,
    TailwindStandardWidth,
} from "@oculus/component-library";
import {getCorrectionWithUnits, hasProgramStatic} from "../../helper";
import {ExaminationComponentProps} from "./index";
import {useTranslation} from "@oculus/component-library";

/**
 * This component provides a preview of the ongoing settings selection for a new measurement.
 *
 * @component
 * {@link ExaminationComponentProps}
 */
const SettingsPreview: React.FC<ExaminationComponentProps> = ({settings, programWithRefList}) => {
    const {t} = useTranslation();
    const items: ListItemsI[] = [];

    if (settings.eye !== undefined) {
        items.push({
            left: t("eye"),
            right: {
                left: t("left"),
                right: t("right"),
                both: t("both"),
            }[settings.eye],
        });
    }
    if (settings.manualKinetic !== undefined) {
        if (settings.manualKinetic) {
            items.push({
                left: t("examination_mode"),
                right: t("manual_kinetic")
            })
        }
    }
    if (settings.program !== undefined) {
        const program = programWithRefList?.find((p) => p.uuid === settings.program);
        if (program) {
            items.push({
                left: t("program"),
                right: `${program.name ?? program.uuid} ${hasProgramStatic(program) ? "(" + formatTimeDuration(t,program.ref.duration, true) + ")" : ""}`,
            });
        }
        if (hasProgramStatic(program)) {
            items.push({
                left: t("area"),
                right: program.staticParameters.gridName,
            });
        }
        if (hasProgramStatic(program)) {
            items.push({
                left: t("strategy"),
                right: strategyToString(t,program.staticParameters.strategy),
            });
        }
    }

    if (settings.fixation !== undefined) {
        items.push({
            left: t("fixation_control"),
            right: fixationControlToString(t,settings.fixation),
        });
    }

    if (settings.speed !== undefined) {
        items.push({
            left: t("speed"),
            right: intervalTimeToString(t,settings.speed).toLowerCase(),
        });
    }

    if (settings.lensCorrection) {
        items.push({
            left: t("patient_wears_contact_lenses"),
            right: t("yes"),
        });
    }

    if (settings.lensSphere || settings.lensCylinder || settings.lensAxialLength) {
        items.push({
            left: t("correction"),
            right: getCorrectionWithUnits(
                settings.lensSphere ?? 0,
                settings.lensCylinder ?? 0,
                settings.lensAxialLength ?? 0,
            ),
        });
    }

    if (settings.patientCentered && settings.step) {
        items.push({
            left: t("patient_centred"),
            right: t("yes"),
        });
    }

    return items.length > 0 ? (
        <SelectionMade
            show={true}
            headline={t("selection_made")}
            items={items}
            separator=":"
            width={TailwindStandardWidth.FULL}
        />
    ) : null;
};
export default SettingsPreview;
