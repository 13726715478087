import {MessageType} from "../MessageType";

export enum AmbientBrightnessErrorPopupResponseResponseType {
    Cancel = "Cancel",
    Continue = "Continue",
}

export interface AmbientBrightnessErrorPopupResponse {
    type: MessageType.AmbientBrightnessErrorPopupResponse;
    responseType: AmbientBrightnessErrorPopupResponseResponseType;
}