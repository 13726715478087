import React from "react";
import {
    Dialog,
    TailwindStandardWidth, useTranslation,
} from "@oculus/component-library";

/** `LensChangeInsertPopup` component properties */
export interface LensChangeInsertPopupProps {
    /**
     * Callback function to handle the response of the popup
     *
     * @see LensChangePopupResponse
     */
    onResponse?: () => void;

    /** Controls the visibility of the popup. */
    show?: boolean;
}

/**
 * A popup component for handling `Insert` action of {@link LensChangePopupRequest} message.
 *
 * @component
 * {@link LensChangeInsertPopupProps}
 *
 * @see LensChangeRemovePopup
 */
const LensChangeInsertPopup: React.FC<LensChangeInsertPopupProps> = ({
                                                                         onResponse,
                                                                         show = true,
                                                                     }) => {
    const {t} = useTranslation();
    return <Dialog
        label={t("hint")}
        headline={t("reinsert_correction_lens")}
        text={t("reinsert_correction_lens_text")}
        width={TailwindStandardWidth.W200}
        showCloseButton={false}
        show={show}
        buttons={[
            {
                label: t("button_continue_2"),
                primary: true,
                onClick: onResponse,
            },
        ]}
    />
};

export default LensChangeInsertPopup;