import React, {useState} from "react";
import {
    BodyText,
    BodyTextType,
    Dialog, Dropdown,
    TailwindStandardWidth, useTranslation
} from "@oculus/component-library";
import {
    LightDensityClassPopupResponse
} from "../../../backend/websocket/interfaces/messages/LightDensityClassPopupResponse";
import {
    LightDensityClassPopupRequest
} from "../../../backend/websocket/interfaces/messages/LightDensityClassPopupRequest";
import {LightDensityClass} from "../../../backend/api/interfaces/custom-datatypes/LightDensityClass";
import {lightDensityClassToString} from "../../../helper";

export interface ThresholdSuccessfullyMeasuredNotificationPopUpProps {
    /** Controls the visibility of the popup. */
    show?: boolean;
    /**
     * The age-based class to be displayed to the user.
     */
    preselectedClassBasedOnAge?: LightDensityClassPopupRequest["ageExpectedLightDensityClass"];

    /**
     * The measured class to be displayed to the user.
     */
    measuredClass?: LightDensityClassPopupRequest["measuredLightDensityClass"];
    /**
     * Callback function to handle the response of the popup
     *
     * @see ThresholdSuccessfullyMeasuredConfirmation
     */
    onResponse?: (selected?: LightDensityClassPopupResponse["lightDensityClass"]) => void;

    measuredThreshold?: number
}

const ThresholdSuccessfullyMeasuredNotificationPopUp: React.FC<ThresholdSuccessfullyMeasuredNotificationPopUpProps> = ({
                                                                                                                           show,
                                                                                                                           measuredClass,
                                                                                                                           preselectedClassBasedOnAge,
                                                                                                                           onResponse,
                                                                                                                           measuredThreshold,
                                                                                                                       }) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState(preselectedClassBasedOnAge);
    const items = Object.values(LightDensityClass).map((value) => ({name: lightDensityClassToString(value, t), value}))
    return <Dialog
        label={t("hint")}
        headline={t("measured_threshold_within_expected_range")}
        text={<>
            {t("threshold_successfully_measured_with_value") + " "}
            <BodyText text={`'${measuredThreshold}'`} type={BodyTextType.B1Bold}
                      furtherClasses={"inline" /* HACK: BodyText is div */}/>
            {" " + t("measured") + "."}
            <br/>
            <br/>
            {measuredClass && (
                <>
                    {t("select_luminance_class")}
                    {preselectedClassBasedOnAge && (
                        <>
                            <br/>
                            {t("preselected_age_based_class")}:{" "}
                            <BodyText
                                text={lightDensityClassToString(preselectedClassBasedOnAge, t)}
                                type={BodyTextType.B1Bold}
                                furtherClasses={"inline"}
                            />
                        </>
                    )}
                    {measuredClass && (
                        <>
                            <br/>
                            {t("recommended_class_based_on_measurement")}:{" "}
                            <BodyText
                                text={lightDensityClassToString(measuredClass, t)}
                                type={BodyTextType.B1Bold}
                                furtherClasses={"inline"}
                            />
                        </>
                    )}
                </>
            )}
        </> satisfies React.ReactNode as unknown as string /* HACK: "text" supports only string */}
        width={TailwindStandardWidth.AUTO}
        showCloseButton={false}
        show={show}
        buttons={[
            {
                label: t("button_confirmation"),
                primary: true,
                onClick: () => {
                    if (onResponse) {
                        onResponse(selected);
                    }
                }
            },
        ]}
    >
        {measuredClass && (
            <Dropdown
                currentDropdownItem={items.find(({value}) => value === selected)}
                items={items}
                onChange={({value}) => setSelected(value)}
            />
        )}
    </Dialog>
};

export default ThresholdSuccessfullyMeasuredNotificationPopUp;