import {BodyText, Dialog, DialogType, TailwindStandardWidth} from "@oculus/component-library";
import {exitApp, isElectronEnvironment} from "../helper";
import React, {useEffect, useState} from "react";

//Used Tablet size: 1920 x 1280 with 150% Scaling
// MIN_SCREEN_WIDTH = 1920 / 1.5 = 1280
// MIN_SCREEN_Height = 1280 / 1.5 = 853
const SAVE_MARGIN = 50
const MIN_SCREEN_WIDTH = 1280 - SAVE_MARGIN;
const MIN_SCREEN_HEIGHT = 853 - SAVE_MARGIN;
/**
 * `ScreenSizePopup` is component that checks the screen width of the device and alerts the user
 * if the resolution is not supported by the software.
 *
 * The alert is presented as a dialog with two possible options that depend on whether the window can be resized.
 * - In case the window can be resized, an option is provided to enlarge the window.
 * - If the resolution is too small and cannot be adjusted, the user is advised to exit the software.
 *
 * @component
 */
const ScreenSizePopup: React.FC = () => {
    const [canResize, setCanResize] = useState(false);
    const [screenWidth, setScreenWidth] = useState<number>();
    const [screenHeight, setScreenHeight] = useState<number>();
    const [devToolsOpen, setDevToolsOpen] = useState(false);
    useEffect(() => {
        const updateScreenDimensions = () => {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
        };
        updateScreenDimensions();
        window.addEventListener("resize", updateScreenDimensions);
        return () => window.removeEventListener("resize", updateScreenDimensions);
    }, []);

    useEffect(() => {
        if (!isElectronEnvironment()) {
            return;
        }
        const updateCanResize = () => {
            const availableWidth = window.screen.availWidth - window.outerWidth;
            const availableHeight = window.screen.availHeight - window.outerHeight;
            const widthCanResize = MIN_SCREEN_WIDTH - window.innerWidth;
            const heightCanResize = MIN_SCREEN_HEIGHT - window.innerHeight;
            setCanResize(availableWidth >= widthCanResize && availableHeight >= heightCanResize);
        };
        updateCanResize();
        const intervalId = window.setInterval(updateCanResize, 1000);
        return () => window.clearInterval(intervalId);
    }, [screenWidth, screenHeight]);
    const isDevToolsOpen = () => {
        if (isElectronEnvironment()) {
            return (window as any).electronDevToolsOpen === true;
        } else {
            return window.outerWidth - window.innerWidth > 100 || window.outerHeight - window.innerHeight > 100;
        }
    };
    useEffect(() => {
        const checkDevTools = () => {
            setDevToolsOpen(isDevToolsOpen());
        };

        checkDevTools();
        const intervalId = window.setInterval(checkDevTools, 1000);
        return () => window.clearInterval(intervalId);
    }, []);

    return (
        <Dialog headline="Auflösung wird nicht unterstützt" width={TailwindStandardWidth.W152}
                show={screenWidth !== undefined && screenHeight !== undefined && !devToolsOpen &&  (screenWidth < MIN_SCREEN_WIDTH || screenHeight < MIN_SCREEN_HEIGHT)}
                type={canResize ? DialogType.Info : DialogType.Warning} showCloseButton={false}
                label={canResize ? "Hinweis" : "Fehlermeldung"}
                buttons={[
                    ...canResize ? [{
                        label: "Fenster vergrößern",
                        primary: true,
                        onClick: () => window.resizeBy(Math.max(MIN_SCREEN_WIDTH - window.innerWidth, 0), Math.max(MIN_SCREEN_HEIGHT - window.innerHeight, 0))
                    }] : [],
                    {label: "Software beenden", primary: !canResize, onClick: () => exitApp()},
                ]}>
            <BodyText furtherClasses="!m-0" text={canResize ? (
                "Die von Ihnen gewählte Auflösung wird von unserer Perimeter-Software nicht unterstützt. Für die optimale Bedienbarkeit und Nutzung empfehlen " +
                "wir Ihnen die Verwendung eines Endgeräts oder Displays mit einer höheren Auflösung. " +
                "Sofern möglich, können Sie die Anwendung manuell oder über den unten liegenden Button vergrößern, um wieder zu einer unterstützten " +
                "Auflösung zu gelangen. Sollte dies nicht möglich sein, müssen Sie die Anwendung schließen."
            ) : (
                "Das von Ihnen gewählte Endgerät oder Display verfügt nicht über die notwendige Auflösung, um unserer Software optimal nutzen zu können. " +
                "Bitte beenden Sie die Software."
            )}/>
        </Dialog>
    )
};

export default ScreenSizePopup;