import React from 'react';
import {Headline, HeadlineSize} from "@oculus/component-library";
import {useTranslation} from "@oculus/component-library";

/** `ExaminationManualKineticTimer` component properties */
export interface ExaminationManualKineticTimerProps {
    /** The elapsed time in seconds */
    elapsedTime?: number;
}

/**
 * This component displays a timer for manual kinetic measurements.
 * It shows elapsed time in minutes.
 *
 * @component
 * {@link ExaminationManualKineticTimerProps}
 */
const ExaminationManualKineticTimer: React.FC<ExaminationManualKineticTimerProps> = ({elapsedTime = 0}) => {
    const {t} = useTranslation();
    const formatTime = (timeInSeconds: number) => {
        const minutes = Math.trunc(timeInSeconds / 60);
        const seconds = Math.trunc(timeInSeconds) % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
    return (
        <div className="flex flex-row items-center justify-start mb-3">
            <Headline
                size={HeadlineSize.H3}
                text={`${t("examination_in_progress")}`}
                furtherClasses="mr-1.5 text-blue1"
            />
            <Headline furtherClasses={"mr-2"} size={HeadlineSize.H3} text={formatTime(elapsedTime)} data-test={"Countdown"}/>
            <Headline furtherClasses={"mr-2"} size={HeadlineSize.H3} text={t("minutes")}/>
        </div>

    );
};

export default ExaminationManualKineticTimer;