import React from "react";
import {
    AmbientBrightnessErrorPopupResponse, AmbientBrightnessErrorPopupResponseResponseType
} from "../../../backend/websocket/interfaces/messages/AmbientBrightnessErrorPopupResponse";
import {Dialog, DialogType, TailwindStandardWidth, useTranslation} from "@oculus/component-library";

/** `AmbientBrightnessErrorPopup` component properties */
export interface AmbientBrightnessErrorPopupProps {
    /**
     * Callback function to handle the response of the popup
     *
     * @param response - The `responseType` from {@link AmbientBrightnessErrorPopupResponse}
     */
    onResponse?: (response: AmbientBrightnessErrorPopupResponse["responseType"]) => void;

    /** Controls the visibility of the popup. */
    show?: boolean;
}

/**
 * A popup component for handling {@link AmbientBrightnessErrorPopupRequest} message.
 *
 * @component
 * {@link AmbientBrightnessErrorPopupProps}
 */
const AmbientBrightnessErrorPopup: React.FC<AmbientBrightnessErrorPopupProps> = ({
                                                                                     onResponse,
                                                                                     show = true,
                                                                                 }) => {
    const {t} = useTranslation();
    return <Dialog
        label={t("error_message")}
        headline={t("ambient_light_too_high")}
        text={t("ambient_light_warning_text_2")}
        type={DialogType.Warning}
        width={TailwindStandardWidth.W200}
        showCloseButton={false}
        show={show}
        buttons={[
            {
                label: t("examination_abort"),
                onClick: onResponse && (() => onResponse(AmbientBrightnessErrorPopupResponseResponseType.Cancel)),
                primary: true,
            },
            {
                label: t("button_continue"),
                onClick: onResponse && (() => onResponse?.(AmbientBrightnessErrorPopupResponseResponseType.Continue)),
            },
        ]}
    />
};

export default AmbientBrightnessErrorPopup;