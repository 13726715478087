import {
    Button,
    ButtonTextSize,
    ButtonType,
    ButtonWithIcon,
    CommentCategory,
    CommentType,
    ContentSwitcherGroup,
    CursorArrowGroup,
    Dialog,
    DialogType,
    Dropdown,
    DropdownState,
    getElementMatrix,
    getRandomEnumValue,
    getVectorLength,
    Headline,
    HeadlineSize,
    HelpText,
    InvestigationMap,
    InvestigationMapActivePoint,
    InvestigationMapCartesianPoint,
    InvestigationMapNumberPoint,
    InvestigationMapProps,
    InvestigationMapSymbolPoint,
    IsoptereMode,
    IsoptereModes,
    IsoptereSteps,
    IsoptereSymbolsColor,
    IsoptereSymbolsForm,
    LegendType,
    LightDensityClass,
    MapControlGroup,
    MaterialIconPosition,
    NumberPointColor,
    ProjectorBorder,
    rectMatrixTransform,
    StackedIsoptereLegend,
    StimulusColor,
    subtractVector,
    SymbolPointStyle,
    TailwindColor,
    TailwindStandardHeight,
    TailwindStandardWidth,
    TimelineWithLabel,
    Toggletip,
    ToggletipLabel,
    ToggletipPosition,
    ToggletipProps,
    ToggletipSize,
    useMonotonicTimer,
    useTranslation,
    VisualFieldLimits,
} from "@oculus/component-library";
import React, {useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {
    getMeasurementAbort,
    getMeasurementPause,
    getMeasurementResume, putMaxEccentricity,
    putMeasurementStart,
} from "../../backend/api/Calls";
import {ExaminationComponentProps, ExaminationMeasurement, navigateWithSettings, parseFollowUpPoints} from "./index";
import SettingsPreview from "./SettingsPreview";
import {MeasurementConfigPayload} from "../../backend/api/interfaces/payload/MeasurementConfigPayload";
import {Eye} from "../../backend/api/interfaces/custom-datatypes/Eye";
import {navigate} from "gatsby";
import ExaminationManualKineticTimer from "./ExaminationManualKinteticTimer";
import MeasurementComments from "./MeasurementComments";
import {MeasurementType} from "../../backend/api/interfaces/custom-datatypes/MeasurementType";
import {StaticStart} from "../../backend/websocket/interfaces/messages/StaticStart";
import {Message} from "../../backend/websocket/interfaces/Message";
import {useAppState} from "../../globalAppState";
import {CorrectionType} from "../../backend/api/interfaces/custom-datatypes/CorrectionType";
import {MessageType} from "../../backend/websocket/interfaces/MessageType";
import {Strategy} from "../../backend/api/interfaces/custom-datatypes/Strategy";
import {
    convertColorToNumberPointColor, hasKineticStarted,
    hasProgramKinetic,
    hasProgramStatic,
    isEqualPosition,
    pickRandom,
    useHandleBeforeUnload,
} from "../../helper";
import {StimulusSize} from "../../backend/api/interfaces/custom-datatypes/StimulusSize";
import {StimulusStatus} from "../../backend/api/interfaces/data/StimulusStatus";
import {ManualKineticProps, useManualKinetic} from "./manualKinetic/ManualKinetic";
import {TopBarContext} from "../Header";
import useChinrestController from "../../utils/useChinrestController";
import {convertIsopterConfigToSimpleIsopterConfig, SimpleIsopterConfig} from "./manualKinetic/IsoptereModesController";
import ExaminationLayout from "./ExaminationLayout";
import {ProgramType} from "../../backend/api/interfaces/custom-datatypes/ProgramType";
import IsopterSettings from "./IsopterSettings";
import {PatternType} from "../../backend/api/interfaces/custom-datatypes/PatternType";
import {getMockserver} from "../../backend/websocket/core/Websocket";
import {Color} from "../../backend/api/interfaces/custom-datatypes/Color";
import {StaticUpdate} from "../../backend/websocket/interfaces/messages/StaticUpdate";
import {ProgressUpdate} from "../../backend/websocket/interfaces/messages/ProgressUpdate";
import {
    PauseButtonNotificationButtonState
} from "../../backend/api/interfaces/custom-datatypes/PauseButtonNotificationButtonState";

/**
 * Map between static measurement strategies and their corresponding legend specifications.
 *
 * In the absence of a specified strategy, the `default` entry is used.
 */
const strategyLegendMap: (
    { [Key in Strategy]?: Pick<ToggletipProps, "LegendType" | "LegendIsNumber"> }
    & { default: Pick<ToggletipProps, "LegendType" | "LegendIsNumber"> }
    ) = {
    [Strategy.SuprathresholdTwoZones]: {LegendType: LegendType.Simple},
    [Strategy.SuprathresholdThreeZones]: {LegendType: LegendType.Relativ},
    [Strategy.Classes]: {LegendType: LegendType.Classified},
    [Strategy.SuprathresholdQuantifyDefects]: {LegendType: LegendType.SupraThresholdQuantifyDefects},
    default: {LegendType: LegendType.Simple, LegendIsNumber: true}
};

/**
 * A component that renders a legend button, designed to be used as a `rightChild` in the {@link InvestigationMap}.
 *
 * This button, when clicked, unveils the legend information in a {@link Toggletip}.
 *
 * @component
 * @property strategy - The strategy that determines the content of the legend.
 */
export const MeasurementMapLegend: React.FC<{ strategy: Strategy }> = ({strategy}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    return (
        <div
            className="absolute flex self-end right-0 mr-2" /* HACK: Adjusting the right margin to make the tooltip arrow slightly overlap with the button */>
            <ButtonWithIcon type={ButtonType.Secondary} label={t("legend")} onClick={() => setOpen(!open)}
                            iconKey="format_list_bulleted" iconPosition={MaterialIconPosition.Right}
                            iconColor="currentColor" iconColorActive="currentColor" iconColorHover="currentColor"
                            size={ButtonTextSize.Large}
                            buttonTextFurtherClasses={"hidden 2xl:block h-less-1000:!hidden"}
                            furtherClassesBtn="!min-h-10 !pl-5 h-less-1000:!pl-5 2xl:pl-6 h-less-1000:!gap-x-0 2xl:!gap-x-3 !gap-x-0" /* HACK: In the design specification the font of the button is `ButtonTextSize.Large` but the height is `ButtonTextSize.Medium` */
                            furtherClassesWrapper="w-max -mr-2"/>
            <Toggletip headline={t("legend")} content="" size={ToggletipSize.Medium}
                       arrowPosition={ToggletipPosition.LeftBottom}
                       useArrowPosition show={open} onClose={() => setOpen(false)}
                       {...strategyLegendMap[strategy] ?? strategyLegendMap.default}/>
        </div>
    );
};

/**
 * A component that renders a legend button, designed to be used as a `rightChild` in the {@link InvestigationMap}.
 *
 * This button, when clicked, unveils the legend information in a {@link Toggletip}.
 *
 * @component
 * @property kineticMode - The mode that determines the content of the legend.
 */
export const MeasurementKineticLegend: React.FC<{ kineticMode: IsoptereMode }> = ({kineticMode}) => {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false);
    return (
        <div
            className="absolute flex self-end right-0 mr-2" /* HACK: Adjusting the right margin to make the tooltip arrow slightly overlap with the button */>
            <ButtonWithIcon type={ButtonType.Secondary} label={t("legend")} onClick={() => setOpen(!open)}
                            iconKey="format_list_bulleted" iconPosition={MaterialIconPosition.Right}
                            iconColor="currentColor" iconColorActive="currentColor" iconColorHover="currentColor"
                            size={ButtonTextSize.Large}
                            buttonTextFurtherClasses={"hidden 2xl:block h-less-1000:!hidden"}
                            furtherClassesBtn="!min-h-10 !pl-5 h-less-1000:!pl-5 2xl:pl-6 h-less-1000:!gap-x-0 2xl:!gap-x-3 !gap-x-0" /* HACK: In the design specification the font of the button is `ButtonTextSize.Large` but the height is `ButtonTextSize.Medium` */
                            furtherClassesWrapper="w-max -mr-2"/>
            <Toggletip headline={t("legend")} content="" size={ToggletipSize.Full}
                       arrowPosition={ToggletipPosition.LeftBottom}
                       useArrowPosition show={open} onClose={() => setOpen(false)}
                       LegendType={kineticMode === IsoptereMode.Manual ? LegendType.ManualKinetic : kineticMode === IsoptereMode.SemiManual ? LegendType.SemiManualKinetic : LegendType.Skotomperimetrie}/>
        </div>
    );
};

/**
 * Helper function for transforming the measurements received via WebSocket
 * into data compatible with the {@link InvestigationMap} component.
 *
 * @param measurements - The received {@link Message}s containing the measurement data
 * @returns Points to be consumed by the {@link InvestigationMap} component
 */
export function mapMeasurementsToInvestigationMap(measurements: ExaminationMeasurement[]) {
    const symbolPoints: (InvestigationMapSymbolPoint & { position: InvestigationMapCartesianPoint })[] = [];
    const numberPoints: (InvestigationMapNumberPoint & { position: InvestigationMapCartesianPoint })[] = [];
    measurements.forEach((m) => {
        if (m.type === MessageType.AbsoluteLossStaticDone) {
            symbolPoints.push({
                position: m.position,
                pattern: {type: PatternType.RelativeLoss} as InvestigationMapSymbolPoint["pattern"],
            });
        } else if ((m.type === MessageType.SupraThresholdStaticDone || m.type === MessageType.ThresholdStaticDone) && m.pattern) {
            if (m.pattern.type === PatternType.SmallerZero) {
                numberPoints.push({
                    position: m.position,
                    db: 0,
                    color: NumberPointColor.RED,
                    sign: true,
                });
            } else {
                symbolPoints.push({
                    position: m.position,
                    pattern: m.pattern as InvestigationMapSymbolPoint["pattern"],
                });
            }
        } else if (m.type === MessageType.ThresholdStaticDone && m.db !== undefined && m.db !== null) {
            numberPoints.push({
                position: m.position,
                db: m.db,
                color: convertColorToNumberPointColor(m.color),
            });
        } else {
            console.error("Invalid measurement", m)
        }
    });
    return {symbolPoints, numberPoints};
}

/**
 * This function adjusts the vertical position of {@link MapControlGroup} controls in relation to an element
 * (`mapCenterElement`) within their container (`mapControlsContainerElement`).
 *
 * @returns A pair of setters to establish the link with the DOM.
 * - setMapCenterElement is a setter of the DOM element representing the center of the map.
 * - setMapControlsContainerElement is a setter of the DOM element containing map controls.
 */
export function useMapControlsCentering(): {
    setMapControlsContainerElement: (element: HTMLElement | null) => void,
    setMapCenterElement: (element: HTMLElement | null) => void,
} {
    const [mapCenterElement, setMapCenterElement] = useState<HTMLElement | null>(null);
    const [mapControlsContainerElement, setMapControlsContainerElement] = useState<HTMLElement | null>(null);
    useLayoutEffect(() => {
        if (!mapCenterElement || !mapControlsContainerElement) {
            return;
        }
        const updateCenter = () => {
            const invTargetMatrix = getElementMatrix(mapControlsContainerElement).inverse();
            const centerTop = rectMatrixTransform(mapCenterElement.getBoundingClientRect(), invTargetMatrix).top;
            const containerTop = rectMatrixTransform(mapControlsContainerElement.getBoundingClientRect(), invTargetMatrix).top;
            const mapControlLabelHeight = 22;
            const mapControlDropdownHeight = 40;
            mapControlsContainerElement.style.paddingTop = `${Math.max(0, centerTop - containerTop - mapControlLabelHeight - mapControlDropdownHeight / 2)}px`;
        };
        const resizeObserver = new ResizeObserver(updateCenter);
        resizeObserver.observe(mapControlsContainerElement);
        updateCenter();
        const id = window.setInterval(updateCenter, 100);
        return () => {
            resizeObserver.disconnect();
            window.clearInterval(id);
        };
    }, [mapCenterElement, mapControlsContainerElement]);
    return {setMapControlsContainerElement, setMapCenterElement};
}

export const settingsEyeToEyeMap = {
    "left": Eye.Left,
    "right": Eye.Right,
    "both": Eye.Binocular,
}

export const degSteps = 5;

export const calculateDegOnAxis = async (
    kinetic: boolean,
    program: { type?: ProgramType } | null,
    deviceInformation: { xMaxEccentricity: number; yMaxEccentricity: number } | null,
    isoptereModesProps: any,
    positions: any[],
    setDegOnAxis: (value: number) => void,
    putMaxEccentricity: (data: { positions: any[] }) => Promise<any>
): Promise<void> => {
    const calculateMaxEccentricity = () =>
        Math.ceil(Math.max(deviceInformation?.xMaxEccentricity ?? 0, deviceInformation?.yMaxEccentricity ?? 0) / degSteps) * degSteps;
    if (kinetic || program?.type === ProgramType.AutomaticKinetic || hasKineticStarted(isoptereModesProps)) {
        setDegOnAxis(deviceInformation ? calculateMaxEccentricity() : 90);
        return;
    }

    if (positions.length > 0) {
        try {
            const response = await putMaxEccentricity({positions});
            setDegOnAxis(response.data);
        } catch (error) {
            console.error("Error fetching max eccentricity:", error);
            setDegOnAxis(deviceInformation ? calculateMaxEccentricity() : 90);
        }
        return;
    }
    setDegOnAxis(deviceInformation ? calculateMaxEccentricity() : 90);
};


/**
 * A component designed to display an ongoing measurement.
 *
 * It not only shows the status of the measurement but also handles kinetic mode.
 *
 * @component
 * {@link ExaminationComponentProps}
 */
const ExecuteExaminationPage: React.FC<ExaminationComponentProps> = (props) => {
    const {t} = useTranslation();
    const [reload, setReload] = useState(false);
    const [openPatientPauseDialog, setOpenPatientPauseDialog] = useState(false)
    const [showVisualFieldLimit, setShowVisualFieldLimit] = useState(true)
    const [openPauseDialog, setOpenPauseDialog] = useState(false)
    const [responseButtonTested, setResponseButtonTested] = useState(false)
    useHandleBeforeUnload(setReload, setOpenPauseDialog, props)
    /**
     * Triggers navigation upon reload state change.
     */
    useEffect(() => {
        if (reload) {
            navigateWithSettings("../configuration", settings)
        }
    }, [reload]);

    const [{patientConfig}] = useAppState();
    const manualKineticModeDropdownItems: { name: string, mode: IsoptereMode }[] = [
        {name: t("manual_examination"), mode: IsoptereMode.Manual},
        {name: t("semi_manual_examination"), mode: IsoptereMode.SemiManual},
        {name: t("scotom_perimetrie"), mode: IsoptereMode.Scoptoperimetry},
    ];
    const {
        eyePreview,
        settings,
        programWithRefList,
        deviceInformation,
        comments,
        setComments,
        frontendSettings,
        deviceSettings,
    } = props;
    const [selected, setSelected] = useState<React.Key | null>(1);
    const [manualKineticMode, setManualKineticMode] = useState<IsoptereMode>(IsoptereMode.Manual);
    const [scotomaLength, setScotomaLength] = useState(10);
    const manualKineticHelpTexts: { [mode in IsoptereMode]: string[] } = {
        [IsoptereMode.Manual]: [
            t("manual_kinetic_help_text_paragraph_1"),
            t("manual_kinetic_help_text_paragraph_2"),
            t("manual_kinetic_help_text_paragraph_3"),
        ],
        [IsoptereMode.SemiManual]: [
            t("semi_manual_kinetic_help_text_paragraph_1"),
            t("semi_manual_kinetic_help_text_paragraph_2"),
            t("semi_manual_kinetic_help_text_paragraph_3"),
        ],
        [IsoptereMode.Scoptoperimetry]: [
            t("scotom_perimetrie_help_text_paragraph_1"),
            t("scotom_perimetrie_help_text_paragraph_2"),
            t("scotom_perimetrie_help_text_paragraph_3"),
        ],
    };
    const helpText = manualKineticHelpTexts[manualKineticMode];

    const handleChinrestDirectionToggle = useChinrestController();
    const PauseDialogContinueButton = {
        label: t("examination_continue"),
        primary: true,
        onClick: () => {
            getMeasurementResume()
                .then(() => setOpenPauseDialog(false))
                .catch(props.handleCriticalError);
        }
    };

    const PauseDialogRepeatButton = {
        label: t("examination_repeat"),
        onClick: async () => {
            try {
                await getMeasurementAbort();
            } catch (err) {
                console.error(err)
            }
            navigateWithSettings("../configuration", settings);
        }
    };

    const [isAbortingMeasurement, setIsAbortingMeasurement] = useState(false);

    const PauseDialogCancelButton = {
        label: t("examination_abort"),
        onClick: async () => {
            try {
                setIsAbortingMeasurement(true);
                await getMeasurementAbort();
            } catch (err) {
                console.error(err);
            } finally {
                setIsAbortingMeasurement(false);
            }
            navigate(`../configuration/?step=1`);
        },
        disabled: isAbortingMeasurement,
        loading: isAbortingMeasurement
    };

    const [activeStaticUpdatePoints, setActiveStaticUpdatePoints] = useState<StaticUpdate[]>([]);
    const [activeStaticPoint, setActiveStaticPoint] = useState<StaticStart | null>(null);
    useEffect(() => {
        if (!activeStaticPoint?.durationMs) {
            return;
        }
        // HACK: Remove point after duration with simple timeout. The point is only displayed for a few 100ms, this should be good enough for now and can be upgraded to deadlines based on elapsedTime later.
        const id = setTimeout(() => setActiveStaticPoint((state) => Object.is(state, activeStaticPoint) ? null : state), activeStaticPoint.durationMs);
        return () => clearTimeout(id);
    }, [activeStaticPoint]);

    const handleMeasurementDone = async () => {
        await isoptereController.finish();
        if (settings.isTestRun) {
            try {
                await getMeasurementAbort();
            } catch (e) {
                console.error(e);
            }
            navigateWithSettings("../configuration", {
                ...settings,
                manualKinetic: settings.step === 3 ? false : settings.manualKinetic
            });
        } else {
            sessionStorage.removeItem('reload')
            navigateWithSettings("../finished", settings, {replace: true});
        }
    }

    const doOpenIsopterDialog = (defaultConfig?: Partial<SimpleIsopterConfig>) => setOpenIsopterDialog(
        (prevState) => ({
            steps: prevState?.steps ?? defaultConfig?.steps ?? IsoptereSteps.E,
            color: prevState?.color ?? defaultConfig?.color ?? IsoptereSymbolsColor.DarkBlue,
            form: prevState?.form ?? defaultConfig?.form ?? IsoptereSymbolsForm.Triangle,
        }));
    const [openIsopterDialog, setOpenIsopterDialog] = useState<SimpleIsopterConfig | null>(null)
    const [hideComments, setHideComments] = useState(false);
    const [hideAxialValues, setHideAxialValues] = useState(false);
    const [hideRadialValues, setHideRadialValues] = useState(!settings.manualKinetic);
    const {setMapControlsContainerElement, setMapCenterElement} = useMapControlsCentering();

    // settings are checked on higher level
    const program = settings.manualKinetic ? null : programWithRefList?.find(({uuid}) => uuid === settings.program) ?? null;
    const staticDots = useMemo(() => {
        if (program?.type !== ProgramType.Static && program?.type !== ProgramType.Combi) {
            return null;
        }
        if (settings.followUpPoints !== undefined) {
            return parseFollowUpPoints(settings.followUpPoints);
        }
        return program.staticParameters.staticDots[settingsEyeToEyeMap[settings.eye!]] ?? null;
    }, [program, settings.eye, settings.followUpPoints]);
    useEffect(() => {
        const ws = props.websocket;
        if (!ws) {
            return;
        }
        const handleMessage = (message: Message) => {
            /*
             * Keep in mind that closures capture variables from the scope where they were defined.
             * Thus, any changes to those variables after the closure's creation won't be reflected within it.
             * Ensure that this doesn't lead to discrepancies between the actual and expected state.
             */
            switch (message.type) {
                case MessageType.StaticStart:
                    setActiveStaticPoint(message);
                    break;
                case MessageType.StaticUpdate:
                    setActiveStaticUpdatePoints((prev) => [...prev, message]);
                    break;
                case MessageType.ArrowStart:
                    setDegOnAxis(deviceInformation ? Math.ceil(Math.max(deviceInformation?.xMaxEccentricity ?? 0, deviceInformation?.yMaxEccentricity ?? 0) / degSteps) * degSteps : 90);
                    isoptereControllerRef.current.arrowStart(message);
                    break;
                case MessageType.KineticVectorDone:
                    isoptereControllerRef.current.kineticVectorDone(message);
                    break;
                case MessageType.KineticIsopterDone:
                    isoptereControllerRef.current.kineticIsopterDone(message);
                    break;
                case MessageType.PositionUpdate:
                    isoptereControllerRef.current.positionUpdate(message);
                    break;
                case MessageType.KineticConfigurePopupRequest:
                    isoptereControllerRef.current.kineticConfigurePopupRequest(message);
                    break;
                case MessageType.ProgressUpdate:
                    setProgress(message);
                    updateProgressTime(message)
                    break;
                case MessageType.AbsoluteLossStaticDone:
                case MessageType.SupraThresholdStaticDone:
                case MessageType.ThresholdStaticDone:
                    setMeasurementsRef.current((prevState) => ([...prevState, message]));
                    break;
                case MessageType.Done:
                    handleMeasurementDoneRef.current();
                    break;
                case MessageType.QualityUpdate:
                    setQualityScoreRef.current(message.qualityScore);
                    break;
                case MessageType.PauseButtonNotification:
                    if (message.buttonState === PauseButtonNotificationButtonState.Pressed) {
                        setOpenPatientPauseDialog(true)
                    } else if (message.buttonState === PauseButtonNotificationButtonState.Released) {
                        setOpenPatientPauseDialog(false)
                    } else {
                        console.error("Unknown buttonState:", message.buttonState);
                    }
                    break;
                case MessageType.ResponseButtonPressed:
                    setResponseButtonTested(true)
                    break;
            }
        };
        ws.addMessageListener(handleMessage);
        return () => {
            ws.removeMessageListener(handleMessage);
        };
    }, [props.websocket]);

    const [kineticConfigureRequest, setKineticConfigureRequest] = React.useState<{
        resolve: (value: ReturnType<NonNullable<ManualKineticProps["onConfigureRequest"]>> extends Promise<infer R> ? R | PromiseLike<R> : never) => void,
        reject: (reason?: any) => void
    }>();
    useEffect(() => {
        if (patientConfig?.wsUrl === "mockserver") {
            const handleKeyBoardEvent = (e: KeyboardEvent) => {
                if (e.key === "x" && !responseButtonTested) {
                    getMockserver()?.send({
                        type: MessageType.ResponseButtonPressed,
                    });
                }
            };
            window.addEventListener("keydown", handleKeyBoardEvent);
            return () => {
                window.removeEventListener("keydown", handleKeyBoardEvent);
            };
        }
    }, [patientConfig?.wsUrl, responseButtonTested]);
    // Generate random measurements for mockserver
    useEffect(() => {
        if (patientConfig?.wsUrl !== "mockserver" || !hasProgramStatic(program) || !staticDots?.length || !responseButtonTested) {
            return;
        }
        const remainingPoints = [...staticDots];
        const stimulusStatus: StimulusStatus = {
            size: getRandomEnumValue(StimulusSize),
            color: StimulusColor.White,
        };
        const possibleMockMeasurementTypes: ("numbers" | "staticUpdate" | "classes" | "seen" | "absoluteLoss" | "relativeLoss")[] = [];
        const legendType = strategyLegendMap[program.staticParameters.strategy] ?? strategyLegendMap.default;
        if (legendType.LegendType === LegendType.Simple && legendType.LegendIsNumber
            || legendType.LegendType === LegendType.SupraThresholdQuantifyDefects
        ) {
            possibleMockMeasurementTypes.push("numbers");
            possibleMockMeasurementTypes.push("staticUpdate");
        }
        if (legendType.LegendType === LegendType.Simple && !legendType.LegendIsNumber
            || legendType.LegendType === LegendType.SupraThresholdQuantifyDefects
            || legendType.LegendType === LegendType.Classified
            || legendType.LegendType === LegendType.Relativ
        ) {
            possibleMockMeasurementTypes.push("seen");
        }
        if (legendType.LegendType === LegendType.Classified) {
            possibleMockMeasurementTypes.push("classes");
        }
        if (legendType.LegendType === LegendType.Simple && !legendType.LegendIsNumber
            || legendType.LegendType === LegendType.SupraThresholdQuantifyDefects
            || legendType.LegendType === LegendType.Relativ
        ) {
            possibleMockMeasurementTypes.push("absoluteLoss");
        }
        if (legendType.LegendType === LegendType.Relativ) {
            possibleMockMeasurementTypes.push("relativeLoss");
        }
        const startTime = window.performance.now();
        let timeoutIds: number[] = [];
        const intervalId = window.setInterval(() => {
            /*
             * Keep in mind that closures capture variables from the scope where they were defined.
             * Thus, any changes to those variables after the closure's creation won't be reflected within it.
             * Ensure that this doesn't lead to discrepancies between the actual and expected state.
             */
            const position = remainingPoints.splice(Math.trunc(Math.random() * remainingPoints.length), 1)[0];
            if (!position) {
                return;
            }
            const mockMeasurementType = possibleMockMeasurementTypes.length > 0 ? pickRandom(possibleMockMeasurementTypes) : null;
            if (mockMeasurementType === "staticUpdate") {
                remainingPoints.push(position);
            }
            getMockserver()?.send({
                type: MessageType.StaticStart,
                stimulusStatus,
                position,
                durationMs: 100,
                isQualityCheck: Math.random() < 0.5,
            });
            const timeoutId = window.setTimeout(() => {
                timeoutIds.splice(timeoutIds.indexOf(timeoutId), 1);
                switch (mockMeasurementType) {
                    case "relativeLoss":
                        getMockserver()?.send({
                            type: MessageType.SupraThresholdStaticDone,
                            stimulusStatus,
                            position,
                            pattern: {
                                type: PatternType.RelativeLoss,
                            },
                        });
                        break;
                    case "absoluteLoss":
                        getMockserver()?.send({
                            type: MessageType.AbsoluteLossStaticDone,
                            stimulusStatus,
                            position,
                        });
                        break;
                    case "seen":
                        getMockserver()?.send({
                            type: MessageType.SupraThresholdStaticDone,
                            stimulusStatus,
                            position,
                            pattern: {
                                type: PatternType.Seen,
                            },
                        });
                        break;
                    case "staticUpdate":
                        getMockserver()?.send({
                            type: MessageType.StaticUpdate,
                            stimulusStatus,
                            position,
                            db: Math.trunc(Math.random() * 20) + 1,
                            color: getRandomEnumValue(Color)
                        });
                        break;
                    case "numbers":
                        getMockserver()?.send(Math.random() < 0.2 ? {
                            type: MessageType.ThresholdStaticDone,
                            stimulusStatus,
                            position,
                            pattern: {
                                type: PatternType.SmallerZero,
                            },
                            color: Color.Red,
                        } : {
                            type: MessageType.ThresholdStaticDone,
                            stimulusStatus,
                            position,
                            db: Math.trunc(Math.random() * 20) + 1,
                            color: getRandomEnumValue(Color),
                        });
                        break;
                    case "classes":
                        getMockserver()?.send({
                            type: MessageType.SupraThresholdStaticDone,
                            stimulusStatus,
                            position,
                            pattern: {
                                type: PatternType.LightDensityClass,
                                lightDensityClass: getRandomEnumValue(LightDensityClass),
                            },
                        });
                        break;
                    default:
                        void (mockMeasurementType satisfies null);
                }
                getMockserver()?.send(
                    {
                        type: MessageType.ProgressUpdate,
                        progress: {total: staticDots?.length, actual: (staticDots?.length - remainingPoints.length)},
                        duration: Math.trunc((window.performance.now() - startTime) / 1000),
                        eta: !staticDots.length ? 0 : (program.ref.duration / staticDots?.length * remainingPoints.length),
                    }
                )
            }, 500);
            timeoutIds.push(timeoutId);
        }, program.ref.duration / (remainingPoints.length + 1) * 1000);
        timeoutIds.push(window.setTimeout(() => {
            const randomQualityRatio = () => {
                const total = Math.trunc(Math.random() * 11);
                const fail = Math.trunc(Math.random() * (total + 1));
                return {total, fail};
            };
            getMockserver()?.send({
                type: MessageType.QualityUpdate,
                qualityScore: {
                    score: Math.trunc(Math.random() * 101),
                    falsePositive: randomQualityRatio(),
                    falseNegative: randomQualityRatio(),
                    fixationCheck: randomQualityRatio(),
                    fixationCheckType: settings.fixation!,
                },
            });
        }, 10 * 1000));
        return () => {
            window.clearInterval(intervalId);
            timeoutIds.forEach(window.clearTimeout);
        }
    }, [patientConfig?.wsUrl, program, responseButtonTested]);

    useEffect(() => {
        // Remove old measurement results
        if (settings.followUpPoints !== undefined) {
            props.setMeasurements((measurements) =>
                measurements.filter(({position}) => !staticDots?.some((oPosition) => isEqualPosition(position, oPosition)))
            );
        } else {
            props.setMeasurements(() => []);
            void props.setExaminationInfo("", true);
            props.setExaminationDateTime(new Date().toISOString());
            void setComments([], true);
        }
        props.isoptereModesControllerState.reset();
        props.setQualityScore(null);
        if (!responseButtonTested || !props.patient || !props.deviceInformation || !settings.manualKinetic && !program) {
            return;
        }

        const measurementType = (() => {
            if (settings.manualKinetic) {
                return MeasurementType.KineticManual;
            }
            if (program?.type === ProgramType.AutomaticKinetic) {
                return MeasurementType.KineticAutomatic;
            }
            if (program?.type === ProgramType.Combi) {
                return MeasurementType.Combi;
            }
            if (program?.type === ProgramType.Static) {
                return MeasurementType.Static;
            }
            return MeasurementType.Static;
        })();
        const measurementConfigPayload: MeasurementConfigPayload = {
            correction: settings.lensSphere || settings.lensCylinder || settings.lensAxialLength || settings.lensCorrection ? {
                type: CorrectionType.Lens ?? 0,
                sphere: settings.lensSphere ?? 0,
                cylinder: settings.lensCylinder ?? 0,
                axis: settings.lensAxialLength ?? 0,
                clUsed: settings.lensCorrection ?? false,
            } : {
                type: CorrectionType.No,
            },
            eye: settingsEyeToEyeMap[settings.eye!],
            fixationControl: settings.fixation,
            intervalTime: settings.speed,
            programRef: program?.ref,
            staticDots: staticDots ?? undefined,
            measurementType,
        }
        const startMeasurement = () => {
            putMeasurementStart(measurementConfigPayload)
                .then(() => {
                    if (settings.followUpDate) {
                        void setComments([{
                            timestamp: settings.followUpCurrentDate!,
                            type: CommentType.Confirmation,
                            category: CommentCategory.Measurement,
                            text: t("data_transfer_success", {
                                eye: settings.followUpEye === "left" ? t("left_eye") : settings.followUpEye === "right" ? t("right_eye") : t("both_eyes"),
                                date: settings.followUpDate.split("/")[0],
                            }),
                        }])
                    }
                })
                .catch((err) => props.handleCriticalError(err, () => startMeasurement()));
        };
        startMeasurement();
    }, [props.patient, props.deviceInformation, program, responseButtonTested]);
    const paused = openPauseDialog || !responseButtonTested;

    const [progress, setProgress] = useState<ProgressUpdate>()
    const [elapsedTime, updateProgressTime] = (() => {
        const [timeSinceUpdate, resetTimeSinceUpdate] = useMonotonicTimer({paused});
        const [duration, setDuration] = useState(0)
        const updateProgressTime = useCallback(({

                                                    duration = 0
                                                }: Pick<ProgressUpdate, "duration">) => {
            resetTimeSinceUpdate();
            setDuration(duration);
        }, [setDuration, resetTimeSinceUpdate]);
        return [duration + Math.trunc(timeSinceUpdate), updateProgressTime];
    })();

    // Fake measurement Done, when mockserver is used
    useEffect(() => {
        if (patientConfig?.wsUrl !== "mockserver") {
            return
        }
        if (hasProgramKinetic(program)) {
            if (elapsedTime - (progress?.duration ?? 0) > 40) {
                getMockserver()?.send({
                    type: MessageType.Done,
                });
            }
        } else if ((progress?.eta ?? Infinity) <= 0) {
            getMockserver()?.send({
                type: MessageType.Done,
            });
        }
    }, [progress?.eta, patientConfig?.wsUrl, elapsedTime, progress?.duration]);

    const {
        symbolPoints,
        numberPoints
    } = useMemo(() => {
        let {symbolPoints, numberPoints} = mapMeasurementsToInvestigationMap(props.measurements);
        activeStaticUpdatePoints.toReversed().forEach(({
                                                           position,
                                                           db,
                                                           color
                                                       }) => !numberPoints.some((p) => isEqualPosition(position, p.position)) && numberPoints.push({
            position,
            db,
            color: convertColorToNumberPointColor(color)
        }))
        return {symbolPoints, numberPoints};
    }, [props.measurements, activeStaticUpdatePoints]);
    const activePoints = useMemo(() => {
        let found = false;
        const usedPositions = [...symbolPoints, ...numberPoints]
        const points = (staticDots ?? []).map<InvestigationMapActivePoint & {
            position: InvestigationMapCartesianPoint
        }>(dot => {
            if (!!activeStaticPoint && getVectorLength(subtractVector(activeStaticPoint.position, dot)) <= 0.01) {
                found = true;
                return {
                    position: activeStaticPoint.position,
                    active: activeStaticPoint.isQualityCheck ? "qualityCheck" : true
                };
            }
            return {position: dot, active: false};
        }).filter(({position, active}) => active
            || !usedPositions.some(({position: usedPosition}) => getVectorLength(subtractVector(position, usedPosition)) <= 0.01));
        if (!found && activeStaticPoint) {
            points.push({
                position: activeStaticPoint.position,
                active: activeStaticPoint.isQualityCheck ? "qualityCheck" : true
            } satisfies InvestigationMapActivePoint)
        }
        return points;
    }, [staticDots, activeStaticPoint, symbolPoints, numberPoints]);

    const baseInvestigationMapProps: Partial<InvestigationMapProps> = {
        eyeSelection: settings.eye,
        hideAxialValues,
        hideRadialValues,
    };

    const {
        dialogs: manualKineticDialogs,
        stackedIsoptereLegendProps,
        isoptereController,
        isoptereModesProps
    } = useManualKinetic({
        dialogProps: {investigationMapProps: baseInvestigationMapProps},
        mode: settings.manualKinetic ? manualKineticMode : hasProgramKinetic(program) ? "Automatic" : null,
        scotomaLength,
        state: props.isoptereModesControllerState,
        mockBackend: patientConfig?.wsUrl === "mockserver" && responseButtonTested && (!hasProgramStatic(program) || (progress?.eta ?? Infinity) <= 0),
        projectorBorder: deviceInformation ?? undefined,
        onConfigureRequest: (defaultConfig) => {
            doOpenIsopterDialog(defaultConfig);
            return new Promise((resolve, reject) => setKineticConfigureRequest({resolve, reject}));
        },
        sendKineticConfigurePopupResponse: async (message) => {
            props.websocket?.send(message);
        },
    });
    const [degOnAxis, setDegOnAxis] = useState<number>(0)
    const [eccentricity, setEccentricity] = useState<number | null>(null);
    const positions = [
        ...symbolPoints.map(point => point.position),
        ...numberPoints.map(point => point.position),
        ...activePoints.map(point => point.position)
    ];

    useEffect(() => {
        calculateDegOnAxis(
            settings.manualKinetic ?? false,
            program,
            deviceInformation,
            isoptereModesProps,
            positions,
            setDegOnAxis,
            putMaxEccentricity
        ).then();
        }, [eccentricity]);


    const AddIsoptere = () => {
        return <>
            <div className="space-y-4 max-w-82">
                <ButtonWithIcon
                    iconKey="add"
                    iconColor={TailwindColor.White}
                    iconColorHover={TailwindColor.White}
                    iconColorActive={TailwindColor.White}
                    iconPosition={MaterialIconPosition.Left}
                    disabled={!isoptereController.editable && !kineticConfigureRequest}
                    label={t("create_isopters")}
                    onClick={() => doOpenIsopterDialog(deviceSettings ?
                        convertIsopterConfigToSimpleIsopterConfig({
                            stimulusSize: deviceSettings.examination.defaultIsopterStimulusSize,
                            brightness: deviceSettings.examination.defaultIsopterBrightness,
                        }) : undefined)}
                />
                <Dropdown
                    furtherClasses={"truncate"}
                    onChange={(item) => setManualKineticMode(item.mode)}
                    width={TailwindStandardWidth.FULL}
                    currentDropdownItem={manualKineticModeDropdownItems.find(({mode}) => mode === manualKineticMode)}
                    items={manualKineticModeDropdownItems}
                    state={isoptereController.editable && Object.values(IsoptereMode).includes(isoptereModesProps.mode as IsoptereMode) ? DropdownState.Default : DropdownState.Disabled
                    }/>
                {manualKineticMode !== IsoptereMode.Manual &&
                    <ButtonWithIcon type={ButtonType.Primary}
                                    label={"Start"}
                                    iconColor="white"
                                    iconColorHover="white"
                                    width={TailwindStandardWidth.FULL}
                                    iconColorActive="white"
                                    iconPosition={MaterialIconPosition.Left}
                                    iconKey={"play_arrow"}
                                    onClick={() => {
                                        isoptereController.start();
                                    }}
                                    disabled={!isoptereController.canStart}
                    />}
                {manualKineticMode === IsoptereMode.Scoptoperimetry && (() => {
                    const scotomaLengthDropdownItems = [10, 20, 30].map((value) => ({
                        value,
                        name: `${value}°`,
                    }));
                    return (
                        <Dropdown items={scotomaLengthDropdownItems} width={TailwindStandardWidth.FULL}
                                  currentDropdownItem={scotomaLengthDropdownItems.find(({value}) => value === scotomaLength) ?? {
                                      name: `${scotomaLength}°`,
                                      value: scotomaLength
                                  }}
                                  onChange={(item) => setScotomaLength(item.value)}
                                  label={<ToggletipLabel label="Segmentlänge" openedComponent={
                                      <Toggletip headline={"Segmentlänge"}
                                                 content="TODO"
                                                 size={ToggletipSize.Medium}
                                                 closeByOutsideClick
                                                 useArrowPosition
                                                 arrowPosition={ToggletipPosition.TopCenter}/>
                                  }/>}
                                  state={isoptereController.editable ? DropdownState.Default : DropdownState.Disabled}/>
                    );
                })()}
            </div>
            <Dialog
                type={DialogType.None} label={t("manual_kinetic")} headline={t("add_isopter")}
                text={t("examination_manual_kinetic_dialog_paragraph_1")}
                width={TailwindStandardWidth.W200}
                onClose={() => setOpenIsopterDialog(null)}
                show={!!openIsopterDialog && responseButtonTested}
                buttons={[
                    {
                        disabled: !isoptereController.canHandleRequests && !kineticConfigureRequest,
                        label: t("add"), primary: true, onClick: () => {
                            if (!openIsopterDialog) {
                                return;
                            }
                            if (kineticConfigureRequest) {
                                kineticConfigureRequest.resolve(openIsopterDialog);
                                setKineticConfigureRequest(undefined);
                            } else {
                                void isoptereController.createIsopter(openIsopterDialog);
                            }
                            setOpenIsopterDialog(null);
                        }
                    },
                    {label: t("button_cancel"), primary: false, onClick: () => setOpenIsopterDialog(null)},
                ]}
            >
                {openIsopterDialog && <IsopterSettings {...openIsopterDialog} onChange={setOpenIsopterDialog} stimulusSizes={deviceInformation?.supportedManualKineticStimulusSizes ?? []}/>}
            </Dialog>
        </>
    }

    const ManualKineticSelectedArea = () => {
        return (
            <>
                {selected === 1 &&
                    <StackedIsoptereLegend height={TailwindStandardHeight.FULL} width={TailwindStandardWidth.FULL}
                                           {...stackedIsoptereLegendProps}/>}
                {selected === 2 &&
                    <MeasurementComments
                        dateFormat={deviceSettings?.main.dateFormat}
                        comments={comments}
                        onCommentsChange={settings.isTestRun ? undefined : setComments}
                        isTestRun={settings.isTestRun}/>}
                {selected === 3 &&
                    <HelpText sectionHeadlines={["", `${t("left_mouse_button")}: `, `${t("right_mouse_button")}: `]}
                              headline={manualKineticModeDropdownItems.find(({mode}) => mode === manualKineticMode)!.name}
                              content={helpText}
                              width={TailwindStandardWidth.FULL} height={TailwindStandardHeight.FULL}/>}
            </>
        );
    }

    // Workarounds for closures
    const handleMeasurementDoneRef = useRef(handleMeasurementDone);
    useEffect(() => {
        handleMeasurementDoneRef.current = handleMeasurementDone;
    }, [handleMeasurementDone])
    const setMeasurementsRef = useRef(props.setMeasurements);
    useEffect(() => {
        setMeasurementsRef.current = props.setMeasurements;
    }, [props.setMeasurements]);
    const setQualityScoreRef = useRef(props.setQualityScore);
    useEffect(() => {
        setQualityScoreRef.current = props.setQualityScore;
    }, [props.setQualityScore]);
    const isoptereControllerRef = useRef(isoptereController);
    useEffect(() => {
        isoptereControllerRef.current = isoptereController;
    }, [isoptereController]);

    return (
        <TopBarContext.Provider value={{
            examination: {
                qualityScore: props.qualityScore ?? undefined,
                examinationInfo: props.examinationInfo,
                examinationDateTime: props.examinationDateTime,
                onExaminationInfoChange: props.setExaminationInfo
            }
        }}>
            <ExaminationLayout
                sidepanelCamera={eyePreview}
                sidepanel={<>
                    <div className={"hidden xl:block mb-2"}>
                        <SettingsPreview {...props}  />
                    </div>
                    {settings.manualKinetic
                        ?
                        <div className="flex-1 flex flex-col gap-1">
                            <ContentSwitcherGroup
                                selected={selected}
                                onChange={(itemKey) => {
                                    itemKey !== null && setSelected(itemKey);

                                }}
                                width={TailwindStandardWidth.FULL}
                                items={[
                                    {
                                        iconFill: false,
                                        selectedIconKey: "is_optere",
                                        iconKey: "is_optere",
                                        sectionText: t("isopters"),
                                        key: 1,
                                    },
                                    {
                                        sectionText: t("log_entries"),
                                        iconKey: "comment",
                                        key: 2,
                                    },
                                    {
                                        sectionText: t("help"),
                                        iconFill: false,
                                        iconKey: "info",
                                        key: 3,
                                    }
                                ]}/>
                            <div className="flex-1 relative">
                                <div className="absolute inset-0 overflow-hidden">
                                    {ManualKineticSelectedArea()}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="flex-1 relative">
                            <div className="absolute inset-0 overflow-hidden">
                                <MeasurementComments
                                    dateFormat={deviceSettings?.main.dateFormat}
                                    comments={comments}
                                    onCommentsChange={settings.isTestRun ? undefined : setComments}
                                    isTestRun={settings.isTestRun}/>
                            </div>
                        </div>
                    }
                </>
                }
                brandSidebarDisabled={true}
                sidepanelButtons={
                    settings.manualKinetic ?
                        <>
                            <Button size={ButtonTextSize.Large}
                                    label={t("examination_pause")}
                                    furtherClassesBtn="!min-h-full"
                                    type={ButtonType.Secondary}
                                    onClick={() => {
                                        getMeasurementPause().catch(props.handleCriticalError);
                                        setOpenPauseDialog(true);
                                    }}/>
                            <Button size={ButtonTextSize.Large}
                                    label={t("examination_end")}
                                    furtherClassesBtn="!min-h-full"
                                    disabled={!isoptereController.canHandleRequests}
                                    onClick={handleMeasurementDone}/>
                        </>
                        :
                        <Button size={ButtonTextSize.Large}
                                label={t("examination_pause")}
                                furtherClassesBtn="!min-h-full"
                                disabled={false}
                                onClick={() => {
                                    getMeasurementPause().catch(props.handleCriticalError);
                                    setOpenPauseDialog(true);
                                }}/>
                }
            >
                {manualKineticDialogs}
                {openPauseDialog &&
                    <Dialog
                        width={TailwindStandardWidth.AUTO}
                        furtherclass={"max-w-228"}
                        onClose={() => setOpenPauseDialog(false)}
                        type={DialogType.Info} label={""}
                        headline={t("examination_paused")}
                        text={t("examination_paused_dialog_paragraph_1")}
                        buttons={[PauseDialogContinueButton, PauseDialogRepeatButton, PauseDialogCancelButton]}
                        children={<div className="flex flex-col items-center ">
                            <div className="flex">
                                <ToggletipLabel
                                    tabindex={0}
                                    label={
                                        <Headline
                                            size={HeadlineSize.H4}
                                            text={t("configuration_steps_main_label_4_kinetic")}
                                            furtherClasses="!mb-0"
                                        />
                                    }
                                    iconSize={28}
                                    openedComponent={
                                        <Toggletip
                                            headline={t("configuration_steps_main_label_4_kinetic")}
                                            content={t("step_4_patient_center_toggle_tip_text")}
                                            size={ToggletipSize.Medium}
                                            closeByOutsideClick={true}
                                            useArrowPosition={true}
                                            arrowPosition={ToggletipPosition.BottomCenter}
                                            show={true}
                                            onClose={() => {
                                            }}
                                        />
                                    }
                                />
                            </div>
                            <div className="mt-4 mb-6 h-full w-full">{eyePreview}</div>
                            {deviceInformation?.supportsVerticalChinrestMove || deviceInformation?.supportsHorizontalChinrestMove ? (
                                <CursorArrowGroup
                                    onDirectionToggle={handleChinrestDirectionToggle}
                                    enableKeyboardNavigation={true}
                                    disableVertical={!deviceInformation.supportsVerticalChinrestMove}
                                    disableHorizontal={!deviceInformation.supportsHorizontalChinrestMove}/>
                            ) : null}
                        </div>}
                    />
                }
                {openPatientPauseDialog &&
                    <Dialog width={TailwindStandardWidth.AUTO}
                            type={DialogType.Info} label={""}
                            showCloseButton={false}
                            headline={t("examination_paused")}
                            text={
                                <>
                                    {t("examination_patient_paused_dialog_paragraph_1")}
                                    <br/>
                                    {t("examination_patient_paused_dialog_paragraph_2")}
                                </> satisfies React.ReactNode as unknown as string /* HACK: "text" supports only string */}
                            furtherclass=" !w-2/3 xl:!w-1/6 "
                    />
                }
                {!responseButtonTested &&
                    <Dialog width={TailwindStandardWidth.W152}
                            type={DialogType.Info} label={""}
                            showCloseButton={false}
                            headline={t("response_button_test_dialog_headline")}
                            text={
                                <>
                                    {t("response_button_test_dialog_body")}
                                </> satisfies React.ReactNode as unknown as string /* HACK: "text" supports only string */}
                            buttons={[{
                                label: t("examination_abort"),
                                onClick: () => {
                                    navigateWithSettings("../configuration", {...settings, step: settings.step - 1, patientCentered: false})
                                }
                            }]}
                            furtherclass=""
                    />
                }
                <div className={`${settings.manualKinetic ? "col-span-9 " : "col-span-12"}`}>
                    {settings.manualKinetic || (hasProgramKinetic(program) && hasKineticStarted(isoptereModesProps))
                        ? <ExaminationManualKineticTimer elapsedTime={elapsedTime}/>
                        : <TimelineWithLabel
                            label={settings.isTestRun ? `${t("remaining_time")} ${t("test_run")}:` : `${t("remaining_time")}:`}
                            duration={progress?.duration}
                            actual={!progress ? undefined : progress?.progress.total - progress?.progress.actual}
                            total={!progress ? undefined : progress?.progress.total}
                            remainingTime={progress?.eta}
                            useTransition
                            width={TailwindStandardWidth.FULL}/>
                    }
                </div>
                <div className="col-span-12 mt-5 z-10 flex justify-center items-center gap-4">
                    <div className={"h-full max-w-[90%] aspect-square"}>
                        <InvestigationMap
                            {...baseInvestigationMapProps}
                            degOnAxis={eccentricity && settings.manualKinetic ? eccentricity : degOnAxis}
                            degSteps={degSteps}
                            staticMap={!settings.manualKinetic}
                            activePoints={activePoints}
                            symbolPoints={symbolPoints}
                            numberPoints={numberPoints}
                            symbolPointsStyle={(frontendSettings?.useModernDesign ?? true) ? SymbolPointStyle.Modern : SymbolPointStyle.Classic}
                            rightChild={hasProgramStatic(program) ?
                                <MeasurementMapLegend
                                    strategy={program.staticParameters.strategy}/> : settings.manualKinetic ?
                                    <MeasurementKineticLegend kineticMode={manualKineticMode}/> : null}
                        >{(childProps) => (
                            <>
                                <div className="absolute top-1/2 right-0" ref={setMapCenterElement}/>
                                {showVisualFieldLimit && (
                                    <VisualFieldLimits
                                        {...childProps}
                                        manualKinetic={settings.manualKinetic}
                                        blindspot={props.visualFieldLimit?.blindspot}
                                        peripheral={props.visualFieldLimit?.peripheral}
                                        projectorBorder={deviceInformation ?? undefined}
                                    />
                                )}
                                {deviceInformation &&
                                    <ProjectorBorder {...deviceInformation} {...childProps}/>}
                                <IsoptereModes {...childProps} {...isoptereModesProps} withOpacity={true}
                                               stackedIsoptereLegendPropsIndices={
                                                   stackedIsoptereLegendProps.selection === null
                                                       ? null
                                                       : stackedIsoptereLegendProps.legendData
                                                           ?.map(isopter => isopter.isopterIndex)
                                                           .filter(index => stackedIsoptereLegendProps.selection.includes(index))
                                               }
                                               avoidLabelPositions={[...activePoints, ...symbolPoints, ...numberPoints]}/>
                            </>
                        )}</InvestigationMap>
                    </div>
                    <div
                        className={`h-full flex flex-col col-start-10 ${settings.manualKinetic ? "row-start-1 row-span-2" : ""}`}>
                        {settings.manualKinetic && AddIsoptere()}
                        <div className="flex-1" ref={setMapControlsContainerElement}>
                            <MapControlGroup
                                degOnAxis={eccentricity && settings.manualKinetic ? eccentricity : degOnAxis}
                                onDegOnAxisChange={setEccentricity}
                                degOnAxisItems={settings.manualKinetic ? Array.from({length: Math.trunc(degOnAxis / degSteps)}, (_, i) => (i + 1) * degSteps) : undefined}
                                singleView
                                withHideComments={settings.manualKinetic ?? false}
                                withShowLimits={!!props.visualFieldLimit}
                                showLimits={showVisualFieldLimit}
                                onShowLimitsChange={() => setShowVisualFieldLimit(!showVisualFieldLimit)}
                                hideComments={hideComments}
                                onHideCommentsChange={setHideComments}
                                withHideRadialValues={!!settings.manualKinetic}
                                hideRadialValues={hideRadialValues}
                                onHideRadialValuesChange={setHideRadialValues}
                                withHideAxialValues={!!settings.manualKinetic}
                                hideAxialValues={hideAxialValues}
                                onHideAxialValuesChange={setHideAxialValues}
                            />
                        </div>
                    </div>
                </div>
            </ExaminationLayout>
        </TopBarContext.Provider>
    );
};

export default ExecuteExaminationPage;