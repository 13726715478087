import {HeadFC} from "gatsby";
import * as React from "react";

/**
 * `NotFoundPage` provides a generic 404 error page.
 * It is displayed whenever a user tries to navigate to a route that does not exist.
 *
 * @component
 */
const NotFoundPage: React.FC = () => {
    return (
        <main>
            <h1 className="text-3xl font-bold">Unexpected Error, please use the Feeedback-Tool to report this.</h1>
        </main>
    );
};

export default NotFoundPage;

/** Sets the title for the page */
export const Head: HeadFC = () => <title>Not found</title>;
