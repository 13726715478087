import {
    AudioPlayer,
    AudioPlayerType,
    BodyText,
    BodyTextType,
    ListItem,
    Listing,
    ListingLevel,
    Dialog,
    DialogType,
    TailwindStandardWidth,
    ButtonWithIcon,
    ButtonTextSize,
    MaterialIconPosition,
    ButtonType, TailwindColor,
} from "@oculus/component-library";
import React, {useEffect, useState} from "react";
import {ConfigurationStepProps} from "./index";
import SettingsPreview from "../SettingsPreview";
import FooterNav from "./FooterNav";
import {ExaminationComponentProps, navigateWithSettings} from "../index";
import ConfigurationLayout from "./ConfigurationLayout";
import {useTranslation} from "@oculus/component-library";

/** @see ConfigurationStep */
export function checkSettings({}: ExaminationComponentProps) {
    return true;
}

/** @see ConfigurationStep */
const FifthStep: React.FC<ConfigurationStepProps> = (props) => {
    const {t} = useTranslation();
    const listItems: ListItem[] = [
        {
            content: t("step_5_bullet_point_1"),
        },
        {
            content: t("step_5_bullet_point_2"),
        },
    ];
    const innerListItems: ListItem[] = [
        {
            content: t("step_5_bullet_point_2_1"),
        },
        {
            content: t("step_5_bullet_point_2_2"),
        },
        {
            content: t("step_5_bullet_point_2_3"),
        },
        {
            content: t("step_5_bullet_point_2_4"),
        },
        {
            content: t("step_5_bullet_point_2_5"),
        },
        {
            content: t("step_5_bullet_point_2_6"),
        },
        {
            content: t("step_5_bullet_point_2_7"),
        },
    ];

    const {eyePreview, audioExplanation1, audioExplanation2, settings} = props;
    const [showReloadDialog, setShowReloadDialog] = useState(false)
    audioExplanation1.pause();
    useEffect(() => {
        if (sessionStorage.getItem('reload') === 'true') {
            setShowReloadDialog(true);
            sessionStorage.removeItem('reload')
        }
    }, [showReloadDialog]);
    const startTestRun = () => {
        navigateWithSettings("../execution", {...settings, isTestRun: true}, {replace: true});
        return;
    };
    return (
        <ConfigurationLayout
            headline={t("step_5_headline")}
            footer={<FooterNav {...props}/>}
            sidepanelCamera={eyePreview}
            sidepanel={<SettingsPreview {...props} />}
            sidepanelMessage={
            <div className={"p-4 bg-white"}>
                <ButtonWithIcon
                    size={ButtonTextSize.Large}
                    furtherClassesBtn={"h-14"}
                    iconKey="keyboard_arrow_right"
                    iconColor={TailwindColor.Grey4}
                    iconColorHover={TailwindColor.Grey4}
                    iconColorActive={TailwindColor.Grey4}
                    iconPosition={MaterialIconPosition.Right}
                    label={t("test_run")}
                    disabled={settings.manualKinetic}
                    type={ButtonType.Tertiary}
                    onClick={() => startTestRun()}
                />
            </div>
            }
        >
            <Dialog label={t("warning")} furtherWrapperClasses={"z-0"} furtherclass={"z-0"}
                    headline={t("step_5_dialog_headline")}
                    width={TailwindStandardWidth.W200}
                    type={DialogType.Warning}
                    show={showReloadDialog}
                    showCloseButton={true}
                    onClose={() => setShowReloadDialog(false)}
                    buttons={[{
                        label: t("step_5_dialog_button_text"),
                        primary: true,
                        onClick: () => setShowReloadDialog(false)
                    }]}>
                <BodyText
                    text={t("step_5_dialog_body_text")}></BodyText>
            </Dialog>
            <div className={`col-span-12`}>
                <BodyText
                    text={t("step_5_paragraph_1")}
                    type={BodyTextType.B1Regular}
                    furtherClasses="text-grey5 !mb-6"
                />
            </div>
            <div className="col-span-12 mb-6">
                <AudioPlayer
                    state={audioExplanation2}
                    title={t("audio_Explanation_2_title")}
                    type={AudioPlayerType.Default}
                />
            </div>

            <div className="col-span-12">
                <BodyText
                    type={BodyTextType.B1Bold}
                    text={t("step_5_paragraph_2")}
                />
            </div>
            <div className="col-span-12">
                <Listing items={listItems} renderAsLevel={ListingLevel.Level1}/>
                <Listing
                    items={innerListItems}
                    renderAsLevel={ListingLevel.Level2}
                />
            </div>
        </ConfigurationLayout>

    );
};
export default FifthStep;
