import React from "react";
import {
    Dialog,
    DialogType,
    TailwindStandardWidth, useTranslation,
} from "@oculus/component-library";
import {BaseCriticalErrorPopupProps} from "./index";

/** `CommunicationErrorPopup` component properties */
export interface CommunicationErrorPopupProps extends BaseCriticalErrorPopupProps {
    /**
     * Callback invoked when the user opts for retrying the initialization process.
     * If this property is not set, the retry button will be disabled.
     */
    onRetry?: () => void,
}

/**
 * A popup component for handling special {@link InitializationError} message.
 *
 * @component
 * {@link CommunicationErrorPopupProps}
 */
const CommunicationErrorPopup: React.FC<CommunicationErrorPopupProps> = ({
                                                                                   onRetry,
                                                                                   onExit,
                                                                                   onSwitchToEvaluation,
                                                                                   show = true,
                                                                               }) => {
    const {t} =useTranslation();
    return <Dialog
        label={t("error_message")}
        headline={t("connection_lost")}
        text={t("connection_lost_text")}
        type={DialogType.Warning}
        width={TailwindStandardWidth.W200}
        showCloseButton={false}
        show={show}
        buttons={[
            {
                label: t("retry_connection"),
                primary: true,
                onClick: onRetry,
                disabled: !onRetry,
            },
            {
                label: t("exit_software"),
                onClick: onExit,
            },
            ...onSwitchToEvaluation ? [{
                label: t("evaluate_examination"),
                onClick: onSwitchToEvaluation,
            }] : [],
        ]}
    />
};

export default CommunicationErrorPopup;