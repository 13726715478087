/** See "Perimeter Device Backend REST-Protocol" for details */
export enum ChinrestMoveVerticalDirection {
    Up = "Up",
    Down = "Down",
    None = "None",
}
/** See "Perimeter Device Backend REST-Protocol" for details */
export enum ChinrestMoveHorizontalDirection {
    Left = "Left",
    Right = "Right",
    None = "None",
}