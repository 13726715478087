import {
    Button,
    ButtonTextSize,
    ButtonType,
    ContentSwitcherGroup,
    Dialog,
    DialogType,
    Headline,
    HeadlineSize,
    InvestigationMap,
    InvestigationMapProps,
    IsoptereModes,
    IsoptereModesProps,
    MapControlGroup,
    Printer,
    PrintInvestigationMap,
    ProjectorBorder,
    StackedIsoptereLegend,
    SymbolPointStyle,
    TailwindStandardHeight,
    TailwindStandardWidth,
    VisualFieldLimits,
} from "@oculus/component-library";
import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {ExaminationComponentProps, navigateWithSettings} from "./index";
import {navigate} from "gatsby";
import {
    getMeasurementAbort,
    getMeasurementExport,
    getMeasurementSave, putMaxEccentricity,
} from "../../backend/api/Calls";
import {useAppState} from "../../globalAppState";
import {encodeConfigToken} from "../../utils/configToken";
import MeasurementComments from "./MeasurementComments";
import {
    mapMeasurementsToInvestigationMap,
    degSteps,
    MeasurementMapLegend,
    settingsEyeToEyeMap,
    useMapControlsCentering, calculateDegOnAxis,
} from "./ExecuteExaminationPage";
import {useManualKinetic} from "./manualKinetic/ManualKinetic";
import {TopBarContext} from "../Header";
import ExaminationLayout from "./ExaminationLayout";
import {ProgramType} from "../../backend/api/interfaces/custom-datatypes/ProgramType";
import {MeasurementExportData} from "../../backend/api/interfaces/data/MeasurementExportData";
import {
    convertStaticSymbolsToSymbolAndNumberPoints, convertStaticValuesToNumberPoints, getIsoptereProps,
    getValueMapLegend, getValueMapLegendType,
    mergeNamedArrays
} from "../examinationResult/helper";
import {StatisticalDataType} from "../../backend/api/interfaces/custom-datatypes/StatisticalDataType";
import {useTranslation} from "@oculus/component-library";

/**
 * Generates a component for manual examination controls, which includes a content switcher and dynamic content rendering based
 * on the selected option. The component can toggle between displaying an isoptere legend or a comments section.
 *
 */
const getManualExaminationControls = (commentsSection: ReactElement, stackedIsoptereLegendProps: ReturnType<typeof useManualKinetic>["stackedIsoptereLegendProps"]) => {
    const [selected, setSelected] = useState<React.Key | null>(1);
    return (
        <div className="flex flex-col gap-2 w-full h-full">
            <ContentSwitcherGroup
                selected={selected}
                onChange={(itemKey) => {
                    itemKey !== null && setSelected(itemKey);

                }}
                width={TailwindStandardWidth.FULL}
                items={[
                    {
                        iconFill: false,
                        selectedIconKey: "is_optere",
                        iconKey: "is_optere",
                        sectionText: "Isoptere",
                        key: 1,
                    },
                    {
                        sectionText: "Log-Einträge",
                        iconKey: "comment",
                        iconFill: false,
                        key: 2,
                    }
                ]}/>
            <div className="flex-1 relative">
                <div className="absolute inset-0">
                    {selected === 1 &&
                        <StackedIsoptereLegend height={TailwindStandardHeight.FULL} width={TailwindStandardWidth.FULL}
                                               {...stackedIsoptereLegendProps}/>
                    }
                    {selected === 2 && commentsSection}
                </div>
            </div>
        </div>
    );
}

/**
 * A component designed to render the result page following a measurement.
 * It enables the user to modify comments and save the results of the measurement.
 *
 * @component
 * {@link ExaminationComponentProps}
 */
const FinishedExaminationPage: React.FC<ExaminationComponentProps> = (props) => {
    const [{patientConfig}] = useAppState();
    const {
        settings,
        comments,
        setComments,
        programWithRefList,
        deviceInformation,
        frontendSettings,
        deviceSettings,
        patient,
        info,
    } = props;
    const program = settings.manualKinetic ? null : programWithRefList?.find(({uuid}) => uuid === settings.program) ?? null;
    const {t} = useTranslation();
    const [showVisualFieldLimit, setShowVisualFieldLimit] = useState(true)
    const [openDeleteExaminationDialog, setOpenDeleteExaminationDialog] = useState(false)
    const [hideComments, setHideComments] = useState(false);
    const [hideAxialValues, setHideAxialValues] = useState(false);
    const [hideRadialValues, setHideRadialValues] = useState(!settings.manualKinetic);
    const [isSave, setIsSave] = useState(false)
    const [eccentricity, setEccentricity] = useState<number | null>(null);
    const {setMapControlsContainerElement, setMapCenterElement} = useMapControlsCentering();
    const [print, setPrint] = useState<false | true>(false);
    const [measurementExportData, setMeasurementExportData] = useState<MeasurementExportData>()
    const examinationId = measurementExportData?.examinationData.examinationIndex ?? null;
    const exportValueMapProps = useMemo(() => measurementExportData?.valueMap && {
        ...mergeNamedArrays<Pick<InvestigationMapProps, "symbolPoints" | "numberPoints">>([
            convertStaticSymbolsToSymbolAndNumberPoints(measurementExportData?.valueMap.staticSymbols ?? []),
            {numberPoints: convertStaticValuesToNumberPoints(measurementExportData?.valueMap.staticValues ?? [])},
        ]),
        ...getIsoptereProps(measurementExportData?.valueMap.kineticValues, {
            isIsopterVisible: () => true,
            setIsopterVisible: () => null
        }, examinationId ?? 0),
    }, [measurementExportData?.valueMap, examinationId]);
    const {
        symbolPoints,
        numberPoints
    } = useMemo(() => mapMeasurementsToInvestigationMap(props.measurements), [props.measurements]);
    const [exportDegOnAxis, setExportDegOnAxis] = useState<number>(0)
    const exportPositions = [
        ...(exportValueMapProps?.symbolPoints ?? []).map(point => point.position),
        ...(exportValueMapProps?.numberPoints ?? []).map(point => point.position),
        ...(exportValueMapProps?.isopteres?.flatMap(({ elements }) => elements) ?? [])
    ];
    useEffect(() => {
        calculateDegOnAxis(
            settings.manualKinetic ?? false,
            program,
            deviceInformation,
            isoptereModesProps,
            exportPositions,
            setExportDegOnAxis,
            putMaxEccentricity
        ).then();
    }, [eccentricity]);
    const symbolPointsStyle = (frontendSettings?.useModernDesign ?? true) ? SymbolPointStyle.Modern : SymbolPointStyle.Classic;
    const exportLegendProps = {
        ...getValueMapLegendType(measurementExportData?.examinationData.examinationHeader?.staticHeader?.strategy),
        withKinetic: exportValueMapProps ? !!exportValueMapProps.isopteres : false,
        printVariant: true,
        isopteres: exportValueMapProps?.isopteres ?? [],
        ...(exportValueMapProps?.isopterLegend ? {isopterLegend: exportValueMapProps.isopterLegend} : {}),
    } satisfies Parameters<typeof getValueMapLegend>[0];
    const exportIsoptereProps = {
        mode: null,
        avoidLabelPositions: [...exportValueMapProps?.numberPoints ?? [], ...exportValueMapProps?.symbolPoints ?? []],
        isopteres: exportValueMapProps?.isopteres ?? [],
    } satisfies Partial<IsoptereModesProps>;
    const deleteExaminationDialog = () => {
        return (
            <Dialog width={TailwindStandardWidth.AUTO} onClose={() => setOpenDeleteExaminationDialog(false)}
                    type={DialogType.Warning} label={t("hint")} headline={t("examination_getting_removed")}
                    text={t("delete_examination_dialog_text")}
                    buttons={[
                        {
                            label: t("examination_remove"),
                            primary: true,
                            onClick: async () => {
                                try {
                                    await getMeasurementAbort();
                                } catch (e) {
                                    console.error(e);
                                }
                                void navigate(`../configuration/?step=1`);
                            }
                        },
                        {
                            label: t("button_cancel"),
                            primary: false,
                            onClick: () => {
                                setOpenDeleteExaminationDialog(false)
                            }
                        }
                    ]}
                    furtherclass={"!w-2/3 xl:!w-1/3 "}/>
        )
    }

    const baseInvestigationMapProps: Partial<InvestigationMapProps> = {
        eyeSelection: settings.eye,
        hideAxialValues,
        hideRadialValues,
    };

    const {dialogs: manualKineticDialogs, stackedIsoptereLegendProps, isoptereModesProps} = useManualKinetic({
        dialogProps: {investigationMapProps: baseInvestigationMapProps},
        mode: settings.manualKinetic && !isSave ? "Edit" : null,
        scotomaLength: 10,
        state: props.isoptereModesControllerState,
        mockBackend: patientConfig?.wsUrl === "mockserver",
        projectorBorder: deviceInformation ?? undefined,
    });

    const [degOnAxis, setDegOnAxis] = useState<number>(0)
    const positions = [
        ...symbolPoints.map(point => point.position),
        ...numberPoints.map(point => point.position)
    ];
    useEffect(() => {
        calculateDegOnAxis(
            settings.manualKinetic ?? false,
            program,
            deviceInformation,
            isoptereModesProps,
            positions,
            setDegOnAxis,
            putMaxEccentricity
        ).then();
    }, [eccentricity]);

    return (
        <TopBarContext.Provider
            value={{
                examination: {
                    qualityScore: props.qualityScore ?? undefined,
                    examinationInfo: props.examinationInfo,
                    examinationDateTime: props.examinationDateTime,
                    onExaminationInfoChange: props.setExaminationInfo
                },
                disableExamInfo: isSave,
            }}>
            <ExaminationLayout
                brandSidebarDisabled={!isSave}
                sidepanel={<>
                    {!isSave ? (<>
                        <Button
                            label={settings.followUpPoints !== undefined ? t("examination_save_follow_up") : t("examination_save")}
                            type={ButtonType.Primary}
                            size={ButtonTextSize.Large}
                            onClick={async () => {
                                setIsSave(true);
                                try {
                                    const exportResult = await getMeasurementExport();
                                    setMeasurementExportData(exportResult.data);
                                    await getMeasurementSave();
                                } catch (error) {
                                    console.error(error);
                                    setIsSave(false);
                                }
                            }}
                        />
                        {(program?.type === ProgramType.Static || program?.type === ProgramType.Combi) && settings.followUpPoints === undefined ? (
                            <Button
                                label={t("examination_start_follow_up")}
                                type={ButtonType.Secondary}
                                size={ButtonTextSize.Large}
                                onClick={async () => {
                                    try {
                                        await getMeasurementAbort();
                                    } catch (e) {
                                        console.error(e);
                                    }
                                    navigateWithSettings("../follow-up", {
                                        ...settings,
                                        followUpPoints: undefined,
                                    })
                                }}
                            />
                        ) : null}
                        <Button
                            label={settings.followUpPoints !== undefined ? t("examination_repeat_follow_up") : t("examination_repeat")}
                            type={ButtonType.Secondary}
                            size={ButtonTextSize.Large}
                            onClick={async () => {
                                try {
                                    await getMeasurementAbort();
                                } catch (e) {
                                    console.error(e);
                                }
                                navigateWithSettings("../configuration", settings);
                            }}
                        />
                        <Button
                            label={settings.followUpPoints !== undefined ? t("examination_remove_follow_up") : t("examination_remove")}
                            type={ButtonType.PrimaryRed}
                            size={ButtonTextSize.Large}
                            onClick={() => setOpenDeleteExaminationDialog(true)}
                        />
                    </>) : (<>
                        {!settings.secondExamination && settings.eye !== "both" ?
                            <Button
                                label={`${settings.eye === "right" ? t("left_eye_examination") : t("right_eye_examination")}`}
                                type={ButtonType.Primary}
                                size={ButtonTextSize.Large}
                                onClick={() => (
                                    navigateWithSettings("../configuration", {
                                        ...settings,
                                        eye: settings.eye === "right" ? "left" : "right",
                                        step: 3,
                                        unlockedStep: 3,
                                        secondExamination: true,
                                        patientCentered: false,
                                        followUpPoints: undefined
                                    })
                                )}
                            />
                            :
                            <>
                                <Button
                                    label={t("examination_mode_finish")}
                                    type={ButtonType.Primary}
                                    size={ButtonTextSize.Large}
                                    onClick={() => {
                                        void (navigate(`/patients/${encodeConfigToken(patientConfig)}/examinations/`));
                                    }}/>
                            </>
                        }
                        <Button
                            label={t("export_results")}
                            type={ButtonType.Secondary}
                            size={ButtonTextSize.Large}
                            disabled={!isSave || !measurementExportData}
                            onClick={() => {
                                setPrint(true)
                            }}
                        />
                    </>)}
                    <div className="mt-4 flex-1 relative min-h-72">
                        {(() => {
                            const commentsSection = (
                                <div className="absolute inset-0  min-h-72 mb-10 overflow-hidden">
                                    <MeasurementComments
                                        dateFormat={deviceSettings?.main.dateFormat}
                                        isSave={isSave} comments={comments}
                                        onCommentsChange={setComments}/>
                                </div>
                            );
                            return settings.manualKinetic ? getManualExaminationControls(commentsSection, stackedIsoptereLegendProps) : commentsSection;
                        })()}
                    </div>
                </>}>
                {manualKineticDialogs}
                {openDeleteExaminationDialog && deleteExaminationDialog()}
                <Headline
                    size={HeadlineSize.H3}
                    text={settings.followUpPoints !== undefined ? t("examination_finished_follow_up") : t("examination_finished")}
                    furtherClasses="col-span-8 !mb-0 text-blue1"
                />
                <div className="col-span-12 mt-8 z-10 flex justify-center items-center gap-4">
                    <div className={"h-full max-w-[90%] aspect-square"}>
                        <InvestigationMap
                            {...baseInvestigationMapProps}
                            degOnAxis={degOnAxis}
                            degSteps={degSteps}
                            staticMap={!settings.manualKinetic}
                            symbolPointsStyle={frontendSettings?.useModernDesign ?? true ? SymbolPointStyle.Modern : SymbolPointStyle.Classic}
                            symbolPoints={symbolPoints}
                            numberPoints={numberPoints}
                            rightChild={program?.type === ProgramType.Static || program?.type === ProgramType.Combi ?
                                <MeasurementMapLegend strategy={program.staticParameters.strategy}/> : null}
                        >
                            {(childProps) => (
                                <>
                                    <div className="absolute top-1/2 right-0" ref={setMapCenterElement}/>
                                    {showVisualFieldLimit && (
                                        <VisualFieldLimits
                                            {...childProps}
                                            manualKinetic={settings.manualKinetic}
                                            blindspot={props.visualFieldLimit?.blindspot}
                                            peripheral={props.visualFieldLimit?.peripheral}
                                            projectorBorder={props.deviceInformation ?? undefined}
                                        />
                                    )}
                                    {props.deviceInformation &&
                                        <ProjectorBorder {...props.deviceInformation} {...childProps}/>}
                                    <IsoptereModes {...childProps} {...isoptereModesProps}
                                                   avoidLabelPositions={[...symbolPoints, ...numberPoints]}/>
                                </>
                            )}
                        </InvestigationMap>
                    </div>
                    <div className="" ref={setMapControlsContainerElement}>
                        <MapControlGroup
                            degOnAxis={degOnAxis}
                            onDegOnAxisChange={setEccentricity}
                            degOnAxisItems={settings.manualKinetic ? Array.from({length: Math.trunc(90 / degSteps)}, (_, i) => (i + 1) * degSteps) : undefined}
                            singleView
                            withHideComments={settings.manualKinetic ?? false}
                            withShowLimits={!!props.visualFieldLimit}
                            showLimits={showVisualFieldLimit}
                            onShowLimitsChange={setShowVisualFieldLimit}
                            hideComments={hideComments}
                            onHideCommentsChange={setHideComments}
                            withHideRadialValues={!!settings.manualKinetic}
                            hideRadialValues={hideRadialValues}
                            onHideRadialValuesChange={setHideRadialValues}
                            withHideAxialValues={!!settings.manualKinetic}
                            hideAxialValues={hideAxialValues}
                            onHideAxialValuesChange={setHideAxialValues}
                        />
                    </div>
                </div>

            </ExaminationLayout>
            <Printer print={print} onAfterPrint={() => setPrint(false)} title={t("evaluation")}
                     pageFormat={deviceSettings?.pdf.pageFormat}>
                <PrintInvestigationMap
                    {...exportValueMapProps}
                    staticMap={!exportValueMapProps?.isopteres}
                    praxisName={deviceSettings?.main?.praxisName}
                    doctorsName={deviceSettings?.main?.doctorsName}
                    patient={patient ? {...patient, externalId: patient?.externalId ?? ""} : undefined}
                    versionString={info?.versionShort}
                    dateFormat={deviceSettings?.main.dateFormat}
                    title={t("measured_values")}
                    indices={measurementExportData?.examinationData?.statisticalData && measurementExportData?.examinationData.statisticalData.type !== StatisticalDataType.No ? measurementExportData?.examinationData.statisticalData : undefined}
                    page={1}
                    totalPages={1}
                    examinationHeader={measurementExportData?.examinationData?.examinationHeader}
                    deviceName={measurementExportData?.examinationData?.examinationHeader?.deviceName}
                    eye={settingsEyeToEyeMap[settings?.eye ?? "left"]}
                    eyeSelection={settings?.eye}
                    degOnAxis={exportDegOnAxis}
                    degSteps={degSteps}
                    symbolPointsStyle={symbolPointsStyle}
                    bottomChild={getValueMapLegend({...exportLegendProps}, symbolPointsStyle)}
                >{(childProps) => <>
                    <IsoptereModes {...childProps} {...exportIsoptereProps}/>
                    {!!props.visualFieldLimit && (
                        <VisualFieldLimits
                            {...childProps}
                            manualKinetic={false}
                            blindspot={props.visualFieldLimit?.blindspot}
                            peripheral={props.visualFieldLimit?.peripheral}
                        />
                    )}
                </>}</PrintInvestigationMap>
            </Printer>
        </TopBarContext.Provider>
    );
};

export default FinishedExaminationPage;
