import {CrossHair, Spinner, TailwindStandardSize} from "@oculus/component-library";
import React from "react";

/** `EyePreview` component properties */
export interface EyePreviewProps {
    cameraUri?: string | null;
    imgClass?: string;
}

/**
 * This component displays the eye camera image from the device.
 *
 * @component
 * {@link EyePreviewProps}
 */
const EyePreview: React.FC<EyePreviewProps> = ({cameraUri = null, imgClass=""}) => {
    return (
        <div className={`flex flex-col gap-3 select-none items-center justify-center w-full h-full aspect-[384/200] ${cameraUri === null ? "bg-black" : ""}`}>
            <div className="relative h-full w-full">
                <div className={"flex items-center justify-center h-full w-full"}>
                    {cameraUri !== null && (
                        <img src={cameraUri} className={"object-contain h-full border-gray-5 border-2 " + imgClass} alt={"videoImg"} />
                    )}
                </div>
                <div
                    className={"absolute inset-0 flex items-center justify-center"}>
                    {cameraUri !== null ? (
                        <CrossHair width={160} height={120}/>
                    ) : (
                        <Spinner size={TailwindStandardSize.S32}/>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EyePreview;