/** See "Perimeter Device Backend REST-Protocol" for details */
export enum Strategy {
    Classes = "Classes",
    SuprathresholdTwoZones = "Supra threshold 2-zones",
    SuprathresholdThreeZones = "Supra threshold 3-zones",
    SuprathresholdQuantifyDefects = "Supra threshold quantify defects",
    FastThreshold = "Fast Threshold",
    Threshold = "Threshold",
    Clip = "CLIP",
    SparkTraining = "SPARK training",
    SparkQuick = "SPARK quick",
    SparkPrecision = "SPARK precision",
    SparkNTraining = "SPARKN training",
    SparkNQuick = "SPARKN quick",
    SparkNPrecision = "SPARKN precision",
}