import {
    Dialog,
    DialogType,
    getVectorLength,
    Headline,
    HeadlineSize,
    InvestigationMap,
    IsoptereModes,
    isSamePosition,
    Line,
    ProjectorBorder,
    useResizeObserver,
} from "@oculus/component-library";
import {SimpleIsoptere} from "./IsoptereModesController";
import React, {useEffect, useState} from "react";
import {BaseIsopterDialogProps} from "./ManualKinetic";
import {degSteps} from "../ExecuteExaminationPage";

/** `CombineIsopterDialog` component properties */
export interface LinkIsopterDialogProps extends BaseIsopterDialogProps {
    /** Identifier of the isoptere for manual linking */
    isopterIndex?: number;
}

/**
 * A dialog component for manual linking of an isopter.
 *
 * @component
 * @see LinkIsopterDialogProps
 */
const LinkIsopterDialog: React.FC<LinkIsopterDialogProps> = ({
                                                                 investigationMapProps,
                                                                 isopteresController,
                                                                 isopterIndex,
                                                                 show = true,
                                                                 onClose,
                                                                 projectorBorder,
                                                             }) => {
    const [mapContainerSize, mapContainerSizeRef] = useResizeObserver({width: 0, height: 0}, ({
                                                                                                  width,
                                                                                                  height
                                                                                              }) => ({width, height}));
    const isopter = isopteresController.isopteres.find(({isopterIndex: oIsopterIndex}) => oIsopterIndex === isopterIndex) ?? null;
    const [linkCustom, setLinkCustom] = useState<Line>([]);
    const linkedPreviewIsopter: SimpleIsoptere | null = isopter && (() => {
        if (linkCustom.length !== isopter.elements.length) {
            return null;
        }
        const elements: (typeof isopter)["elements"] = [];
        for (const position of linkCustom) {
            const element = isopter.elements.find(({position: oPosition}) => isSamePosition(position, oPosition));
            if (!element) {
                return null;
            }
            elements.push(element);
        }
        return {...isopter, elements}
    })();
    useEffect(() => {
        // Reset when linkCustom is invalid
        if (linkCustom.length > 0 && !linkedPreviewIsopter) {
            setLinkCustom([]);
        }
    }, [linkCustom.length > 0, !linkedPreviewIsopter]);
    useEffect(() => {
        setLinkCustom([]);
    }, [isopterIndex, show]);
    const degOnAxis = (isopter?.elements ?? []).reduce((acc, {position}) => Math.max(acc, getVectorLength(position)), 0) || undefined;
    return (
        <Dialog label="" headline="Isoptere neu verbinden" type={DialogType.None} showCloseButton={false}
                furtherclass={"!w-[100vmin]"} buttons={[
            {
                primary: true, label: "Isoptere neu verbinden",
                disabled: !isopteresController.canHandleRequests || !linkedPreviewIsopter,
                onClick: async () => {
                    await isopteresController.linkIsoptere(isopterIndex!, linkCustom);
                    onClose?.();
                }
            },
            {
                primary: false, label: "Abbrechen", onClick: onClose,
                disabled: !isopteresController.canHandleRequests
            },
        ]} show={show}>{({contentHeight, maxContentHeight}) => (
            <div className="flex flex-col">
                <div className="bg-grey4 text-white p-2 mb-4">
                    <Headline text="Vorschau" size={HeadlineSize.Subtitle1}
                              furtherClasses="text-center text-white !m-0"/>
                </div>
                <div style={{
                    height: Math.min(mapContainerSize.width, maxContentHeight - (contentHeight - mapContainerSize.height)),
                }} ref={mapContainerSizeRef}>
                    <InvestigationMap {...investigationMapProps}
                                      degOnAxis={degOnAxis} degSteps={degSteps}>
                        {(childProps) => (
                            <>
                                {projectorBorder && <ProjectorBorder {...childProps} {...projectorBorder}/>}
                                <IsoptereModes {...childProps} withOpacity={false}
                                               mode={!linkedPreviewIsopter ? "Link" : null}
                                               isopteres={isopter ? [{
                                                   connect: true,
                                                   canLink: false,
                                                   canCombine: false, ...(linkedPreviewIsopter ?? isopter)
                                               }] : []}
                                               onLinkIsopterDone={(isopterIndex, positions) => setLinkCustom(positions)}
                                />
                            </>
                        )}</InvestigationMap>
                </div>
            </div>
        )}</Dialog>
    );
};

export default LinkIsopterDialog;