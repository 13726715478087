import {
    AudioPlayer,
    AudioPlayerType,
    BodyText,
    BodyTextType,
    ButtonTextSize,
    ButtonType,
    ButtonWithIcon,
    Dialog,
    DialogType,
    fixationControlToString,
    GroupLayout,
    Headline,
    HeadlineSize,
    intervalTimeToString, isNotNull,
    MaterialIconPosition,
    RadioButtonGroup,
    ScrollPane,
    stimulusColorToString,
    stimulusSizeToString,
    strategyToString,
    TailwindColor,
    TailwindStandardWidth,
    Toggletip,
    ToggletipLabel,
    ToggletipPosition,
    ToggletipSize,
    useResizeObserver,
    useTranslation,
} from "@oculus/component-library";
import React, {useEffect, useMemo, useState} from "react";

import {FixationControl} from "../../../backend/api/interfaces/custom-datatypes/FixationControl";
import {IntervalTime} from "../../../backend/api/interfaces/custom-datatypes/IntervalTime";
import {ConfigurationStepProps} from "./index";
import SettingsPreview from "../SettingsPreview";
import FooterNav from "./FooterNav";
import {ExaminationComponentProps, navigateWithSettings} from "../index";
import FollowUpNotification from "../FollowUpNotification";
import ConfigurationLayout from "./ConfigurationLayout";
import {ProgramType} from "../../../backend/api/interfaces/custom-datatypes/ProgramType";
import {hasProgramKinetic, hasProgramStatic, programTypeToString} from "../../../helper";
import {TFunction} from "i18next";
import {DeviceNameDeviceName} from "../../../backend/api/interfaces/custom-datatypes/DeviceNameDeviceName";
import {getProgramRefListAll, getProgramsListAll} from "../../../backend/api/Calls";
import {ProgramRefListResponse} from "../../../backend/api/interfaces/response/ProgramRefListResponse";
import {ProgramListResponse} from "../../../backend/api/interfaces/response/ProgramListResponse";
import {PrivatePatientComponentProps} from "../../PrivatePatientRoute";
import {ProgramRef} from "../../../backend/api/interfaces/data/ProgramRef";

interface RadioBtnGroupElemInterface {
    key: React.Key;
    label?: any;
    disabled?: boolean;
    error?: boolean;
}

/** @see ConfigurationStep */
export function checkSettings({settings, programWithRefList}: ExaminationComponentProps) {
    if (settings.manualKinetic) {
        return !settings.program && !settings.fixation && !settings.speed;
    }
    const program = (settings.program && programWithRefList?.find((p) => p.uuid === settings.program)) || null;
    if (!program) {
        return false;
    }
    if (hasProgramStatic(program)) {
        return !!settings.fixation && !!settings.speed;
    } else {
        return !settings.fixation && !settings.speed;
    }
}

const MAX_FAVORITES_LENGTH = 6;

/** @see ConfigurationStep */
const ThirdStep: React.FC<ConfigurationStepProps> = (props) => {
    const {
        deviceInformation,
        eyePreview,
        settings,
        setSettings,
        audioExplanation1,
        audioExplanation2,
        deviceSettings,
        programFavoriteList
    } = props;
    audioExplanation2.pause();
    const {t} = useTranslation();
    const [programRefList, setProgramRefList] = useState<ProgramRefListResponse | null>(null);
    const [programListAll, setProgramListAll] = useState<ProgramListResponse | null>(null);
    useEffect(() => {
        getProgramRefListAll(settings.eye)
            .then(({ data }) => setProgramRefList(data));
        getProgramsListAll(settings.eye)
            .then(({ data }) => setProgramListAll(data));
    }, []);
    const programWithRefList: PrivatePatientComponentProps["programWithRefList"] = useMemo(() => {
        if (!programListAll || !programRefList) {
            return null;
        }
        return programListAll.map((program) => {
            let ref = programRefList.find(({uuid}) => uuid === program.uuid);
            if (ref) {
                ref.type = program.type;
                return {...program, ref, isFavoriteOnly: false};
            }
            const fakeRef: ProgramRef = {
                uuid: program.uuid,
                name: program.name,
                custom: program.custom,
                type:program.type,
                duration: 0, // TODO: Value not provided by server protocol
            };

            if (hasProgramStatic(program)) {
                fakeRef.staticParameters = program.staticParameters;
            }

            if (hasProgramKinetic(program)) {
                fakeRef.kineticParameters = program.kineticParameters;
            }

            return { ...program, ref: fakeRef, isFavoriteOnly: true };
        }).filter(isNotNull);
    }, [programRefList, programListAll]);
    const [programSelectionDialog, setProgramSelectionDialog] = useState<string>()
    const [openFixationDialog, setOpenFixationDialog] = useState(false)
    const fixationControlElements = [
        {
            key: FixationControl.Central,
            label: fixationControlToString(t, FixationControl.Central),
        },
        {
            key: FixationControl.HeijlKrakau,
            label: fixationControlToString(t, FixationControl.HeijlKrakau),
        },
    ];

    const setRadioBtnElements = (intervalTime: IntervalTime, t: TFunction) => {
        const text = intervalTimeToString(t, intervalTime).toLowerCase();
        let content = ""
        if (intervalTime === IntervalTime.Normal) {
            content = " (600 ms)"
        } else if (intervalTime === IntervalTime.Fast) {
            content = " (300 ms)"
        } else if (intervalTime === IntervalTime.Slow) {
            content = " (900 ms)"
        } else if (intervalTime === IntervalTime.Adaptive) {
            return text
        }
        return (
            <BodyText
                text={text + content}
                furtherClasses="!mb-0 text-grey5 group-hover:text-grey4 group-hover:font-medium"
            />
        );
    }

    const getRadioBtnListElement = (item: NonNullable<typeof programWithRefList>[0]) => ({
        key: item.uuid,
        label:  (<ToggletipLabel
                label={<div className="block">{hasProgramStatic(item) ? (item.name || item.uuid) + " | " + t("strategy") + ": " + strategyToString(t, item.staticParameters.strategy) : `${item.name || item.uuid}`}</div>
                }
                iconSize={28}
                openedComponent={
                    <Toggletip
                        headline={`${item.name || item.uuid}`}
                        content={<>
                            <BodyText text={
                                t("examination_type") + ": " +
                                programTypeToString(item.type, t)} furtherClasses={"!mb-0"}/>
                            {hasProgramStatic(item) ? <>
                                <BodyText
                                    text={t("strategy") + ": " + strategyToString(t, item.staticParameters.strategy)} furtherClasses={"!mb-0"}/>
                                <BodyText
                                    text={t("fixation_control") + ": " + fixationControlToString(t, item.staticParameters.fixationControl)} furtherClasses={"!mb-0"}/>
                                <BodyText
                                    text={t("stimulus_size") + ": " + stimulusSizeToString(item.staticParameters.stimulusSize)} furtherClasses={"!mb-0"}/>
                                <BodyText
                                    text={t("stimulus_color") + ": " + stimulusColorToString(t, item.staticParameters.stimulusColor)} furtherClasses={"!mb-0"}/>
                                <BodyText text={t("grid_name") + ": " + item.staticParameters.gridName} furtherClasses={"!mb-0"}/>
                            </> : null}
                            {hasProgramKinetic(item) ?
                                <BodyText
                                    text={t("isoptere_filenames") + ": " + item.kineticParameters.isopterNames.join(', ')} furtherClasses={"!mb-0"}/> : null}
                        </> as unknown as string}
                        size={ToggletipSize.Large}
                        closeByOutsideClick={true}
                        arrowPosition={ToggletipPosition.LeftCenter}
                        useArrowPosition={true}
                        show={true}
                        onClose={() => {
                        }}
                    />
                }
                furtherClasses="mb-4"
            />
        ) as unknown as string
    });

    const speedElements: RadioBtnGroupElemInterface[] = [
        {
            key: IntervalTime.Normal,
            label: setRadioBtnElements(IntervalTime.Normal, t),
        },
        {
            key: IntervalTime.Fast,
            label: setRadioBtnElements(IntervalTime.Fast, t),
        },
        {
            key: IntervalTime.Slow,
            label: setRadioBtnElements(IntervalTime.Slow, t),
        },
        {
            key: IntervalTime.Adaptive,
            label: setRadioBtnElements(IntervalTime.Adaptive, t),
        },
    ];

    const findProgram = <T extends {
        uuid: string,
    }, >(program: string, list: T[] | null | undefined): T | undefined => list?.find((p) => p.uuid === program);

    const programListFavourites: NonNullable<typeof programWithRefList> = [];
    // Add program preferences to favorites
    programFavoriteList?.programReferences.forEach((ref) => {
        const favoriteProgram = programWithRefList?.find(({uuid}) => ref.uuid === uuid);
        if (programListFavourites.length < MAX_FAVORITES_LENGTH && favoriteProgram) {
            programListFavourites.push(favoriteProgram);
        }
    });
    /*
    // Fill favorites with programs
    while (programListFavourites.length < MAX_FAVORITES_LENGTH) {
        const program = programWithRefList?.find(({uuid}) => !findProgram(uuid, programListFavourites));
        if (!program) {
            break;
        }
        programListFavourites.push(program);
    }
    */
    // Ensure that the last selected program is in the favorites (replace last entry if necessary)
    const [lastProgramNotInFavourites, setLastProgramNotInFavourites] = useState<string>("");
    if (lastProgramNotInFavourites && findProgram(lastProgramNotInFavourites, programWithRefList) && !findProgram(lastProgramNotInFavourites, programListFavourites)) {
        const lastProgram = findProgram(lastProgramNotInFavourites, programWithRefList)!;
        if (programListFavourites.length === 0 || programListFavourites.length < MAX_FAVORITES_LENGTH) {
            programListFavourites.push(lastProgram);
        } else {
            programListFavourites[programListFavourites.length - 1] = lastProgram;
        }
    }
    useEffect(() => {
        if (settings.program && !findProgram(settings.program, programListFavourites)) {
            setLastProgramNotInFavourites(settings.program);
        }
    }, [settings.program, programWithRefList]);

    const programListRadioBtnGroupElements = programListFavourites.map(getRadioBtnListElement);
    // Init default settings
    if (!settings.manualKinetic) {
        if (programWithRefList) {
            const currentProgram = settings.program && findProgram(settings.program, programWithRefList) || null;
            const newProgram = !settings.manualKinetic ?
                currentProgram
                ?? (programListFavourites.length > 0 ? programListFavourites[0] : null)
                ?? (programWithRefList.length > 0 ? programWithRefList[0] : null)
                : null;
            const newFixation = newProgram && hasProgramStatic(newProgram) ?
                (currentProgram && settings.fixation ?
                        settings.fixation
                        : newProgram.staticParameters.fixationControl
                ) : undefined;
            const newSpeed = newProgram && hasProgramStatic(newProgram) ?
                (currentProgram && settings.speed ?
                        settings.speed
                        : newProgram.staticParameters.intervalTime
                ) : undefined;
            if (settings.program !== newProgram?.uuid || settings.fixation !== newFixation || settings.speed !== newSpeed) {
                setSettings({
                    ...settings,
                    program: newProgram?.uuid,
                    fixation: newFixation,
                    speed: newSpeed,
                    manualKinetic: undefined,
                });
            }
        }
    } else if (settings.program !== undefined || settings.fixation !== undefined || settings.speed !== undefined) {
        setSettings({
            ...settings,
            manualKinetic: undefined,
        });
    }

    const programListCombiTypeElements: RadioBtnGroupElemInterface[] = programWithRefList
        ?.filter(({type, isFavoriteOnly}) => !isFavoriteOnly && type === ProgramType.Combi)
        .map(getRadioBtnListElement) ?? [];
    const programListStaticTypeElements: RadioBtnGroupElemInterface[] = programWithRefList
        ?.filter(({type, isFavoriteOnly}) => !isFavoriteOnly && type === ProgramType.Static)
        .map(getRadioBtnListElement) ?? [];
    const programListKineticTypeElements: RadioBtnGroupElemInterface[] = programWithRefList
        ?.filter(({type, isFavoriteOnly}) => !isFavoriteOnly && type === ProgramType.AutomaticKinetic)
        .map(getRadioBtnListElement) ?? [];

    const [dialogFixation, setDialogFixation] = useState(settings.fixation);
    const [dialogSpeed, setDialogSpeed] = useState(settings.speed);
    const [contentHeight, contentSizeRef] = useResizeObserver(0, ({height}) => height);

    return (
        <ConfigurationLayout
            headline={t("step_3_headline")}
            gridLayout={"grid-rows-[auto_1fr]"}
            player={
                <AudioPlayer
                    state={audioExplanation1}
                    title={t("audio_Explanation_1_title_small")}
                    type={AudioPlayerType.Small}
                />
            }
            footer={<FooterNav {...props}/>}
            sidepanelCamera={eyePreview}
            sidepanel={<SettingsPreview {...props} />}
            sidepanelMessage={settings.followUpDate ?
                <FollowUpNotification settings={settings} withTimeout={true}
                                      dateFormat={deviceSettings?.main.dateFormat}/> : null}
        >
            <div className={`col-span-12`}>
                <BodyText
                    text={t("step_3_paragraph_1")}
                    type={BodyTextType.B1Regular}
                    furtherClasses="text-grey5 !mb-6"
                />
            </div>
            <ScrollPane
                className="col-span-12 w-full h-full pr-4"
                containerStyle={{height: contentHeight}}
                containerClassName="xl:!h-full"
            >
                <div className="col-span-12 flex flex-col" ref={contentSizeRef}>
                    <RadioButtonGroup layout={GroupLayout.GridCols3}
                                      elements={programListRadioBtnGroupElements}
                                      onChange={(v) => {
                                          setSettings(
                                              {
                                                  ...settings,
                                                  program: v as string,
                                                  // Automatically set next render
                                                  fixation: undefined,
                                                  speed: undefined,
                                              })
                                      }}
                                      selected={settings.program}
                                      label={
                                          <ToggletipLabel
                                              label={
                                                  <Headline
                                                      size={HeadlineSize.H4}
                                                      text={t("step_3_headline_examination")}
                                                      furtherClasses="!mb-0 text-dark"
                                                  />
                                              }
                                              iconSize={28}
                                              openedComponent={
                                                  <Toggletip
                                                      headline={t("step_3_headline_examination")}
                                                      content={t("step_3_headline_examination_toggle_tip")}
                                                      size={ToggletipSize.Medium}
                                                      closeByOutsideClick={true}
                                                      arrowPosition={ToggletipPosition.LeftCenter}
                                                      useArrowPosition={true}
                                                      show={true}
                                                      onClose={() => {
                                                      }}
                                                  />
                                              }
                                              furtherClasses="mb-4"
                                          />
                                      }
                    />
                </div>
            </ScrollPane>
            <div className={"col-span-12 w-full flex flex-col mt-6 gap-6"}>
                <ButtonWithIcon
                    iconKey="keyboard_arrow_right"
                    iconColor={TailwindColor.Grey4}
                    iconColorHover={TailwindColor.Grey4}
                    iconColorActive={TailwindColor.Grey4}
                    iconPosition={MaterialIconPosition.Right}
                    label={t("all_programs")}
                    type={ButtonType.Tertiary}
                    furtherClassesWrapper="!w-1/3"
                    size={ButtonTextSize.XLarge}
                    onClick={() => setProgramSelectionDialog(settings.program ?? "")}
                />
                <div className={"flex justify-between"}>
                    <ButtonWithIcon
                        iconKey="settings" iconFill={false}
                        iconColor={TailwindColor.Grey4}
                        iconColorHover={TailwindColor.Grey4}
                        iconColorActive={TailwindColor.Grey4}
                        iconPosition={MaterialIconPosition.Left}
                        label={t("step_3_headline_fixation_control") + " & " + t("step_3_headline_interval_time")}
                        type={ButtonType.Tertiary}
                        furtherClassesWrapper="!w-1/3"
                        size={ButtonTextSize.XLarge}
                        onClick={() => setOpenFixationDialog(true)}
                        disabled={settings.manualKinetic || programListKineticTypeElements.map(({key}) => key === settings.program).includes(true)}
                    />
                    {deviceInformation?.supportsKinetic && (deviceInformation.connectedDevice.name === DeviceNameDeviceName.Twinfield || deviceInformation.connectedDevice.name === DeviceNameDeviceName.Twinfield3) && (
                        <ButtonWithIcon
                            iconKey="keyboard_arrow_right"
                            iconColor="white"
                            iconColorHover="white"
                            iconColorActive="white"
                            iconPosition={MaterialIconPosition.Right}
                            label={t("manual_kinetic")}
                            type={ButtonType.Secondary}
                            furtherClassesWrapper="!w-1/3"
                            size={ButtonTextSize.XLarge}
                            onClick={() => {
                                navigateWithSettings("", {
                                    ...settings,
                                    manualKinetic: true,
                                    program: undefined,
                                    fixation: undefined,
                                    speed: undefined,
                                    step: settings.step + 1
                                })
                            }}
                        />)}
                </div>
            </div>
            <Dialog headline={t("step_3_fixation_interval_time_dialog_headline")} label={""} showCloseButton={false}
                    show={openFixationDialog} onClose={() => setOpenFixationDialog(false)}
                    width={TailwindStandardWidth.W152}
                    type={DialogType.None}
                    buttons={[
                        {
                            label: t("button_save"),
                            primary: true,
                            onClick: () => {
                                setSettings({
                                    ...settings,
                                    fixation: dialogFixation,
                                    speed: dialogSpeed,
                                });
                                setOpenFixationDialog(false);
                            }
                        },
                        {
                            label: t("button_cancel"),
                            onClick: () => {
                                setDialogFixation(settings.fixation)
                                setDialogSpeed(settings.speed)
                                setOpenFixationDialog(false)
                            },
                        }
                    ]}
                    text={<>{t("step_3_fixation_interval_time_dialog_part_1")} <br/>
                        <br/> {t("step_3_fixation_interval_time_dialog_part_2")}</> satisfies React.ReactNode as unknown as string}>
                <div className={"flex mt-2 gap-8 w-full"}>
                    <div className={"flex-1"}>
                        <RadioButtonGroup furtherBaseToggleClasses={"flex-1 mb-3"}
                                          label={
                                              <Headline
                                                  size={HeadlineSize.H4}
                                                  text={t("step_3_headline_fixation_control")}
                                                  furtherClasses="!mb-3"
                                              />
                                          }
                                          layout={GroupLayout.GridCols1}
                                          elements={fixationControlElements}
                                          onChange={(v) => setDialogFixation(v as FixationControl)}
                                          selected={dialogFixation}
                        />
                    </div>
                    <div className={"flex-1"}>
                        <RadioButtonGroup furtherBaseToggleClasses={"flex-1 mb-3"}
                                          label={
                                              <Headline
                                                  size={HeadlineSize.H4}
                                                  text={t("step_3_headline_interval_time")}
                                                  furtherClasses="!mb-3"
                                              />
                                          }
                                          layout={GroupLayout.GridCols1}
                                          elements={speedElements}
                                          onChange={(v) => setDialogSpeed(v as IntervalTime)}
                                          selected={dialogSpeed}
                        />
                    </div>
                </div>
            </Dialog>
            <Dialog
                label={t("step_3_headline_examination")}
                headline={t("all_programs")}
                text={t("step_3_headline_examination_dialog_content")}
                show={programSelectionDialog !== undefined}
                onClose={() => setProgramSelectionDialog(undefined)}
                showCloseButton={false}
                type={DialogType.None}
                width={TailwindStandardWidth.W228}
                buttons={[
                    {
                        label: t("button_continue"),
                        primary: true,
                        disabled: !programSelectionDialog,
                        onClick: () => {
                            setSettings({
                                ...settings,
                                program: programSelectionDialog,
                                // Automatically set next render
                                fixation: undefined,
                                speed: undefined,
                            });
                            setProgramSelectionDialog(undefined);
                        }
                    },
                    {
                        label: t("button_cancel"),
                        onClick: () => setProgramSelectionDialog(undefined),
                    }
                ]}
            >
                <div className="grid grid-cols-1 gap-y-4">
                    {deviceInformation?.supportsKinetic ?
                        <>
                            {programListStaticTypeElements.length > 0 &&
                                <RadioButtonGroup layout={GroupLayout.GridCols3} furtherBaseToggleClasses={"flex1"}
                                                  label={<Headline size={HeadlineSize.H4}
                                                                   text={t("static_programs")}/>}
                                                  elements={programListStaticTypeElements}
                                                  selected={programSelectionDialog}
                                                  onChange={(key) => setProgramSelectionDialog(key as string)}
                                />
                            }
                            {programListKineticTypeElements.length > 0 &&
                                <RadioButtonGroup layout={GroupLayout.GridCols3} furtherBaseToggleClasses={"flex1"}
                                                  label={<Headline size={HeadlineSize.H4}
                                                                   text={t("kinetic_programs")}/>}
                                                  elements={programListKineticTypeElements}
                                                  selected={programSelectionDialog}
                                                  onChange={(key) => setProgramSelectionDialog(key as string)}
                                />
                            }
                            {programListCombiTypeElements.length > 0 &&
                                <RadioButtonGroup layout={GroupLayout.GridCols3} furtherBaseToggleClasses={"flex1"}
                                                  label={<Headline size={HeadlineSize.H4}
                                                                   text={t("combi_programs")}/>}
                                                  elements={programListCombiTypeElements}
                                                  selected={programSelectionDialog}
                                                  onChange={(key) => setProgramSelectionDialog(key as string)}
                                />
                            }
                        </>
                        :
                        <>
                            <RadioButtonGroup layout={GroupLayout.GridCols3} furtherBaseToggleClasses={"flex1"}
                                              label={<Headline size={HeadlineSize.H4} text={t("static_programs")}/>}
                                              elements={programListStaticTypeElements}
                                              selected={programSelectionDialog}
                                              onChange={(key) => setProgramSelectionDialog(key as string)}
                            />
                        </>
                    }
                </div>
            </Dialog>
        </ConfigurationLayout>
    );
};
export default ThirdStep;
