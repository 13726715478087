import React from "react";
import {Dialog, DialogType, TailwindStandardWidth, useTranslation} from "@oculus/component-library";
import {BaseCriticalErrorPopupProps} from "./index";

/**
 * Represents the properties for the ReferencePositioningFailedPopup component.
 */
export interface ReferencePositioningFailedPopupProps extends BaseCriticalErrorPopupProps{}

/**
 * `ReferencePositioningFailedPopup` is a React functional component that renders
 * a warning dialog to inform the user about a critical error related to reference
 * positioning failure. It provides options to either exit the software or switch
 * to examination evaluation mode, depending on the props passed.
 */
const ReferencePositioningFailedPopup: React.FC<ReferencePositioningFailedPopupProps> = ({
                                                                                                       onExit,
                                                                                                       onSwitchToEvaluation,
                                                                                                       show = true,
                                                                                                   }) => {
    const {t} = useTranslation();
    return <Dialog
        label={t("error_message")}
        headline={t("critical_error")}
        text={t("reference_position_failed_message")}
        type={DialogType.Warning}
        width={TailwindStandardWidth.W200}
        showCloseButton={false}
        buttons={[
            {
                label: t("cancel_examination_exit_software"),
                primary: true,
                onClick: onExit,
            },
            ...onSwitchToEvaluation ? [{
                label: t("evaluate_examination"),
                onClick: onSwitchToEvaluation,
            }] : [],
        ]}
        show={show}
    />
};

export default ReferencePositioningFailedPopup;