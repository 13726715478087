import {MessageType} from "../MessageType";

export enum LampColorFilterPopupRequestFilter {
    White = "White",
    Yellow = "Yellow",
}

export interface LampColorFilterPopupRequest {
    type: MessageType.LampColorFilterPopupRequest,
    filter: LampColorFilterPopupRequestFilter,
}
