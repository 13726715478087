import {
    BigButton,
    ButtonType, formatMinutes,
    MaterialIconPosition,
    MilestoneState,
    MilestoneType,
    Progressbar,
    TailwindStandardHeight,
    TailwindStandardMarginTopEnum,
    TailwindStandardWidth, Tooltip,
    useResizeObserver,
    useTranslation,
} from "@oculus/component-library";
import {MaterialIconInterface} from "@oculus/component-library/lib/components/atoms/icons/MaterialIcons";
import React, {useMemo} from "react";
import {ConfigurationStepProps} from "./index";
import {navigateWithSettings} from "../index";
import {MilestoneItem} from "@oculus/component-library/lib/components/atoms/navigation/Milestone";
import {hasProgramKinetic} from "../../../helper";

const getIcon = (iconKey: string, size?: number) => {
    return {
        icon: iconKey,
        weight: 400,
        grade: 0,
        size: size ?? 32,
        color: "white",
    } as MaterialIconInterface;
};

const getIconByState = (state: MilestoneState) => {
    if (state === MilestoneState.Current) {
        return getIcon("edit", 20);
    }
    if (state === MilestoneState.Finished) {
        return getIcon("done", 24);
    }
    if (state === MilestoneState.Error) {
        return getIcon("close", 24);
    }
    return getIcon("", 0);
};

const pxToTailwindWidthLUT: [number, TailwindStandardWidth][] = Object.entries(TailwindStandardWidth)
    .filter(([k]) => k.match(/^W[0-9]+$/))
    .map(([k, tw]) => [Number(k.substring(1)) * 4, tw]);

const mainLabelTopMargin = TailwindStandardMarginTopEnum.MT20;

/**
 * This component renders the footer section for configuration steps.
 *
 * Its main purpose is to display a summary and offer controls for navigation between the steps.
 *
 * @component
 * {@link ConfigurationStepProps}
 */
const FooterNav: React.FC<ConfigurationStepProps> = (props) => {
    const {t} = useTranslation();
    const {settings, steps, programWithRefList, referencePositioningFailed, referencePositioning} = props;
    const settingsChecks = steps.map((step) => step.checkSettings(props));

    const [progressContainerWidth, progressContainerRef] = useResizeObserver(0, ({width}) => width);
    const dynamicItemWidth = useMemo(() => {
        const maxHalfWidth = (Math.max(0, progressContainerWidth) / steps.length / 2) - 5;
        const fittedHalfWidth = pxToTailwindWidthLUT.reduce((acc, [w]) =>
            pxToTailwindWidthLUT.find(([w2]) => w * 2 === w2) && (
                acc === null || (acc > maxHalfWidth && w < acc) || (w <= maxHalfWidth && w > acc)
            ) ? w : acc, null as null | number);
        if (fittedHalfWidth === null) {
            return {
                halfWidth: TailwindStandardWidth.W36,
                fullWidth: TailwindStandardWidth.W72,
            }
        }
        return ({
            halfWidth: pxToTailwindWidthLUT.find(([w]) => w === fittedHalfWidth)![1],
            fullWidth: pxToTailwindWidthLUT.find(([w2]) => w2 === 2 * fittedHalfWidth)![1],
        })
    }, [progressContainerWidth, steps.length]);
    const mileStoneItems = steps.map(({mainLabel}, i) => {
        let state = MilestoneState.NotStartedYet;
        if (settings.step === i) {
            state = MilestoneState.Current;
        } else if (i <= settings.unlockedStep) {
            state = settingsChecks[i] ? MilestoneState.Finished : MilestoneState.Error;
        }
        return {
            state: state,
            type: i === 0 ? MilestoneType.Start : (i === steps.length - 1 ? MilestoneType.End : MilestoneType.Between),
            icon: getIconByState(state),
            onClick: i <= settings.unlockedStep && i !== settings.step ? () => navigateWithSettings("", {
                ...settings,
                step: i,
                patientCentered: false
            }) : undefined,
            mainLabel: typeof mainLabel === "object" ? (settings.manualKinetic ? t(mainLabel.kinetic) : t(mainLabel.static)) : t(mainLabel),
            mainLabelTopMargin,
            mainLabelWidth: dynamicItemWidth.fullWidth,
            alwaysCenterLabel: i === 0 && settings.step === 0, // "Zurück"-Button not visible
            progressIndicator: i < settings.unlockedStep ? "full" : i > settings.unlockedStep ? "none" : "half",
        } satisfies MilestoneItem;
    });
    const program = programWithRefList?.find((p) => p.uuid === settings.program);
    const loading = !props.patient || !props.programWithRefList || !props.deviceInformation || !props.deviceSettings || !props.frontendSettings;
    return (
        <>
            <div
                className={`col-span-2 xl:col-span-1 -mr-20 z-10`}>
                <BigButton
                    iconKey="keyboard_arrow_left"
                    type={ButtonType.Secondary}
                    iconPos={MaterialIconPosition.Left}
                    label={t("button_back")}
                    height={"h-22" as TailwindStandardHeight /* HACK */}
                    disabled={settings.step === 0}
                    onClick={() => {
                        navigateWithSettings("", {...settings, step: settings.step - 1, patientCentered: false})
                    }}
                />
            </div>
            <div className="col-span-8 xl:col-span-10 relative p-2" data-test="StepsProgression">
                <div
                    className="flex justify-center items-center w-full absolute top-[calc(50%-14px)]"
                    ref={progressContainerRef}>
                    <Progressbar
                        items={mileStoneItems}
                        startAndEndItemWidth={dynamicItemWidth.halfWidth}
                        middleItemWidth={dynamicItemWidth.fullWidth}
                    />
                </div>
            </div>
            {settings.step + 1 < steps.length ? (
                <div className="col-span-2 xl:col-span-1 -ml-20 z-10" data-test="Weiter-Button">
                    <BigButton
                        iconKey="keyboard_arrow_right"
                        height={"h-22" as TailwindStandardHeight /* HACK */}
                        type={ButtonType.Primary}
                        iconPos={MaterialIconPosition.Right}
                        disabled={settings.step !== 3 ? (loading || !settingsChecks[settings.step]) : loading}
                        label={t("button_continue_2")}
                        onClick={() => {
                            settingsChecks[settings.step] ?
                                navigateWithSettings("", {
                                    ...settings,
                                    step: settings.step == 0 && settings.followUpDate ? settings.step + 2 : settings.step + 1
                                })
                                : props.continueButtonClicked ? props.continueButtonClicked() : undefined
                        }}
                        loading={loading}
                    />
                </div>
            ) : (
                <div className="col-span-2 xl:col-span-1 -ml-20 z-10" data-test="Weiter-Button">
                    <BigButton
                        iconKey="keyboard_arrow_right"
                        height={"h-22" as TailwindStandardHeight /* HACK */}
                        type={ButtonType.Primary}
                        iconPos={MaterialIconPosition.Right}
                        label={<>
                            <div className="flex flex-col items-center justify-center">
                                {t("button_start")}
                                {program && !hasProgramKinetic(program) ?
                                    <span className={"-mr-1"}>({formatMinutes(program.ref.duration)})</span>
                                    : ""}
                            </div>
                            {referencePositioning &&
                                <Tooltip tooltipContent={t("motor_wait_reference_position")}>
                                    <div className={"absolute top-0 left-0 w-full h-full"}/>
                                </Tooltip>
                            }
                        </>satisfies React.ReactNode as unknown as string /* HACK: "label" supports only string */}
                        disabled={!settingsChecks[settings.step] || loading || referencePositioningFailed || referencePositioning}
                        onClick={() => navigateWithSettings("../execution", {
                            ...settings,
                            isTestRun: false
                        }, {replace: true})}
                        loading={loading || referencePositioningFailed || referencePositioning}
                    />
                </div>
            )}
        </>
    );
};
export default FooterNav;
