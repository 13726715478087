import React, {useEffect, useState} from "react";
import {
    formatTimePretty,
    Notification,
    NotificationSize,
    NotificationState,
    TailwindStandardWidth, useTranslation
} from "@oculus/component-library";
import {ExaminationSettings} from "./index";
import {CombinedDateFormat} from "../../backend/api/interfaces/data/DeviceSettings";

/**
 * Interface representing the properties required for follow-up notifications.
 */
interface FollowUpNotificationProps {
    /** The examination settings used to configure the follow-up notification behavior. */
    settings: ExaminationSettings;

    /** The date format to be applied when rendering or parsing notification dates.*/
    dateFormat: CombinedDateFormat | undefined;

    /*** A boolean indicating whether the follow-up notification mechanism is time-bound. */
    withTimeout: boolean;
}

/**
 * FollowUpNotification renders a notification with content describing a follow-up action, including the
 * eye and date data formatted for user understanding. It supports closing or reopening notifications
 * through state management. Displays either a single-line or multi-line notification based on the
 * configuration and notification state.
 */
const FollowUpNotification: React.FC<FollowUpNotificationProps> = ({settings, withTimeout, dateFormat}) => {
    const {t} = useTranslation();
    const [notificationClose, setNotificationClose] = useState(false);
    useEffect(() => {
        if (!withTimeout) {
            setNotificationClose(true)
        } else {
            if (!notificationClose) {
                const timer = setTimeout(() => {
                    setNotificationClose(true);
                }, 5000);

                return () => clearTimeout(timer);
            }
        }
    }, []);
    if (!settings.followUpDate) {
        return null;
    }
    if (notificationClose) {
        return (
            <div className={"w-full"} onClick={() => setNotificationClose(false)}>
                <Notification
                    infoText={""}
                    width={TailwindStandardWidth.FULL}
                    size={NotificationSize.OneLine}
                    showClose={false}
                    state={NotificationState.Confirmation}
                    headline={t("button_confirmation")}
                    content={t("data_transfer_success", {
                        eye: settings.followUpEye === "left" ? t("left_eye") : settings.followUpEye === "right" ? t("right_eye") : t("both_eyes"),
                        date: settings.followUpDate.split("/")[0],
                    })}/>
            </div>
        );
    }
    return (
        <Notification
            infoText={formatTimePretty(new Date(settings.followUpCurrentDate!), dateFormat)}
            width={TailwindStandardWidth.FULL}
            size={NotificationSize.MultiLine}
            onClose={() => setNotificationClose(true)}
            state={NotificationState.Confirmation}
            headline={t("button_confirmation")}
            content={t("data_transfer_success", {
                eye: settings.followUpEye === "left" ? t("left_eye") : settings.followUpEye === "right" ? t("right_eye") : t("both_eyes"),
                date: settings.followUpDate.split("/")[0],
            })}/>    )
};

export default FollowUpNotification;