import React from "react";
import {
    Dialog,
    DialogType,
    TailwindStandardWidth, useTranslation,
} from "@oculus/component-library";
import {BaseCriticalErrorPopupProps} from "./index";

/** `InitializationAmbientBrightnessErrorPopup` component properties */
export interface InitializationAmbientBrightnessErrorPopupProps extends BaseCriticalErrorPopupProps {
    /** Callback invoked when the user opts for retrying the initialization process */
    onRetry?: () => void,
}

/**
 * A popup component for handling special {@link InitializationError} message.
 *
 * @component
 * {@link InitializationAmbientBrightnessErrorPopupProps}
 */
const InitializationAmbientBrightnessErrorPopup: React.FC<InitializationAmbientBrightnessErrorPopupProps> = ({
                                                                                                                 onExit,
                                                                                                                 onRetry,
                                                                                                                 onSwitchToEvaluation,
                                                                                                                 show = true,
                                                                                                             }) => {
    const {t} = useTranslation();
    return <Dialog
        label={t("error_message")}
        headline={t("ambient_light_too_high")}
        text={t("ambient_light_warning_text")}
        type={DialogType.Warning}
        width={TailwindStandardWidth.W200}
        showCloseButton={false}
        show={show}
        buttons={[
            {
                label: t("new_initialization"),
                primary: true,
                onClick: onRetry,
            },
            {
                label: t("exit_software"),
                onClick: onExit,
            },
            ...onSwitchToEvaluation ? [{
                label: t("evaluate_examination"),
                onClick: onSwitchToEvaluation,
            }] : [],
        ]}
    />
};

export default InitializationAmbientBrightnessErrorPopup;