import {MessageType} from "../MessageType";

export enum LensChangePopupRequestAction {
    Insert = "Insert",
    Remove = "Remove",
}

export interface LensChangePopupRequest {
    type: MessageType.LensChangePopupRequest,
    action: LensChangePopupRequestAction,
}