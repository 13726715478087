import Layout from "../../Layout";
import React, {PropsWithChildren, ReactNode} from "react";
import {Headline, HeadlineSize, ScrollPane, useResizeObserver} from "@oculus/component-library";

/**
 * Interface representing the properties for the configuration layout component.
 * This interface extends from the `PropsWithChildren` type to allow for nested components.
 *
 * @interface ConfigurationLayoutProps
 * @extends {PropsWithChildren}
 *
 * @property {ReactNode} [sidepanelCamera] - Component or element to be rendered in the camera side panel.
 * @property {ReactNode} [sidepanel] - Component or element to be rendered in the side panel.
 * @property {ReactNode} [sidepanelMessage] - Component or element to be rendered in the side panel for messages.
 * @property {ReactNode} [footer] - Component or element to be rendered in the footer section.
 * @property {ReactNode} [player] - Component or element to be rendered in the player section.
 * @property {String} [gridLayout] - string to set the grid Layout of the left card.
 */
export interface ConfigurationLayoutProps extends PropsWithChildren {
    sidepanelCamera?: ReactNode,
    sidepanel?: ReactNode,
    sidepanelMessage?: ReactNode,
    footer?: ReactNode,
    player?: ReactNode,
    gridLayout?: string,
    headline: string,
}

/**
 * ConfigurationLayout component is a React functional component that serves as a layout for displaying various
 * sections such as side panels, camera, messages, footer, and a main content area.
 * @component
 * {@link ConfigurationLayoutProps}
 */
const ConfigurationLayout: React.FC<ConfigurationLayoutProps> = ({
                                                                     children,
                                                                     sidepanelCamera,
                                                                     sidepanel,
                                                                     sidepanelMessage,
                                                                     footer,
                                                                     player,
                                                                     gridLayout = "auto-rows-min",
                                                                     headline
                                                                 }) => {
    const [headlineHeight, headlineHeightRef] = useResizeObserver(0, ({height}) => height);

    return (
        <Layout brandSidebarDisabled={false}>
            <div className="w-full h-full flex flex-col gap-2">
                <div className="flex-1 relative">
                    <ScrollPane className="absolute inset-0">
                        <div
                            className="absolute inset-0 h-full flex flex-row gap-2">
                            <div
                                className={`order-10 flex  bg-transparent flex-col min-h-[auto] gap-2 w-[473px] relative ${(sidepanelMessage ?? null) !== null ? "pb-6" : "pb-0"}`}>
                                <div
                                    className="relative bg-white p-4 gap-4 grow-0 flex flex-col min-h-65 items-center justify-center ">
                                    {sidepanelCamera}
                                </div>
                                <div
                                    className={`bg-white flex-1 ${(sidepanelMessage ?? null) !== null ? "pb-8" : ""}`}>
                                    <ScrollPane
                                        className="!p-4 w-full h-full">
                                        <div className="flex flex-col gap-3 min-h-full relative">
                                            {sidepanel}
                                        </div>
                                    </ScrollPane>
                                </div>
                                <div className="absolute inset-x-0 bottom-0">
                                    {sidepanelMessage}
                                </div>
                            </div>
                            <div className="flex-[2_2_0%] bg-white relative">
                                <div ref={headlineHeightRef}>
                                <Headline
                                    size={HeadlineSize.H3}
                                    text={headline}
                                    furtherClasses="mt-8 !mb-0 ml-8 text-blue1"
                                />
                                </div>
                                <ScrollPane
                                    containerClassName={"[&>.simplebar-track.simplebar-vertical]:!-right-5 [&>.simplebar-track.simplebar-horizontal]:!-bottom-5"}
                                    className="!p-8 w-full"
                                    style={{ height: `calc(100% - ${headlineHeight+32}px` }}
                                >
                                    <div className={`grid grid-cols-12 relative h-full ${gridLayout} `}>
                                        {children}
                                    </div>
                                </ScrollPane>
                                <div className="absolute right-0 top-0 z-10">
                                    {player}
                                </div>
                            </div>
                        </div>
                    </ScrollPane>
                </div>
                <div className="relative grid grid-cols-12 bg-white h-22 pb-2">
                    {footer}
                </div>
            </div>
        </Layout>
    )
};

export default ConfigurationLayout;