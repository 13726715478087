import React from "react";
import {
    Dialog,
    DialogType,
    TailwindStandardWidth, useTranslation,
} from "@oculus/component-library";
import {BaseCriticalErrorPopupProps} from "./index";

/** `ZeroPositionInvalidPopup` component properties */
export interface ZeroPositionInvalidPopupProps extends BaseCriticalErrorPopupProps {
}


/**
 * A popup component for handling `ZeroPositionInvalidNotification`.
 *
 * @component
 * {@link ZeroPositionInvalidPopupProps}
 */
const ZeroPositionInvalidPopup: React.FC<ZeroPositionInvalidPopupProps> = ({
                                                                               onExit,
                                                                               onSwitchToEvaluation,
                                                                               show = true,
                                                                           }) => {
    const {t} = useTranslation();
    return <Dialog
        label={t("error_message")}
        headline={t("critical_error")}
        text={t("zero_position_invalid_text")}
        type={DialogType.Warning}
        width={TailwindStandardWidth.W200}
        showCloseButton={false}
        show={show}
        buttons={[
            {
                label: t("exit_software"),
                primary: true,
                onClick: onExit,
            },
            ...onSwitchToEvaluation ? [{
                label: t("evaluate_examination"),
                onClick: onSwitchToEvaluation,
            }] : [],
        ]}
    />
};

export default ZeroPositionInvalidPopup;