import React from "react";
import {ExaminationComponentProps, ExaminationSettings} from "../index";
import FirstStep, {checkSettings as checkSettingsFirst} from "./FirstStep";
import SecondStep, {checkSettings as checkSettingsSecond} from "./SecondStep";
import ThirdStep, {checkSettings as checkSettingsThird} from "./ThirdStep";
import FourthStep, {checkSettings as checkSettingsFourth} from "./FourthStep";
import FifthStep, {checkSettings as checkSettingsFifth} from "./FifthStep";
import {AudioPlayerState} from "@oculus/component-library";

/** Representing a configuration step */
interface ConfigurationStep {
    /** The primary label displayed for the step in the {@link FooterNav} */
    mainLabel: string | { static: string, kinetic: string },

    /**
     * The functional component responsible for rendering the configuration settings.
     *
     * @component
     * {@link ConfigurationStepProps}
     */
    component: React.FC<ConfigurationStepProps>;

    /**
     * This function validates the settings of the configuration step.
     *
     * This validation influences the display in {@link FooterNav} and whether the measurement can be started or not.
     *
     * @param props - The properties of the ExaminationComponent
     * @returns Returns true if the settings are valid, false otherwise.
     */
    checkSettings: (props: ExaminationComponentProps) => Boolean;
}

/** The specific steps required to configure a new measurement. */
export const DefaultConfigurationSteps: ConfigurationStep[] = [
    {
        mainLabel: "configuration_steps_main_label_1",
        component: FirstStep,
        checkSettings: checkSettingsFirst,
    },
    {
        mainLabel: "configuration_steps_main_label_2",
        component: SecondStep,
        checkSettings: checkSettingsSecond,
    },
    {
        mainLabel: "configuration_steps_main_label_3",
        component: ThirdStep,
        checkSettings: checkSettingsThird,
    },
    {
        mainLabel: {
            static: "configuration_steps_main_label_4_static",
            kinetic: "configuration_steps_main_label_4_kinetic"
        },
        component: FourthStep,
        checkSettings: checkSettingsFourth,
    },
    {
        mainLabel: "configuration_steps_main_label_5",
        component: FifthStep,
        checkSettings: checkSettingsFifth,
    },
];

/**
 * The properties supplied to configuration step components
 *
 * @see ConfigurationStep
 */
export interface ConfigurationStepProps extends ExaminationComponentProps {
    /** A callback function to update the settings */
    setSettings: (settings: ExaminationSettings) => void;

    /** An array with all steps */
    steps: ConfigurationStep[];

    /** The state of the first audio player meant for the first explanation. */
    audioExplanation1: AudioPlayerState;

    /** The state of the first audio player meant for the second explanation. */
    audioExplanation2: AudioPlayerState;

    /** CallBack to check if continue Button was clicked*/
    continueButtonClicked?: () => void;
}
