import {MessageType} from "../MessageType";

export enum FixationShiftPopupResponseResponseType {
    Cancel = "Cancel",
    Continue = "Continue",
}

export interface FixationShiftPopupResponse {
    type: MessageType.FixationShiftPopupResponse,
    responseType: FixationShiftPopupResponseResponseType,
}