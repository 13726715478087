import {Eye} from "../../backend/api/interfaces/custom-datatypes/Eye";
import {
    CombiLegends,
    CombiLegendsProps,
    CombiType,
    ComponentPreviewListMeasurementType,
    ComponentPreviewListType,
    DefectCurveProps,
    ExaminationPreviewItem,
    ExaminationSymbolMap,
    GlaucomaDefectCategory,
    GlaucomaStageCategory,
    GlaucomaStagingProgram,
    GlaucomaStagingProgramProps,
    GlaucomaStagingSystemProps,
    GreyscaleImageColorScale,
    InvestigationMapNumberPoint,
    InvestigationMapProps,
    InvestigationMapSymbolPoint,
    isNotNull,
    IsoptereLegend,
    IsoptereLegendProps, IsoptereModesProps,
    NumberPointColor,
    NumberPointLegend,
    NumberSymbolPointLegend,
    SymbolMapLegend,
    SymbolPointStyle,
} from "@oculus/component-library";
import {ComponentType} from "../../backend/api/interfaces/custom-datatypes/ComponentType";
import {Strategy} from "../../backend/api/interfaces/custom-datatypes/Strategy";
import React, {ReactElement, useEffect, useState} from "react";
import {
    GlaucomaStagingSystem as GlaucomaStagingSystemType
} from "../../backend/api/interfaces/data/GlaucomaStagingSystem";
import {
    GlaucomaStagingProgram as GlaucomaStagingProgramType
} from "../../backend/api/interfaces/data/GlaucomaStagingProgram";
import {VisualFieldClassCategory} from "../../backend/api/interfaces/custom-datatypes/VisualFieldClassCategory";
import {RiskValueCategory} from "../../backend/api/interfaces/custom-datatypes/RiskValueCategory";
import {DefectCurveValue} from "../../backend/api/interfaces/data/DefectCurve";
import {VisualfieldLimits} from "../../backend/api/interfaces/data/VisualfieldLimits";
import {EyeRef} from "../../backend/api/interfaces/data/EyeRef";
import {putVisualfieldLimits} from "../../backend/api/Calls";
import {PatternType} from "../../backend/api/interfaces/custom-datatypes/PatternType";
import {GreyscaleColorBarValue} from "../../backend/api/interfaces/data/GreyscaleColorBarValue";
import {PreviewItem} from "../../backend/api/interfaces/data/PreviewItem";
import {convertKineticValuesToSimpleIsopteres, convertColorToNumberPointColor} from "../../helper";
import {GreyscaleMap} from "../../backend/api/interfaces/data/GreyscaleMap";
import {ExaminationType} from "../../backend/api/interfaces/custom-datatypes/ExaminationType";
import {KineticMeasurementValue} from "../../backend/api/interfaces/data/KineticMeasurementValue";
import {MapControls} from "./ExaminationResultPage";
import {GoldmannBrightnessLetter} from "../../backend/api/interfaces/custom-datatypes/GoldmannBrightnessLetter";
import {
    GoldmannBrightnessNumericValue
} from "../../backend/api/interfaces/custom-datatypes/GoldmannBrightnessNumericValue";
import {StaticMeasurementValue} from "../../backend/api/interfaces/data/StaticMeasurementValue";
import {StaticMeasurementSymbol} from "../../backend/api/interfaces/data/StaticMeasurementSymbol";

/** Prepare data received from the backend for {@link DefectCurve} */
export function getDefectCurveValues(curve: DefectCurveValue[]): DefectCurveProps["values"] {
    return curve.toSorted(({index: indexA}, {index: indexB}) => indexA - indexB);
}

/** Prepare data received from the backend for {@link GlaucomaStagingSystem} */
export function getGssDefect(gss: GlaucomaStagingSystemType): GlaucomaStagingSystemProps["defect"] {
    return {
        mdNormalized: gss.mdNormalized, cpsdNormalized: gss.cpsdNormalized,
        stage: GlaucomaStageCategory[gss.stageCategory],
        category: GlaucomaDefectCategory[gss.defectCategory]
    };
}

/** Prepare data received from the backend for {@link GlaucomaStagingProgram} */
export function getGspClasses(gsp: GlaucomaStagingProgramType): Pick<GlaucomaStagingProgramProps, "risk" | "visualFieldClasses"> {
    let visualFieldClasses: Parameters<typeof GlaucomaStagingProgram>[0]["visualFieldClasses"];
    if (gsp) {
        const getVisualFieldClassValue = (category: VisualFieldClassCategory) => gsp.visualFieldClasses.find(({category: oCategory}) => category === oCategory)!.value;
        visualFieldClasses = {
            artifactual: getVisualFieldClassValue(VisualFieldClassCategory.Artifactual),
            neuro: getVisualFieldClassValue(VisualFieldClassCategory.Neuro),
            glaucomatous: getVisualFieldClassValue(VisualFieldClassCategory.Glaucomatous),
            normal: getVisualFieldClassValue(VisualFieldClassCategory.Normal),
        }
    }
    let risk: Parameters<typeof GlaucomaStagingProgram>[0]["risk"];
    if (gsp.showRiskValues) {
        const getRiskValue = (category: RiskValueCategory) => gsp.riskValues.find(({category: oCategory}) => category === oCategory)!.value;
        risk = {
            severe: getRiskValue(RiskValueCategory.Severe),
            moderate: getRiskValue(RiskValueCategory.Moderate),
            mild: getRiskValue(RiskValueCategory.Mild),
            preperimetric: getRiskValue(RiskValueCategory.Preperimetric),
            suspect: getRiskValue(RiskValueCategory.Suspect),
            normal: getRiskValue(RiskValueCategory.Normal),
            likelihoodIndex: gsp.indexValue,
        };
    }
    return {visualFieldClasses, risk};
}

/**
 * Determines the type of legend for a given strategy.
 *
 * @param {Strategy | undefined | null} strategy - The strategy to get the legend type for.
 * @return {{ withNumbers: boolean, examination: ExaminationSymbolMap | null }} An object containing the legend type.
 */
export function getValueMapLegendType(strategy: Strategy | undefined | null): {
    withNumbers: boolean,
    examination: ExaminationSymbolMap | null,
} {
    switch (strategy) {
        case Strategy.FastThreshold:
        case Strategy.Threshold:
        case Strategy.Clip:
        case Strategy.SparkTraining:
        case Strategy.SparkQuick:
        case Strategy.SparkPrecision:
        case Strategy.SparkNTraining:
        case Strategy.SparkNQuick:
        case Strategy.SparkNPrecision:
            return {
                withNumbers: true,
                examination: null,
            };
        case Strategy.Classes:
            return {
                withNumbers: false,
                examination: ExaminationSymbolMap.ClassifiedDefects,
            };
        case Strategy.SuprathresholdTwoZones:
            return {
                withNumbers: false,
                examination: ExaminationSymbolMap.OnlyDefects,
            };
        case Strategy.SuprathresholdThreeZones:
            return {
                withNumbers: false,
                examination: ExaminationSymbolMap.RelativeDefects,
            };
        case Strategy.SuprathresholdQuantifyDefects:
            return {
                withNumbers: true,
                examination: ExaminationSymbolMap.RelativeDefects,
            };
        default:
            if (strategy) {
                console.warn(`no legend defined for strategy "${strategy}"`);
            }
            return {
                withNumbers: false,
                examination: null,
            };
    }
}

/**
 * Generates a legend component based on various parameters such as numbers, examination type, kinetic activity, and isopteres.
 *
 * @param {Object} options - Object containing parameters to configure the legend.
 * @param {boolean} options.withNumbers - Indicates if numbers should be included in the legend.
 * @param {string} [options.examination] - Specifies the type of examination for the legend.
 * @param {boolean} options.withKinetic - Indicates if kinetic data should be included.
 * @param {Array} options.isopteres - List of isopteres to be represented in the legend.
 * @param {Function} options.onVisibilityChange - Callback function called when the visibility of elements changes.
 * @param {string} options.printVariant - Specifies the print variant for the legend.
 * @param {Object} symbolPointStyle - Style configuration for symbol points in the legend.
 *
 * @return {ReactElement|undefined} The generated legend component, or undefined if no legend is applicable.
 */
export function getValueMapLegend({
                                      withNumbers,
                                      examination,
                                      withKinetic,
                                      isopteres,
                                      onVisibilityChange,
                                      printVariant,
                                  }: ReturnType<typeof getValueMapLegendType> & Pick<IsoptereLegendProps, "isopteres" | "onVisibilityChange" | "printVariant"> & {
    withKinetic: boolean
}, symbolPointStyle: SymbolPointStyle): ReactElement | undefined {
    const combiLegendProps = {
        isopteres,
        onVisibilityChange,
        printVariant,
        symbolPointStyle,
    } satisfies Partial<CombiLegendsProps>;
    if (withNumbers && examination) {
        if (withKinetic) {
            return <CombiLegends combiType={CombiType.ISOPTERE_NUMBER_POINT_SYMBOL_POINT} {...combiLegendProps}/>
        }
        return <NumberSymbolPointLegend symbolPointStyle={symbolPointStyle}/>;
    }
    if (withNumbers) {
        if (withKinetic) {
            return <CombiLegends combiType={CombiType.ISOPTERE_NUMBER_POINT}  {...combiLegendProps}/>
        }
        return <NumberPointLegend furtherWrapperClass=""/>;
    }
    if (examination) {
        if (withKinetic) {
            return <CombiLegends combiType={{
                [ExaminationSymbolMap.ClassifiedDefects]: CombiType.ISOPTERE_DEFECT_CLASSES,
                [ExaminationSymbolMap.RelativeDefects]: CombiType.ISOPTERE_FAILURES,
                [ExaminationSymbolMap.OnlyDefects]: CombiType.ISOPTERE_SEEN_FAILURE,
            }[examination]}  {...combiLegendProps}/>
        }
        return <SymbolMapLegend examination={examination} symbolPointStyle={symbolPointStyle}/>
    }
    if (withKinetic) {
        return <IsoptereLegend {...combiLegendProps}/>
    }
    return undefined;
}

/**
 * Determines the type of measurement to be used based on the given strategy and examination type.
 *
 * @param {Strategy} strategy - The strategy to evaluate.
 * @param {ExaminationType} type - The type of examination.
 * @return {ComponentPreviewListMeasurementType | undefined} - The type of measurement to be used, or undefined if no type is defined for the given strategy.
 */
export function getValueMapMeasurementsType(strategy: Strategy, type: ExaminationType): ComponentPreviewListMeasurementType | undefined {
    if (type && type !== ExaminationType.Static) {
        return ComponentPreviewListMeasurementType.Kinetic;
    }
    switch (strategy) {
        case Strategy.FastThreshold:
        case Strategy.Threshold:
        case Strategy.Clip:
        case Strategy.SparkTraining:
        case Strategy.SparkQuick:
        case Strategy.SparkPrecision:
        case Strategy.SparkNTraining:
        case Strategy.SparkNQuick:
        case Strategy.SparkNPrecision:
            return ComponentPreviewListMeasurementType.Values;
        case Strategy.Classes:
        case Strategy.SuprathresholdTwoZones:
        case Strategy.SuprathresholdThreeZones:
            return ComponentPreviewListMeasurementType.ValuesThreshold;
        case Strategy.SuprathresholdQuantifyDefects:
            return ComponentPreviewListMeasurementType.QuantifyDefects;
        default:
            console.warn(`no component preview list measurement type defined for strategy "${strategy}"`);
            return undefined;
    }
}

/**
 * Merges an array of objects, each containing arrays, into a single object.
 *
 * @param items - An array of objects which have arrays as their values.
 */
export function mergeNamedArrays<T extends { [key: string]: any[] }>(items: Partial<T>[]): T {
    const merged: { [key: string]: any[] } = {};
    for (const item of items) {
        for (const [key, value] of Object.entries(item)) {
            merged[key] = merged[key] ?? [];
            merged[key].push(...value);
        }
    }
    return merged as T;
}

/** Prepare data received from the backend for `InvestigationMap` */
export function convertStaticSymbolsToSymbolAndNumberPoints(values: StaticMeasurementSymbol[]): {
    symbolPoints: InvestigationMapSymbolPoint[],
    numberPoints: InvestigationMapNumberPoint[],
} {
    const symbolPoints: InvestigationMapSymbolPoint[] = [];
    const numberPoints: InvestigationMapNumberPoint[] = [];
    values.forEach(value => {
        if (value.pattern.type === PatternType.SmallerZero) {
            numberPoints.push({
                position: value.position,
                db: 0,
                color: NumberPointColor.RED,
                sign: true,
            });
            return;
        }
        symbolPoints.push(value as InvestigationMapSymbolPoint);
    });
    return {symbolPoints, numberPoints};
}

/** Prepare data received from the backend for `InvestigationMap` */
export function convertStaticValuesToNumberPoints(values: StaticMeasurementValue[], relative: boolean = false, mode: "normal" | "corrected" = "normal"): InvestigationMapNumberPoint[] {
    return (values ?? []).map(
        ({position, ...value}) => ({
            position,
            db: relative ? (mode === "normal" ? value.deviation : value.correctedDeviation) : value.brightness,
            color: convertColorToNumberPointColor(value.color),
        })).map(({db, ...params}) => isNotNull(db) ? {db, ...params} : null).filter(isNotNull);
}

/**
 * This function converts an `Eye` value from the backend API to a corresponding `eyeSelection` value
 * for the `InvestigationMap` and `MapWithMapControls` component.
 *
 * @param eye - The Eye value received from the backend API. Can be undefined or null for no selection.
 * @returns The corresponding type.
 */
export function mapEyeToInvestigationMap(eye: Eye | undefined | null): NonNullable<InvestigationMapProps["eyeSelection"]> {
    switch (eye) {
        case Eye.Binocular:
            return "both";
        case Eye.Left:
            return "left";
        case Eye.Right:
            return "right";
        default:
            void (eye satisfies undefined | null);
            return "";
    }
}

/**
 * This function maps each `ComponentType` (used in the backend API) to
 * one or more `ComponentPreviewListTypes` .
 *
 * @param componentType - The component type from backend API.
 * @returns An array of corresponding types.
 */
export function mapComponentTypeToComponentPreviewListTypes(componentType: ComponentType): ComponentPreviewListType[] {
    switch (componentType) {
        case ComponentType.GreyscaleMap:
            return [ComponentPreviewListType.greyImage];
        case ComponentType.ProbabilityMap:
            return [ComponentPreviewListType.deviationProbability];
        case ComponentType.ValueMap:
            return [ComponentPreviewListType.measurements];
        case ComponentType.DeviationMap:
            return [ComponentPreviewListType.deviation];
        case ComponentType.DefectCurve:
            return [ComponentPreviewListType.defectCurve];
        case ComponentType.GlaucomaStagingProgram:
            return [ComponentPreviewListType.gsp];
        case ComponentType.GlaucomaStagingSystem:
            return [ComponentPreviewListType.gss];
        default:
            void (componentType satisfies never);
            throw new Error(`unexpected componentType ${componentType}`);
    }
}

/**
 * Checks if a list of components includes a specific component type.
 *
 * @param {ComponentType[]} haystack - The array of ComponentType objects to search.
 * @param {ComponentPreviewListType} needle - The specific ComponentPreviewListType to find in the array.
 */
export function componentListIncludesType(haystack: ComponentType[], needle: ComponentPreviewListType) {
    return haystack.some((component) => mapComponentTypeToComponentPreviewListTypes(component).includes(needle));
}

/**
 * Custom hook to manage the visual field limit state for a given eye.
 *
 * @param {Eye | undefined} eye - The eye for which to fetch and manage the visual field limit data.
 * @return An object containing:
 * - visualFieldLimit: The current visual field limit data.
 * - showVisualFieldLimit: A boolean indicating whether to show the visual field limit.
 * - setShowVisualFieldLimit: Function to update the showVisualFieldLimit state.
 */
export function useVisualFieldLimit(eye: Eye | undefined) {
    const [visualFieldLimit, setVisualFieldLimit] = useState<VisualfieldLimits>();
    const [showVisualFieldLimit, setShowVisualFieldLimit] = useState(true);

    useEffect(() => {
        if (eye) {
            const eyeRef: EyeRef = {eye: eye};
            putVisualfieldLimits(eyeRef).then(({data}) => {
                setVisualFieldLimit(data);
            });
        }
    }, [eye]);

    return {visualFieldLimit, showVisualFieldLimit, setShowVisualFieldLimit};
}

/**
 * Determines the dominant eye based on a series of eye examinations.
 *
 * @param {Array} examinations An array of objects containing examination references with eye information.
 * @param {Object} examinations[].examinationRef Reference to the examination details.
 * @param {Eye} examinations[].examinationRef.eye The examined eye.
 */
export function findDominantEye(examinations: { examinationRef: { eye: Eye } }[]) {
    let dominantEye: Eye | undefined = undefined;
    for (const {examinationRef: {eye}} of examinations) {
        if (!dominantEye || dominantEye === Eye.Binocular || dominantEye === Eye.Left && eye === Eye.Right) {
            dominantEye = eye;
        }
    }
    return dominantEye ?? Eye.Right;
}

/**
 * Generates a greyscale image color scale from a given color bar.
 *
 * The function takes an array of GreyscaleColorBarValue objects, sorts them based on their dbValue,
 * and constructs a GreyscaleImageColorScale by mapping each element to an object containing the original
 * dbValue, its type, and its corresponding color in hexadecimal format.
 *
 * @param {GreyscaleColorBarValue[]} colorBar - An array of objects containing dbValue and rgbValue properties.
 * @returns {GreyscaleImageColorScale} - The constructed greyscale image color scale.
 */
export function makeGreyscaleImageColorScale(colorBar: GreyscaleColorBarValue[]): GreyscaleImageColorScale {
    return colorBar.toSorted(({dbValue: dbValueA}, {dbValue: dbValueB}) => dbValueA - dbValueB)
        .map(({dbValue, rgbValue}, index, colorBar) => ({
            value: dbValue,
            type: index > 0 ? ">=" : (colorBar[1]?.dbValue === dbValue ? "<" : "approx"),
            color: `#${rgbValue.toString(16).padStart(6, "0")}`,
        }));
}

/**
 * Creates an examination preview item by transforming the given preview item.
 *
 * @param {PreviewItem} item - The preview item to be transformed into an examination preview item.
 * @return {ExaminationPreviewItem} The transformed examination preview item.
 */
export function makeExaminationPreviewItem(item: PreviewItem): ExaminationPreviewItem {
    const exampleGoldmanBrightness = {
        letter: GoldmannBrightnessLetter.A,
        numericValue: GoldmannBrightnessNumericValue.Two
    }
    return {
        ...item,
        previewSymbolMap: item.previewSymbolMap && {
            ...item.previewSymbolMap,
            symbols: item.previewSymbolMap.symbols.filter(({pattern: {type}}) => type !== PatternType.SmallerZero) as InvestigationMapSymbolPoint[],
        },
        previewGreyscaleMap: item.previewGreyscaleMap && {
            ...item.previewGreyscaleMap,
            colorScale: makeGreyscaleImageColorScale(item.previewGreyscaleMap.greyscaleColorBarValues),
        },
        previewIsopterMap: item.previewIsopterMap ? {
            ...item.previewIsopterMap,
            isopteres: convertKineticValuesToSimpleIsopteres(item.previewIsopterMap.isopters.map(item => ({
                ...item,
                goldmannBrightness: exampleGoldmanBrightness
            }))).map(item => ({...item, canLink: false, canCombine: false, connect: true})),
        } : undefined,
    };
}

/**
 * Converts a GreyscaleMap to number points.
 *
 * @param greyscaleMap - The GreyscaleMap object
 * @param relative - Flag to indicate if the absolute or relative values should be used.
 * @returns Returns an array of number points.
 */
export function getGreyscaleMapNumberPoints(greyscaleMap: Pick<Partial<GreyscaleMap>, "staticValues" | "staticSymbols"> | undefined,
                                            relative: boolean = false): NonNullable<InvestigationMapProps["numberPoints"]> {
    const {staticValues = [], staticSymbols = []} = greyscaleMap ?? {};
    return [
        ...convertStaticValuesToNumberPoints(staticValues, relative),
        ...!relative ? convertStaticSymbolsToSymbolAndNumberPoints(staticSymbols).numberPoints : [],
    ].map((point) => ({...point, stroke: true}))
}

/**
 * Generates properties for isopteres based on kinetic measurement values and map control visibility settings.
 *
 * @param {KineticMeasurementValue[] | null | undefined} values - The kinetic measurement values used to determine isopteres.
 * @param {Object} config - An object containing map control properties to manage isopter visibility.
 * @param {function} config.isIsopterVisible - A function to check if a specific isopter is visible.
 * @param {function} config.setIsopterVisible - A function to set the visibility of a specific isopter.
 * @param {number} examinationIndex - The index of the examination to consider when determining isopter visibility.
 * @return {Object} An object containing filtered and mapped isopteres and an isopter legend configuration.
 * @return {IsoptereModesProps["isopteres"]} isopteres - The filtered and mapped isopteres.
 * @return {Pick<IsoptereLegendProps, "onVisibilityChange" | "isopteres">} isopterLegend - The legend configuration for isopteres, including visibility properties and change handler.
 */
export function getIsoptereProps(values: KineticMeasurementValue[] | null | undefined, {
    isIsopterVisible,
    setIsopterVisible
}: Pick<MapControls, "isIsopterVisible" | "setIsopterVisible">, examinationIndex: number): {
    isopteres: IsoptereModesProps["isopteres"];
    isopterLegend: Pick<IsoptereLegendProps, "onVisibilityChange" | "isopteres">;
} {
    const isopteres = convertKineticValuesToSimpleIsopteres(values ?? []);
    return {
        isopteres: isopteres.length === 0 ? undefined : isopteres.filter(({isopterIndex}) => isIsopterVisible(examinationIndex, isopterIndex)).map((isopter) => ({
            ...isopter,
            connect: true,
            canLink: false,
            canCombine: false,
        })),
        isopterLegend: {
            isopteres: isopteres.map((isopter) => ({
                ...isopter,
                visible: isIsopterVisible(examinationIndex, isopter.isopterIndex)
            })),
            onVisibilityChange: (isopterIndex, visible) => setIsopterVisible(examinationIndex, isopterIndex, visible),
        }
    }
}