import React from "react";
import {
    Dialog,
    TailwindStandardWidth, useTranslation,
} from "@oculus/component-library";
import {
    LampColorFilterPopupRequest,
    LampColorFilterPopupRequestFilter,
} from "../../../backend/websocket/interfaces/messages/LampColorFilterPopupRequest";
import {TFunction} from "i18next";

/** `LampColorFilterInsertPopup` component properties */
export interface LampColorFilterInsertPopupProps {
    /**
     * The filter to be displayed to the user.
     * See {@link LampColorFilterPopupRequest}
     */
    filter: LampColorFilterPopupRequest["filter"],

    /**
     * Callback function to handle the response of the popup
     *
     * @see LampColorFilterPopupResponse
     */
    onResponse?: () => void;

    /** Controls the visibility of the popup. */
    show?: boolean;
}

/**
 * Converts a `LampColorFilterPopupRequestFilter` enumeration value to its string representation for display purposes.
 *
 * @param filter - The `LampColorFilterPopupRequestFilter` enumeration value to be converted.
 * @param t - Translation function from i18next
 * @param adjective - A boolean indicating if the output should be in adjective form. (default: false)
 * @returns The corresponding string representation of the filter.
 */
export function lampColorFilterPopupRequestFilterToString(filter: LampColorFilterPopupRequestFilter, adjective: boolean = false, t:TFunction): string {
    switch (filter) {
        case LampColorFilterPopupRequestFilter.White:
            return adjective ? t("white_adjective") : t("white");
        case LampColorFilterPopupRequestFilter.Yellow:
            return adjective ? t("yellow_adjective") : t("yellow");
        default:
            return filter satisfies never;
    }
}

/**
 * A popup component for handling {@link LampColorFilterPopupRequest} message.
 *
 * @component
 * {@link LampColorFilterInsertPopupProps}
 */
const LampColorFilterInsertPopup: React.FC<LampColorFilterInsertPopupProps> = ({
                                                                                   filter,
                                                                                   onResponse,
                                                                                   show = true,
                                                                               }) => {
    const {t} = useTranslation();
    return <Dialog
        label={t("hint")}
        headline={t("use_color_filter")}
        text={`${t("color_filter_dialog_text_1")} ${lampColorFilterPopupRequestFilterToString(filter, true, t)} ${t("color_filter_dialog_text_2_1")}`}
        width={TailwindStandardWidth.W200}
        showCloseButton={false}
        show={show}
        buttons={[
            {
                label: t("button_continue_2"),
                primary: true,
                onClick: onResponse,
            },
        ]}
    />
};

export default LampColorFilterInsertPopup;