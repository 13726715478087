import {
    AudioPlayer, AudioPlayerType,
    BodyText,
    BodyTextType,
    EyeStatusBig,
    EyeStatusBigResult,
    EyeStatusBigType,
} from "@oculus/component-library";
import React from "react";
import {ConfigurationStepProps} from "./index";
import SettingsPreview from "../SettingsPreview";
import FooterNav from "./FooterNav";
import {ExaminationComponentProps} from "../index";
import ConfigurationLayout from "./ConfigurationLayout";
import {useTranslation} from "@oculus/component-library";
/** @see ConfigurationStep */
export function checkSettings({settings}: ExaminationComponentProps) {
    return Boolean(settings.eye);
}

/** @see ConfigurationStep */
const SecondStep: React.FC<ConfigurationStepProps> = (props) => {
    const { t } = useTranslation();
    const helpTextRight: string =  t("step_2_help_text_right");
    const helpTextLeft: string =  t("step_2_help_text_left");
    const helpTextBoth: string = t("step_2_help_text_both");
    const {eyePreview, settings, setSettings, audioExplanation1, audioExplanation2} = props;
    audioExplanation2.pause();
    const eyeStatusSelection: EyeStatusBigResult = {
        type: EyeStatusBigType.Binocular,
        isLeftEyeSelected: settings.eye === "left",
        isRightEyeSelected: settings.eye === "right",
        isBinocularSelected: settings.eye === "both",
    }

    return (
        <ConfigurationLayout
            headline={t("step_2_headline")}
            player={
                <AudioPlayer
                    state={audioExplanation1}
                    title={t("audio_Explanation_1_title_small")}
                    type={AudioPlayerType.Small}
                />
            }
            footer={<FooterNav {...props}/>}
            sidepanelCamera={eyePreview}
            sidepanel={<SettingsPreview {...props} />}
        >
            <div className={`col-span-12`}>
                <BodyText
                    text={t("step_2_paragraph_1")}
                    type={BodyTextType.B1Regular}
                    furtherClasses="text-grey5 !mb-6"
                />
            </div>
            <div className="col-span-12 mb-4">
                <EyeStatusBig
                    key={settings.eye /* HACK: Drop internal state */}
                    type={EyeStatusBigType.RightLeft}
                    onChange={(res) => {
                        if (res.isLeftEyeSelected) {
                            setSettings({
                                ...settings,
                                eye: "left",
                            });
                        }
                        if (res.isRightEyeSelected) {
                            setSettings({
                                ...settings,
                                eye: "right",
                            });
                        }
                    }}
                    reset={false}
                    preSelected={eyeStatusSelection}
                    selectedSensitive={false}
                />
            </div>
            <div className="col-span-12 mb-6">
                <EyeStatusBig
                    key={settings.eye /* HACK: Drop internal state */}
                    type={EyeStatusBigType.Binocular}
                    onChange={(res) => {
                        if (res.isBinocularSelected) {
                            setSettings({
                                ...settings,
                                eye: "both",
                            });
                        }
                    }}
                    reset={false}
                    preSelected={eyeStatusSelection}
                    selectedSensitive={false}
                />
            </div>
            <div className="col-span-12">
                <BodyText type={BodyTextType.B1Bold} furtherClasses={"!mb-3.5"}
                          text={`${settings.eye == undefined ? "" : t("step_2_help_text_headline")}`}/>
                <BodyText
                    type={BodyTextType.B1Regular}
                    furtherClasses="text-grey5 !mb-0"
                    text={`${settings.eye === "left" ? helpTextLeft : settings.eye === "right" ? helpTextRight : settings.eye === "both" ? helpTextBoth : ""}`}
                />
            </div>
        </ConfigurationLayout>
    );
};
export default SecondStep;
