import {
    isEqualIsopterConfig,
    IsoptereModesControllerHandler,
    IsoptereModesControllerProps,
    SimpleIsoptere,
    useIsoptereModesController,
    useMockIsoptereModesBackend,
} from "./IsoptereModesController";
import React, {ReactNode, useEffect, useState} from "react";
import {
    getManualKineticIsopterFinish,
    getManualKineticIsopterList,
    putManualKineticDirection,
    putManualKineticIsopterCombine, putManualKineticIsopterComment,
    putManualKineticIsopterCreate,
    putManualKineticIsopterRemove,
    putManualKineticPosition,
    putManualKineticScotoma,
    putManualKineticVectorAdd,
    putManualKineticVectorComment,
    putManualKineticVectorLink,
    putManualKineticVectorRemove
} from "../../../backend/api/Calls";
import {
    DialogProps,
    InvestigationMapProps,
    IsoptereLegendData,
    IsoptereModesProps,
    ProjectorBorderShape,
    StackedIsoptereLegendProps
} from "@oculus/component-library";
import CombineIsopterDialog from "./CombineIsopterDialog";
import LinkIsoptereDialog from "./LinkIsoptereDialog";

/** Base properties for manual kinetic dialog components */
export interface BaseIsopterDialogProps extends Pick<DialogProps, "onClose" | "show"> {
    /** Properties for the `InvestigationMap` component in the dialog */
    investigationMapProps?: Pick<InvestigationMapProps, "eyeSelection" | "hideAxialValues" | "hideRadialValues">;

    /** The projector's border */
    projectorBorder?: ProjectorBorderShape;

    /** Controller for isopteres */
    isopteresController: IsoptereModesControllerHandler;
}

/** `useManualKinetic` function properties */
export interface ManualKineticProps extends Pick<IsoptereModesControllerProps, "mode" | "scotomaLength" | "state">, Partial<Pick<IsoptereModesControllerProps, "onConfigureRequest" | "sendKineticConfigurePopupResponse">> {
    /** Use a simulated backend for testing instead of the actual service */
    mockBackend?: boolean;

    /** Properties for {@link CombineIsopterDialog} and {@link LinkIsoptereDialog} */
    dialogProps?: Pick<BaseIsopterDialogProps, "investigationMapProps">;

    /** The projector's border */
    projectorBorder?: ProjectorBorderShape;
}

interface ManualKineticReturnType {
    stackedIsoptereLegendProps: Omit<StackedIsoptereLegendProps, "width" | "height">;
    isoptereController: IsoptereModesControllerHandler;
    dialogs: ReactNode;
    isoptereModesProps: ReturnType<typeof useIsoptereModesController>[1] & Pick<IsoptereModesProps, "onDeleteIsopter" | "onDeleteVector" | "onCombineIsopter" | "onLinkIsopter" | "projectorBorder" | "onCommentIsopter" | "onCommentVector">
}

/** A hook that manages all logic related to manual kinetic measurements. */
export const useManualKinetic: (props: ManualKineticProps) => ManualKineticReturnType = ({
                                                                                             mode,
                                                                                             scotomaLength,
                                                                                             state,
                                                                                             mockBackend = false,
                                                                                             dialogProps,
                                                                                             projectorBorder,
                                                                                             onConfigureRequest,
                                                                                             sendKineticConfigurePopupResponse,
                                                                                         }) => {
    const mockIsoptereBackend = useMockIsoptereModesBackend({
        enabled: mockBackend,
        automatic: mode === "Automatic",
        projectorBorder,
    });
    const [linkIsopterIndex, setLinkIsopterIndex] = useState<null | number>(null);
    const [combineIsopterState, setCombineIsopterState] = useState<null | {
        preview: boolean,
        unselected: number[],
        filter: Pick<SimpleIsoptere, "form" | "steps" | "color">
    }>(null);

    const [isoptereController, isoptereModesProps] = useIsoptereModesController({
        mode: linkIsopterIndex === null && combineIsopterState === null ? mode : null,
        scotomaLength,
        onConfigureRequest: onConfigureRequest ?? (async () => {
            throw new Error("not available");
        }),
        sendKineticConfigurePopupResponse: sendKineticConfigurePopupResponse ?? (async () => {
            throw new Error("not available");
        }),
        ...mockBackend ? mockIsoptereBackend : {
            putManualKineticPosition, putManualKineticScotoma, putManualKineticDirection, putManualKineticIsopterCreate,
            putManualKineticIsopterCombine, putManualKineticVectorLink, putManualKineticVectorAdd,
            putManualKineticIsopterRemove, putManualKineticVectorRemove, getManualKineticIsopterList,
            putManualKineticIsopterComment, putManualKineticVectorComment, getManualKineticIsopterFinish,
        },
        state,
    });

    const combineIsopterSelectedIndices = combineIsopterState && isoptereController.isopteres
        .filter((isopter) => isEqualIsopterConfig(combineIsopterState.filter, isopter))
        .map(({isopterIndex}) => isopterIndex)
        .filter((isopterIndex) => !combineIsopterState.unselected.includes(isopterIndex));

    const handleCombineIsopterStart = (isopterIndex: number) => {
        const isopter = isoptereController.isopteres.find(({isopterIndex: oIsopterIndex}) => isopterIndex == oIsopterIndex);
        if (!isopter) {
            console.error(`Invalid isopterIndex: ${isopterIndex}`);
            return;
        }
        setCombineIsopterState({filter: isopter, unselected: [], preview: false});
    }
    const [hiddenIsopteres, setHiddenIsopteres] = useState<number[]>([])
    useEffect(() => {
        setHiddenIsopteres((hiddenIsopters) => !hiddenIsopteres.every((index) => isoptereModesProps.isopteres?.find(({isopterIndex}) => index === isopterIndex)) ? hiddenIsopters.filter((index) => isoptereModesProps.isopteres?.find(({isopterIndex}) => index === isopterIndex)) : hiddenIsopters)
    }, [isoptereModesProps.isopteres]);
    const stackedIsoptereLegendProps: ManualKineticReturnType["stackedIsoptereLegendProps"] = {
        legendData: isoptereController.isopteres
            .filter((isopter) => !combineIsopterState || isEqualIsopterConfig(isopter, combineIsopterState.filter))
            .map((_, i, arr) => arr[arr.length - 1 - i])
            .map((isopter) => ({
                visible: !hiddenIsopteres.includes(isopter.isopterIndex),
                isoptereStep: isopter.steps,
                disableVisibility: !isopter.connect,
                isoptereSymbolColor: isopter.color,
                isoptereSymbolForm: isopter.form,
                isIsopteresymbol: true,
                isopterIndex: isopter.isopterIndex,
                disableCombine: !isoptereController.editable || !isopter.canCombine,
                disableDelete: !isoptereController.editable,
                disableLink: !isoptereController.editable || !isopter.canLink,
            } satisfies IsoptereLegendData)),
        onDeleteItem: isoptereController.deleteIsoptere,
        onVisibilityItem: (isopterIndex) => setHiddenIsopteres((hiddenIsopters) => !hiddenIsopteres.includes(isopterIndex) ? [...hiddenIsopters, isopterIndex] : hiddenIsopters.filter((oIsopterIndex) => oIsopterIndex != isopterIndex)),
        onLinkItem: setLinkIsopterIndex,
        onCombineItem: handleCombineIsopterStart,
        onSelectionCancel: () => setCombineIsopterState(null),
        onSelectionFinish: () => setCombineIsopterState((prevState) => prevState && {...prevState, preview: true}),
        onSelectionChange: (isopterIndex, selected) => setCombineIsopterState((prevState) => prevState && {
            ...prevState,
            unselected: [...prevState.unselected.filter((oIsopterIndex) => oIsopterIndex !== isopterIndex), ...selected ? [] : [isopterIndex]]
        }),
        selection: combineIsopterSelectedIndices,
        disableSelectionFinish: (combineIsopterSelectedIndices?.length ?? 0) < 2,
    };
    return {
        isoptereModesProps: {
            ...isoptereModesProps,
            isopteres: isoptereModesProps.isopteres?.filter(({isopterIndex}) => !hiddenIsopteres.includes(isopterIndex)),
            onCombineIsopter: handleCombineIsopterStart,
            onLinkIsopter: setLinkIsopterIndex,
            onCommentIsopter: isoptereController.commentIsopter,
            onCommentVector: isoptereController.commentVector,
            onDeleteIsopter: isoptereController.deleteIsoptere,
            onDeleteVector: isoptereController.deleteVector,
            projectorBorder,
        },
        stackedIsoptereLegendProps,
        isoptereController,
        dialogs: <>
            <CombineIsopterDialog
                {...dialogProps}
                projectorBorder={projectorBorder}
                isopteresController={isoptereController}
                isopterIndices={combineIsopterSelectedIndices ?? undefined}
                show={!!combineIsopterState?.preview}
                onClose={() => setCombineIsopterState(null)}
            />
            <LinkIsoptereDialog
                {...dialogProps}
                projectorBorder={projectorBorder}
                isopteresController={isoptereController}
                isopterIndex={linkIsopterIndex ?? undefined}
                show={linkIsopterIndex !== null}
                onClose={() => setLinkIsopterIndex(null)}
            />
        </>,
    }
}