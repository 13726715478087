import {useEffect, useRef, useState} from "react";
import {
    addVector,
    getRandomEnumValue,
    getVectorLength,
    isInsideProjectorBorder,
    IsoptereMode,
    IsoptereModesProps,
    IsoptereSteps,
    IsoptereSymbolsColor,
    IsoptereSymbolsForm,
    isSamePosition,
    Line,
    multiplyVector,
    ProjectorBorderShape,
    rotateVector,
} from "@oculus/component-library";
import {ArrowStart} from "../../../backend/websocket/interfaces/messages/ArrowStart";
import {KineticVectorDone} from "../../../backend/websocket/interfaces/messages/KineticVectorDone";
import {PositionUpdate} from "../../../backend/websocket/interfaces/messages/PositionUpdate";
import {Arrow} from "../../../backend/api/interfaces/data/Arrow";
import {Position} from "../../../backend/api/interfaces/data/Position";
import {Isopter} from "../../../backend/api/interfaces/data/Isopter";
import {GoldmannBrightnessLetter} from "../../../backend/api/interfaces/custom-datatypes/GoldmannBrightnessLetter";
import {
    GoldmannBrightnessNumericValue
} from "../../../backend/api/interfaces/custom-datatypes/GoldmannBrightnessNumericValue";
import {StimulusSize} from "../../../backend/api/interfaces/custom-datatypes/StimulusSize";
import {
    getManualKineticIsopterFinish,
    getManualKineticIsopterList,
    putManualKineticDirection,
    putManualKineticIsopterCombine,
    putManualKineticIsopterComment,
    putManualKineticIsopterCreate,
    putManualKineticIsopterRemove,
    putManualKineticPosition,
    putManualKineticScotoma,
    putManualKineticVectorAdd,
    putManualKineticVectorComment,
    putManualKineticVectorLink,
    putManualKineticVectorRemove,
} from "../../../backend/api/Calls";
import {IsopterManualConfig} from "../../../backend/api/interfaces/data/IsopterManualConfig";
import {KineticPosition} from "../../../backend/api/interfaces/data/KineticPosition";
import {
    KineticConfigurePopupRequest
} from "../../../backend/websocket/interfaces/messages/KineticConfigurePopupRequest";
import {
    KineticConfigurePopupResponse
} from "../../../backend/websocket/interfaces/messages/KineticConfigurePopupResponse";
import {Message} from "../../../backend/websocket/interfaces/Message";
import {asyncSleep} from "../../../helper";
import {StimulusColor} from "../../../backend/api/interfaces/custom-datatypes/StimulusColor";
import {GoldmannBrightness} from "../../../backend/api/interfaces/data/GoldmannBrightness";
import {KineticIsopterDone} from "../../../backend/websocket/interfaces/messages/KineticIsopterDone";
import AsyncLock from "../../../utils/AsyncLock";
import {
    KineticVectorDoneAssistance,
} from "../../../backend/api/interfaces/custom-datatypes/KineticVectorDoneAssistance";
import {MessageType} from "../../../backend/websocket/interfaces/MessageType";
import {getMockserver} from "../../../backend/websocket/core/Websocket";

/** `useIsoptereModesController` function properties */
export interface IsoptereModesControllerProps {
    /** Current isoptere mode. */
    mode: IsoptereMode | "Edit" | "Automatic" | null;

    /** The length of the lines used to measure a scotoma */
    scotomaLength: number;

    /**
     * Callback function that is called when configuration is requested
     *
     * @param defaultConfig - The default configuration.
     * @returns A promise that resolves with the new configuration.
     */
    onConfigureRequest: (defaultConfig: SimpleIsopterConfig) => Promise<SimpleIsopterConfig>;

    /** Send {@link KineticConfigurePopupResponse} to backend */
    sendKineticConfigurePopupResponse: (message: KineticConfigurePopupResponse) => Promise<void>;

    /** @see putManualKineticPosition */
    putManualKineticPosition: typeof putManualKineticPosition;

    /** @see putManualKineticDirection */
    putManualKineticDirection: typeof putManualKineticDirection;

    /** @see putManualKineticScotoma */
    putManualKineticScotoma: typeof putManualKineticScotoma;

    /** @see putManualKineticIsopterCreate */
    putManualKineticIsopterCreate: typeof putManualKineticIsopterCreate;

    /** @see getManualKineticIsopterFinish */
    getManualKineticIsopterFinish: typeof getManualKineticIsopterFinish;

    /** @see putManualKineticIsopterCombine */
    putManualKineticIsopterCombine: typeof putManualKineticIsopterCombine;

    /** @see putManualKineticIsopterRemove */
    putManualKineticIsopterRemove: typeof putManualKineticIsopterRemove;

    /** @see putManualKineticIsopterComment */
    putManualKineticIsopterComment: typeof putManualKineticIsopterComment;

    /** @see putManualKineticVectorRemove */
    putManualKineticVectorRemove: typeof putManualKineticVectorRemove;

    /** @see putManualKineticVectorAdd */
    putManualKineticVectorAdd: typeof putManualKineticVectorAdd;

    /** @see putManualKineticVectorLink */
    putManualKineticVectorLink: typeof putManualKineticVectorLink;

    /** @see putManualKineticVectorComment */
    putManualKineticVectorComment: typeof putManualKineticVectorComment;

    /** @see getManualKineticIsopterList */
    getManualKineticIsopterList: typeof getManualKineticIsopterList;

    /** The lifted state. See {@link useIsoptereModesControllerState} */
    state: IsoptereModesControllerState;
}

/** Lifted state for {@link useIsoptereModesController} */
export interface IsoptereModesControllerState {
    /** Reset the state */
    reset: () => void;

    _state: State;
    _setState: (setter: (prevState: State) => State) => void;
}

/** Simplified KineticVectorDone interface for internal use */
type SimpleKineticVectorDone =
    Pick<KineticVectorDone, "position" | "direction" | "isopterIndex" | "goldmannBrightness" | "pressed">
    & {
    stimulusStatus: Pick<KineticVectorDone["stimulusStatus"], "size">,
};

/** Simplified Isopter interface for internal use */
export type SimpleIsoptere = Pick<NonNullable<IsoptereModesProps["isopteres"]>[0], "form" | "steps" | "color" | "isopterIndex" | "elements">

/** Simplified Isopter config interface for internal use */
export type SimpleIsopterConfig = Pick<SimpleIsoptere, "form" | "steps" | "color">;

/** Compare {@link SimpleIsopterConfig} objects */
export function isEqualIsopterConfig(a: SimpleIsopterConfig, b: SimpleIsopterConfig) {
    return (["form", "steps", "color"] satisfies (keyof SimpleIsopterConfig)[]).every((key) => a[key] === b[key]);
}

/** Handler for backend and application events */
export interface IsoptereModesControllerHandler {
    /** Backend sends PositionUpdate */
    positionUpdate: (message: Pick<PositionUpdate, "position">) => void;

    /** Backend sends ArrowStart */
    arrowStart: (message: Pick<ArrowStart, "arrow">) => void;

    /** Backend sends KineticVectorDone */
    kineticVectorDone: (message: SimpleKineticVectorDone) => void;

    /** Backend sends KineticIsopterDone */
    kineticIsopterDone: (message: Pick<KineticIsopterDone, "isopter">) => void;

    /** Backend sends KineticConfigurePopupRequest */
    kineticConfigurePopupRequest: (message: KineticConfigurePopupRequest) => void;

    /** The Isopteres managed by the controller */
    isopteres: (SimpleIsoptere & { canCombine: boolean, canLink: boolean, connect: boolean, comment:string })[];

    /** Handles the addition of a new Isoptere */
    createIsopter: (config: SimpleIsopterConfig) => Promise<void>;

    /** Handles addition a new comment for Isoptere */
    commentIsopter?: (isopterIndex: number, comment: string) => void;

    /** Handles addition a new comment for Vector */
    commentVector?: (isopterIndex: number, comment: string, position: Position) => void;

    /** Handles the removal of an Isoptere by its unique identifier */
    deleteIsoptere: (isopterIndex: number) => Promise<void>;

    deleteVector: (isopterIndex: number, position: Position) => Promise<void>;

    /** Handles the manual linking of an Isoptere */
    linkIsoptere: (isopterIndex: number, positions: Position[]) => Promise<Isopter>;

    /** Handles combining an Isoptere */
    combineIsopteres: (isopterIndices: number[]) => Promise<Isopter>;

    /** Preview the outcome of combining Isopteres */
    combineIsopteresPreview: (isopterIndices: number[]) => Promise<Isopter>;

    /** Start measurement line */
    start: () => void;

    /** Finish measurement */
    finish: () => Promise<void>;

    /** Indicates whether a measurement can be started. */
    canStart: boolean;

    /** Indicates whether a current running measurement can be paused. */
    canPause: boolean;

    /** Specifies if a measurement is currently running. */
    running: boolean;

    /** Indicates whether the measurement configuration can be edited (no measurement or backend requests in progress and mode is not `null`). */
    editable: boolean;

    /** Indicates whether requests can be handled (similar to `editable` but mode can be `null`) */
    canHandleRequests: boolean;
}

type PendingAction = (
    {
        type: IsoptereMode.SemiManual;
        direction: Arrow;
    } | {
    type: IsoptereMode.Scoptoperimetry;
    scotomaLength: number;
    position: Position;
    directions: Arrow[];
}
    );

interface State {
    pendingActions: PendingAction[];
    action: null | (
        PendingAction & {
        activeDirection?: Arrow | null;
        activePosition?: Position | null;
        pendingDirections?: Arrow[];
    } | {
        type: IsoptereMode.Manual;
        activeLine?: Line;
    } | {
        type: "Automatic";
        activeDirection?: Arrow | null;
        activePosition?: Position | null;
    });
    paused: boolean;
    isopteres: (Isopter & { canAddNewVectors?: boolean })[];
    failedDirections: Arrow[];
    projectorPosition: Position | undefined;
    backendRequestLock: AsyncLock;
    manualPositionSequence: number;
}

function getArrowError(a: Arrow, b: Arrow) {
    return Math.pow(getVectorLength(addVector(a.start, multiplyVector(b.start, -1))), 2)
        + Math.pow(getVectorLength(addVector(a.end, multiplyVector(b.end, -1))), 2);
}

/** Creates state for use with {@link useIsoptereModesController} */
export const useIsoptereModesControllerState = (): IsoptereModesControllerState => {
    const initialState: State = {
        pendingActions: [],
        action: null,
        failedDirections: [],
        projectorPosition: undefined,
        paused: false,
        isopteres: [],
        backendRequestLock: new AsyncLock(),
        manualPositionSequence: 0,
    };
    const [state, setState] = useState(initialState);
    return {
        _state: state,
        _setState: setState,
        reset: () => setState(initialState),
    };
}

const goldmanBrightnessLetterToIsoptereStepsMap: { [Key in GoldmannBrightnessLetter]: IsoptereSteps } = {
    [GoldmannBrightnessLetter.A]: IsoptereSteps.A,
    [GoldmannBrightnessLetter.B]: IsoptereSteps.B,
    [GoldmannBrightnessLetter.C]: IsoptereSteps.C,
    [GoldmannBrightnessLetter.D]: IsoptereSteps.D,
    [GoldmannBrightnessLetter.E]: IsoptereSteps.E,
};
const isoptereStepsToGoldmanBrightnessLetterMap = Object.fromEntries(Object.entries(goldmanBrightnessLetterToIsoptereStepsMap)
    .map(([key, value]) => [value, key])) as { [Key in IsoptereSteps]: GoldmannBrightnessLetter };

const goldmannBrightnessNumericValueToIsoptereSymbolsFormMap: { [Key in GoldmannBrightnessNumericValue]: IsoptereSymbolsForm } = {
    [GoldmannBrightnessNumericValue.One]: IsoptereSymbolsForm.Circle,
    [GoldmannBrightnessNumericValue.Two]: IsoptereSymbolsForm.Hexagon,
    [GoldmannBrightnessNumericValue.Three]: IsoptereSymbolsForm.Square,
    [GoldmannBrightnessNumericValue.Four]: IsoptereSymbolsForm.Triangle,
};
const isoptereSymbolsFormToGoldmannBrightnessNumericValueMap = Object.fromEntries(Object.entries(goldmannBrightnessNumericValueToIsoptereSymbolsFormMap)
    .map(([key, value]) => [value, key])) as { [Key in IsoptereSymbolsForm]: GoldmannBrightnessNumericValue };

const stimulusSizeToIsoptereSymbolsColorMap: { [Key in StimulusSize]: IsoptereSymbolsColor } = {
    [StimulusSize.One]: IsoptereSymbolsColor.Orange,
    [StimulusSize.Two]: IsoptereSymbolsColor.Magenta,
    [StimulusSize.Three]: IsoptereSymbolsColor.DarkBlue,
    [StimulusSize.Four]: IsoptereSymbolsColor.Violet,
    [StimulusSize.Five]: IsoptereSymbolsColor.Red,
};
const isoptereSymbolsColorToStimulusSizeMap = Object.fromEntries(Object.entries(stimulusSizeToIsoptereSymbolsColorMap)
    .map(([key, value]) => [value, key])) as { [Key in IsoptereSymbolsColor]: StimulusSize };

export function convertToSimpleIsopter(isopter: Isopter): SimpleIsoptere {
    return {
        elements: isopter.kineticPositions,
        ...convertIsopterConfigToSimpleIsopterConfig({
            brightness: isopter.goldmannBrightness,
            stimulusSize: isopter.stimulusSize,
        }),
        isopterIndex: isopter.isopterRef.index,
    };
}

export function convertIsopterConfigToSimpleIsopterConfig({stimulusSize, brightness}: {
    stimulusSize: StimulusSize,
    brightness: GoldmannBrightness,
}): SimpleIsopterConfig {
    return {
        form: goldmannBrightnessNumericValueToIsoptereSymbolsFormMap[brightness.numericValue],
        color: stimulusSizeToIsoptereSymbolsColorMap[stimulusSize],
        steps: goldmanBrightnessLetterToIsoptereStepsMap[brightness.letter],
    };
}

export function convertSimpleIsopterConfigToIsopterConfig({form, color, steps}: SimpleIsopterConfig): {
    stimulusSize: StimulusSize,
    brightness: GoldmannBrightness,
} {
    return {
        brightness: {
            numericValue: isoptereSymbolsFormToGoldmannBrightnessNumericValueMap[form],
            letter: isoptereStepsToGoldmanBrightnessLetterMap[steps],
        },
        stimulusSize: isoptereSymbolsColorToStimulusSizeMap[color],
    };
}

/** Helper for connecting the `IsoptereModes` component with the backend. */
export const useIsoptereModesController: (props: IsoptereModesControllerProps) => [
        IsoptereModesControllerHandler,
        {
            [Key in keyof Pick<IsoptereModesProps,
            "mode" | "projectorPosition" | "onManualPosition" | "onManualLineStart" | "onManualLinePoint" | "onManualLineEnd" | "lines"
            | "activeLine" | "onSemiManualLine" | "onScotoma" | "isopteres" | "scotomaLength" | "onSemiManualLineDelete" | "failedLines"
        >]: Exclude<IsoptereModesProps[Key], undefined>
        },
    ] = ({
             mode,
             scotomaLength,
             onConfigureRequest,
             sendKineticConfigurePopupResponse,
             putManualKineticPosition,
             putManualKineticDirection,
             putManualKineticScotoma,
             putManualKineticIsopterCreate,
             getManualKineticIsopterFinish,
             putManualKineticIsopterRemove,
             putManualKineticVectorAdd,
             putManualKineticVectorLink,
             putManualKineticVectorRemove,
             putManualKineticIsopterCombine,
             putManualKineticIsopterComment,
             putManualKineticVectorComment,
             getManualKineticIsopterList,
             state: {_state: state, _setState},
         }) => {
        const updateState: (update: (state: State) => State) => void = (update) => {
            let newState: { prevState: State, state: State } | null = null;
            // The update function is not pure as it involves backend requests
            _setState((prevState) => {
                if (!newState || !Object.is(prevState, newState.prevState)) {
                    newState = {prevState, state: update(prevState)};
                }
                return newState.state;
            });
        };
        useEffect(() => {
            void finishMode();
        }, [mode]);

        function finishIsopter(): Promise<void> {
            return new Promise<void>((resolve, reject) => {
                updateState((state) => {
                    if (mode === "Automatic" || mode === "Scoptoperimetry" || state.isopteres.every(
                        ({
                             canAddNewVectors,
                             kineticPositions,
                         }) => !canAddNewVectors || kineticPositions.length === 0)) {
                        resolve();
                        return {
                            ...state,
                            failedDirections: [],
                        };
                    }
                    state.backendRequestLock.acquire().then(async () => {
                        const {data: isopter} = await getManualKineticIsopterFinish();
                        if (!isopter) {
                            return;
                        }
                        updateState((state) => {
                            const oldIsopterIndex = state.isopteres.findIndex(({isopterRef: {index}}) => index === isopter.isopterRef.index);
                            if (oldIsopterIndex < 0) {
                                console.error(`Invalid isopterIndex ${isopter.isopterRef.index}`);
                                return state;
                            }
                            return {
                                ...state,
                                isopteres: state.isopteres.toSpliced(oldIsopterIndex, 1, isopter),
                            };
                        });
                    }).finally(() => state.backendRequestLock.release()).then(resolve, reject);
                    return {
                        ...state,
                        failedDirections: [],
                        isopteres: state.isopteres.map((isoptere) => ({
                            ...isoptere,
                            canAddNewVectors: isoptere.canAddNewVectors && isoptere.kineticPositions.length === 0,
                        })),
                    };
                });
            });
        }

        async function finishMode(): Promise<void> {
            updateState((state) => ({
                ...state,
                action: null,
                pendingActions: [],
                paused: false,
            }));
            await finishIsopter();
        }

        const getPendingDirections = (action: typeof state["action"]): Arrow[] => {
            switch (action?.type) {
                case IsoptereMode.SemiManual:
                    return action.pendingDirections ?? [action.direction];
                case IsoptereMode.Scoptoperimetry:
                    return action.pendingDirections ?? action.directions;
                default:
                    void (action satisfies null | { type: IsoptereMode.Manual } | { type: "Automatic" })
                    return [];
            }
        };

        function advanceAction(state: State): State {
            switch (state.action?.type) {
                case IsoptereMode.SemiManual:
                case IsoptereMode.Scoptoperimetry:
                    if (getPendingDirections(state.action).length > 0) {
                        return {...state, action: {...state.action, activeDirection: null}};
                    }
                // fallthrough!
                case IsoptereMode.Manual:
                    state = {...state, action: null};
                    break;
                default:
                    void (state.action satisfies null | { type: "Automatic" });
            }
            if (state.paused) {
                return {...state, paused: state.pendingActions.length > 0, action: null};
            }
            const nextAction = state.pendingActions[0] ?? null;
            if (nextAction) {
                switch (nextAction.type) {
                    case IsoptereMode.SemiManual:
                        void (putManualKineticDirection(nextAction.direction));
                        break;
                    case IsoptereMode.Scoptoperimetry:
                        void (putManualKineticScotoma({
                            position: nextAction.position,
                            maxRadius: nextAction.scotomaLength
                        }));
                        break;
                    default:
                        void (nextAction satisfies never);
                }
            }
            return {...state, action: nextAction, pendingActions: state.pendingActions.slice(1)};
        }

        const handleManualPosition: IsoptereModesProps["onManualPosition"] = (position) => {
            updateState((state) => {
                state.backendRequestLock.acquire().then(async () => {
                    await putManualKineticPosition({position, activeProjector: false});
                }).finally(() => state.backendRequestLock.release());
                return ({...state, projectorPosition: position});
            });
        };

        const handleManualLineStart: IsoptereModesProps["onManualLineStart"] = (position) => {
            updateState(state => {
                if (!state.isopteres.at(-1)?.canAddNewVectors) {
                    return state;
                }
                state.backendRequestLock.acquire().then(async () => {
                    await putManualKineticPosition({position, activeProjector: false});
                }).finally(() => state.backendRequestLock.release());
                return {...state, action: {type: IsoptereMode.Manual}, projectorPosition: position};
            });
        };

        const handleManualLinePoint: IsoptereModesProps["onManualLinePoint"] = (position) => {
            updateState((state) => {
                const mySequence = state.manualPositionSequence + 1;
                state.backendRequestLock.acquire().then(async () => {
                    const sequence = await new Promise((resolve) => {
                        updateState((state) => {
                            resolve(state.manualPositionSequence);
                            return state;
                        });
                    });
                    if (sequence !== mySequence) {
                        return;
                    }
                    await putManualKineticPosition({position, activeProjector: true});
                }).finally(() => state.backendRequestLock.release());
                return {
                    ...state,
                    manualPositionSequence: mySequence,
                };
            });
        };

        const handleManualLineEnd: IsoptereModesProps["onManualLineEnd"] = () => {
            updateState((state) => {
                state.backendRequestLock.acquire().then(async () => {
                    await (putManualKineticPosition({
                        position: state.projectorPosition ?? {x: 0, y: 0},
                        activeProjector: false,
                    }));
                }).finally(() => state.backendRequestLock.release());
                return advanceAction(state);
            });
        };

        const handleSemiManualLine: IsoptereModesProps["onSemiManualLine"] = (line) => {
            updateState((state) => ({
                ...state,
                pendingActions: [...state.pendingActions,
                    {
                        type: IsoptereMode.SemiManual,
                        direction: {
                            start: line[0],
                            end: line[line.length - 1]
                        },
                    }],
            }));
        };

        const handleScotoma: IsoptereModesProps["onScotoma"] = (lines, position) => {
            updateState((state) => ({
                ...state,
                pendingActions: [
                    {
                        type: IsoptereMode.Scoptoperimetry,
                        scotomaLength,
                        position,
                        directions: lines.map((line) => ({start: line[0], end: line[line.length - 1]})),
                    }],
            }));
        };

        const handleSemiManualLineDelete: IsoptereModesProps["onSemiManualLineDelete"] = (line) => {
            updateState((state) => ({
                ...state,
                pendingActions: state.pendingActions.filter((action) => action.type !== IsoptereMode.SemiManual || action.direction.start !== line[0] || action.direction.end !== line[line.length - 1]),
            }))
        }

        const handleDeleteIsopter = (isopterIndex: number): Promise<void> => {
            return new Promise((resolve, reject) => {
                updateState((state) => {
                    const oldIsopterIndex = state.isopteres.findIndex(({isopterRef: {index}}) => index === isopterIndex);
                    const oldIsopter = oldIsopterIndex >= 0 ? state.isopteres[oldIsopterIndex] : null;
                    if (!oldIsopter) {
                        reject(`Invalid isopterRef: ${isopterIndex}`);
                        return state;
                    }
                    state.backendRequestLock.acquire().then(async () => {
                        await putManualKineticIsopterRemove({index: isopterIndex});
                    }).finally(() => state.backendRequestLock.release()).then(resolve, reject);
                    return {
                        ...state,
                        isopteres: state.isopteres.toSpliced(oldIsopterIndex, 1),
                    };
                });
            });
        };

        const handleCommentIsopter = (isopterIndex: number, comment: string): Promise<void> => {
            return new Promise((resolve, reject) => {
                const oldIsopterIndex = state.isopteres.findIndex((isopter) => isopter.isopterRef.index === isopterIndex);
                const oldIsopter = oldIsopterIndex >= 0 ? state.isopteres[oldIsopterIndex] : null;
                if (!oldIsopter) {
                    reject(`Invalid isopterRef ${isopterIndex}`);
                    return state;
                }
                updateState((state) => {
                    state.backendRequestLock.acquire().then(async () => {
                        await putManualKineticIsopterComment({...state.isopteres[isopterIndex], comment: comment});
                    }).finally(() => state.backendRequestLock.release()).then(resolve, reject);
                    return {
                        ...state,
                        isopteres: state.isopteres.map((isoptere, index) =>
                            index === isopterIndex
                                ? {...isoptere, comment: comment}
                                : isoptere
                        ),
                    };
                });
            });
        };

        const handleCommentVector = (isopterIndex: number, comment: string, position: Position): Promise<void> => {
            return new Promise((resolve, reject) => {
                const oldIsopterIndex = state.isopteres.findIndex((isopter) => isopter.isopterRef.index === isopterIndex);
                const oldIsopter = oldIsopterIndex >= 0 ? state.isopteres[oldIsopterIndex] : null;
                if (!oldIsopter) {
                    reject(`Invalid isopterRef ${isopterIndex}`);
                    return state;
                }
                const oldKineticPositionIndex = oldIsopter.kineticPositions
                    .findIndex(({position: oPosition}) => isSamePosition(oPosition, position));
                if (oldKineticPositionIndex < 0) {
                    reject(`Invalid kineticPosition: (${position.x},${position.y})`);
                    return state;
                }
                updateState((state) => {
                    state.backendRequestLock.acquire().then(async () => {
                        await putManualKineticVectorComment({isopterRef: state.isopteres[isopterIndex].isopterRef, kineticPosition: {...state.isopteres[isopterIndex].kineticPositions[oldKineticPositionIndex], comment: comment},});
                    }).finally(() => state.backendRequestLock.release()).then(resolve, reject);
                    return {
                        ...state,
                        isopteres: state.isopteres.map((isoptere, index) =>
                            index === isopterIndex
                                ? {
                                    ...isoptere,
                                    kineticPositions: isoptere.kineticPositions.map((kineticPosition, kineticIndex) =>
                                        kineticIndex === oldKineticPositionIndex
                                            ? {...kineticPosition, comment: comment}
                                            : kineticPosition
                                    ),
                                }
                                : isoptere
                        ),
                    };
                });
            });
        };

        const handleCombineIsopteres = (isopterIndices: number[], isPreview: boolean = false): Promise<Isopter> => {
            return new Promise((resolve, reject) => {
                updateState((state) => {
                    const oldIsopterIndices: number[] = [];
                    for (const isopterIndex of isopterIndices) {
                        const oldIsopterIndex = state.isopteres.findIndex((isopter) => isopter.isopterRef.index === isopterIndex);
                        if (oldIsopterIndex < 0) {
                            reject(`Invalid isopterRef ${isopterIndex}`);
                            return state;
                        }
                        oldIsopterIndices.push(oldIsopterIndex);
                    }
                    state.backendRequestLock.acquire().then(async () => {
                        const {data: newIsopter} = await putManualKineticIsopterCombine({
                            isopterRefs: isopterIndices.map((index) => ({index})),
                            isPreview,
                        });
                        if (!isPreview) {
                            updateState((state) => ({
                                ...state,
                                isopteres: state.isopteres.filter((isopter) => isopter.isopterRef.index !== newIsopter.isopterRef.index).toSpliced(Math.min(0, ...oldIsopterIndices), 0, newIsopter),
                            }));
                        }
                        return newIsopter;
                    }).finally(() => state.backendRequestLock.release()).then(resolve, reject);
                    if (!isPreview) {
                        state = {
                            ...state,
                            isopteres: state.isopteres.filter(({isopterRef: {index}}) => isopterIndices.every((istopterIndex) => index !== istopterIndex)),
                        };
                    }
                    return state;
                });
            });
        };

        const handleLinkIsoptere = (isopterIndex: number, positions: Line): Promise<Isopter> => {
            return new Promise((resolve, reject) => {
                updateState((state) => {
                    const oldIsopterIndex = state.isopteres.findIndex((isopter) => isopter.isopterRef.index === isopterIndex);
                    const oldIsopter = oldIsopterIndex >= 0 ? state.isopteres[oldIsopterIndex] : null;
                    if (!oldIsopter) {
                        reject(`Invalid isopterRef ${isopterIndex}`);
                        return state;
                    }
                    const kineticPositions: KineticPosition[] = [];
                    for (const position of positions) {
                        const direction = oldIsopter.kineticPositions.find(({position: oPosition}) => isSamePosition(position, oPosition))?.direction;
                        if (!direction) {
                            reject(`Position (${position.x},${position.y}) not found in isopter ${isopterIndex}`);
                            return state;
                        }
                        kineticPositions.push({position, direction, comment: ""});
                    }
                    state.backendRequestLock.acquire().then(async () => {
                        const {data: newIsopter} = await putManualKineticVectorLink({...oldIsopter, kineticPositions});
                        updateState((state) => ({
                            ...state,
                            isopteres: state.isopteres.filter((isopter) => isopter.isopterRef.index !== newIsopter.isopterRef.index).toSpliced(oldIsopterIndex, 0, newIsopter),
                        }));
                        return newIsopter;
                    }).finally(() => state.backendRequestLock.release()).then(resolve, reject);
                    return {
                        ...state,
                        isopteres: state.isopteres.toSpliced(oldIsopterIndex, 1),
                    };
                });
            })
        };

        const handleDeleteVector = (isopterIndex: number, position: Position): Promise<void> => {
            return new Promise((resolve, reject) => {
                updateState((state) => {
                    const oldIsopterIndex = state.isopteres.findIndex(({isopterRef: {index}}) => index === isopterIndex);
                    const oldIsopter = oldIsopterIndex >= 0 ? state.isopteres[oldIsopterIndex] : null;
                    if (!oldIsopter) {
                        reject(`Invalid isopterRef ${isopterIndex}`);
                        return state;
                    }
                    const oldKineticPositionIndex = oldIsopter.kineticPositions
                        .findIndex(({position: oPosition}) => isSamePosition(oPosition, position));
                    if (oldKineticPositionIndex < 0) {
                        reject(`Invalid kineticPosition: (${position.x},${position.y})`);
                        return state;
                    }
                    state.backendRequestLock.acquire().then(async () => {
                        const {data: newIsopter} = await putManualKineticVectorRemove({
                            isopterRef: {index: isopterIndex},
                            kineticPosition: oldIsopter.kineticPositions[oldKineticPositionIndex]
                        });
                        updateState((state) => ({
                            ...state,
                            isopteres: state.isopteres.filter((isopter) => isopter.isopterRef.index !== newIsopter.isopterRef.index).toSpliced(oldIsopterIndex, 0, newIsopter),
                        }));
                    }).finally(() => state.backendRequestLock.release()).then(resolve, reject);
                    return {
                        ...state,
                        isopteres: state.isopteres.toSpliced(oldIsopterIndex, 1),
                    };
                });
            });
        };

        const lines: Line[] = [
            ...state.pendingActions.flatMap((action): Arrow[] => {
                switch (action.type) {
                    case IsoptereMode.SemiManual:
                        return [action.direction];
                    case IsoptereMode.Scoptoperimetry:
                        return action.directions;
                    default:
                        void (action satisfies never);
                        return [];
                }
            }),
            ...getPendingDirections(state.action),
        ].map(({start, end}) => [start, end]);

        const activeLine: Exclude<IsoptereModesProps["activeLine"], undefined> = (() => {
            if (!state.action) {
                return null;
            }
            switch (state.action.type) {
                case IsoptereMode.SemiManual:
                case IsoptereMode.Scoptoperimetry:
                case "Automatic":
                    if (state.action.activeDirection) {
                        if (state.action.activePosition) {
                            return {
                                finishedSegment: [state.action.activeDirection.start, state.action.activePosition],
                                unfinishedSegment: [state.action.activePosition, state.action.activeDirection.end],
                            };
                        }
                        return {
                            unfinishedSegment: [state.action.activeDirection.start, state.action.activeDirection.end],
                        };
                    }
                    return {};
                case IsoptereMode.Manual:
                    return {finishedSegment: state.action.activeLine};
                default:
                    void (state.action satisfies never);
                    return null;
            }
        })();

        const failedLines: NonNullable<IsoptereModesProps["failedLines"]> = state.failedDirections
            .map(({start, end}) => [start, end]);

        const isopteres: IsoptereModesControllerHandler["isopteres"] & NonNullable<IsoptereModesProps["isopteres"]> = state.isopteres
            .map((isopter) => ({
                ...convertToSimpleIsopter(isopter),
                canLink: isopter.kineticPositions.length >= 2,
                connect: !isopter.canAddNewVectors,
                comment: isopter.comment,
            }))
            .map((isopter, _, isopteres) => ({
                ...isopter,
                canCombine: isopteres.some((oIsopter) => isopter.isopterIndex !== oIsopter.isopterIndex && isEqualIsopterConfig(isopter, oIsopter)),
            }));
        state.isopteres.map((isopter) => ({
            ...isopter,
            kineticPositions: [...isopter.kineticPositions].sort((a, b) => (a.vectorIndex ?? -Infinity) - (b.vectorIndex ?? -Infinity))
        }));
        const getEffectiveMode = (mode: IsoptereMode | "Edit" | "Automatic" | null) => {
            if (Object.values(IsoptereMode).includes(mode as IsoptereMode)) {
                const canAddVectors = state.isopteres.some(isopter => isopter.canAddNewVectors);
                return canAddVectors ? mode : "Edit";
            }
            return mode;
        };


        return [
            {
                positionUpdate: ({position}) => {
                    updateState((state) => {
                        state = {...state, projectorPosition: position};
                        switch (state.action?.type) {
                            case IsoptereMode.Manual:
                                return {
                                    ...state,
                                    action: {
                                        ...state.action,
                                        activeLine: [...state.action.activeLine ?? [], position],
                                    },
                                };
                            case IsoptereMode.SemiManual:
                            case IsoptereMode.Scoptoperimetry:
                            case "Automatic":
                                return {...state, action: {...state.action, activePosition: position}};
                            default:
                                void (state.action satisfies null);
                                return state;
                        }
                    });
                },
                kineticVectorDone: (message) => {
                    updateState((state) => {
                        const newKineticPosition = {
                            position: message.position,
                            direction: message.direction,
                            comment: "",
                        };
                        const oldIsopterIndex = state.isopteres.findIndex(({isopterRef: {index}}) => index === message.isopterIndex);
                        const oldIsopter = oldIsopterIndex >= 0 ? state.isopteres[oldIsopterIndex] : null;
                        if (mode === "Automatic" || mode === "Scoptoperimetry") {
                            if (!oldIsopter) {
                                state = {
                                    ...state,
                                    isopteres: [...state.isopteres.map(({canAddNewVectors, ...isopter}) => isopter), {
                                        comment: "",
                                        isopterRef: {index: message.isopterIndex},
                                        canAddNewVectors: true,
                                        kineticPositions: message.pressed ? [newKineticPosition] : [],
                                        stimulusSize: message.stimulusStatus.size,
                                        goldmannBrightness: message.goldmannBrightness,
                                    }],
                                };
                            } else {
                                if (message.pressed) {
                                    state = {
                                        ...state,
                                        isopteres: state.isopteres.toSpliced(oldIsopterIndex, 1, {
                                            ...oldIsopter,
                                            kineticPositions: [...oldIsopter.kineticPositions, newKineticPosition],
                                        }),
                                    };
                                }
                            }
                        } else {
                            if (!oldIsopter) {
                                console.error(`Invalid isopterIndex ${message.isopterIndex}`);
                                return state;
                            }
                            if (message.pressed) {
                                putManualKineticVectorAdd({
                                    isopterRef: oldIsopter.isopterRef,
                                    kineticPosition: newKineticPosition,
                                }).then(({data: newIsopter}) => {
                                    updateState((state) => ({
                                        ...state,
                                        isopteres: state.isopteres.filter((isopter) => isopter.isopterRef.index !== newIsopter.isopterRef.index).toSpliced(oldIsopterIndex, 0, {
                                            ...newIsopter,
                                            canAddNewVectors: oldIsopter.canAddNewVectors,
                                        }),
                                    }));
                                });
                            }
                        }
                        if (state.action) {
                            if (!message.pressed && state.action.type !== IsoptereMode.Manual && state.action.activeDirection) {
                                state = {
                                    ...state,
                                    failedDirections: [...state.failedDirections, state.action.activeDirection],
                                };
                            }
                            state = advanceAction(state);
                        }
                        return state;
                    });
                },
                kineticIsopterDone: ({isopter}) => {
                    updateState((state) => {
                        const oldIsopterIndex = state.isopteres.findIndex(({isopterRef: {index}}) => index === isopter.isopterRef.index);
                        if (oldIsopterIndex < 0) {
                            console.error(`Invalid isopterIndex ${isopter.isopterRef.index}`);
                            return state;
                        }
                        return {
                            ...state,
                            isopteres: state.isopteres.toSpliced(oldIsopterIndex, 1, isopter),
                        };
                    });
                },
                arrowStart: ({arrow}) => {
                    updateState((state) => {
                        if (mode === "Automatic" && !state.action) {
                            state = {
                                ...state,
                                action: {type: "Automatic"},
                            };
                        }
                        if (state.action?.type !== IsoptereMode.SemiManual && state.action?.type !== IsoptereMode.Scoptoperimetry && state.action?.type !== "Automatic") {
                            console.error("Unexpected ArrowStart received")
                            return state;
                        }
                        if (state.action.type === "Automatic") {
                            return {
                                ...state,
                                action: {
                                    ...state.action,
                                    activeDirection: arrow,
                                    activePosition: null,
                                }
                            }
                        }
                        const pendingDirections = getPendingDirections(state.action);
                        if (pendingDirections.length === 0) {
                            console.error("Unexpected ArrowStart received")
                            return state;
                        }
                        const nextDirection = pendingDirections.reduce((acc, direction) => getArrowError(direction, arrow) < getArrowError(acc, arrow) ? direction : acc);
                        return {
                            ...state,
                            action: {
                                ...state.action,
                                pendingDirections: pendingDirections.filter((direction) => direction !== nextDirection),
                                activeDirection: nextDirection,
                                activePosition: null,
                            }
                        }
                    });
                },
                kineticConfigurePopupRequest: async (message) => {
                    await finishIsopter();
                    await state.backendRequestLock.acquire();
                    try {
                        const props = await onConfigureRequest(convertIsopterConfigToSimpleIsopterConfig(message));
                        await sendKineticConfigurePopupResponse({
                            ...message,
                            type: MessageType.KineticConfigurePopupResponse,
                            ...convertSimpleIsopterConfigToIsopterConfig(props),
                        });
                        const {data: isopteres} = await (async () => {
                            let backoffMs = 100;
                            while (true) {
                                await asyncSleep(backoffMs);
                                backoffMs = Math.min(backoffMs * 2, 30_000);
                                try {
                                    return (await getManualKineticIsopterList());
                                } catch (error) {
                                    console.error(error);
                                }
                            }
                        })();
                        updateState((state) => ({
                            ...state,
                            isopteres: isopteres.map((isoptere, i) => ({
                                ...isoptere,
                                canAddNewVectors: i === isopteres.length - 1 && isoptere.kineticPositions.length === 0,
                            }))
                        }));
                    } finally {
                        await state.backendRequestLock.release();
                    }
                },
                createIsopter: async (props) => {
                    await finishIsopter();
                    updateState((state) => ({
                        ...state,
                        isopteres: state.isopteres.map(({canAddNewVectors, ...isopter}) => isopter),
                    }));
                    await state.backendRequestLock.acquire();
                    try {
                        const {data: newIsopter} = await putManualKineticIsopterCreate({
                            goldmannBrightness: {
                                numericValue: isoptereSymbolsFormToGoldmannBrightnessNumericValueMap[props.form],
                                letter: isoptereStepsToGoldmanBrightnessLetterMap[props.steps],
                            },
                            stimulusSize: isoptereSymbolsColorToStimulusSizeMap[props.color],
                        });
                        updateState((state) => ({
                            ...state,
                            isopteres: [...state.isopteres, {
                                ...newIsopter,
                                canAddNewVectors: newIsopter.kineticPositions.length === 0
                            }]
                        }));
                    } finally {
                        state.backendRequestLock.release();
                    }
                },
                deleteVector: handleDeleteVector,
                deleteIsoptere: handleDeleteIsopter,
                commentIsopter: handleCommentIsopter,
                commentVector: handleCommentVector,
                linkIsoptere: handleLinkIsoptere,
                combineIsopteres: (isopterIndices) => handleCombineIsopteres(isopterIndices),
                combineIsopteresPreview: (isopterIndices) => handleCombineIsopteres(isopterIndices, true),
                isopteres,
                start: () => updateState((state) => advanceAction({...state, paused: false})),
                finish: finishMode,
                canHandleRequests: state.action === null && !state.backendRequestLock.isLocked(),
                editable: mode !== null && state.action === null && !state.paused && !state.backendRequestLock.isLocked(),
                running: mode !== null && state.action !== null,
                canStart: mode !== null && state.action === null && state.pendingActions.length > 0 && !!state.isopteres.at(-1)?.canAddNewVectors && !state.backendRequestLock.isLocked(),
                canPause: mode !== null && state.action !== null && !state.paused && !state.backendRequestLock.isLocked(),
            },
            {
                mode: state.paused || state.backendRequestLock.isLocked() ? null : getEffectiveMode(mode),
                scotomaLength,
                projectorPosition: state.projectorPosition,
                onManualPosition: handleManualPosition,
                onManualLineStart: handleManualLineStart,
                onManualLinePoint: handleManualLinePoint,
                onManualLineEnd: handleManualLineEnd,
                onSemiManualLineDelete: handleSemiManualLineDelete,
                lines,
                failedLines,
                activeLine,
                onSemiManualLine: handleSemiManualLine,
                onScotoma: handleScotoma,
                isopteres,
            },
        ];
    }
;

/**
 * A mock backend for use with the {@link useIsoptereModesController}.
 * Use this for development, debugging, or testing interactions with the Isoptere modes controller.
 *
 * @property enabled - A toggle for enabling or disabling the mock backend.
 * @property automatic - When enabled, the automatic kinetic mode generates random Isopters and ArrowStart events.
 * @property projectorBorder - The border for random automatic lines.
 */
export const useMockIsoptereModesBackend: (props: {
    enabled?: boolean,
    automatic?: boolean,
    projectorBorder?: ProjectorBorderShape,
}) => Pick<IsoptereModesControllerProps,
    "putManualKineticIsopterCreate" | "getManualKineticIsopterFinish" | "putManualKineticScotoma" | "putManualKineticPosition" | "putManualKineticDirection"
    | "putManualKineticIsopterCombine" | "putManualKineticVectorLink" | "putManualKineticVectorAdd" | "putManualKineticIsopterRemove"
    | "putManualKineticVectorRemove" | "getManualKineticIsopterList" | "putManualKineticIsopterComment" | "putManualKineticVectorComment"
> = ({enabled = true, automatic = false, ...props}) => {
    const SPEED = 2; // Degrees per Update
    const UPDATE_INTERVAL = 50; // Milliseconds

    type Task = { position: Position, type: "move" | "direction" | "position", autoAddKineticPosition?: boolean }
        | { type: "isopter-done" };

    const propertiesRef = useRef({...props});
    useEffect(() => {
        propertiesRef.current = {...props};
    }, [props]);

    const stateRef = useRef<{
        taskQueue: Task[],
        activeTask: Task | null,
        isopters: Isopter[],
        nextIsopterIndex: number,
        initialized: boolean,
    }>({taskQueue: [], isopters: [], nextIsopterIndex: 0, initialized: false, activeTask: null});

    useEffect(() => {
        if (!enabled) {
            return;
        }
        let position: Position = {x: 0, y: 0};

        const update = () => {
            if (stateRef.current.taskQueue.length > 0 && stateRef.current.activeTask?.type === "position") {
                stateRef.current.activeTask = null;
            }
            if (automatic && propertiesRef.current.projectorBorder && !stateRef.current.activeTask && stateRef.current.taskQueue.length === 0) {
                const {projectorBorder} = propertiesRef.current;
                const randomPosition = (): Position => {
                    const {xMaxEccentricity, yMaxEccentricity} = projectorBorder;
                    while (true) {
                        const position = {
                            x: (Math.random() * 2 - 1) * xMaxEccentricity,
                            y: (Math.random() * 2 - 1) * yMaxEccentricity,
                        };
                        if (isInsideProjectorBorder(projectorBorder, position)) {
                            return position;
                        }
                    }
                }
                stateRef.current.taskQueue.push({
                    type: "move",
                    position: randomPosition(),
                    autoAddKineticPosition: true,
                });
                stateRef.current.taskQueue.push({
                    type: "direction",
                    position: randomPosition(),
                    autoAddKineticPosition: true,
                });
                if (stateRef.current.isopters.length === 0 || Math.random() < 0.1) {
                    const lastIsopter = stateRef.current.isopters.at(-1) ?? null;
                    if (lastIsopter) {
                        getMockserver()?.send({
                            type: MessageType.KineticIsopterDone,
                            isopter: lastIsopter,
                        });
                    }
                    stateRef.current.isopters.push({
                        isopterRef: {
                            index: stateRef.current.nextIsopterIndex++,
                        },
                        kineticPositions: [],
                        stimulusSize: getRandomEnumValue(StimulusSize),
                        goldmannBrightness: {
                            numericValue: getRandomEnumValue(GoldmannBrightnessNumericValue),
                            letter: getRandomEnumValue(GoldmannBrightnessLetter),
                        },
                        comment: "",
                    });
                }
            }
            for (let remainder = SPEED; ;) {
                if (!stateRef.current.activeTask && stateRef.current.taskQueue.length > 0) {
                    stateRef.current.activeTask = stateRef.current.taskQueue.shift()!;
                    if (stateRef.current.activeTask.type === "direction") {
                        getMockserver()?.send({
                            type: MessageType.ArrowStart,
                            arrow: {
                                start: position,
                                end: stateRef.current.activeTask.position,
                            }
                        });
                    }
                } else if (!stateRef.current.activeTask) {
                    break;
                }
                if (stateRef.current.activeTask.type === "isopter-done") {
                    const activeIsopter = stateRef.current.isopters.at(-1) ?? null;
                    if (activeIsopter) {
                        getMockserver()?.send({
                            type: MessageType.KineticIsopterDone,
                            isopter: activeIsopter,
                        });
                    }
                    stateRef.current.activeTask = null;
                    continue;
                }
                const vec = {
                    x: stateRef.current.activeTask.position.x - position.x,
                    y: stateRef.current.activeTask.position.y - position.y
                };
                const vecLen = Math.sqrt(vec.x * vec.x + vec.y * vec.y);
                if (vecLen <= remainder) {
                    remainder -= vecLen;
                    position = stateRef.current.activeTask.position;
                    if (stateRef.current.activeTask.type === "position" || stateRef.current.activeTask.type === "direction") {
                        getMockserver()?.send({
                            type: MessageType.PositionUpdate,
                            position: stateRef.current.activeTask.position,
                        });
                    }
                    const activeIsopter = stateRef.current.isopters.at(-1) ?? null;
                    if (activeIsopter && stateRef.current.activeTask.type === "direction") {
                        getMockserver()?.send({
                            type: MessageType.KineticVectorDone,
                            direction: {
                                x: 0,
                                y: 0,
                            },
                            position,
                            isopterIndex: activeIsopter.isopterRef.index,
                            stimulusStatus: {
                                size: activeIsopter.stimulusSize,
                                color: StimulusColor.White,
                            },
                            goldmannBrightness: activeIsopter.goldmannBrightness,
                            pressed: false,
                            assistance: KineticVectorDoneAssistance.Automatic,
                            speed: 0,
                            db: 0,
                        });
                    }
                    if (stateRef.current.activeTask.type === "position") {
                        break;
                    }
                    stateRef.current.activeTask = null;
                } else {
                    position = {
                        x: position.x + vec.x * (remainder / vecLen),
                        y: position.y + vec.y * (remainder / vecLen)
                    };
                    break;
                }
            }
            if (stateRef.current.activeTask?.type === "position" || stateRef.current.activeTask?.type === "direction") {
                getMockserver()?.send(({
                    type: MessageType.PositionUpdate,
                    position,
                }));
            }
        };

        const handleKeydown = (event: KeyboardEvent) => {
            if (event.key.toLowerCase() === "x" && (stateRef.current.activeTask?.type === "position" || stateRef.current.activeTask?.type === "direction")) {
                event.preventDefault();
                const activeIsopter = stateRef.current.isopters.at(-1) ?? null;
                if (!activeIsopter) {
                    return;
                }
                const direction = {
                    x: stateRef.current.activeTask.position.x - position.x,
                    y: stateRef.current.activeTask.position.y - position.y,
                };
                getMockserver()?.send({
                    type: MessageType.KineticVectorDone,
                    direction,
                    position,
                    isopterIndex: activeIsopter.isopterRef.index,
                    stimulusStatus: {
                        size: activeIsopter.stimulusSize,
                        color: StimulusColor.White,
                    },
                    goldmannBrightness: activeIsopter.goldmannBrightness,
                    pressed: true,
                    assistance: KineticVectorDoneAssistance.Automatic,
                    speed: 0,
                    db: 0,
                });
                if (stateRef.current.activeTask.autoAddKineticPosition) {
                    activeIsopter.kineticPositions.push({direction, position, comment: ""});
                }
                stateRef.current.taskQueue = stateRef.current.taskQueue.filter(({type}) => type !== "position");
                stateRef.current.activeTask = null;
            }
        };

        const handleWebSocketMessage = (message: Message) => {
            if (message.type === MessageType.KineticConfigurePopupResponse) {
                if (stateRef.current.initialized) {
                    throw new Error(`Already configured`);
                }
                const isopter: Isopter = {
                    goldmannBrightness: message.brightness,
                    stimulusSize: message.stimulusSize,
                    kineticPositions: [],
                    comment: "",
                    isopterRef: {
                        index: stateRef.current.nextIsopterIndex++,
                    },
                };
                stateRef.current.isopters.push(isopter);
                stateRef.current.initialized = true;
            }
        };

        if (automatic) {
            stateRef.current.initialized = true;
        } else if (!stateRef.current.initialized) {
            // Send Configure request
            getMockserver()?.send({
                type: MessageType.KineticConfigurePopupRequest,
                stimulusSize: getRandomEnumValue(StimulusSize),
                stimulusColor: getRandomEnumValue(StimulusColor),
                brightness: {
                    letter: getRandomEnumValue(GoldmannBrightnessLetter),
                    numericValue: getRandomEnumValue(GoldmannBrightnessNumericValue),
                },
                area: Math.trunc(Math.random() * 50),
                combineDots: true,
            });
        }

        getMockserver()?.addMessageListener(handleWebSocketMessage);
        const workerId = window.setInterval(update, UPDATE_INTERVAL);
        window.addEventListener("keydown", handleKeydown);
        return () => {
            window.removeEventListener("keydown", handleKeydown);
            window.clearInterval(workerId);
            getMockserver()?.removeMessageListener(handleWebSocketMessage);
        };
    }, [enabled, automatic]);

    return {
        putManualKineticIsopterCreate: async (config: IsopterManualConfig) => {
            console.log("putManualKineticIsopterCreate", config);
            if (!stateRef.current.initialized) {
                throw new Error(`Not configured`);
            }
            const isopter: Isopter = {
                ...config,
                comment: "",
                kineticPositions: [],
                isopterRef: {
                    index: stateRef.current.nextIsopterIndex++,
                },
            };
            stateRef.current.isopters.push(isopter);
            return {data: isopter, headers: {}};
        },
        getManualKineticIsopterFinish: async () => {
            console.log("getManualKineticIsopterFinish");
            const activeIsopter = stateRef.current.isopters.at(-1) ?? null;
            if (activeIsopter) {
                getMockserver()?.send({type: MessageType.KineticIsopterDone, isopter: activeIsopter})
            } else {
                throw new Error("No isopter");
            }
            return {data: activeIsopter, headers: {}};
        },
        putManualKineticPosition: async ({position, activeProjector}) => {
            console.log("putManualKineticPosition", position, activeProjector);
            if (!stateRef.current.initialized && activeProjector) {
                throw new Error(`Not configured`);
            }
            const task: Task = {position, type: activeProjector ? "position" : "move"};
            if (stateRef.current.activeTask?.type === "position" || stateRef.current.activeTask?.type === task.type) {
                stateRef.current.activeTask = task;
            } else {
                stateRef.current.taskQueue = [
                    ...stateRef.current.taskQueue.filter(({type}) => type !== "position" && type !== task.type),
                    task,
                ];
            }
            return {data: null as never, headers: {}};
        },
        putManualKineticDirection: async (arrow) => {
            console.log("putManualKineticDirection", arrow);
            if (!stateRef.current.initialized) {
                throw new Error(`Not configured`);
            }
            stateRef.current.taskQueue.push(
                {position: arrow.start, type: "move"},
                {position: arrow.end, type: "direction"},
            );
            return {data: null as never, headers: {}};
        },
        putManualKineticScotoma: async ({position, maxRadius}) => {
            console.log("putManualKineticScotoma", {position, maxRadius});
            if (!stateRef.current.initialized) {
                throw new Error(`Not configured`);
            }
            stateRef.current.taskQueue.push(
                ...Array.from({length: 8}, (_, i) => {
                    const angle = -360 / 8 * i;
                    const start = addVector(rotateVector({x: 0, y: 0}, angle), position);
                    const end = addVector(rotateVector({x: 0, y: maxRadius}, angle), position);
                    return [
                        {position: start, type: "move", autoAddKineticPosition: true},
                        {position: end, type: "direction", autoAddKineticPosition: true},
                    ] satisfies Task[];
                }).flat(),
                {type: "isopter-done"},
            );
            return {data: null as never, headers: {}};
        },
        putManualKineticIsopterCombine: async ({isopterRefs, isPreview}) => {
            console.log("putManualKineticIsopterCombine", {isopterRefs, isPreview});
            if (!stateRef.current.initialized) {
                throw new Error(`Not configured`);
            }
            if (!isopterRefs.length) {
                throw Error(`Empty isopterRefs list`);
            }
            const isopters = isopterRefs.map(({index}) => {
                const isopter = stateRef.current.isopters.find(({isopterRef: {index: oIndex}}) => index === oIndex);
                if (!isopter) {
                    throw Error(`Invalid isopterRef: ${index}`);
                }
                return isopter;
            });
            const newIsopter = {
                ...isopters[0],
                kineticPositions: isopters.flatMap(({kineticPositions}) => kineticPositions),
            };
            if (!isPreview) {
                stateRef.current.isopters = stateRef.current.isopters
                    .map((isopter) => isopter.isopterRef.index === newIsopter.isopterRef.index ? newIsopter : isopter)
                    .filter(({isopterRef: {index}}) => index === newIsopter.isopterRef.index || isopters.every(({isopterRef: {index: oIndex}}) => index !== oIndex));
            }
            return {
                data: newIsopter,
                headers: {},
            };
        },
        putManualKineticIsopterComment: async ({isopterRef: {index}, comment}) => {
            console.log(`putManualKineticIsopterComment`, {index, comment});
            if (!stateRef.current.initialized) {
                throw new Error(`Not configured`);
            }
            const oldIsopter = stateRef.current.isopters.find((isopter) => isopter.isopterRef.index === index);
            if (!oldIsopter) {
                throw Error(`Invalid isopterRef: ${index}`);
            }
            const newIsopter = {...oldIsopter, comment};
            stateRef.current.isopters = stateRef.current.isopters.map((isopter) => Object.is(isopter, oldIsopter) ? newIsopter : isopter);
            return {
                data: null as never,
                headers: {},
            };
        },
        putManualKineticVectorComment: async ({
                                                  isopterRef: {index},
                                                  kineticPosition: {position, direction, comment}
                                              }) => {
            console.log("putManualKineticVectorComment", {index, position, direction, comment});
            if (!stateRef.current.initialized) {
                throw new Error(`Not configured`);
            }
            const oldIsopterIndex = stateRef.current.isopters.findIndex((isopter) => isopter.isopterRef.index === index);
            const oldIsopter = oldIsopterIndex >= 0 ? stateRef.current.isopters[oldIsopterIndex] : null;
            if (!oldIsopter) {
                throw Error(`Invalid isopterRef: ${index}`);
            }
            const oldKineticPositionIndex = oldIsopter.kineticPositions
                .findIndex(({
                                position: oPosition,
                                direction: oDirection
                            }) => isSamePosition(oPosition, position) && isSamePosition(oDirection, direction));
            const oldKineticPosition = oldKineticPositionIndex >= 0 ? oldIsopter.kineticPositions[oldKineticPositionIndex] : null;
            if (!oldKineticPosition) {
                throw Error(`Invalid kineticPosition: (${position.x},${position.y}) -(${direction.x},${direction.y})->`);
            }
            const newKineticPosition = {...oldKineticPosition, comment}
            const newIsopter = {
                ...oldIsopter,
                kineticPositions: oldIsopter.kineticPositions.toSpliced(oldKineticPositionIndex, 1, newKineticPosition),
            };
            stateRef.current.isopters = stateRef.current.isopters.toSpliced(oldIsopterIndex, 1, newIsopter);
            return {
                data: null as never,
                headers: {},
            };
        },
        putManualKineticVectorLink: async ({isopterRef: {index}, kineticPositions}) => {
            console.log("putManualKineticVectorLink", {isopterRef: {index}, kineticPositions});
            if (!stateRef.current.initialized) {
                throw new Error(`Not configured`);
            }
            const oldIsopter = stateRef.current.isopters.find((isopter) => isopter.isopterRef.index === index);
            if (!oldIsopter) {
                throw Error(`Invalid isopterRef: ${index}`);
            }
            const newIsopter = {...oldIsopter, kineticPositions: [...kineticPositions]};
            stateRef.current.isopters = stateRef.current.isopters.map((isopter) => Object.is(isopter, oldIsopter) ? newIsopter : isopter);
            return {
                data: newIsopter,
                headers: {},
            };
        },
        getManualKineticIsopterList: async () => {
            console.log("getManualKineticIsopterList");
            if (!stateRef.current.initialized) {
                throw new Error(`Not configured`);
            }
            return {data: stateRef.current.isopters, headers: {}};
        },
        putManualKineticVectorAdd: async ({isopterRef: {index}, kineticPosition}) => {
            console.log("putManualKineticVectorAdd", {isopterRef: {index}, kineticPosition});
            if (!stateRef.current.initialized) {
                throw new Error(`Not configured`);
            }
            const oldIsopterIndex = stateRef.current.isopters.findIndex((isopter) => isopter.isopterRef.index === index);
            const oldIsopter = oldIsopterIndex >= 0 ? stateRef.current.isopters[oldIsopterIndex] : null;
            if (!oldIsopter) {
                throw Error(`Invalid isopterRef: ${index}`);
            }
            const newIsopter = {...oldIsopter, kineticPositions: [...oldIsopter.kineticPositions, kineticPosition]};
            stateRef.current.isopters = stateRef.current.isopters.toSpliced(oldIsopterIndex, 1, newIsopter);
            return {
                data: newIsopter,
                headers: {},
            };
        },
        putManualKineticIsopterRemove: async ({index}) => {
            console.log("putManualKineticIsopterRemove", {index});
            if (!stateRef.current.initialized) {
                throw new Error(`Not configured`);
            }
            const oldIsopterIndex = stateRef.current.isopters.findIndex((isopter) => isopter.isopterRef.index === index);
            if (oldIsopterIndex < 0) {
                throw Error(`Invalid isopterRef: ${index}`);
            }
            stateRef.current.isopters = stateRef.current.isopters.toSpliced(oldIsopterIndex, 1);
            return {
                data: null as never,
                headers: {},
            };
        },
        putManualKineticVectorRemove: async ({isopterRef: {index}, kineticPosition: {position, direction}}) => {
            console.log("putManualKineticVectorRemove", {isopterRef: {index}, kineticPosition: {position, direction}});
            if (!stateRef.current.initialized) {
                throw new Error(`Not configured`);
            }
            const oldIsopterIndex = stateRef.current.isopters.findIndex((isopter) => isopter.isopterRef.index === index);
            const oldIsopter = oldIsopterIndex >= 0 ? stateRef.current.isopters[oldIsopterIndex] : null;
            if (!oldIsopter) {
                throw Error(`Invalid isopterRef: ${index}`);
            }
            const oldKineticPositionIndex = oldIsopter.kineticPositions
                .findIndex(({
                                position: oPosition,
                                direction: oDirection
                            }) => isSamePosition(oPosition, position) && isSamePosition(oDirection, direction));
            if (oldKineticPositionIndex < 0) {
                throw Error(`Invalid kineticPosition: (${position.x},${position.y}) -(${direction.x},${direction.y})->`);
            }
            const newIsopter = {
                ...oldIsopter,
                kineticPositions: oldIsopter.kineticPositions.toSpliced(oldKineticPositionIndex, 1)
            };
            stateRef.current.isopters = stateRef.current.isopters.toSpliced(oldIsopterIndex, 1, newIsopter);
            return {
                data: newIsopter,
                headers: {},
            };
        },
    };
};