import {
    EyeStatusSelectedBorderType, formatDate,
    TopBar, genderToString,
} from "@oculus/component-library";
import React, {createContext, useContext} from "react";
import {useAppState} from "../globalAppState";
import {TopBarProps} from "@oculus/component-library/lib/components/molecules/topbar/TopBar";
import {useTranslation} from "@oculus/component-library";

export const TopBarContext = createContext<Pick<TopBarProps, "title" | "examination" | "disableExamInfo">>({});

/**
 * This component is responsible for creating the header for the application.
 *
 * The content is controlled by {@link TopBarContext} and {@link useAppState}.
 *
 * @component
 * @see TopBar
 */
const Header: React.FC<{ dialogIsOpen: boolean; setDialogIsOpen: (value: boolean) => void }> = ({ dialogIsOpen, setDialogIsOpen }) => {
    const {t} = useTranslation();
    const [{patientInfo, eye, deviceSettings}] = useAppState();
    const context = useContext(TopBarContext);
    return (
        <header className="bg-white ml-2 -mr-2">
            <TopBar {...context}
                    dialogIsOpen={dialogIsOpen}
                    setDialogIsOpen={setDialogIsOpen}
                    dateFormat={deviceSettings?.main.dateFormat}
                    eyeStatus={{
                        leftSelected: eye === "both" || eye === "left",
                        rightSelected: eye === "both" || eye === "right",
                        selectedBorderType: EyeStatusSelectedBorderType.Merge,
                    }}
                    field1={patientInfo && `${patientInfo.firstName} ${patientInfo.lastName}`}
                    field2={patientInfo && ["ID", patientInfo.externalId && patientInfo.externalId.trim() !== "" ? patientInfo.externalId : "-"]}
                    field3={patientInfo && (context.examination ? `${patientInfo.age} ${t("years")}` : [t("age"),`${patientInfo.age} ${t("years")}`])}
                    field4={patientInfo && [
                        t("date_of_birth"),
                        formatDate(new Date(patientInfo.dateOfBirth), deviceSettings?.main.dateFormat),
                    ]}
                    field6={patientInfo && [`${t("gender")} `, genderToString(patientInfo.sex, t)]}
            />
        </header>
    );
};
export default Header;
