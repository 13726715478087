import {CursorArrowGroupProps, CursorArrowType} from "@oculus/component-library";
import {useCallback, useRef} from "react";
import {ChinrestMove} from "../backend/api/interfaces/data/ChinrestMove";
import {
    ChinrestMoveHorizontalDirection,
    ChinrestMoveVerticalDirection
} from "../backend/api/interfaces/custom-datatypes/ChinrestMove";
import {putChinrestStartMove, putChinrestStopMove} from "../backend/api/Calls";
import AsyncLock from "./AsyncLock";

function isEqualMove(a: ChinrestMove, b: ChinrestMove) {
    return a.horizontalDirection === b.horizontalDirection && a.verticalDirection === b.verticalDirection;
}

const cursorArrowToDirectionMap = {
    [CursorArrowType.Left]: ChinrestMoveHorizontalDirection.Left,
    [CursorArrowType.Right]: ChinrestMoveHorizontalDirection.Right,
    [CursorArrowType.Up]: ChinrestMoveVerticalDirection.Up,
    [CursorArrowType.Down]: ChinrestMoveVerticalDirection.Down,
}

/**
 * `useChinrestController` is a custom hook responsible for communicating with the backend in response to direction toggles solicited by the `CursorArrowGroup` component.
 *
 * The primary role of the hook is to transform button presses - captured by the `CursorArrowGroup` component - into backend calls that control a specific device's movements.
 *
 * @returns A callback function for the `onDirectionToggle` property of the `CursorArrowGroup` component.
 */
const useChinrestController = (): NonNullable<CursorArrowGroupProps["onDirectionToggle"]> => {
    const state = useRef<{ current: ChinrestMove, target: ChinrestMove, lock: AsyncLock }>({
        current: {
            horizontalDirection: ChinrestMoveHorizontalDirection.None,
            verticalDirection: ChinrestMoveVerticalDirection.None,
        },
        target: {
            horizontalDirection: ChinrestMoveHorizontalDirection.None,
            verticalDirection: ChinrestMoveVerticalDirection.None,
        },
        lock: new AsyncLock(),
    });
    return useCallback(async (arrow, pressed) => {
        // Update target state
        if (arrow === CursorArrowType.Left || arrow === CursorArrowType.Right) {
            if (pressed) {
                state.current.target.horizontalDirection = cursorArrowToDirectionMap[arrow];
            } else if (state.current.target.horizontalDirection === cursorArrowToDirectionMap[arrow]) {
                state.current.target.horizontalDirection = ChinrestMoveHorizontalDirection.None;
            }
        }
        if (arrow === CursorArrowType.Up || arrow === CursorArrowType.Down) {
            if (pressed) {
                state.current.target.verticalDirection = cursorArrowToDirectionMap[arrow];
            } else if (state.current.target.verticalDirection === cursorArrowToDirectionMap[arrow]) {
                state.current.target.verticalDirection = ChinrestMoveVerticalDirection.None;
            }
        }

        // Update backend
        await state.current.lock.acquire();
        try {
            // Copy target move because it can be modified by other task
            const targetMove = {...state.current.target};
            const stopMove: ChinrestMove = {
                horizontalDirection: state.current.current.horizontalDirection !== targetMove.horizontalDirection
                    ? ChinrestMoveHorizontalDirection.None
                    : state.current.current.horizontalDirection,
                verticalDirection: state.current.current.verticalDirection !== targetMove.verticalDirection
                    ? ChinrestMoveVerticalDirection.None
                    : state.current.current.verticalDirection,
            };
            if (!isEqualMove(state.current.current, stopMove)) {
                await putChinrestStopMove(stopMove);
                state.current.current = stopMove;
                if (!isEqualMove(state.current.target, targetMove)) {
                    // Abort the operation if the target has been altered during this process.
                    return;
                }
            }
            if (!isEqualMove(state.current.current, targetMove)) {
                await putChinrestStartMove(targetMove);
                state.current.current = targetMove;
                if (!isEqualMove(state.current.target, targetMove)) {
                    // Abort the operation if the target has been altered during this process.
                    return;
                }
            }
        } finally {
            state.current.lock.release();
        }
    }, []);
};

export default useChinrestController;