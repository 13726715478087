import React from "react";
import {
    BodyText,
    BodyTextType,
    Dialog,
    DialogType,
    TailwindStandardWidth, useTranslation,
} from "@oculus/component-library";
import {ErrorMessage} from "../../../backend/api/interfaces/data/ErrorMessage";
import {errorMessageToString} from "../../../helper";
import {BaseCriticalErrorPopupProps} from "./index";

/** `CriticalExaminationErrorPopup` component properties */
export interface CriticalExaminationErrorPopupProps extends BaseCriticalErrorPopupProps {
    /** The error message to be displayed to the user. */
    error?: ErrorMessage,
}

// TODO
const expectedErrorMessage = `%0`;

/**
 * A popup component for handling examination error. (?)
 *
 * @component
 * {@link CriticalExaminationErrorPopupProps}
 */
const CriticalExaminationErrorPopup: React.FC<CriticalExaminationErrorPopupProps> = ({
                                                                                         error,
                                                                                         onExit,
                                                                                         onSwitchToEvaluation,
                                                                                         show = true,
                                                                                     }) => {
    const {t} = useTranslation();
    return <Dialog
        label={t("error_message")}
        headline={t("critical_error")}
        text={
            <>
                {t("investigation_cannot_continue")}{" "}
                {error?.message === expectedErrorMessage && error.arguments.length === 1 ? (
                    <>
                        {t("check_error_code")}{" "}
                        <BodyText
                            text={`${error.arguments[0].value}`}
                            type={BodyTextType.B1Bold}
                            furtherClasses={"inline"}
                        />{" "}
                        {t("refer_to_manual")}
                    </>
                ) : error && errorMessageToString(error)}
            </> satisfies React.ReactNode as unknown as string /* HACK: "text" supports only string */}

        type={DialogType.Warning}
        width={TailwindStandardWidth.W200}
        showCloseButton={false}
        show={show}
        buttons={[
            {
                label: t("cancel_examination_exit_software"),
                primary: true,
                onClick: onExit,
            },
            ...onSwitchToEvaluation ? [{
                label: t("evaluate_examination"),
                onClick: onSwitchToEvaluation,
            }] : [],
        ]}
    />
};

export default CriticalExaminationErrorPopup;