import React, {useState} from "react";
import {
    BodyText,
    BodyTextType,
    Dialog, Dropdown,
    TailwindStandardWidth, useTranslation
} from "@oculus/component-library";
import {
    UnexpectedThresholdPopupResponse,
    UnexpectedThresholdPopupResponseResponseType
} from "../../../backend/websocket/interfaces/messages/UnexpectedThresholdPopupResponse";
import {errorMessageToString, lightDensityClassToString} from "../../../helper";
import {
    UnexpectedThresholdPopupRequest
} from "../../../backend/websocket/interfaces/messages/UnexpectedThresholdPopupRequest";
import {
    LightDensityClassPopupRequest
} from "../../../backend/websocket/interfaces/messages/LightDensityClassPopupRequest";
import {LightDensityClass} from "../../../backend/api/interfaces/custom-datatypes/LightDensityClass";
import {
    LightDensityClassPopupResponse
} from "../../../backend/websocket/interfaces/messages/LightDensityClassPopupResponse";

/** `UnexpectedThresholdPopup` component properties */
export interface UnexpectedThresholdPopupProps {
    error?: UnexpectedThresholdPopupRequest["errorMessage"],
    /**
     * The age-based class to be displayed to the user.
     */
    preselectedClassBasedOnAge?: LightDensityClassPopupRequest["ageExpectedLightDensityClass"];

    /**
     * The measured class to be displayed to the user.
     */
    measuredClass?: LightDensityClassPopupRequest["measuredLightDensityClass"];
    /**
     * Callback function to handle the response of the popup
     *
     * @param response - The `responseType` from {@link UnexpectedThresholdPopupResponse}
     */
    onResponse?: (response: UnexpectedThresholdPopupResponse["responseType"], selected?: LightDensityClassPopupResponse["lightDensityClass"]) => void;

    /** Controls the visibility of the popup. */
    show?: boolean;

    /** The measured threshold of the patient. */
    measuredThreshold?: number | null

    /** The expected threshold based on the patient's age. */
    ageExpectedThreshold?: number
}

const expectedErrorMessage = `The central measurement of threshold %0 is unexpected, the age expected threshold is %1!`;

/**
 * A popup component for handling {@link UnexpectedThresholdPopupRequest} message.
 *
 * @component
 * {@link UnexpectedThresholdPopupProps}
 */
const UnexpectedThresholdPopup: React.FC<UnexpectedThresholdPopupProps> = ({
                                                                               error,
                                                                               measuredThreshold,
                                                                               ageExpectedThreshold,
                                                                               measuredClass,
                                                                               preselectedClassBasedOnAge,
                                                                               onResponse,
                                                                               show = true,
                                                                           }) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState(preselectedClassBasedOnAge);
    const items = Object.values(LightDensityClass).map((value) => ({name: lightDensityClassToString(value, t), value}))
    return <Dialog
        label={t("hint")}
        headline={t("measured_threshold_not_within_expected_range")}
        text={<>
            {error?.message === expectedErrorMessage ? (<>
                {t("measured_value_deviates_from_expected")} <br/>
                {t("measured_threshold_value")}:{" "}
                <BodyText text={`${measuredThreshold === null ? "<0" : measuredThreshold}`} type={BodyTextType.B1Bold}
                          furtherClasses={"inline" /* HACK: BodyText is div */}/>
                <br/>
                {t("expected_threshold_value")}:{" "}
                <BodyText text={`${ageExpectedThreshold}`} type={BodyTextType.B1Bold}
                          furtherClasses={"inline" /* HACK: BodyText is div */}/>
            </>) : error && errorMessageToString(error)}
            <br/>{t("repeat_or_continue_options")}
            <br/>
            <br/>
            {measuredClass && <>{t("select_luminance_class")}
                {preselectedClassBasedOnAge ? <><br/>{t("preselected_age_based_class")}: <BodyText
                    text={lightDensityClassToString(preselectedClassBasedOnAge, t)}
                    type={BodyTextType.B1Bold}
                    furtherClasses={"inline" /* HACK: BodyText is div */}/></> : null}
                {measuredClass ? <><br/>{t("recommended_class_based_on_measurement")}: <BodyText
                    text={lightDensityClassToString(measuredClass, t)} type={BodyTextType.B1Bold}
                    furtherClasses={"inline" /* HACK: BodyText is div */}/></> : null}
            </>
            }
        </> satisfies React.ReactNode as unknown as string /* HACK: "text" supports only string */}
        width={TailwindStandardWidth.AUTO}
        showCloseButton={false}
        show={show}
        buttons={[
            {
                label: t("repeat_measurement"),
                primary: true,
                onClick: () => {
                    if (onResponse) {
                        onResponse(UnexpectedThresholdPopupResponseResponseType.Repeat, selected);
                    }
                }
            },
            {
                label: t("examination_abort"),
                onClick: onResponse && (() => onResponse(UnexpectedThresholdPopupResponseResponseType.Cancel)),
            },
            {
                label: t("button_confirmation"),
                onClick: () => {
                    if (onResponse) {
                        onResponse(UnexpectedThresholdPopupResponseResponseType.Continue, selected);
                    }
                }
            },
        ]}
    >
        {measuredClass && (
            <Dropdown
                currentDropdownItem={items.find(({value}) => value === selected)}
                items={items}
                onChange={({value}) => setSelected(value)}
            />
        )}
    </Dialog>
};

export default UnexpectedThresholdPopup;