import React, {useState} from "react";
import {
    BodyText,
    BodyTextType,
    Dialog, Dropdown,
    TailwindStandardWidth, useTranslation
} from "@oculus/component-library";
import {
    ContradictoryThresholdPopupResponse,
    ContradictoryThresholdPopupResponseResponseType
} from "../../../backend/websocket/interfaces/messages/ContradictoryThresholdPopupResponse";
import {errorMessageToString, lightDensityClassToString} from "../../../helper";
import {
    ContradictoryThresholdPopupRequest
} from "../../../backend/websocket/interfaces/messages/ContradictoryThresholdPopupRequest";
import {
    LightDensityClassPopupRequest
} from "../../../backend/websocket/interfaces/messages/LightDensityClassPopupRequest";
import {LightDensityClass} from "../../../backend/api/interfaces/custom-datatypes/LightDensityClass";
import {
    LightDensityClassPopupResponse
} from "../../../backend/websocket/interfaces/messages/LightDensityClassPopupResponse";

/** `ContradictoryThresholdPopup` component properties */
export interface ContradictoryThresholdPopupProps {

    /** The threshold value measured. */
    measuredThreshold?: number | null;

    /** The control threshold. */
    controlThreshold?: number;

    /**
     * The age-based class to be displayed to the user.
     */
    preselectedClassBasedOnAge?: LightDensityClassPopupRequest["ageExpectedLightDensityClass"];

    /**
     * The measured class to be displayed to the user.
     */
    measuredClass?: LightDensityClassPopupRequest["measuredLightDensityClass"];
    /**
     * The error message to be displayed to the user.
     * See {@link ContradictoryThresholdPopupRequest}
     */
    error?: ContradictoryThresholdPopupRequest["errorMessage"];

    /**
     * Callback function to handle the response of the popup
     *
     * @param response - The `responseType` from {@link ContradictoryThresholdPopupResponse}
     */
    onResponse?: (response: ContradictoryThresholdPopupResponse["responseType"], selected?: LightDensityClassPopupResponse["lightDensityClass"]) => void;

    /** Controls the visibility of the popup. */
    show?: boolean;
}

const expectedErrorMessage = `The central measurement of threshold %0 is contradictory, because the control measurement threshold of %1 was not seen!`;

/**
 * A popup component for handling {@link ContradictoryThresholdPopupRequest} message.
 *
 * @component
 * {@link ContradictoryThresholdPopupProps}
 */
const ContradictoryThresholdPopup: React.FC<ContradictoryThresholdPopupProps> = ({
                                                                                     error,
                                                                                     controlThreshold,
                                                                                     measuredThreshold,
                                                                                     measuredClass,
                                                                                     preselectedClassBasedOnAge,
                                                                                     onResponse,
                                                                                     show = true,
                                                                                 }) => {
    const {t} = useTranslation();
    const [selected, setSelected] = useState(preselectedClassBasedOnAge);
    const items = Object.values(LightDensityClass).map((value) => ({name: lightDensityClassToString(value, t), value}))
    return <Dialog
        label={t("hint")}
        headline={t("inconsistent_responses_threshold_measurement")}
        text={
            <>
                {error?.message === expectedErrorMessage ? (
                    <>
                        {t("threshold_measurement_inconsistent_results")} <br />
                        {t("measured_threshold_value")}:{" "}
                        <BodyText
                            text={`${measuredThreshold === null ? "<0" : measuredThreshold}`}
                            type={BodyTextType.B1Bold}
                            furtherClasses={"inline"}
                        />
                        <br />
                        {t("expected_threshold_value")}:{" "}
                        <BodyText
                            text={`${controlThreshold}`}
                            type={BodyTextType.B1Bold}
                            furtherClasses={"inline"}
                        />
                    </>
                ) : error && errorMessageToString(error)}
                <br />
                {t("repeat_or_change_options")}
                <br />
                <br />
                {measuredClass && (
                    <>
                        {t("select_luminance_class")}{" "}
                        {preselectedClassBasedOnAge && (
                            <>
                                <br />
                                {t("preselected_age_based_class")}:{" "}
                                <BodyText
                                    text={lightDensityClassToString(preselectedClassBasedOnAge, t)}
                                    type={BodyTextType.B1Bold}
                                    furtherClasses={"inline"}
                                />
                            </>
                        )}
                        {measuredClass && (
                            <>
                                <br />
                                {t("recommended_class_based_on_measurement")}:{" "}
                                <BodyText
                                    text={lightDensityClassToString(measuredClass, t)}
                                    type={BodyTextType.B1Bold}
                                    furtherClasses={"inline"}
                                />
                            </>
                        )}
                    </>
                )}
            </> satisfies React.ReactNode as unknown as string /* HACK: "text" supports only string */}
        width={TailwindStandardWidth.AUTO}
        showCloseButton={false}
        show={show}
        buttons={[
            {
                label: t("repeat_measurement"),
                primary: true,
                onClick: () => {
                    if (onResponse) {
                        onResponse(ContradictoryThresholdPopupResponseResponseType.Repeat, selected);
                    }
                }
            },
            {
                label: t("switch_to_heijl_krakau_fixation_control"),
                onClick: () => {
                    if (onResponse) {
                        onResponse(ContradictoryThresholdPopupResponseResponseType.Switch, selected);
                    }
                }
            },
            {
                label: t("examination_abort"),
                onClick: onResponse && (() => onResponse(ContradictoryThresholdPopupResponseResponseType.Cancel)),
            },
            {
                label: t("button_continue"),
                onClick: () => {
                    if (onResponse) {
                        onResponse(ContradictoryThresholdPopupResponseResponseType.Continue, selected);
                    }
                }
            },
        ]}
    >
        {measuredClass && (
            <Dropdown
                currentDropdownItem={items.find(({value}) => value === selected)}
                items={items}
                onChange={({value}) => setSelected(value)}
            />
        )}
    </Dialog>
};

export default ContradictoryThresholdPopup;