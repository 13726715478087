import {
    AudioPlayer,
    AudioPlayerType,
    BodyText,
    BodyTextType,
    Checkbox,
    CheckBoxState,
    CursorArrowGroup,
    DistanceCorrectionArrow,
    Headline,
    HeadlineSize,
    IconButton,
    IconButtonType,
    Input,
    InputControlType,
    InputState,
    InputType,
    InputValidator,
    TailwindStandardWidth,
    Toggletip,
    ToggletipLabel,
    ToggletipPosition,
    ToggletipSize,
    useTranslation,
} from "@oculus/component-library";
import React, {useEffect, useState} from "react";
import {ConfigurationStepProps} from "./index";
import SettingsPreview from "../SettingsPreview";
import FooterNav from "./FooterNav";
import {ExaminationComponentProps} from "../index";
import {InputProps} from "@oculus/component-library/lib/components/molecules/inputs/Input";
import {putPatientFarCorrection} from "../../../backend/api/Calls";
import {LensCorrection} from "../../../backend/api/interfaces/data/LensCorrection";
import {CorrectionType} from "../../../backend/api/interfaces/custom-datatypes/CorrectionType";
import useChinrestController from "../../../utils/useChinrestController";
import ConfigurationLayout from "./ConfigurationLayout";
import {MaterialSymbol} from "react-material-symbols";

/** @see ConfigurationStep */
export function checkSettings({settings}: ExaminationComponentProps) {
    return Boolean(settings.patientCentered);
}

function useNumberInputProps({
                                 min,
                                 max,
                                 steps = 1
                             }: Pick<InputProps, "min" | "max" | "steps">, value: number = 0, setter?: (value: number) => void) {
    const [uncheckedValue, setUncheckedValue] = useState<string>();
    const onChange = (rawValue: string) => {
        setUncheckedValue((state) => state !== undefined ? rawValue : undefined);
        const value = Math.max(min ?? (-Infinity), Math.min(max ?? Infinity, Math.round(Number(rawValue) / steps) * steps));
        setter?.(value);
    };
    return {
        min, max, steps,
        type: InputType.Number,
        onChange,
        value: uncheckedValue ?? value.toString(),
        onBlur: () => setUncheckedValue(undefined),
        onFocus: () => setUncheckedValue((state) => state ?? value.toString()),
    };
}

/** @see ConfigurationStep */
const FourthStep: React.FC<ConfigurationStepProps> = (props) => {
    const {
        eyePreview,
        settings,
        setSettings,
        audioExplanation1,
        audioExplanation2,
        deviceInformation,
    } = props;
    audioExplanation2.pause();
    const [isContinueButtonClicked, setIsContinueButtonClicked] = useState(false)
    const {t} = useTranslation();
    const lensSphereProps = useNumberInputProps({
        min: -31.75,
        max: 31.75,
        steps: 0.25
    }, settings.lensSphere, (lensSphere) => setSettings({...settings, lensSphere}));
    const lensCylinderProps = useNumberInputProps({
        min: -31.75,
        max: 31.75,
        steps: 0.25
    }, settings.lensCylinder, (lensCylinder) => setSettings({...settings, lensCylinder}));
    const lensAxialLengthProps = useNumberInputProps({
        min: 0,
        max: 179,
        steps: 1
    }, settings.lensAxialLength, (lensAxialLength) => setSettings({...settings, lensAxialLength}));

    const distanceSphereProps = useNumberInputProps({
        min: -31.75,
        max: 31.75,
        steps: 0.25
    }, settings.distanceSphere, (distanceSphere) => setSettings({...settings, distanceSphere}));
    const distanceCylinderProps = useNumberInputProps({
        min: -31.75,
        max: 31.75,
        steps: 0.25
    }, settings.distanceCylinder, (distanceCylinder) => setSettings({...settings, distanceCylinder}));
    const distanceAxialLengthProps = useNumberInputProps({
        min: 0,
        max: 179,
        steps: 1
    }, settings.distanceAxialLength, (distanceAxialLength) => setSettings({...settings, distanceAxialLength}));
    useEffect(() => {
        setSettings({
            ...settings,
            startValueLensSphere: settings.startValueLensSphere ? settings.startValueLensSphere : 0,
            startValueLensCylinder: settings.startValueLensCylinder ? settings.startValueLensCylinder : 0,
            startValueLensAxialLength: settings.startValueLensAxialLength ? settings.startValueLensAxialLength : 0,
            patientCentered: settings.patientCentered || false,
            lensCylinder: settings.lensCylinder ? settings.lensCylinder : 0,
            lensAxialLength: settings.lensAxialLength ? settings.lensAxialLength : 0,
            lensSphere: settings.lensSphere ? settings.lensSphere : 0,

        })
    }, []);
    useEffect(() => {
        let isActive = true;
        if (settings.distanceCorrection) {
            const lensCorrection: LensCorrection = {
                type: settings.lensCorrection ? CorrectionType.Lens : CorrectionType.No,
                clUsed: settings.lensCorrection || false,
                sphere: settings.distanceSphere ? settings.distanceSphere : 0,
                cylinder: settings.distanceCylinder ? settings.distanceCylinder : 0,
                axis: settings.distanceAxialLength ? settings.distanceAxialLength : 0,
            }
            putPatientFarCorrection(lensCorrection).then(({data}) => {
                if (isActive) {
                    setSettings({
                        ...settings,
                        lensAxialLength: data.axis,
                        startValueLensSphere: data.sphere,
                        startValueLensCylinder: data.cylinder,
                        startValueLensAxialLength: data.axis,
                        lensCylinder: data.cylinder,
                        lensSphere: data.sphere,
                    })
                }
            })
        }

        return () => {
            isActive = false; // Setze das Flag auf false, wenn der Effect neu getriggert oder unmounted wird
        };
    }, [settings.distanceCylinder, settings.distanceSphere, settings.distanceAxialLength]);

    const isLensAxialLengthChanged = settings.lensAxialLength !== settings.startValueLensAxialLength;
    const isLensSphereChanged = settings.lensSphere !== settings.startValueLensSphere;
    const isLensCylinderChanged = settings.lensCylinder !== settings.startValueLensCylinder;

    const handleChinrestDirectionToggle = useChinrestController();

    const resetButton = (disabled: boolean, disabled2: boolean, disabled3: boolean, startValue?: number, type?: string,) => {
        return <div className="flex items-end justify-end ml-1">
            {type === "lensSphere" ? (
                <IconButton isDisabled={disabled}
                            onClick={() =>
                                setSettings({
                                    ...settings,
                                    lensSphere: Number(startValue),
                                })
                            }
                            iconKey="restart_alt"
                            type={IconButtonType.Tertiary}
                />
            ) : type === "lensCylinder" ? (
                <IconButton isDisabled={disabled2}
                            onClick={() => {
                                setSettings({
                                    ...settings,
                                    lensCylinder: Number(startValue),
                                })
                            }
                            }
                            iconKey="restart_alt"
                            type={IconButtonType.Tertiary}
                />
            ) : type === "AxialLenght" ? (
                <IconButton isDisabled={disabled3}
                            onClick={() =>
                                setSettings({
                                    ...settings,
                                    lensAxialLength: Number(startValue)
                                })
                            }
                            iconKey="restart_alt"
                            type={IconButtonType.Tertiary}
                />
            ) : null}
        </div>
    };
    return (
        <ConfigurationLayout
            headline={settings.manualKinetic ? t("step_4_headline_examination_manual_kinetic") : t("step_4_headline_examination_static")}
            player={
                <AudioPlayer
                    state={audioExplanation1}
                    title={t("audio_Explanation_1_title_small")}
                    type={AudioPlayerType.Small}
                />
            }
            footer={<FooterNav {...props} continueButtonClicked={() => setIsContinueButtonClicked(true)}/>}
            sidepanelCamera={<>
                {eyePreview}
                {deviceInformation?.supportsVerticalChinrestMove || deviceInformation?.supportsHorizontalChinrestMove ? (
                    <div className="col-span-6 mb-6 xl:mb-0">
                        <CursorArrowGroup onDirectionToggle={handleChinrestDirectionToggle}
                                          enableKeyboardNavigation
                                          disableVertical={!deviceInformation.supportsVerticalChinrestMove}
                                          disableHorizontal={!deviceInformation.supportsHorizontalChinrestMove}/>
                    </div>
                ) : null}
            </>}
            sidepanelMessage={
                <div className={"bg-white"}>
                    <div className={"w-full h-2 bg-grey2"}></div>
                    <div className={`px-4 py-1 flex justify-between items-center ${isContinueButtonClicked && !settings.patientCentered ? "outline outline-2 outline-red1 outline-offset-[-2px]" : ""}`}>
                        <Checkbox furtherClasses={"!text-red1"}
                                  state={settings.patientCentered ? CheckBoxState.Default : CheckBoxState.Error}
                                  mainLabel={t("step_4_patient_centred_and_controlled_check_box")}
                                  onChange={(checked: boolean) => setSettings({
                                      ...settings,
                                      patientCentered: checked,
                                  })}
                                  checked={settings.patientCentered}
                        />
                        {isContinueButtonClicked && !settings.patientCentered &&  <MaterialSymbol icon={"warning"} size={24} color={"#CC0003"} as="div"/>}
                    </div>
                </div>}
            sidepanel={<SettingsPreview {...props}/>}>
            {settings.manualKinetic ? (<>
                    <div className="col-span-12 mb-10">
                        <BodyText
                            text={t("step_4_paragraph_2")}
                            type={BodyTextType.B1Regular}
                            furtherClasses="text-grey5 !mb-0"
                        />
                    </div>
                </>)
                : (<>
                    <div className="col-span-12 mb-6">
                        <BodyText
                            text={t("step_4_paragraph_1")}
                            type={BodyTextType.B1Regular}
                            furtherClasses="text-grey5 !mb-0"
                        />
                    </div>
                    <div className="col-span-12 flex flex-row">
                        <div className="flex-1  mb-0.5 content-start">
                            <div className="mb-3">
                                <ToggletipLabel
                                    label={
                                        <Headline
                                            size={HeadlineSize.H4}
                                            text={t("step_4_headline_correction")}
                                            furtherClasses="!mb-0"
                                        />
                                    }
                                    iconSize={28}
                                    openedComponent={
                                        <Toggletip
                                            headline={t("step_4_headline_correction")}
                                            content={t("step_4_headline_correction_toggle_tip")}
                                            size={ToggletipSize.Medium}
                                            closeByOutsideClick={true}
                                            arrowPosition={ToggletipPosition.LeftCenter}
                                            useArrowPosition={true}
                                            show={true}
                                            onClose={() => {
                                            }}
                                        />
                                    }
                                />
                            </div>

                            <Checkbox
                                mainLabel={t("step_4_lens_checkbox")}
                                state={CheckBoxState.Default}
                                checked={settings.lensCorrection}
                                onChange={(checked) => setSettings({
                                    ...settings,
                                    lensCorrection: checked,
                                    distanceCorrection: checked,
                                })}
                            />
                            <Checkbox
                                mainLabel={t("step_4_far_correction_checkbox")}
                                state={CheckBoxState.Default}
                                disabled={settings.lensCorrection}
                                checked={settings.distanceCorrection}
                                onChange={(checked) => {
                                    setSettings({
                                        ...settings,
                                        distanceCorrection: checked,
                                    })
                                }
                                }
                            />
                        </div>
                        <div className={`${(settings.lensCorrection || settings.distanceCorrection) ? "flex-[1_1_56px]" : "flex-1" } mb-0.5 ml-16 mr-7`}>
                            <div className="mb-3">
                                <ToggletipLabel
                                    label={
                                        <Headline
                                            size={HeadlineSize.H4}
                                            text={t("step_4_headline_correction_lens")}
                                            furtherClasses="!mb-0"
                                        />
                                    }
                                    iconSize={28}
                                    openedComponent={
                                        <Toggletip
                                            headline={t("step_4_headline_correction_lens")}
                                            content={t("step_4_headline_correction_lens_toggle_tip")}
                                            size={ToggletipSize.Medium}
                                            closeByOutsideClick={true}
                                            arrowPosition={ToggletipPosition.LeftCenter}
                                            useArrowPosition={true}
                                            show={true}
                                            onClose={() => {
                                            }}
                                        />
                                    }
                                />
                            </div>

                            <div className=" gap-4">
                                <div className="flex row gap-3">
                                    <div className="w-full">
                                        <Input
                                            {...lensSphereProps}
                                            furtherBaseInputClasses={settings.distanceCorrection && isLensSphereChanged ? "" : `${isLensSphereChanged && settings.startValueLensSphere != Number(lensSphereProps.value) ? "" : "!text-grey3 hover:!text-grey4 !font-regular hover:!font-medium"} `}
                                            controlType={InputControlType.ArrowHorizontal}
                                            placeholder={"0,0"}
                                            width={TailwindStandardWidth.FULL}
                                            label={
                                                <BodyText
                                                    type={BodyTextType.B1Regular}
                                                    text={t("spherical_dpt")}
                                                    furtherClasses={`!mb-0 text-sm text-grey6`}
                                                />
                                            }
                                            showStateText={false}
                                            state={InputState.Default}
                                            stateText={""}
                                            name={""}
                                            nameSpace={""}
                                            inputValidator={{} as InputValidator}
                                        />
                                    </div>
                                    {resetButton(!isLensSphereChanged, !isLensCylinderChanged, !isLensAxialLengthChanged, settings.startValueLensSphere, "lensSphere")}
                                </div>
                                <div className="flex row gap-3">
                                    <div className="w-full">
                                        <Input
                                            {...lensCylinderProps}
                                            furtherBaseInputClasses={settings.distanceCorrection && isLensCylinderChanged ? "" : `${isLensCylinderChanged && settings.startValueLensCylinder != Number(lensCylinderProps.value) ? "" : "!text-grey3 hover:!text-grey4 !font-regular hover:!font-medium"} `}
                                            controlType={InputControlType.ArrowHorizontal}
                                            width={TailwindStandardWidth.FULL}
                                            placeholder={"0,0"}
                                            label={
                                                <BodyText
                                                    type={BodyTextType.B1Regular}
                                                    text={"Zylindrische dpt"}
                                                    furtherClasses={`!mb-0 text-sm text-grey6 mt-4`}
                                                />
                                            }
                                            showStateText={false}
                                            state={InputState.Default}
                                            stateText={""}
                                            name={""}
                                            nameSpace={""}
                                            inputValidator={{} as InputValidator}
                                        />
                                    </div>
                                    {resetButton(!isLensSphereChanged, !isLensCylinderChanged, !isLensAxialLengthChanged, settings.startValueLensCylinder, "lensCylinder")}
                                </div>
                                <div className="flex row gap-3">
                                    <div className="w-full">
                                        <Input
                                            {...lensAxialLengthProps}
                                            furtherBaseInputClasses={settings.distanceCorrection && isLensAxialLengthChanged ? "" : `${isLensAxialLengthChanged && settings.startValueLensAxialLength != Number(lensAxialLengthProps.value) ? "" : "!text-grey3 hover:!text-grey4 !font-regular hover:!font-medium"} `}
                                            controlType={InputControlType.ArrowHorizontal}
                                            width={TailwindStandardWidth.FULL}
                                            placeholder={"0,0"}
                                            label={
                                                <BodyText
                                                    type={BodyTextType.B1Regular}
                                                    text={t("Axis_position_in_degrees")}
                                                    furtherClasses={`!mb-0 text-sm text-grey6 mt-4`}
                                                />
                                            }
                                            showStateText={false}
                                            state={InputState.Default}
                                            stateText={""}
                                            name={""}
                                            nameSpace={""}
                                            inputValidator={{} as InputValidator}
                                        />
                                    </div>
                                    {resetButton(!isLensSphereChanged, !isLensCylinderChanged, !isLensAxialLengthChanged, settings.startValueLensAxialLength, "AxialLenght")}
                                </div>
                            </div>
                        </div>
                        <div className="mt-19 mb-3 flex flex-col justify-between">
                            <div
                                className={`${isLensSphereChanged || !settings.distanceCorrection || settings.lensCorrection ? "invisible" : ""} `}
                            >
                                <DistanceCorrectionArrow/>
                            </div>
                            <div
                                className={`${isLensCylinderChanged || !settings.distanceCorrection || settings.lensCorrection ? "invisible" : ""} `}>
                                <DistanceCorrectionArrow/>
                            </div>
                            <div
                                className={`${isLensAxialLengthChanged || !settings.distanceCorrection || settings.lensCorrection ? "invisible" : ""} `}>
                                <DistanceCorrectionArrow/>
                            </div>
                        </div>
                        {(settings.lensCorrection || settings.distanceCorrection) && (
                            <div className="flex-1 ml-7 col-start-9 col-span-3  mb-0.5">
                                <Headline
                                    size={HeadlineSize.H4}
                                    text={t("far_correction")}
                                    furtherClasses={`!mb-3 ${

                                        settings.distanceCorrection &&
                                        !settings.lensCorrection
                                            ? "text-blue1"
                                            : ""}`
                                    }
                                />
                                <div className="gap-4">
                                    <Input
                                        {...distanceSphereProps}
                                        controlType={InputControlType.ArrowHorizontal}
                                        placeholder={"0,0"}
                                        width={TailwindStandardWidth.FULL}
                                        disabled={settings.lensCorrection || isLensSphereChanged || isLensAxialLengthChanged || isLensCylinderChanged}
                                        label={
                                            <BodyText
                                                type={BodyTextType.B1Regular}
                                                text={t("spherical_dpt")}
                                                furtherClasses={`!mb-0 text-sm ${settings.lensCorrection || isLensSphereChanged || isLensAxialLengthChanged || isLensCylinderChanged ? "text-grey3" : "text-grey6"}`}
                                            />
                                        }
                                        showStateText={false}
                                        state={InputState.Default}
                                        stateText={""}
                                        name={""}
                                        nameSpace={""}
                                        inputValidator={{} as InputValidator}
                                    />
                                    <Input
                                        {...distanceCylinderProps}
                                        controlType={InputControlType.ArrowHorizontal}
                                        width={TailwindStandardWidth.FULL}
                                        disabled={settings.lensCorrection || isLensSphereChanged || isLensAxialLengthChanged || isLensCylinderChanged}
                                        placeholder={"0,0"}
                                        label={
                                            <BodyText
                                                type={BodyTextType.B1Regular}
                                                text={t("cylindrical_dpt")}
                                                furtherClasses={`!mb-0 !mt-4 text-sm ${settings.lensCorrection || isLensSphereChanged || isLensAxialLengthChanged || isLensCylinderChanged ? "text-grey3" : "text-grey6"}`}
                                            />
                                        }
                                        showStateText={false}
                                        state={InputState.Default}
                                        stateText={""}
                                        name={""}
                                        nameSpace={""}
                                        inputValidator={{} as InputValidator}
                                    />
                                    <Input
                                        {...distanceAxialLengthProps}
                                        controlType={InputControlType.ArrowHorizontal}
                                        width={TailwindStandardWidth.FULL}
                                        disabled={settings.lensCorrection || isLensSphereChanged || isLensAxialLengthChanged || isLensCylinderChanged}
                                        placeholder={"0,0"}
                                        label={
                                            <BodyText
                                                type={BodyTextType.B1Regular}
                                                text={t("Axis_position_in_degrees")}
                                                furtherClasses={`!mb-0 !mt-4 text-sm ${settings.lensCorrection || isLensSphereChanged || isLensAxialLengthChanged || isLensCylinderChanged ? "text-grey3" : "text-grey6"}`}
                                            />
                                        }
                                        showStateText={false}
                                        state={InputState.Default}
                                        stateText={""}
                                        name={""}
                                        nameSpace={""}
                                        inputValidator={{} as InputValidator}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={"col-span-12"}>
                        <Headline size={HeadlineSize.H4} text={"Patient Zentrieren"} furtherClasses={"mt-6"}/>
                        <BodyText text={t("step_4_paragraph_2")} type={BodyTextType.B1Regular}
                                  furtherClasses={"text-grey5 !mb-6"}/>
                        <Headline size={HeadlineSize.H4} text={"Sicht prüfen"}/>
                        <BodyText text={t("step_4_paragraph_3")} type={BodyTextType.B1Regular}
                                  furtherClasses={"text-grey5 !mb-0"}/>

                    </div>
                </>)}
        </ConfigurationLayout>
    );
};
export default FourthStep;
